import {Injectable} from '@angular/core'
import {MsalService} from '@azure/msal-angular';

import {AuthService} from './services/auth.service'
import {CaseWorkerAuthorizeService} from './services/caseWorkerAuthorizeService';

@Injectable()
export class ExternalRouter {
  private keepSession = false
  private showLeavePagePrompt = true;

  constructor(
       private authService: AuthService,
       private window: Window,
       private msalService: MsalService,
       private caseWorkerAuthorizeService: CaseWorkerAuthorizeService) {
    (this.window as any).addEventListener('beforeunload', (event) => {
      if(!this.showLeavePagePrompt){
        return;
      }
      if (!this.keepSession) {
        event.preventDefault();
        (event || (this.window as any).event).returnValue = null;
      }
    })

    this.window.addEventListener('pagehide', () => {
      if (!this.keepSession) {
        this.authService.scheduleLogout();
        if (this.caseWorkerAuthorizeService.isAuthorized()) {
          this.caseWorkerAuthorizeService.setClaims(null);
          this.msalService.logout();
        }
      }
    });
  }

  navigate(url: string, askToLeave = true) {
    this.showLeavePagePrompt = askToLeave;
    this.window.location.href = url;
  }

  navigateWithSession(url: string) {
    this.keepSession = true;
    this.navigate(url);
  }

  /**
   * Authenticate user against Azure AD using MSAL for Angular library
   * visit: https://aka.ms/msal-overview & https://www.npmjs.com/package/@azure/msal-angular/v/3.0.4
   * Note: Previously-entered application data will be cleared when the case-worker flow browser-tab is closed.
   */
  loginRedirect(){
    this.msalService.instance.initialize().then(() => {  // Needs to be initialized before making any MSAL call for v3.
      this.msalService.instance.handleRedirectPromise()
        .then((tokenResponse) => {
          if (!tokenResponse) {
            const accounts = this.msalService.instance.getAllAccounts();
            if (accounts.length === 0) {
              // Not authenticated yet
              console.log('calling msalService.loginRedirect ');
              this.msalService.loginRedirect();
            }
          }
        })
        .catch(err => {
          // log error
          console.error('MSAL loginRedirect error', err);
        });
    }, (err) => {
      // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md for details.
      console.error('MSAL initialize error', err);
    })
  }
}
