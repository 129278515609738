// Page elements
export const FormMapping = {
  SELECT_SPONSORED_PERSON_ELEMENT: 'selectSponsoredPerson',
  SPONSOR_DETAIL_PANEL_ELEMENT: 'sponsorDetailPanel',  //  For single person
  SAME_SPONSOR_DETAIL_PANEL_ELEMENT: 'sameSponsorDetailPanel',  // For multiple persons
  DIFFERENT_SPONSOR_DETAIL_PANEL_ELEMENT: 'diffSponsorDetailPanel',  // For multiple persons
  PANELS_ELEMENT: 'panels',
}

// Common Page and appData attributes
export const FormDataMapping = {
  Common: {
    COMMON_SPONSOR_FIRST_NAME: 'commonSponsorFirstName',
    COMMON_SPONSOR_LAST_NAME: 'commonSponsorLastName',
    COMMON_SPONSOR_NO_LAST_NAME: 'commonSponsorNoLastName',
  },
  Sponsor: {
    SPONSOR_FIRST_NAME: 'sponsorFirstName',
    SPONSOR_LAST_NAME: 'sponsorLastName',
    SPONSOR_NO_LAST_NAME: 'sponsorNoLastName',
    SPONSOR_SUPPORT_AMOUNT: 'sponsorSupportAmount',
    SPONSOR_LIVES_WITH: 'sponsorLivesWith',
    SPONSOR_END_DATE: 'sponsorEndDate'
  },
  Root: {
    ALL_HAVE_SAME_SPONSOR: 'allHaveSameSponsor',
    ANYONE_SPONSORED_CHECKBOX: 'anyoneSponsoredCheckbox',
    ANYONE_SPONSORED_LIST: 'anyoneSponsoredList',
  }
}
