import {FormToAppDataBase} from '../../../common/utils/converters/form-to-app-data-base';
import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {Injectable} from '@angular/core';

@Injectable()
export class FormToFamilyInformationData extends FormToAppDataBase
  implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
   this.convertSocialAssistanceInPast(form, appData, questions);
   this.convertDisabilityProgram(form, appData, questions);
   this.convertLivingInInstitution(form, appData, questions);
   this.convertCurrentlyIncarcerated(form, appData, questions);
   this.convertFamilyAccommodationServices(form,appData,questions);
   this.convertSpecialDiet(form, appData, questions);
   this.convertReceivingACSDQuestions(form, appData, questions)
   this.convertMoneyForImmediateNeed(form, appData, questions);
   this.convertAdditionalNutritionalNeedsQuestions(form, appData, questions);
   this.convertFullTimeStudentQuestions(form, appData, questions);
   this.setValueFromForm(form, appData, 'caringForChild');
  }

  private convertSocialAssistanceInPast(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'familyReceivedSocialAssistanceInPast');
    if (form.get('familyReceivedSocialAssistanceInPast')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'receivedSocialAssistanceInPastPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'receivedSocialAssistanceInPastPanel',
        'receivedSocialAssistanceInPastCheckBox', 'socialAssistanceRecipients');

      // populate details list
      this.populateDetailsList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'receivedSocialAssistanceInPastPanel',
        'receivedSocialAssistanceInPastCheckBox', 'socialAssistanceDetailsList',
        [{name: 'receivedSocialAssistanceProgram'}, {name: 'applicationDate'}, {name: 'memberID'}]);
    } else {
      delete appData.jsonData.receivedSocialAssistanceInPastCheckBox;
      delete appData.jsonData.socialAssistanceDetailsList;
    }
  }

  private convertDisabilityProgram(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'personWithDisabilityInFamily');
    if (form.get('personWithDisabilityInFamily')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'disabilityPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'disabilityPanel',
        'personWithDisabilityInFamilyCheckbox', 'personWithDisabilityInFamilyCheckbox');

      // populate details list
      this.populateDetailsList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'disabilityPanel',
        'personWithDisabilityInFamilyCheckbox', 'developmentServicesOntarioList',
        [{name: 'developmentServicesOntario'}]);
    } else {
      delete appData.jsonData.personWithDisabilityInFamilyCheckbox;
      delete appData.jsonData.developmentServicesOntarioList;
    }
  }

  private convertLivingInInstitution(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'familyMembersResidingInInstitution');
    if (form.get('familyMembersResidingInInstitution')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'livingInAnInstitutionPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'livingInAnInstitutionPanel',
        'familyMembersResidingInInstitutionCheckbox', 'familyMembersResidingInInstitutionCheckbox');

      // populate details list
      this.populateDetailsList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'livingInAnInstitutionPanel',
        'familyMembersResidingInInstitutionCheckbox', 'familyMembersResidingInInstitutionList',
        [{name: 'institutionLocation'}, {name: 'institutionName'}, {name: 'stayPartTimeOrFullTime'}]);
    } else {
      delete appData.jsonData.familyMembersResidingInInstitutionCheckbox;
      delete appData.jsonData.familyMembersResidingInInstitutionList;
    }
  }

  private convertCurrentlyIncarcerated(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'familyMembersCurrentlyIncarcerated');
    if (form.get('familyMembersCurrentlyIncarcerated')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'currentlyIncarceratedPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'currentlyIncarceratedPanel',
        'familyMembersCurrentlyIncarceratedCheckbox', 'familyMembersCurrentlyIncarceratedCheckbox');

      // populate details list
      this.populateDetailsList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'currentlyIncarceratedPanel',
        'familyMembersCurrentlyIncarceratedCheckbox', 'familyMembersCurrentlyIncarceratedList',
        [{name: 'expectedReleaseDate'}, {name: 'stayPartTimeOrFullTime'}]);
    } else {
      delete appData.jsonData.familyMembersCurrentlyIncarceratedCheckbox;
      delete appData.jsonData.familyMembersCurrentlyIncarceratedList;
    }
  }

  private convertFamilyAccommodationServices(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'additionalFamilyAccommodationServices');
    if (form.get('additionalFamilyAccommodationServices')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'accommodationServicesPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'accommodationServicesPanel',
        'familyAccommodationServicesCheckbox', 'familyAccommodationServicesCheckbox');

      // populate details list
      this.populateDetailsList(form, appData, panelQuestion && panelQuestion.panels &&
        panelQuestion.panels[0].questions || [],
        'accommodationServicesPanel',
        'familyAccommodationServicesCheckbox', 'additionalAccommodationServicesInFamilyList',
        [{name: 'visualCheckbox', type: 'checkbox'}, {name: 'hearingCheckbox', type: 'checkbox'},
          {name: 'verbalCheckbox', type: 'checkbox'}, {name: 'communicationCheckbox', type: 'checkbox'},
          {name: 'mobilityCheckbox', type: 'checkbox'}, {name: 'visualDescription'}, {name: 'hearingDescription'},
          {name: 'verbalDescription'}, {name: 'communicationDescription'}, {name: 'mobilityDescription'}], true);
    } else {
      delete appData.jsonData.familyAccommodationServicesCheckbox;
      delete appData.jsonData.additionalAccommodationServicesInFamilyList;
    }
  }

  private convertSpecialDiet(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'specialDietOrMedicalConditionInFamily');
    if (form.get('specialDietOrMedicalConditionInFamily')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'specialDietOrMedicalConditionPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'specialDietOrMedicalConditionPanel',
        'specialDietOrMedicalConditionInFamilyCheckbox', 'specialDietOrMedicalConditionInFamilyCheckbox');
    } else {
      delete appData.jsonData.specialDietOrMedicalConditionInFamilyCheckbox;
    }
  }

  private convertReceivingACSDQuestions(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'receivingACSDFamily');
    if (form.get('receivingACSDFamily')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'receivingACSDPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'receivingACSDPanel',
        'receivingACSDFamilyCheckbox', 'receivingACSDFamilyCheckbox');
    } else {
      delete appData.jsonData.receivingACSDFamilyCheckbox;
    }
  }

  private convertMoneyForImmediateNeed(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'moneyForImmediateNeedInFamily');
    if (form.get('moneyForImmediateNeedInFamily')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'moneyForImmediateNeedPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
          'moneyForImmediateNeedPanel',
          'moneyForImmediateNeedInFamilyCheckbox', 'moneyForImmediateNeedInFamilyCheckbox');
    } else {
      delete appData.jsonData.moneyForImmediateNeedInFamilyCheckbox;
    }
  }

  private convertAdditionalNutritionalNeedsQuestions(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'pregnantOrBreastFeedingInFamily');
    if (form.get('pregnantOrBreastFeedingInFamily')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'nutritionalNeedsPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'nutritionalNeedsPanel',
        'pregnantOrBreastFeedingInFamilyCheckbox', 'pregnantOrBreastFeedingInFamilyCheckbox');

      // populate details list
      this.populateDetailsList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'nutritionalNeedsPanel',
        'pregnantOrBreastFeedingInFamilyCheckbox', 'pregnantOrBreastFeedingInFamilyList',
        [{name: 'pregnantCheckbox', type: 'checkbox'}, {name: 'breastFeedingCheckbox', type: 'checkbox', valueType: 'string'}, {name: 'dueDate'},{name: 'lactoseIntolerantDiet',type:'radiogroup'}]);
    } else {
      delete appData.jsonData.pregnantOrBreastFeedingInFamilyCheckbox;
      delete appData.jsonData.pregnantOrBreastFeedingInFamilyList;
    }
  }

  private convertFullTimeStudentQuestions(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setValueFromForm(form, appData, 'fullTimeStudentInFamily');
    if (form.get('fullTimeStudentInFamily')?.value === 'yes') {
      const panelQuestion = questions.find(q => q.key === 'fullTimeStudentInFamilyPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'fullTimeStudentInFamilyPanel',
        'fullTimeStudentInFamilyCheckbox', 'fullTimeStudentInFamilyCheckbox');

      // populate details list
      this.populateDetailsList(form, appData, panelQuestion && panelQuestion.panels && panelQuestion.panels[0].questions || [],
        'fullTimeStudentInFamilyPanel',
        'fullTimeStudentInFamilyCheckbox', 'fullTimeStudentInFamilyList',
        [{name: 'schoolType'}]);
    } else {
      delete appData.jsonData.fullTimeStudentInFamilyCheckbox;
      delete appData.jsonData.fullTimeStudentInFamilyList;
    }
  }

  private populateCheckBoxList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[],
                               qPanelKey: string, qKey: string, recipientListName: string): void {
    const FORM_GROUP = qPanelKey.concat('.panels.0');
    const recipientList = [];
    const question = questions.find(q => q.key === qKey);
    const applicantOption = question?.options?.find(o => o.label === 'financial-assets.applicant');

    if (this.isApplicantSelected(qKey, form.get(FORM_GROUP) as FormGroup, questions)) {
      recipientList.push(applicantOption?.alternateValue);
    }

    if (this.isSpouseSelected(qKey, form.get(FORM_GROUP) as FormGroup, questions)) {
      recipientList.push(question?.options?.find(o => o.label === 'financial-assets.spouse')?.alternateValue);
    }

    if (appData?.jsonData?.childrenLivingWithYou === 'yes' && appData.jsonData.childList) {
      appData.jsonData.childList.forEach((child: {childFirstName:string}, i: number) => {
        const altValue = 'Child ' + (i+1) + ': ' + child.childFirstName;
        if (this.isChildSelectedByAltValue(altValue, qKey, form.get(FORM_GROUP) as FormGroup, questions)) {
          const c = question?.options?.find(o => o.alternateValue === altValue);
          recipientList.push(c?.alternateValue);
        }
      })
    }

    if (recipientList.length > 0) {
      appData.jsonData[recipientListName] = recipientList;
    }
  }

  private isValueAvailable(form: FormGroup, panel: string, panelFields: PanelFields[]): boolean {
    let isValueAvailable = false;
    panelFields.forEach(panelField => {
      if (form.get(panel)?.value.panels[0][panelField.name]) {
        isValueAvailable = true;
      }
    })

    return isValueAvailable;
  }

  private populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[],
                      qPanelKey: string, qKey: string, detailAttrName: string, panelFields: PanelFields[], specialFlag = false): void {
    const detailList = [];
    const PAYMENT_FORM_GROUP = qPanelKey.concat('.panels.0');
    const APPLICANT_PANEL = qPanelKey.concat('.panels.0.').concat(qKey).concat('PanelApplicant')
    const SPOUSE_PANEL = qPanelKey.concat('.panels.0.').concat(qKey).concat('PanelSpouse')
    const PANEL_CHILD = 'PanelChild';

    if (this.isApplicantSelected(qKey, form.get(PAYMENT_FORM_GROUP) as FormGroup, questions) &&
      (this.isValueAvailable(form, APPLICANT_PANEL, panelFields))) {
      const obj = this.constructDetailObj(form, APPLICANT_PANEL, 'Applicant', appData.jsonData.firstName, panelFields, specialFlag);
      detailList.push(obj)
    }

    if (this.isSpouseSelected(qKey, form.get(PAYMENT_FORM_GROUP) as FormGroup, questions) &&
      this.isValueAvailable(form, SPOUSE_PANEL, panelFields)) {
      const obj = this.constructDetailObj(form, SPOUSE_PANEL, 'Spouse', appData.jsonData.spouseFirstName, panelFields, specialFlag);
      detailList.push(obj);
    }

    // populate children data
    if (appData?.jsonData?.childrenLivingWithYou === 'yes' && appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++) {
        const CHILD_PANEL_KEY = qPanelKey.concat('.panels.0.').concat(qKey.concat(PANEL_CHILD).concat(String(i)));

        if (this.isValueAvailable(form, CHILD_PANEL_KEY, panelFields)) {
          const child = questions.find(q => q.key === qKey.concat(PANEL_CHILD).concat(String(i)));
          const childFirstName = child?.value?.substr(child.value.indexOf(':') + 2);
          if (this.isChildSelected(i, qKey, form.get(PAYMENT_FORM_GROUP) as FormGroup, questions)) {
            const obj = this.constructDetailObj(form, CHILD_PANEL_KEY, 'Child'.concat(' '.concat(String(i))),
              childFirstName, panelFields, specialFlag);
            detailList.push(obj);
          }
        }
      }
    }

    if (detailList.length > 0) {
      appData.jsonData[detailAttrName] = detailList;
    }
  }

  private constructDetailObj(form: FormGroup, panelName: string, applicantTypeStr: string, firstName = '',
                             panelFields: PanelFields[], specialFlag = false) {
    const obj: {[propName: string]: string | string[]} = {
      applicant: applicantTypeStr.concat(': ').concat(firstName),
      applicantType: applicantTypeStr,
      applicantDisplayType: applicantTypeStr,
      applicantName: ' '.concat(firstName),
    };
    panelFields.forEach(panelField => {
      const value = form.get(panelName)?.value?.panels[0][panelField.name];

      if (value) {
        if ((panelField.type === 'checkbox') && specialFlag) {
          if (value[0]) {
            obj[panelField.name] = 'yes'; // Only add to jsonData if user selects checkbox
          }
        }
        else if (panelField.type === 'checkbox') {
          if(panelField.valueType === 'string'){
            obj[panelField.name] = value[0] ? 'yes' : 'no';
          }else{
            obj[panelField.name] = value[0] ? ['yes'] : ['no'];
          }
        } else {
          obj[panelField.name] = value
        }
      }
    })

    return obj;
  }

  private setValueFromForm(form: FormGroup, appData: ApplicationAnswers, fieldName: string) {
    if (form.get(fieldName)?.value) {
      appData.jsonData[fieldName] = form.get(fieldName)?.value;
    } else {
      delete appData.jsonData[fieldName];
    }
  }
}

export interface PanelFields {
  name: string;
  type?: string;
  valueType?: string;
}
