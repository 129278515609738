
export abstract class PageScrollingUtil {

  public static locateElement(queryString: string){
    const element = document.querySelector(queryString) as HTMLElement;
    return element;
  }

  public static scrollToElement(element: HTMLElement, labelOffset = 50) {
    if (element) {
      window.scroll({
        top: this.getTopOffset(element, labelOffset),
        left: 0,
        behavior: 'smooth'
      });

      // Focus on invalid control option, regardless of scroll
      element.focus({preventScroll: true});
    }
  }

  private static getTopOffset(controlEl: HTMLElement, labelOffset: number): number {
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

}
