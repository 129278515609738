import {Component, Input, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'sd-back-to-top-button',
  templateUrl: './back-to-top-button.component.html',
  styleUrls: ['./back-to-top-button.component.scss']
})
export class BackToTopButtonComponent implements OnInit {

  @Input()
  disableScrollEvent: boolean;

  buttonLabel = 'btn.back.to.top';

  bttElement: HTMLElement;

  throttleTime = 50; // ms -- increase the throttle if there are performance issues

  private removeEvent: () => void;

  constructor(private renderer2: Renderer2) { }

  ngOnInit(): void {
    if (!this.disableScrollEvent) {
      this.removeEvent = this.renderer2.listen('window','scroll', this.throttleFn())
      // Using Renderer2 to interact with the DOM, easier to remove the event compared to adding manually
    }
  }

  ngOnDestroy(): void {
    if (!this.disableScrollEvent) {
      this.removeEvent();
    }
  }

  onClick() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 500, { once: true })
  }

  toggleButton(): void {
    if (this.bttElement == null) { // Retrieve the element from DOM only once per page
      this.bttElement = document.getElementById('btt-button');
    }
    this.bttElement.classList.toggle('active', window.scrollY > 200);
  }

  throttleFn() {
    let waiting = false;
    return () => {
      if (waiting) {
        return; // do nothing if waiting
      }
      this.toggleButton(); // otherwise execute fn, now in waiting state
      waiting = true;

      setTimeout(() => { // reset waiting state after the throttle time
        waiting = false;
      }, this.throttleTime)
    }
  }

}

