<div class="form-group" [formGroup]="form">
  <sd-radio-question [showError]="showError" [id]="'income-type-'+applicantType+'-'+parentFormIndex"
                     [required]="true"
                     [choices]="incomeTypes"
                     label="earned-income.income.type"
                     [labelParam]="applicantTypeNameLabelParam"
                     [context]="incomeTypeContext"
                     [controlName]="formControlNames.INCOME_TYPE">
  </sd-radio-question>
  <sd-text-question *ngIf="incomeType === 'fullTime' || incomeType === 'partTime'"
                    [showError]="showError"
                    [id]="'employer-name-'+applicantType+'-'+parentFormIndex"
                    label="earned-income.employer.name"
                    [required]=true
                    [maxLength]=130
                    [validationRegex]="nameCharacterPattern" [validationRegexError]="'error.invalid.nameCharacter'"
                    [minLength]=2
                    [capitalizeFirstLetter]=true
                    hintText="earned-income.hint-text.employer.name"
                    [controlName]="formControlNames.EMPLOYER_NAME">
  </sd-text-question>
  <sd-text-question *ngIf="incomeType === 'selfEmployed'"
                    [showError]="showError"
                    [id]="'business-name-'+applicantType+'-'+parentFormIndex"
                    label="earned-income.business.name"
                    [required]=true
                    [maxLength]=130
                    [minLength]=2
                    [capitalizeFirstLetter]=true
                    [validationRegex]="nameCharacterPattern" [validationRegexError]="'error.invalid.nameCharacter'"
                    hintText="earned-income.hint-text.business.name"
                    [controlName]="formControlNames.BUSINESS_NAME">
  </sd-text-question>
  <sd-text-question *ngIf="incomeType === 'training'"
                    [showError]="showError"
                    [id]="'program-name-'+applicantType+'-'+parentFormIndex"
                    label="earned-income.program.name"
                    [required]=true
                    [maxLength]=130
                    [minLength]=2
                    [capitalizeFirstLetter]=true
                    [validationRegex]="nameCharacterPattern" [validationRegexError]="'error.invalid.nameCharacter'"
                    hintText="earned-income.hint-text.program.name"
                    [controlName]="formControlNames.PROGRAM_NAME">
  </sd-text-question>
  <ng-container *ngIf="incomeType">
    <sd-text-question [showError]="showError"
                      [id]="'monthly-payment-'+applicantType+'-'+parentFormIndex"
                      label="earned-income.monthly.payment"
                      [labelParam]="monthAndYear"
                      [required]=true
                      [isDollarValue]=true
                      [characterWidth]=10
                      hintText="earned-income.hint-text.monthly.payment"
                      [validationRegex]="accountValuePattern" [validationRegexError]="'error.invalid.currency.amount'"
                      [validationFns]="accountValueValidationFns"
                      [isAlert]="isEarnedIncomeMonthSame"
                      [alertMessage]="'earned-income.monthly.payment.alert.message'"
                      [controlName]="formControlNames.PAY">
    </sd-text-question>
    <sd-radio-question [showError]="showError" [id]="'continue-this-income-'+applicantType+'-'+parentFormIndex"
                       label="earned-income.continue.this.income"
                       [labelParam]="applicantTypeNameLabelParam"
                       [context]="continueThisIncomeContextLabel"
                       [required]="true"
                       [controlName]="formControlNames.CONTINUE_RECEIVE_INCOME">
    </sd-radio-question>
    <sd-date [showError]="showError"
             [id]="'date-of-pay-'+applicantType+'-'+parentFormIndex"
             label="earned-income.date.of.pay"
             [controlNameDate]="formControlNames.DATE_OF_FIRST_PAY"
             [validationFns]="dateValidationFns"
             [smallRightMargin]=true
             [needMarginTop] = true
             [required]=true>
    </sd-date>
    <sd-radio-question *ngIf="isDisability" [showError]="showError" [id]="'disability-work-expense-'+applicantType+'-'+parentFormIndex"
                       label="earned-income.disability.work.expense"
                       [required]="true"
                       [labelParam]="applicantTypeNameLabelParam"
                       [context]="disabilityWorkExpenseContext"
                       [controlName]="formControlNames.DISABILITY_WORK_EXPENSE">
    </sd-radio-question>
    <sd-text-question *ngIf="isDisability && isDisabilityWorkExpense" [showError]="showError"
                      [id]="'disability-work-expense-monthly-amount-'+applicantType+'-'+parentFormIndex"
                      label="earned-income.disability.work.expense.monthly.amount"
                      [characterWidth]="10"
                      [required]=true
                      [isDollarValue]=true
                      [validationRegex]="accountValuePattern" [validationRegexError]="'error.invalid.currency.amount'"
                      [validationFns]="disabilityWorkExpenseValueValidationFns"
                      [controlName]="formControlNames.DISABILITY_WORK_EXPENSE_MONTHLY_AMOUNT">
    </sd-text-question>
  </ng-container>
</div>
