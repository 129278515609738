import {Choice} from '../../common/utils/questions/question-choices';

export const MailingDeliveryTypeChoices: Choice[] = [
  {value: 'Standard street address', label: 'address-information.choices.address.type.standard.street.address'},
  {value: 'PO Box', label: 'address-information.choices.address.type.po.box'},
  {value: 'Rural route', label: 'address-information.choices.address.type.rural.route'},
  {value: 'General delivery', label: 'address-information.choices.address.type.general.delivery'}
];

export const HomeDeliveryTypeChoices: Choice[] = [
  {value: 'Standard street address', label: 'address-information.choices.address.type.standard.street.address'},
  {value: 'Rural route', label: 'address-information.choices.address.type.rural.route'}
];

export const StreetNumberSuffixChoices: Choice[] = [
  {value: 'A', label: 'A'},
  {value: 'B', label: 'B'},
  {value: 'C', label: 'C'},
  {value: 'D', label: 'D'},
  {value: 'E', label: 'E'},
  {value: 'F', label: 'F'},
  {value: 'G', label: 'G'},
  {value: 'H', label: 'H'},
  {value: 'I', label: 'I'},
  {value: 'J', label: 'J'},
  {value: 'K', label: 'K'},
  {value: 'L', label: 'L'},
  {value: 'M', label: 'M'},
  {value: 'N', label: 'N'},
  {value: 'O', label: 'O'},
  {value: 'P', label: 'P'},
  {value: 'Q', label: 'Q'},
  {value: 'R', label: 'R'},
  {value: 'S', label: 'S'},
  {value: 'T', label: 'T'},
  {value: 'U', label: 'U'},
  {value: 'V', label: 'V'},
  {value: 'W', label: 'W'},
  {value: 'X', label: 'X'},
  {value: 'Y', label: 'Y'},
  {value: 'Z', label: 'Z'},
  {value: '1/2', label: '1/2'},
  {value: '1/4', label: '1/4'},
  {value: '3/4', label: '3/4'}
];

export const StreetTypeChoices: Choice[] = [
  {value: 'Abbey', label: 'Abbey'},
  {value: 'Acres', label: 'Acres'},
  {value: 'Alley', label: 'Alley'},
  {value: 'Avenue', label: 'Avenue'},
  {value: 'Bay', label: 'Bay'},
  {value: 'Beach', label: 'Beach'},
  {value: 'Bend', label: 'Bend'},
  {value: 'Boulevard', label: 'Boulevard'},
  {value: 'By-pass', label: 'By-pass'},
  {value: 'Byway', label: 'Byway'},
  {value: 'Campus', label: 'Campus'},
  {value: 'Centre', label: 'Centre'},
  {value: 'Circle', label: 'Circle'},
  {value: 'Close', label: 'Close'},
  {value: 'Common', label: 'Common'},
  {value: 'Concession', label: 'Concession'},
  {value: 'Corners', label: 'Corners'},
  {value: 'Court', label: 'Court'},
  {value: 'Cove', label: 'Cove'},
  {value: 'Crescent', label: 'Crescent'},
  {value: 'Crossing', label: 'Crossing'},
  {value: 'Dale', label: 'Dale'},
  {value: 'Dell', label: 'Dell'},
  {value: 'Diversion', label: 'Diversion'},
  {value: 'Downs', label: 'Downs'},
  {value: 'Drive', label: 'Drive'},
  {value: 'End', label: 'End'},
  {value: 'Esplanade', label: 'Esplanade'},
  {value: 'Estates', label: 'Estates'},
  {value: 'Expressway', label: 'Expressway'},
  {value: 'Extension', label: 'Extension'},
  {value: 'Farm', label: 'Farm'},
  {value: 'Field', label: 'Field'},
  {value: 'Freeway', label: 'Freeway'},
  {value: 'Front', label: 'Front'},
  {value: 'Gardens', label: 'Gardens'},
  {value: 'Gate', label: 'Gate'},
  {value: 'Glade', label: 'Glade'},
  {value: 'Glen', label: 'Glen'},
  {value: 'Green', label: 'Green'},
  {value: 'Grounds', label: 'Grounds'},
  {value: 'Grove', label: 'Grove'},
  {value: 'Harbour', label: 'Harbour'},
  {value: 'Heath', label: 'Heath'},
  {value: 'Heights', label: 'Heights'},
  {value: 'Highlands', label: 'Highlands'},
  {value: 'Highway', label: 'Highway'},
  {value: 'Hill', label: 'Hill'},
  {value: 'Hollow', label: 'Hollow'},
  {value: 'Inlet', label: 'Inlet'},
  {value: 'Island', label: 'Island'},
  {value: 'Key', label: 'Key'},
  {value: 'Knoll', label: 'Knoll'},
  {value: 'Landing', label: 'Landing'},
  {value: 'Lane', label: 'Lane'},
  {value: 'Limits', label: 'Limits'},
  {value: 'Line', label: 'Line'},
  {value: 'Link', label: 'Link'},
  {value: 'Lookout', label: 'Lookout'},
  {value: 'Loop', label: 'Loop'},
  {value: 'Mall', label: 'Mall'},
  {value: 'Manor', label: 'Manor'},
  {value: 'Maze', label: 'Maze'},
  {value: 'Meadow', label: 'Meadow'},
  {value: 'Mews', label: 'Mews'},
  {value: 'Moor', label: 'Moor'},
  {value: 'Mount', label: 'Mount'},
  {value: 'Mountain', label: 'Mountain'},
  {value: 'Orchard', label: 'Orchard'},
  {value: 'Parade', label: 'Parade'},
  {value: 'Park', label: 'Park'},
  {value: 'Parkway', label: 'Parkway'},
  {value: 'Passage', label: 'Passage'},
  {value: 'Path', label: 'Path'},
  {value: 'Pathway', label: 'Pathway'},
  {value: 'Pines', label: 'Pines'},
  {value: 'Place', label: 'Place'},
  {value: 'Plaza', label: 'Plaza'},
  {value: 'Point', label: 'Point'},
  {value: 'Port', label: 'Port'},
  {value: 'Private', label: 'Private'},
  {value: 'Promenade', label: 'Promenade'},
  {value: 'Quai', label: 'Quai'},
  {value: 'Quay', label: 'Quay'},
  {value: 'Ramp', label: 'Ramp'},
  {value: 'Rang', label: 'Rang'},
  {value: 'Range', label: 'Range'},
  {value: 'Ridge', label: 'Ridge'},
  {value: 'Rise', label: 'Rise'},
  {value: 'Road', label: 'Road'},
  {value: 'Row', label: 'Row'},
  {value: 'Run', label: 'Run'},
  {value: 'Square', label: 'Square'},
  {value: 'Street', label: 'address-information.choices.street.type.street'},
  {value: 'Subdivision', label: 'Subdivision'},
  {value: 'Terrace', label: 'Terrace'},
  {value: 'Thicket', label: 'Thicket'},
  {value: 'Towers', label: 'Towers'},
  {value: 'Townline', label: 'Townline'},
  {value: 'Trail', label: 'Trail'},
  {value: 'Turnabout', label: 'Turnabout'},
  {value: 'Vale', label: 'Vale'},
  {value: 'Via', label: 'Via'},
  {value: 'View', label: 'View'},
  {value: 'Village', label: 'Village'},
  {value: 'Villas', label: 'Villas'},
  {value: 'Vista', label: 'Vista'},
  {value: 'Walk', label: 'Walk'},
  {value: 'Way', label: 'Way'},
  {value: 'Wharf', label: 'Wharf'},
  {value: 'Wood', label: 'Wood'},
  {value: 'Wynd', label: 'Wynd'}
];

export const DirectionChoices: Choice[] = [
  {value: 'East', label: 'address-information.choices.direction.east'},
  {value: 'North', label: 'address-information.choices.direction.north'},
  {value: 'Northeast', label: 'address-information.choices.direction.northeast'},
  {value: 'Northwest', label: 'address-information.choices.direction.northwest'},
  {value: 'South', label: 'address-information.choices.direction.south'},
  {value: 'Southeast', label: 'address-information.choices.direction.southeast'},
  {value: 'Southwest', label: 'address-information.choices.direction.southwest'},
  {value: 'West', label: 'address-information.choices.direction.west'}
];

export const ProvinceChoices: Choice[] = [
  {value: 'Ontario', label: 'choices.province.ontario'},
  {value: 'Alberta', label: 'choices.province.alberta'},
  {value: 'British Columbia', label: 'choices.province.britishcolumbia'},
  {value: 'Manitoba', label: 'choices.province.manitoba'},
  {value: 'New Brunswick', label: 'choices.province.newbrunswick'},
  {value: 'Newfoundland and Labrador', label: 'choices.province.newfoundlandlabrador'},
  {value: 'Northwest Territories', label: 'choices.province.northwestterritories'},
  {value: 'Nova Scotia', label: 'choices.province.novascotia'},
  {value: 'Nunavut', label: 'choices.province.nunavut'},
  {value: 'Prince Edward Island', label: 'choices.province.princeedwardisland'},
  {value: 'Quebec', label: 'choices.province.quebec'},
  {value: 'Saskatchewan', label: 'choices.province.saskatchewan'},
  {value: 'Yukon', label: 'choices.province.yukon'}
];
