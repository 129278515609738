import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {ConfigService} from './config.service';
import {StorageService} from './storage.service';
import {ValidateRedirectTokenResponse} from '../models/validate-redirect-token-response';

import {ValidateTokenAndInitializeSessionRequest} from '../models/validate-token-and-initialize-session-request';
import {ValidateEmailTokenAndInitializeSessionRequest} from '../models/validate-email-token-and-initialize-session-request';
import {PsRedirectUrlRequest} from '../models/ps-redirect-url-request';


@Injectable({
  providedIn: 'root'
})
export class AuthServiceImpl implements AuthService {
  private authorizedToSave = false;

  private activeSessionExists = false;

  private userName: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // Kept at service level instead of localstorage to handle multi tab scenarios between authenticated and anonymous applications
  private accessToken: string;

  private myBApplicant: boolean;

  constructor(private storageService: StorageService,
              private http: HttpClient,
              public configService: ConfigService) {
  }

  getAuthToken(): string {
    return this.storageService.getAuthToken()
  }

  checkLoggedIn() {
    const url = location.href
    if (url.indexOf('id_token=') >= 0) {
      const authToken = url.split('id_token=')[1].split('&')[0]
      this.storageService.saveAuthToken(authToken)
    }
  }

  isLoggedIn() {
    return !!this.storageService.getAuthToken();
  }

  login(pathname = ''): void {
    location.href = `${this.configService.getClientConfig()?.ssoUrl}/oauth/authorize?response_type=id_token
        &client_id=${this.configService.getClientConfig()?.clientId}
        &redirect_uri=${this.configService.getClientConfig()?.redirectUrl}${pathname.trim() || location.pathname}`
  }

  logout(): Observable<object> {
    this.storageService.removeAuthToken();
    this.resetAuthValues();
    return this.http.post(`/v1/logout`,{}, {withCredentials: true});
  }

  /**
   * This method clears the auth-token from local-storage, the local auth values and returns the URL to logout all sessions from PSE.
   * @param redirectToUrl The URL to redirect to after the PS logging out process is completed.
   */
  psLogout(redirectToUrl: string): Observable<any> {
    this.storageService.removeAuthToken();
    this.resetAuthValues();
    if (!!redirectToUrl) {
      const request = {redirectToUrl};
      return this.http.post(`/v1/auth/logout`, request, {responseType: 'text', withCredentials: true});
    }
  }

  scheduleLogout(): void {
    navigator.sendBeacon(`/v1/logout`);
  }

  validateRedirectTokenAndInitializeSession(token: string, isNew: string, lang: string): Observable<boolean> {
    const request: ValidateTokenAndInitializeSessionRequest = {token, startNew: isNew === 'yes', lang};
    return this.http.post<ValidateRedirectTokenResponse>(`/v1/auth/redirect/token/validate`, request, {
        withCredentials: true
      }
    ).pipe(
      map(response => {
        const psEmail = response.psEmail;
        const accessToken = response.accessToken;
        this.setUserName(psEmail);
        this.setAccessToken(accessToken);
        this.authorizedToSave = !!accessToken; // authorized to save if access token is returned
        this.myBApplicant = response.myBApplicant;
        return !!accessToken
      }) // map string accessToken response to boolean
    );
  }

  validateEmailTokenAndInitializeSession(token: string): Observable<boolean> {
    const request: ValidateEmailTokenAndInitializeSessionRequest = {token};
    return this.http.post<ValidateRedirectTokenResponse>(`/v1/auth/email/token/validate`, request, {
      withCredentials: true}
    ).pipe(
      map(response => {
        const psEmail = response.psEmail;
        const accessToken = response.accessToken;
        this.setUserName(psEmail);
        this.setAccessToken(accessToken)
        this.authorizedToSave = !!accessToken; // authorized to save if access token is returned
        this.myBApplicant = response.myBApplicant;
        return !!accessToken
      }) // map string accessToken response to boolean
    );
  }

  isAuthorizedToSave(): boolean {
    return this.authorizedToSave;
  }

  setAuthorizedToSave(authorizedToSave: boolean) {
    this.authorizedToSave = authorizedToSave;
  }

  isActiveSessionExists(): boolean {
    return this.activeSessionExists;
  }

  setActiveSessionExists(activeSessionExists: boolean) {
    this.activeSessionExists = activeSessionExists;
  }

  getPSRedirectURL(email: string, lang: string): Observable<string> {
    const request: PsRedirectUrlRequest = {email, lang};
    return this.http.post(`/v1/auth/url`, request, {responseType: 'text', withCredentials: true});
  }

  resetApplication(lang: string): Observable<null> {
    return this.http.post<null>(`/v1/auth/reset-application`, {withCredentials: true},{
      params: {lang}
    });
  }

  mockLogin(email: string): Observable<URL> {
    return this.http.get<URL>(`/v1/mock/auth/mock-login`, {params: {email}, withCredentials: true});
  }

  getUserName(): Observable<string> {
    return this.userName.asObservable();
  }

  setUserName(userName: string): void {
    this.userName.next(userName);
  }

  getAccessToken(): string {
    return this.accessToken;
  }

  setAccessToken(token: string): void {
    this.accessToken = token;
  }

  isMyBApplicant(): boolean {
    return this.myBApplicant;
  }

  resetAuthValues() {
    this.setActiveSessionExists(false);
    this.setAuthorizedToSave(false);
    this.setUserName('');
    this.setAccessToken('');
    this.myBApplicant = false;
  }
}
