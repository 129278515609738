import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {HHIDataConversionConfig} from '../model/hhi-data-conversion-config';
import {BaseHHIFormToData} from './base-hhi-form-to-data';

export class HHIOtherIncomeFormData extends BaseHHIFormToData {
  static readonly PAYMENT_PANEL_CHILD = 'WhoGetsThePaymentPanelChild';

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[],
          dataConversionConfig: HHIDataConversionConfig): void {
    super.convert(form, appData, questions, dataConversionConfig)
  }

  populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[],
                      dataConversionConfig: HHIDataConversionConfig): void {
    const paymentDetailList = [];
    const qKey = dataConversionConfig.questionKey;
    const qPanelKey = dataConversionConfig.questionPanelKey;
    const PAYMENT_FORM_GROUP = qPanelKey.concat('.panels.0');
    const APPLICANT_PANEL = qPanelKey.concat('.panels.0.').concat(qKey).concat('WhoGetsThePaymentPanelApplicant')
    const SPOUSE_PANEL = qPanelKey.concat('.panels.0.').concat(qKey).concat('WhoGetsThePaymentPanelSpouse')

    if (this.isApplicantSelected(qKey.concat('WhoGetsThePayment'), form.get(PAYMENT_FORM_GROUP) as FormGroup, questions) &&
            form.get(APPLICANT_PANEL)?.value.panels) {
      paymentDetailList.push(this.getIncomeInfo('Applicant', appData.jsonData.firstName, APPLICANT_PANEL, form))
    }

    if (this.isSpouseSelected(qKey.concat('WhoGetsThePayment'),
      form.get(PAYMENT_FORM_GROUP) as FormGroup, questions) && form.get(SPOUSE_PANEL)?.value.panels) {
      paymentDetailList.push(this.getIncomeInfo('Spouse', appData.jsonData.spouseFirstName, SPOUSE_PANEL, form))
    }

    // populate children data
    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++) {
        const CHILD_PANEL_KEY = qPanelKey.concat('.panels.0.')
          .concat(qKey.concat(HHIOtherIncomeFormData.PAYMENT_PANEL_CHILD).concat(String(i)));
        const childPanel = form.get(CHILD_PANEL_KEY);

        if (childPanel?.value.panels) {
          const child = questions.find(
            q => q.key === qKey.concat(HHIOtherIncomeFormData.PAYMENT_PANEL_CHILD).concat(String(i)));

          if (this.isChildSelected(i, qKey.concat('WhoGetsThePayment'),
            form.get(PAYMENT_FORM_GROUP) as FormGroup, questions)) {
            paymentDetailList.push(this.getIncomeInfo('Child'.concat(' '.concat(String(i))),
              child.value.substr(child.value.indexOf(':') + 2), CHILD_PANEL_KEY, form))
          }
        }
      }
    }

    if (paymentDetailList.length > 0) {
      appData.jsonData[dataConversionConfig.incomeDetailAttributeName] = paymentDetailList;
    }
  }

  private getIncomeInfo(applicantTypeStr: string, firstName: string, panelName: string, form: FormGroup) {
    const spousePaymentDetail = {
      applicant: applicantTypeStr.concat(': ').concat(firstName),
      applicantType: applicantTypeStr,
      applicantDisplayType: applicantTypeStr,
      applicantName: ' '.concat(firstName),
      otherIncomeOtherPaymentList: []
    }

    form.get(panelName)?.value.panels.forEach(panel => {
      const incomeSourceDetail: any = {
        otherIncomeSourceReceiving: panel.tryingOrReceivingThisIncome,
        otherIncomeType: panel.otherIncomeType,
        otherIncomeDescription: !!panel.otherIncomeDesc ? panel.otherIncomeDesc : panel.otherIncomeType,
      }
      if (panel.monthlyAmount) {
        incomeSourceDetail.otherIncomeSourceMonthlyAmount = panel.monthlyAmount
      }

      if (panel.annualAmount) {
        incomeSourceDetail.otherIncomeSourceAnnualAmount = panel.annualAmount
      }

      if (panel.totalValueOfPension) {
        incomeSourceDetail.otherIncomeValueOfPension = panel.totalValueOfPension
      }
      spousePaymentDetail.otherIncomeOtherPaymentList.push(incomeSourceDetail)
    })

    return spousePaymentDetail;
  }
}
