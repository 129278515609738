<sd-info-text header="demographic-information.landing.page.alert.title" content="demographic-information.landing.page.alert.content" contentId="pageAlertContentId"></sd-info-text>
<h1 data-e2e="pageTitle" id="{{pageId}}.page.title" class="ontario-h1" aria-level="1" tabindex="0">{{pageId + ".page.title" | translate}}</h1>
<div *ngFor="let applicant of applicants; let lastItem = last">
  <div class="row my-5">
    <div class="col row person-row">
      <div class="ontario-h4 col-8 word-text-wrap">{{getApplicantLabel(applicant.type) | translate: {name: applicant.name, index: applicant.childIndex + 1} }}</div>
      <div class="status col-4 d-flex justify-content-end">
        <app-mccss-submit-button *ngIf="!applicant.wdaComplete" buttonLabel="btn.start" [removeMargin]="true" [preventDoubleClick]="true" (handleClick)="onStartClicked(applicant)"></app-mccss-submit-button>
        <div *ngIf="applicant.wdaComplete" class="wda-status">
          <div class="wda-status">
            <svg class="ontario-icon checkmark-img" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" tabindex="-1">
              <use href="#ontario-icon-alert-success"></use>
            </svg>
            <span><strong>{{'demographic-information.landing.completed' | translate}}</strong></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!lastItem" class="divider" aria-hidden="true"></div>
</div>

<app-mccss-submit-button dataE2E="wdaContinueBtn" buttonLabel="btn.continue" [preventDoubleClick]="true" (handleClick)="onSubmit()" [hidden]="hideContinueBtn"></app-mccss-submit-button>
