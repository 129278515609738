<div class="{{formGroupClass}}" [ngClass]="{'required': question.required}" [formGroup]="form">
  <div [class.single-checkbox-borders]="question.checkboxBorders">
    <fieldset class="ontario-fieldset" [ngClass]="backgroundClasses" [attr.aria-label]="getAriaLabel()">
      <div *ngIf="question.label && !question.skipContainerLabel" [ngClass]="{'remove-margin': question.removeLabelMargin}" class="ontario-margin-bottom-16-!">
        <span *ngIf="question.displayLabelNormal" class="ontario-label-normal" id="{{id}}"><span innerHTML="{{question.label | translate:labelParams}}" ></span><span *ngIf="!question.labelOnly" class="ontario-label__flag">{{(question.required === false ? 'Optional' : 'Required') | translate}}</span></span>
        <label *ngIf="!question.displayLabelNormal" class="ontario-label ontario-margin-bottom-0-!" id="{{id}}" [attr.aria-hidden]="question.controlType==='radio' ? true: null" attr.for="{{getForAttr()}}">
          <span innerHTML="{{question.label | translate:labelParams}}"></span><span *ngIf="!question.labelOnly" class="ontario-label__flag">{{(question.required === false ? 'Optional' : 'Required') | translate}}</span>
        </label>
        <label *ngIf="question.hintText?.boldHintLabel" [ngClass]="{'remove-margin': question.hintText.removeHintLabelMargin}" class="ontario-label" id="{{id}}-hintLabel" [attr.aria-hidden]="'true'">{{question.hintText.boldHintLabel | translate}}</label>
        <div *ngIf="question.subLabelList">
          <label id="{{id}}-subLabel" [attr.aria-hidden]="question.ariaHideSubLabelList ? true: undefined">
            <sd-text-list [list]="question.subLabelList"></sd-text-list>
          </label>
        </div>
        <div *ngIf="question.displayCharsRemaining">
          <div class="ontario-margin-bottom-8-!"></div>
          <sd-hint-text id="{{id}}-question-hintText"
                        [noMarginBottom]="true"
                        [occurrence]="panelIndex"
                        [text]="'chars-remaining' | translate:{charsRemaining}">
          </sd-hint-text>
        </div>
        <div *ngIf="question.hintText && !question.hintText.expandableContent">
          <div *ngIf="!question.hintText.removeHintLabelMargin" class="ontario-margin-bottom-16-!"></div>
          <sd-hint-text id="{{id}}-question-hintText"
                        [noMarginBottom]="true"
                        [occurrence]="panelIndex"
                        [text]="question.hintText.text">
          </sd-hint-text>
        </div>
      </div>
      <sd-alert-err
          *ngIf="(showError || formControl?.touched || formControl.hasError('crossField')) && errorKey && !formControl?.valid && question.controlType !== 'panel' && question.controlType !== 'textbox-auto-complete' && question.controlType !== 'odsdate'"
          [errorId]="id.concat('-err')" [isInnerHtml]="question.innerHtmlError ? 'true' : null"
          [errorMessage]="errorKey | translate : resolveDynamicQuestionErrorParam">
      </sd-alert-err>
      <!-- special treatment for predictive text input box-->
      <sd-alert-err *ngIf="shouldAlertPredictiveTextError && errorKey && !formControl?.valid && question.controlType === 'textbox-auto-complete'"
                    [errorId]="id.concat('-err')" [errorMessage]="errorKey | translate">
      </sd-alert-err>

      <sd-alert-err *ngIf="showCustomError"
                    [errorId]="id.concat('custom-err')" [errorMessage]="showCustomError | translate">
      </sd-alert-err>

      <div attr.name="{{id}}" [ngSwitch]="question.controlType">
        <div *ngSwitchCase="'textbox'" [ngClass]="{'currency-wrap': question.isDollarValue }">
          <span *ngIf="question.isDollarValue" class="currency-code">$</span>
          <input  class="ontario-input {{getInputClass(question.characterWidth)}}" [ngClass]="{'currency-padding': question.isDollarValue, 'ontario-input__error': shouldShowErrorStyle }" [id]="id.concat('_input')"
                  [formControlName]="question.key"
                  (blur) = "onTextBoxBlur()"
                  type="text"
                  attr.aria-describedby="{{id}}-err {{id}}-custom-err"
                  [attr.aria-required]="question.required">
        </div>

        <div *ngSwitchCase="'odsdate'">
          <sd-date [showError]="showError"
                   id="{{id}}"
                   label="{{question.label | translate:labelParams}}"
                   controlNameDate="{{question.key}}"
                   [validationFns]="question.validatorFns"
                   [validators]="question.validators"
                   [showDay] = "question.showDay"
                   [needMarginTop] = "false"
                   [needMarginBottom] = 'question.needMarginBottom'
                   [smallRightMargin]="question.dateSmallRightMargin"
                   [customHintText]="question.customHintText"
                   [required]= "question.required">
          </sd-date>
        </div>

        <div *ngSwitchCase="'textbox-auto-complete'">
          <mat-form-field>
            <input class="ontario-input" [ngClass]="{'ontario-input__error': shouldShowPredictiveTextErrorStyle }"
                   type="text"
                   [id]="id.concat('_input')"
                   attr.aria-describedby="{{id}}-err {{id}}-custom-err"
                   [formControlName]="question.key"
                   (blur) = "onTextBoxBlur()"
                   ellipsis
                   matInput
                   [matAutocomplete]="auto"
                   matAutocompletePosition="below"
                   #trigger="matAutocompleteTrigger"
                   autocompletePosition="trigger"
                   [attr.aria-required]="question.required">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredAutoCompleteOptions | async" class="textbox-auto-complete-word-wrap" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="ontario-radios" *ngSwitchCase="'radio'"
             [id]="id.concat('_radio')"
             attr.aria-describedby="{{id}}-err {{id}}-custom-err">
          <div *ngFor="let option of question.options; let i = index" class="ontario-radios__item" >
            <input id="{{id}}-radio-button-option-{{i}}" class="ontario-radios__input" [formControlName]="question.key"
                   [attr.name]="id + '-radio-button'" type="radio" value="{{option.value}}">
            <label class="ontario-label ontario-radios__label" for="{{id}}-radio-button-option-{{i}}">
              {{option.label | translate}}
            </label>
          </div>
        </div>

        <select class="ontario-input ontario-dropdown" [ngClass]="{'ontario-input__error': shouldShowErrorStyle }"
                [id]="id.concat('_select')" *ngSwitchCase="'dropdown'" [formControlName]="question.key"
                attr.aria-describedby="{{id}}-err {{id}}-custom-err"
                [attr.aria-required]="question.required">
          <option value="" selected>{{'btn.choose' | translate}}</option>
          <option *ngFor="let opt of question.options" [value]="opt.value">{{opt.label | translate}}</option>
        </select>

        <ng-container *ngIf="!question.displayInNewPanel">
          <sd-dynamic-panel *ngSwitchCase="'panel'" [id]="id"  [form]="panelForm" [showError]="showError"
                            [panel]="question.panels[0]" [questions]="question.panels[0].questions" [checkboxPanelBorders]="question.checkboxPanelBorders"></sd-dynamic-panel>
        </ng-container>
        <ng-container *ngIf="question.displayInNewPanel">
          <sd-dynamic-question-panel *ngSwitchCase="'panel'" [id]="id"  [form]="panelForm" [showError]="showError"
                             [panel]="question.panels[0]" [questions]="question.panels[0].questions"
                             [panelQuestion]="question">
          </sd-dynamic-question-panel>
        </ng-container>

        <div *ngSwitchCase="'checkbox'"
             class="ontario-checkboxes"
             formArrayName="{{question.key}}"
             attr.aria-labelledby="{{id}}"
             attr.aria-describedby="{{id}}-err {{id}}-custom-err">
          <div class="ontario-checkboxes__item" *ngFor="let item of items?.controls; index as i" >
            <input type="checkbox" [id]="id + i" class="ontario-checkboxes__input" [formControlName]="i">
            <label class="ontario-checkboxes__label" for="{{id}}{{i}}" [id]="id + i + '_label'">
              {{question.options[i].label | translate:question.options[i].labelParam}}
            </label>
          </div>
        </div>

        <div *ngSwitchCase="'bulletPoint'"
             id="{{id}}-bullet-point"
             attr.name="{{id}}">
          <ul>
            <li class="ontario-margin-bottom-16-! dynamic-question-list" *ngFor="let item of question.options; index as i" >{{question.options[i].label | translate:question.options[i].labelParam}}</li>
          </ul>
        </div>

        <div *ngSwitchCase="'textarea'" >
          <textarea class="ontario-input ontario-textarea"
                    (keyup)="updateCharsRemaining()"
                    [id]="id.concat('_textarea')"
                    [formControlName]="question.key"
                    [ngClass]="{'ontario-input__error': shouldShowErrorStyle }"
                    attr.aria-labelledby="{{id}}"
                    attr.aria-describedby="{{id}}-err {{id}}-custom-err"
                    [attr.aria-required]="question.required">
          </textarea>
        </div>

        <div class="ontario-label ontario-margin-top-0-!" [id]="id.concat('_innerHTML')" *ngSwitchCase="'innerHTML'" [innerHTML]="question.innerHTML | translate"></div>

        <div class="{{question.spaceClass}}" *ngSwitchCase="'space'"></div>

        <hr class="hr" *ngSwitchCase="'divider'" aria-hidden="true">

        <div *ngIf="question.fieldDescription" class="ontario-label ontario-margin-top-0-!">
          {{question.fieldDescription | translate}}
        </div>
      </div>
      <div [ngClass]="{'hintText-checkbox':question.controlType==='checkbox'}">
        <sd-hint-text *ngIf="question.hintText && question.hintText.expandableContent"
                      id="{{id}}-question-hintText"
                      [occurrence]="panelIndex"
                      [text]="question.hintText.text"
                      [expandableContent]="question.hintText.expandableContent"
                      [expandableContentParam]="resolveExpandableContentParam(question.hintText.expandableContentParam)">
        </sd-hint-text>
      </div>
    </fieldset>
  </div>
</div>
