<div id="page-container" class="page-container">
  <sd-loading-spinner></sd-loading-spinner>
  <div [hidden]="loadingSpinnerService.loading | async">
    <div id="header-container" class="ontario-header__container">
      <app-mccss-header></app-mccss-header>
    </div>
    <div id="content-container" class="content-wrapper ontario-row">
      <div *ngIf="isErrorOrInfoPage" class="background-container">
        <img class="ont-graphic" src="assets/images/Ontario_Supergraphic.svg">
      </div>
      <div id="page-content" [ngClass]="{'ontario-column': !isLandingPage}">
        <main class="body page__flex-element">
          <section>
            <router-outlet></router-outlet>
          </section>
        </main>
      </div>
    </div>
    <div id="footer-container" class="footer ontario-header__container" [ngClass]="{'footer-height': !isLandingPage, 'landing-footer-height': isLandingPage}">
      <app-mccss-footer></app-mccss-footer>
    </div>
  </div>
</div>
