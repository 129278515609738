import {AfterViewChecked, ChangeDetectorRef, Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PageBaseComponent} from '../PageBaseComponent';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AddressUtil} from '../../utils/address-util';
import * as lodash from 'lodash';
import {PcLookupRequest} from '../../models/pc-lookup-request';
import {AddressLookupService} from '../../services/address-lookup.service';
import {ApplicationAnswers} from '../../models/data.model';
import {SadaErrorCodes} from 'src/app/utils/sada-error-codes';
import {PostalService} from '../../services/postal.service';
import {
  DirectionChoices,
  HomeDeliveryTypeChoices,
  MailingDeliveryTypeChoices,
  ProvinceChoices,
  StreetNumberSuffixChoices,
  StreetTypeChoices
} from './address-question-choices';
import {
  ApartmentNumberValidationFunction,
  CityOrTownValidationFunction,
  MGeneralDeliveryValidationFunction,
  PoBoxValidationFunction,
  RuralRouteValidationFunction,
  StationValidationFunction,
  StreetNameValidationFunction,
  StreetNumberValidationFunction
} from './address-question-validation-functions';
import {ExternalRouter} from '../../external.router';
import {takeUntil} from 'rxjs/operators';
import {ConfigService} from '../../services/config.service';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {UrlInfo} from '../../models/url-map';

@Component({
  selector: 'app-address-information',
  templateUrl: './address-information.component.html',
  styleUrls: ['./address-information.component.scss']
})
export class AddressInformationComponent extends PageBaseComponent implements OnInit, AfterViewChecked, OnDestroy {
  showError = false;
  invalidPostalCodeError: string;
  onlineSocialAssistanceLinkParam: any;
  onlineSocialAssistanceLinkValue: string;
  officeLocatorLinkParam: any;
  postalCodeFinderLinkParam: any;

  deliveryTypeChoices = HomeDeliveryTypeChoices
  mailingAddressDeliveryTypeChoices = MailingDeliveryTypeChoices
  homeAddressDeliveryType = 'Standard street address'
  mailingAddressDeliveryType: string | undefined
  mailingAddressProvinceChoice = ProvinceChoices
  isAddressSame = true
  homeAddressStreetNoMismatch: boolean | false
  mailingAddressStreetNoMismatch: boolean | false
  existingApplicationAnswers: ApplicationAnswers = {jsonData: {}}
  mailingAddressStyle = ''
  onlineSocialAssistanceLink= ''

  addressSameCheckBoxItems = [{value: 'yes', label: 'address-information.addressSame', checked: true}]
  streetNumberSuffixChoices = StreetNumberSuffixChoices
  streetTypeSuffixChoices = StreetTypeChoices
  directionChoices = DirectionChoices
  postalCodeRegex = '^(?![WZDFIOQU])[A-Z]\\d(?![DFIOQU])[A-Z]\\d(?![DFIOQU])[A-Z]\\d$';

  apartmentNumberValidationFunction = ApartmentNumberValidationFunction
  streetNumberValidationFunction = StreetNumberValidationFunction
  streetNameValidationFunction = StreetNameValidationFunction
  cityOrTownValidationFunction = CityOrTownValidationFunction
  ruralRouteValidationFunction = RuralRouteValidationFunction
  mGeneralDeliveryValidationFunction = MGeneralDeliveryValidationFunction
  mPoBoxValidationFunction = PoBoxValidationFunction
  mStationValidationFunction = StationValidationFunction

  constructor(private formBuilder: FormBuilder, private router: Router, public route: ActivatedRoute,
              public intake: IntakeService,
              @Inject(LOCALE_ID) protected localeId: string,
              public translator: TranslateService,
              private addressLookupService: AddressLookupService,
              private postalService: PostalService,
              public ngZone: NgZone,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.addressInfo;
    this.setupLinks();
    StartOnePopup.beforeYouStart1.link = this.onlineSocialAssistanceLink;
    LocationInEmergencyPopup.locationInEmergency.link = this.onlineSocialAssistanceLink;
  }

  ngOnInit(): void {
    this.setupForm()
    this.initializeForm();

    this.form?.controls.deliveryType.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onDeliveryTypeChange(value)
    });
    this.form?.controls.mDeliveryType.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onMailingAddressDeliveryTypeChange(value)
    });
    // tslint:disable-next-line:max-line-length
    this.form.controls.postalCode.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => this.onPostalCodeChange(value));

    this.translator.onLangChange.subscribe((lang) => {
      this.setupLinks();
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onDeliveryTypeChange(value: any) {
    this.homeAddressDeliveryType = value
    if (value === 'Standard street address') {
      // This is mandatory to do because the visibility(if it does not show) will not trigger ngOnChanges event in
      // TextQuestionComponnet to remove the validators.
      this.form.controls.ruralRoute.clearValidators()
      this.form.controls.ruralRoute.updateValueAndValidity()
    }
  }

  onMailingAddressDeliveryTypeChange(value: any) {
    this.mailingAddressDeliveryType = value
    // This is mandatory to do because the visibility(if it does not show) will not trigger ngOnChanges event in
    // TextQuestionComponnet to remove the validators.
    if (value === 'Standard street address') {
      AddressUtil.resetValidatorsForTypeRuralRoute(this.form)
      AddressUtil.resetValidatorsForTypePOBox(this.form)
      AddressUtil.resetValidatorsForTypeGeneralDelivery(this.form)
    } else if (value === 'PO Box') {
      AddressUtil.resetValidatorsForTypeRuralRoute(this.form)
      AddressUtil.resetValidatorsForTypeGeneralDelivery(this.form)
    } else if (value === 'Rural route') {
      AddressUtil.resetValidatorsForTypePOBox(this.form)
      AddressUtil.resetValidatorsForTypeGeneralDelivery(this.form)
    } else if (value === 'General delivery') {
      AddressUtil.resetValidatorsForTypeRuralRoute(this.form)
      AddressUtil.resetValidatorsForTypePOBox(this.form)
      AddressUtil.resetValidatorsForTypeStandStreetAddress(this.form)
    }
  }

  private setupLinks(): void {
    this.onlineSocialAssistanceLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.onlineSocialAssistance);
    this.onlineSocialAssistanceLinkValue = this.onlineSocialAssistanceLink;
    this.officeLocatorLinkParam = {link: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)};
    this.postalCodeFinderLinkParam = {link: this.configService.getUrl(this.translator.currentLang, UrlInfo.postalCodeFinder)};
  }

  setupForm(): void {
    this.form = this.formBuilder.group({
      remoteNorthernCommunityRoadAccess: [],
      deliveryType: [],
      ruralRoute: [],
      addressSame: [],
      apartmentNumber: [],
      streetNumber: [],
      streetNumberSuffix: [''],
      streetName: [],
      streetType: [''],
      direction: [''],
      cityOrTown: [],
      province: ['Ontario'],
      postalCode: [],
      mDeliveryType: [''],
      mRuralRoute: [],
      mPoBox: [],
      mStation: [],
      mGeneralDelivery: [],
      mApartmentNumber: [],
      mStreetNumber: [],
      mStreetNumberSuffix: [''],
      mStreetName: [],
      mStreetType: [''],
      mDirection: [''],
      mCityOrTown: [],
      mProvince: [],
      mPostalCode: []
    });
  }

  postInitializeForm() {
    if (this.applicationAnswers.jsonData?.postalCode?.length) {
      this.form.controls.postalCode.setValue(this.applicationAnswers.jsonData.postalCode)
    }
    if (this.applicationAnswers.jsonData?.addressSame?.length && this.applicationAnswers.jsonData.addressSame[0] === 'yes') {
      this.addressSameCheckBoxItems[0].checked = true
      this.isAddressSame = true
    } else {
      this.addressSameCheckBoxItems[0].checked = false
      this.isAddressSame = false
      this.mailingAddressStyle = 'sada-mailing-address-border-grey'
    }
    if (this.applicationAnswers.jsonData?.deliveryType) {
      this.homeAddressDeliveryType = this.applicationAnswers.jsonData.deliveryType
    }
    if (this.applicationAnswers.jsonData?.mDeliveryType) {
      this.mailingAddressDeliveryType = this.applicationAnswers.jsonData.mDeliveryType
    }
    this.form.controls.province.disable()
  }

  onAddressSameCheckBoxChanges(event: any): void {
    if (event && event.length) {
      this.form.controls.addressSame.setValue(event)
      this.isAddressSame = true;
      this.mailingAddressDeliveryType = ''
      AddressUtil.resetMailingAddressValidators(this.form)
      this.mailingAddressStyle = ''
      this.addressLookupService.clearCachedMailingAddressChoices()
    } else {
      this.isAddressSame = false;
      this.setDefaultValuesForMailingAddress()
    }
  }

  setDefaultValuesForMailingAddress(){
    this.form.controls.mDeliveryType.setValue('Standard street address')
    this.mailingAddressDeliveryType = 'Standard street address'
    this.form.controls.mProvince.setValue('Ontario')
    this.mailingAddressStyle = 'sada-mailing-address-border-grey'
    this.form.controls.mStreetNumber.setValue('')
    this.form.controls.mStreetName.setValue('')
    this.form.controls.mCityOrTown.setValue('')
    this.form.controls.mPostalCode.setValue('')
    this.form.controls.mApartmentNumber.setValue('')
  }

  onPostalCodeChange(value: any) {
    this.invalidPostalCodeError = undefined;
  }

  get postalCodeValue(): string {
    return this.form.controls.postalCode?.value;
  }

  onSubmit(toContinue: boolean): void {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;
    this.existingApplicationAnswers = JSON.parse(JSON.stringify(this.applicationAnswers))

    if (this.form.valid) {
      this.postalService.validatePostalCode(this.postalCodeValue, (result) => {
        if (!result || !result.validPostalCode) {
          this.invalidPostalCodeError = 'start-one.invalid.postalCode';
          this.onlineSocialAssistanceLinkParam = {link: this.onlineSocialAssistanceLinkValue};
          this.showError = true;
          this.openDialog(StartOnePopup.beforeYouStart1)
        } else if (result.locationInEmergencySituation) {
          this.openDialog(LocationInEmergencyPopup.locationInEmergency)
        } else {
          this.showError = false;
          // call Intake service to save new application
          this.saveForm()?.subscribe(x => {
            this.handlePCLookup(toContinue);
          })
        }
      })
    } else {
      this.showError = true;
      this.scrollToInvalidFormControl(toContinue);
    }
  }

  preSaveApplication() {
    if (!this.isAddressSame) {
      this.applicationAnswers.jsonData.addressSame = []
    }
    if (this.homeAddressDeliveryType === 'Standard street address') {
      delete this.applicationAnswers.jsonData?.ruralRoute;
    }

    if (this.isAddressSame) {
      delete this.applicationAnswers.jsonData?.mDeliveryType
      AddressUtil.removeAllFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
    }
    if (this.mailingAddressDeliveryType === 'Standard street address') {
      AddressUtil.removeRuralRouteFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
      AddressUtil.removePoBoxFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
      AddressUtil.removeGeneralDeliveryFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
    } else if (this.mailingAddressDeliveryType === 'PO Box') {
      AddressUtil.removeRuralRouteFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
      AddressUtil.removeGeneralDeliveryFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
    } else if (this.mailingAddressDeliveryType === 'Rural route') {
      AddressUtil.removePoBoxFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
      AddressUtil.removeGeneralDeliveryFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
    } else if (this.mailingAddressDeliveryType === 'General delivery') {
      AddressUtil.removeRuralRouteFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
      AddressUtil.removePoBoxFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
      AddressUtil.removeStandardStreetAddressFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
    }
  }

  private handlePCLookup(toContinue: boolean) {
    const isHomeAddressUpdated = this.isHomeAddressUpdated()
    const isMailingAddressUpdated = this.isMailingAddressUpdated()

    if (isHomeAddressUpdated || isMailingAddressUpdated) {
      const pcLookUpRequest: PcLookupRequest = {
        homeAddress: isHomeAddressUpdated ? AddressUtil.constructHomeAddressFromAnswers(this.applicationAnswers.jsonData) : null,
        mailingAddress: isMailingAddressUpdated ? AddressUtil.constructMailingAddressFromAnswers(this.applicationAnswers.jsonData) : null
      }

      this.addressLookupService.getAddressChoicesAndPopulateCache(pcLookUpRequest, isHomeAddressUpdated,
        isMailingAddressUpdated).subscribe(data => {
          if (toContinue && !this.authService.isAuthorizedToSave()) {
            this.intakeService.saveApplication(this.applicationAnswers, this.pageId).subscribe(() => {
              if (data && ((Array.isArray(data.homeAddressChoices) && data.homeAddressChoices.length)
                || (Array.isArray(data.mailingAddressChoices) && data.mailingAddressChoices.length))) {
                this.router.navigate(['/', 'intake', PageInfo.confirmAddress], {skipLocationChange: true})
              } else {
                this.router.navigate(['/', 'intake', PageInfo.housingSituation])
              }
            })
          } else if (toContinue) {
            this.saveApplicationToResume(this.applicationAnswers, this.pageId).subscribe(() => {
              if (data && ((Array.isArray(data.homeAddressChoices) && data.homeAddressChoices.length)
                || (Array.isArray(data.mailingAddressChoices) && data.mailingAddressChoices.length))) {
                this.router.navigate(['/', 'intake', PageInfo.confirmAddress], {skipLocationChange: true})
              } else {
                this.router.navigate(['/', 'intake', PageInfo.housingSituation])
              }
            })
          } else {
            this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
          }
        }, err => {
          this.handleAddressChoiceError(err, toContinue)
        })
    } else if (!toContinue) {
      this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
    } else if (this.addressLookupService.isCachedAddressChoicesAvailable()) {
      this.router.navigate(['/', 'intake', PageInfo.confirmAddress], {skipLocationChange: true})
    } else {
      this.router.navigate(['/', 'intake', PageInfo.housingSituation])
    }
  }

  private isHomeAddressUpdated() {
    const existingHomeAddress = AddressUtil.constructHomeAddressFromAnswers(this.existingApplicationAnswers.jsonData)
    const updatedHomeAddress = AddressUtil.constructHomeAddressFromAnswers(this.applicationAnswers.jsonData)
    return !lodash.isEqual(existingHomeAddress, updatedHomeAddress)
  }

  private isMailingAddressUpdated() {
    if (!this.applicationAnswers.jsonData.addressSame || this.applicationAnswers.jsonData.addressSame[0] !== 'yes') {
      const existingMailingAddress = AddressUtil.constructMailingAddressFromAnswers(this.existingApplicationAnswers.jsonData)
      const updatedMailingAddress = AddressUtil.constructMailingAddressFromAnswers(this.applicationAnswers.jsonData)
      return !lodash.isEqual(existingMailingAddress, updatedMailingAddress)
    } else {
      return false;
    }
  }

  private handleAddressChoiceError(err: any, toContinue: boolean): void {

    if (err.status === 400 && SadaErrorCodes.EC0003 === err.error.errorCode) {
      this.homeAddressStreetNoMismatch = true
      window.scrollTo(0, 0)
    } else if (err.status === 400 && SadaErrorCodes.EC0004 === err.error.errorCode) {
      this.mailingAddressStreetNoMismatch = true
    } else {
      if (!toContinue) { // Save and exit
        this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
      } else {
        this.router.navigate(['/', 'intake', PageInfo.housingSituation])
      }
    }
  }

  private isAddressUpdatedOrChoicesAvailable() {
    // Check for isCachedAddressChoicesAvailable is to handle scenario where user comes back to address info using
    // back button without selecting any choices. In this scenario confirm address should be displayed again.
    return (this.isHomeAddressUpdated() || this.isMailingAddressUpdated() ||
      this.addressLookupService.isCachedAddressChoicesAvailable())
  }
}

export const StartOnePopup = {
  beforeYouStart1: {
    title: 'start-one.dialog.beforeYouStart1.title',
    body: 'start-one.dialog.beforeYouStart1.body',
    button: 'start-one.dialog.beforeYouStart1.button',
    link: ''
  }
}

export const LocationInEmergencyPopup = {
  locationInEmergency: {
    title: 'start-one.dialog.locationInEmergency.title',
    body: 'start-one.dialog.locationInEmergency.body',
    button: 'start-one.dialog.locationInEmergency.button',
    link: ''
  }
}
