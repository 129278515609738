import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RouteStateService } from './route-state.service';

@Injectable({
  providedIn: 'root'
})
export class RouteStateServiceImpl extends RouteStateService {

  private isErrorOrInfoPage = new BehaviorSubject<boolean>(false);
  public isErrorOrInfoPage$: Observable<boolean> = this.isErrorOrInfoPage.asObservable();

  constructor() {
    super();
  }

  public setErrorOrInfoPage(state: boolean): void {
    this.isErrorOrInfoPage.next(state);
  }
}
