import {Converter, FormConverter} from '../../../common/utils/converters/converter';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {AppDataToStandardHHIQuestions} from './app-data-to-std-hhi-questions';
import {AppDataToHHIOtherIncomeQuestions} from './app-data-to-hhi-otherincome-questions';
import {AppDataToHHINoneQuestion} from './app-data-to-hhi-none-question';
import {FormGroup} from '@angular/forms';
import {HHIStandardFormValuesToAppData} from './hhi-std-form-to-data';
import {HHIOtherIncomeFormData} from './hhi-other-income-form-to-data';
import {HHINoOtherIncomeFormToData} from './hhi-none-form-to-data';
import {HouseholdIncomeQuestionHelper as helper} from '../service/household-income-question.helper';

/**
 * Create house hold income questions and populate data if available
 */
export class AppDataToHHIQuestionsConverter implements Converter<ApplicationAnswers, Question<string>[]> {

  convert(appData: ApplicationAnswers, questions: Question<string>[]): void {
    // update questions based on app data
    questions.forEach(question => {
      helper.getStandardHHIQuestionsMappingConfig().get(question.key)?.forEach(config => {
        new AppDataToStandardHHIQuestions().convert(appData, question, config)
      })
    });

    new AppDataToHHIOtherIncomeQuestions().convert(appData, questions.find(q => q.key === 'OtherIncomePanel'),
      helper.getOtherIncomeQuestionsMappingConfig())

    new AppDataToHHINoneQuestion().convert(appData, questions.find(q => q.key === 'NoneOfAbove'))
  }
}

/**
 * Convert form values to application data to send to back end
 */
export class HHIFormToAppDataConverter implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // iterate thru top level controls on form and call mapped converter
    Object.keys(form.controls).forEach(key => {
      helper.getStandardHHIQuestionsMappingConfig().get(key)?.forEach(config => {
        new HHIStandardFormValuesToAppData().convert(form, appData, questions, config);
      })
    });

    new HHIOtherIncomeFormData().convert(form, appData, questions, helper.getOtherIncomeQuestionsMappingConfig())

    new HHINoOtherIncomeFormToData().convert(form, appData, questions)
  }
}
