import {FormArray} from '@angular/forms';

export class FieldsCheckUtil {
  // Checking if the field is invalid on the pages have ASYNC validators
  static findInvalidField(formControls: any): boolean {
    const formArray = formControls as unknown as FormArray;
    const formArrayValues = Object.values(formArray);
    const invalidField = formArrayValues.find((value) => value.status === 'INVALID');
    return !!invalidField;
  }

  // Checking if the field is invalid on the pages have ASYNC validators
  static findInvalidFieldInArray(formControls: any): boolean {
    const formArray = formControls as unknown as FormArray;
    let invalidField;

    formArray.controls.every((formArr) => {
      const valueFormArray = formArr as FormArray;
      invalidField = this.findInvalidField(valueFormArray.controls);

      return !(!!invalidField); // stop running every method when it finds status 'INVALID' field
    });

    return !!invalidField;
  }
}
