import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sd-info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.scss']
})
export class InfoTextComponent implements OnInit {
  @Input()
  isInfoTextOnly: boolean;

  @Input()
  header: string;
  @Input()
  headerParam: any;

  @Input()
  contentId: string | undefined;
  @Input()
  content: string;
  @Input()
  contentParam: any;

  @Input()
  linkId: string | undefined;
  @Input()
  link: string | undefined;
  @Input()
  linkLabel: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
