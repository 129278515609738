import {ComponentType} from '@angular/cdk/overlay';

export const popup = {
  beforeYouStart1: {
    title: `dialog-constant.postcodeNotInPilotTitle`,
    body: `dialog-constant.postcodeNotInPilotBody`,
    button: `dialog-constant.startApplicationButton`,
    link: {
      dev: `dialog-constant.onlineSocialAssistanceLinkDev`,
      ist: `dialog-constant.onlineSocialAssistanceLinkIst`,
      ppe: `dialog-constant.onlineSocialAssistanceLinkPpe`,
      prod: `dialog-constant.onlineSocialAssistanceLinkProd`
    }
  },
  locationInEmergency: {
    title: `dialog-constant.locationInEmergencyTitle`,
    body: `dialog-constant.locationInEmergencyBody`,
    button: `dialog-constant.locationInEmergencyButton`,
    link: ''
  },
  livingOnFirstNationsReserveLand: {
    body: `dialog-constant.contactFirstNationLocalOfficeBody`,
    button: `dialog-constant.findMyOWOfficeButton`,
    link: `dialog-constant.officeLocatorLink`
  },
  eidSkip: {
    title: `dialog-constant.eidConfirmSkipTitle`,
    body: `dialog-constant.eidConfirmSkipBody`,
    leftButtonText: `dialog-constant.eidSkipCancelButton`,
    rightButtonText: `dialog-constant.eidSkipConfirmButton`
  },
  inactiveTimeout: {
    title: `dialog-constant.inactiveTimeoutTitle`,
    body: `dialog-constant.inactiveTimeoutBody`,
    button: `dialog-constant.continueButton`,
    link: '',
    isInactive: `true`
  },
  absoluteTimeout: {
    header:`global-error.session.timeout.error.box.title`,
    body: `global-error.session.timeout.alert.error.message`,
    button: `global-error.session.timeout.button.label`,
    link: ''
  },
  eidInactiveTimeout: {
    title: `dialog-constant.eidInactiveTimeoutTitle`,
    body: `dialog-constant.eidInactiveTimeoutBody`,
    button: `dialog-constant.continueButton`
  },
  submitWithoutESign: {
    title: 'signature-landing.submit.without.esign.title',
    body: 'signature-landing.submit.without.esign',
    button: 'signature-landing.submit.now'
  },
  noLastName: {
    body: `dialog-constant.noLastNameMessageText`,
    button: `dialog-constant.continueButton`,
    isInactive: `true`
  },
  signatureRequestEmailed: {
    title: 'signature-landing.familyapplicants.emailed.title',
    familyApplicants: 'signature-landing.familyapplicants.emailed',
    singleApplicant: 'signature-landing.singleApplicant.emailed',
    button: 'signature-landing.submit.now'
  },
  singleApplicantEmailed: {
    body: 'signature-landing.singleApplicant.emailed',
    button: `dialog-constant.singleApplicantEmailedButton`
  },
  applicationSaved: {
    title: `dialog-constant.applicationSavedTitle`,
    body: `dialog-constant.applicationSavedBody`,
    rightButtonText: `dialog-constant.applicationSavedRightButtonText`,
    button: `dialog-constant.applicationSavedButtonExit`
  },
  startNewApplication: {
    title: 'inprogress-application.dialog.startNewApplication.title',
    body: 'inprogress-application.dialog.startNewApplication.body',
    left_button: `inprogress-application.dialog.startNewApplication.cancel.button`,
    right_button: `inprogress-application.dialog.startNewApplication.startNewApplication.button`
  },
}

export interface DialogConfig {
  component: ComponentType<any>;
  config: {
    panelClass: string,
    autoFocus?: boolean,
    disableClose?: boolean
  }
  content?: {
    title: string,
    body?: string,
    bodyLink?: string,
    link?: string,
    button?: string,
    rightButton?: string,
    isInactive?: string,
    alertInfoKey?: string
  }
  hideCloseButton?: boolean;
  buttonAction?: () => void;
  rightButtonAction?: () => void;
  enableReturnToAppLink?: boolean
}
