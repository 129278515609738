import {AbstractControl, ValidatorFn} from '@angular/forms';

/**
 * validate valid value.
 * @param validValue
 */
export function validValueValidator(validValue: string): ValidatorFn {

  return (control: AbstractControl): {  [key: string]: any } | null => {

    const valid = control.value === validValue;

    return valid ? null : { invalidValue: control.value };
  };
}
