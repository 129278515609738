import {Injectable} from '@angular/core';
import {AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';

import {Question} from './question';
import {validateAtLeastOneCheckboxSelected} from '../validators/atleast-one-checkbox-selected-validator';
import {functionValidator, toAsyncValidatorFn} from '../validators/custom-validators';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';

@Injectable()
export class QuestionControlService {
  constructor(private fb: FormBuilder) { }

  // create form controls based on questions
  toFormGroup(questions: Question<string>[] | undefined ): FormGroup {
    const group: any = {};  // Could be a collection of FormControl, FormGroup or FormArray.
    const formValidators: ValidatorFn[] = [];

    questions?.forEach(question => {
      if (question.controlType === 'checkbox') {
        group[question.key] = this.fb.array(this.populateCheckBoxQuestion(question), this.registerValidators(question)) as FormArray;
      } else if (question.controlType === 'panel') {
        group[question.key] = this.fb.group({
          panels: this.populateFormArray(question)
        }) as FormGroup;
      } else if (question.controlType === 'textbox' || question.controlType === 'textarea') {
        group[question.key] = new FormControl(question.value || '',
          {validators: this.registerValidators(question),
            asyncValidators: this.registerAsyncValidators(question), updateOn: 'blur'}) as FormControl;
      } else {
        group[question.key] = new FormControl(question.value || '', this.registerValidators(question)) as FormControl;
      }

      if (this.getCrossFieldValidator(question)) {
        formValidators.push(this.getCrossFieldValidator(question));
      }
    });

    return new FormGroup(group, formValidators) as FormGroup;
  }

  populateFormArray(question: Question<string>): FormArray {
    const formArray: FormArray = this.fb.array([]);

    question.panels?.forEach(panel =>  {
      formArray.push(this.toFormGroup(panel.questions));
    });

    return formArray;
  }

  getCrossFieldValidator(question: Question<string>): ValidatorFn|undefined {
    if (!question.validators){
      return undefined;
    }

    // convert list of validators to map
    const validatorMap = new Map(question.validators.map(obj => [obj.type, obj]));
    const validator = validatorMap.get('crossField');
    // @ts-ignore
    return validator ? validator.validatorFn : undefined;
  }

  registerAsyncValidators(question: Question<string>): AsyncValidatorFn[]{
    const validators: AsyncValidatorFn[] = [];

    if (!question.validators){
      return validators;
    }

    // convert list of validators to map
    const validatorMap = new Map(question.validators.map(obj => [obj.type, obj]));

    const asyncValidator = validatorMap.get('asyncValidatorFn');

    if (asyncValidator) {
      validators.push(toAsyncValidatorFn(asyncValidator.asyncValidatorFn, asyncValidator.errorKey));
    }

    return validators;
  }

  registerValidators(question: Question<string>): ValidatorFn[]{
    const validators: ValidatorFn[] = [];

    if (!question.validators){
      return validators;
    }

    // convert list of validators to map
    const validatorMap = new Map(question.validators.filter(obj => 'validatorFn' !== obj.type).map(obj => [obj.type, obj]));
    const fnValidators = question.validators.filter(obj => 'validatorFn' === obj.type);

    if (validatorMap.get('required') && question.controlType === 'checkbox'){
      validators.push(validateAtLeastOneCheckboxSelected());
    } else if (validatorMap.get('required')) {
      validators.push(Validators.required);
    }

    if (validatorMap.get('min')) {
      validators.push(Validators.min(validatorMap.get('min').minimumValue));
    }

    if (validatorMap.get('max')) {
      validators.push(Validators.max(validatorMap.get('max').maximumValue));
    }

    if (validatorMap.get('pattern')) {
      validators.push(Validators.pattern(validatorMap.get('pattern').regex));
    }

    fnValidators?.forEach(validator => {
      validators.push(functionValidator(validator?.validatorFn, validator?.errorKey, validator?.errorKeyParam, validator?.params));
    })

    if (validatorMap.get('minLength')) {
      validators.push(Validators.minLength(validatorMap.get('minLength').minLengthValue));
    }

    if (validatorMap.get('maxLength')) {
      validators.push(Validators.maxLength(validatorMap.get('maxLength').maxLengthValue));
    }

    if (validatorMap.get('maxNumericLength')) {
      validators.push(SadaCustomValidator.maxNumericLengthValidator(validatorMap.get('maxNumericLength').maxLengthValue));
    }

    if (validatorMap.get('vehicleYearBefore1900Check')) {
      validators.push(SadaCustomValidator.vehicleYearBefore1900Check(validatorMap.get('vehicleYearBefore1900Check').regex));
    }

    return validators;
  }

  populateCheckBoxQuestion(question: Question<string> | undefined): FormControl[] {
    const items: FormControl[] = [];

    question?.options?.forEach(o => {
        items.push(new FormControl(o.value ? true : false));
    });

    return items;
  }
}
