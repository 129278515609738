import {Injectable} from '@angular/core'
import {MenuService} from './menu.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuServiceImpl implements MenuService {
  private showPSRedirectError: BehaviorSubject<boolean>;

  constructor() {
    this.showPSRedirectError = new BehaviorSubject<boolean>(false);
  }

  getShowPSRedirectError(): Observable<boolean> {
    return this.showPSRedirectError.asObservable();
  }
  setShowPSRedirectError(showPSRedirectError: boolean) {
    this.showPSRedirectError.next(showPSRedirectError);
  }
}
