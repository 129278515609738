import {AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Idle} from '@ng-idle/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Question} from 'src/app/common/utils/questions/question';
import {QuestionControlService} from 'src/app/common/utils/questions/question-control.service';
import {ExternalRouter} from 'src/app/external.router';
import {IntakeService} from 'src/app/services/intake.service';
import {PageBaseComponent} from '../PageBaseComponent';
import {AdditionalInformationMapper} from './additional-information-mapper';
import {AdditionalInformationQuestionService} from './additional-information-question.service';
import {FamilyInformationQuestionService} from './family-information-question.service';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {ConfigService} from '../../services/config.service';
import {Title} from '@angular/platform-browser';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {distinctUntilChanged} from 'rxjs/operators';


@Component({
  selector: 'app-additional-information',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  questions: Question<string>[];
  showError = false;
  isSingle: boolean;

  constructor(
    public intake: IntakeService,
    public translator: TranslateService,
    public ngZone: NgZone,
    public idle: Idle,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public externalRouter: ExternalRouter,
    private fb: FormBuilder,
    private mapper: AdditionalInformationMapper,
    private questionService: AdditionalInformationQuestionService,
    private familyInformationQuestionService: FamilyInformationQuestionService,
    private qcs: QuestionControlService,
    private router: Router,
    private translate: TranslateService,
    private title: Title,
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected configService: ConfigService,
    protected authService: AuthService,
    protected pageService: PageService,
    protected loadingSpinnerService: LoadingSpinnerService
  ) {
  super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.additionalInfo;
  }

  getPageTitleKey(): string {
    if (this.isSingleApplicant) {
      return PageInfo.additionalInfo + '.singleApplicant.page.title';
    } else {
      return PageInfo.additionalInfo + '.multipleApplicants.page.title';
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    this.initializeForm();
    this.isSingle = this.isSingleApplicant;

    // convert applicationAnswers to questions
    if (this.isSingle) {
      this.questions = [...this.questionService.getAdditionalInformationQuestions(this.applicationAnswers)];
      this.title.setTitle(this.translate.instant('headerTitles.additionalinfo'));
    } else {
      this.questions = [...this.familyInformationQuestionService.getMultipleAdditionalInformationQuestions(this.applicationAnswers)];
      this.title.setTitle(this.translate.instant('headerTitles.additionalfamilyinfo'));
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.isSingle) {
        this.title.setTitle(this.translate.instant('headerTitles.additionalinfo'));
      } else {
        this.title.setTitle(this.translate.instant('headerTitles.additionalfamilyinfo'));
      }
    });

    // Add dynamic panels based on applicants and Map answers to corresponding questions
    this.mapper.mapAppDataToQuestions(this.applicationAnswers, this.questions, this.isSingle);

    // generate form controls based on questions
    this.form = this.qcs.toFormGroup(this.questions);
  }
  postInitializeForm() { }

  preSaveApplication() { }

  onSubmit(toContinue?: boolean) {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;

    if ((this.form.valid)) {
      // Convert form data values to application answers
      this.mapper.mapFormValuesToAppData(this.form, this.applicationAnswers, this.questions, this.isSingle)

      this.buttonContinueClick = true

      this.subscriptions$.push(this.saveToIntake()?.pipe(distinctUntilChanged()).subscribe(() => {
        if (toContinue) {
          this.navigateTo();
        } else {
          this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
        }
      }));
    } else {
      this.showError = true;
      this.scrollToInvalidFormControl(toContinue);
    }
  }

  private navigateTo() {
    if (this.isAnyoneSponsored) {
      this.intake.setSponsorshipRequired(true)
      this.router.navigate(['/', 'intake', PageInfo.sponsorship])
    } else {
      this.intake.setSponsorshipRequired(false)
      this.router.navigate(['/', 'intake',PageInfo.addressInfo])
    }
  }

  get isAnyoneSponsored() {
    return this.applicationAnswers.jsonData.applicantSponsored === 'yes' ||
      SadaCustomValidator.isFamilySponsored(
        [this.applicationAnswers.jsonData.spouseSponsored, this.applicationAnswers.jsonData.childList])
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }
}
