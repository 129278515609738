<div>
  <sd-accordion [id]="'financial-eligibility-heading'"
                [heading]="'app-status.ODS.financial-eligibility-heading'"
                [toExpand]="true">
    <div #content>
      <sd-asc-status-progress-bar [id]="'financial-eligibility'"
                                  [progressSteps]="financialEligibilityProgressSteps">
      </sd-asc-status-progress-bar>
    </div>
  </sd-accordion>
  <sd-accordion *ngIf="showMedicalEligibility" [id]="'medical-eligibility-heading'"
                [heading]="isDisabilityAdjudicationUnit ? 'app-status.ODS.dau.medical-eligibility-heading': 'app-status.ODS.medical-eligibility-heading'"
                [toExpand]="true">
    <div #content>
      <sd-asc-status-progress-bar [id]="'medical-eligibility'"
                                  [progressSteps]="medicalEligibilityProgressSteps">
      </sd-asc-status-progress-bar>
    </div>
  </sd-accordion>
  <sd-accordion [id]="'application-decision-heading'"
                [heading]="'app-status.ODS.application-decision-heading'"
                [toExpand]="true">
    <div #content>
      <sd-asc-status-progress-bar [id]="'application-decision'"
                                  [progressSteps]="applicationDecisionProgressSteps">
      </sd-asc-status-progress-bar>
    </div>
  </sd-accordion>
</div>
