<sd-page-container [pageId]="pageId">
  <h2 class="ontario-h2 my-2">
    {{'signature-landing.subtitle' | translate}}
  </h2>
  <div class="mt-3 mb-4">
    <div aria-label="description">
      {{'signature-landing.instruction1' | translate}}
    </div>
    <div aria-label="description">
      {{'signature-landing.instruction2' | translate}}
    </div>
  </div>
  <h2 class="ontario-h2 my-4">{{'signature-landing.expectation.title' | translate}}</h2>
<!--  <div *ngIf="submittedByCaseWorker || isRelatedToLocalOfficeAssistance">-->
    <div *ngIf="isSubmittedToCaseWoker_Or_IsLocalOfficeAssistance()">
    <div *ngIf="!hasFamily()">
      <ul>
        <li>
          {{'signature-landing.expectation.ibau.single.applicant.bullet1' | translate}}
        </li>
        <li>
          {{'signature-landing.expectation.ibau.single.applicant.bullet2' | translate}}
        </li>
      </ul>
    </div>
    <div *ngIf="hasFamily()">
      <ul>
        <li>
          {{'signature-landing.expectation.ibau.multiple.applicant.bullet1' | translate}}
        </li>
        <li>
          {{'signature-landing.expectation.ibau.multiple.applicant.bullet2' | translate}}
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="!isSubmittedToCaseWoker_Or_IsLocalOfficeAssistance()">
    <ul>
      <li>
        {{'signature-landing.expectation.public.bullet1' | translate}}
      </li>
      <li>
        {{'signature-landing.expectation.public.bullet2' | translate}}
      </li>
      <li *ngIf="hasFamily()">
        {{'signature-landing.expectation.public.bullet3' | translate}}
      </li>
    </ul>
  </div>

  <div class="esign-buttons-container row">
    <div id="spinner-container" *ngIf="isLoading">
      <div class="row">
        <div id="spinner-col" class="col-12">
          <div id="spinner">
          <svg class='ontario-loading-indicator__spinner' viewBox='0 0 66 66'>
            <circle fill='none' stroke-width='6' stroke-linecap='round' cx='33' cy='33' r='30'></circle>
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
        </div>
      </div>
      <div class="mt-5 mb-5">
        {{'signature-landing.loadingtext' | translate}}
      </div>
    </div>
  </div>
  <div id="button-container" *ngIf="data?.jsonData?.firstName">
    <app-mccss-submit-button *ngIf="!isSubmittedToCaseWoker_Or_IsLocalOfficeAssistance()" buttonId="continue-button" dataE2E="continueBtn" buttonLabel="signature-landing.takemetosign" [preventDoubleClick]="true" (handleClick)="onSubmit()" [disabled]="isLoading"></app-mccss-submit-button>
    <app-mccss-submit-button *ngIf="isSubmittedToCaseWoker_Or_IsLocalOfficeAssistance() && !hasFamily()" buttonId="continue-button-2" dataE2E="continueBtn" buttonLabel="signature-landing.sendtoapplicanttosign" [preventDoubleClick]="true" (handleClick)="onSubmit()" [disabled]="isLoading"></app-mccss-submit-button>
    <app-mccss-submit-button *ngIf="isSubmittedToCaseWoker_Or_IsLocalOfficeAssistance() && hasFamily()" buttonId="continue-button-3" dataE2E="continueBtn" buttonLabel="signature-landing.sendtoapplicantstosign" [preventDoubleClick]="true" (handleClick)="onSubmit()" [disabled]="isLoading"></app-mccss-submit-button>
  </div>
</sd-page-container>
