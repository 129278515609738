<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner">
  <div id="page-alerts-container">
    <sd-alert-error-box *ngIf="hasError"
                        errorHeader="{{errorHeader | translate}}"
                        errorBody="{{errorBody | translate: { link: emergencyAssistanceLink } }}">
    </sd-alert-error-box>
  </div>
  <div *ngIf="!applicationValidationErrors" >
    <table>
      <tr>
        <td class="blue-info-td"><img class="blue-info-img" src="assets/images/blue-info.svg" alt=""></td>
        <td class="review-instruction">{{'review.instructions' | translate}}</td>
      </tr>
    </table>
  </div>
  <ng-container *ngFor="let page of pages; let i = index">
    <div *ngIf="i !== 0" class="mt-4"></div>
    <div class="review-page-container" id="{{page.name}}" *ngIf="page.isVisible">
      <div class="review-page-title-container row mx-2 my-2">
        <div class="col-10">
          <h2 class="ontario-h4">
            <svg *ngIf="page.isErrorIconVisible" class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                 viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use [attr.href]="'#ontario-icon-alert-error'"></use>
            </svg>
            {{page.title | translate}}
          </h2>
        </div>
        <div class="col-2">
          <div class="review-page-edit" (click)="onEditClicked(page)">
            <fa-icon [icon]="faPencilAlt"></fa-icon>
            <button [attr.aria-label]="editAria(page.title)" class="ml-2 d-inline-block editButton" id="edit-{{page.name}}">
              {{'review.edit.label' | translate}}
            </button>
          </div>
        </div>
      </div>
      <div class="divider" aria-hidden="true"></div>

      <div class="mx-4">
        <ng-container *ngFor="let question of page.questions; let ind = index">
          <sd-app-review-question [question]="question"
                                  [applicationValidationErrors]="applicationValidationErrors"
                                  [reviewValidationErrors]="reviewValidationErrors">
          </sd-app-review-question>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="ontario-padding-top-48-!"></div>
  <app-mccss-save-continue-button [pageId]="pageId"
                                  dataE2E="continueBtn"
                                  [disabled]="hasError"
                                  (handlePrimaryButtonClick)="onSubmit(true)"
                                  (handleSecondaryButtonClick)="onSubmit(false)">
  </app-mccss-save-continue-button>
</sd-page-container>
