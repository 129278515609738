export function getCurrentYear() {
  return new Date().getFullYear();
}

export function getCurrentMonthAsString(lang = 'en') {
  const date = new Date();
  return date.toLocaleString(lang, {month: 'long'});
}

/**
 * This method returns the formatted date string.
 * @param lang Language of the date to be displayed in.
 * @param date The date to be formatted.
 * @param dateFormat The format of the date to be displayed in.
 */
export function formatDateToLocaleString(lang = 'en', date: Date,
                                         dateFormat: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }) {
  if (lang === 'fr') {
    return date?.toLocaleDateString('fr-FR', dateFormat);
  } else {
    return date?.toLocaleDateString('en-US', dateFormat);
  }
}
