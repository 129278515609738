import {Injectable} from '@angular/core';
import {ApplicationAnswers} from '../../../models/data.model';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ChildCare, ChildCareFormNames, EarnedIncomeSourceList, IncomeQuestionsFormNames, IncomeSource} from '../earned-income.model';
import {APPLICANT, CHILD_PREFIX, getChildrenYoungerThan18, NONE, SPOUSE} from '../earned-income.util';
import {CheckboxItem} from '../../../common/ui/checkbox-question/checkbox-question.component';
import {getCurrentMonthAsString} from '../../../utils/date-util';

@Injectable()
export class EarnedIncomeQuestionsService {
  appDataToForm(applicationAnswers: ApplicationAnswers, form: FormGroup, fb: FormBuilder) {
    // populate top level input fields
    Object.keys(form.controls).forEach(key => {
      if (applicationAnswers.jsonData[key]) {
        form?.controls[key]?.setValue(applicationAnswers.jsonData[key]);
      }
    });

    const earnedIncome: EarnedIncomeSourceList[] = applicationAnswers.jsonData.earnedIncome;
    earnedIncome?.forEach(income => {
      // Handle switch from Family application to single applicant
      if (APPLICANT === income.applicantType && form.get('applicantReceiveEarnedIncome') &&
        !applicationAnswers.jsonData.applicantReceiveEarnedIncome) {
        form.get('applicantReceiveEarnedIncome').setValue('yes');
      }

      if (APPLICANT === income.applicantType || SPOUSE === income.applicantType ||
        (income.applicantType.startsWith(CHILD_PREFIX) &&
          income.applicantName === this.getApplicantNameByType(income.applicantType, applicationAnswers))) {
        const formArrayName = getIncomesFormArrayNameByApplicantType(income.applicantType);
        const incomesFormArray = form.get('incomes').get(formArrayName) as FormArray;
        if (incomesFormArray) {
          income.earnedIncomeSourceList.forEach(data => {
            const incomeFormGroup = createIncomeFG(fb);
            this.appDataToFormValue(incomeFormGroup, data)
            incomesFormArray.push(incomeFormGroup);
          })
        }
      }
    });

    if (form.get('childCareExpenses') && applicationAnswers.jsonData.anyChildCareExpenseList?.length) {
      const anyChildCareExpenseList: ChildCare[] = applicationAnswers.jsonData.anyChildCareExpenseList[0].childCare;
      anyChildCareExpenseList?.forEach(childCareExpense => {
        // If the name doesnt match then child name got edited or child has been removed
        if (this.getChildName(applicationAnswers, childCareExpense.applicantType) === childCareExpense.careRecipientName) {
          const fgName = getChildCareExpenseFGName(childCareExpense.applicantType);
          const formGroup = form.get('childCareExpenses').get(fgName) as FormGroup;
          this.appDataToFormValue(formGroup, childCareExpense)
        }
      });
    }
  }

  formToAppData(isSingleApplicant: boolean, form: FormGroup, applicationAnswers: ApplicationAnswers,
                applicantChoices: CheckboxItem[], childCareChildrenOptions: CheckboxItem[]): ApplicationAnswers {
    if (isSingleApplicant) {
      applicationAnswers.jsonData.applicantReceiveEarnedIncome = form.get('applicantReceiveEarnedIncome')?.value;
      this.populateEarnedIncomes(applicationAnswers, form, applicantChoices);
      delete applicationAnswers.jsonData.anyOneReceiveEarnedIncome;
      delete applicationAnswers.jsonData.anyChildCareExpenseList;
      delete applicationAnswers.jsonData.anyChildCareExpense;
    } else {
      applicationAnswers.jsonData.anyOneReceiveEarnedIncome = applicantChoices?.filter(choice => choice.checked)
        .map(choice => choice.value);
      const isNoneSelected = applicationAnswers.jsonData.anyOneReceiveEarnedIncome.some(entry => entry === NONE);
      if (isNoneSelected) {
        delete applicationAnswers.jsonData.anyChildCareExpenseList;
        delete applicationAnswers.jsonData.anyChildCareExpense;
        delete applicationAnswers.jsonData.earnedIncome;
      } else {
        this.populateChildrenExpenses(applicationAnswers, form, childCareChildrenOptions);
        this.populateEarnedIncomes(applicationAnswers, form, applicantChoices);
      }

      delete applicationAnswers.jsonData.applicantReceiveEarnedIncome;
    }

    return applicationAnswers;
  }

  private appDataToFormValue(formGroup: FormGroup, jsonData: any) {
    Object.keys(formGroup.controls).forEach(key => {
      if (jsonData[key]) {
        formGroup.controls[key]?.setValue(jsonData[key]);
      }
    });
  }

  getApplicantNameByType(applicantType: string, applicationAnswers: ApplicationAnswers) {
    let applicantName: any;
    if (APPLICANT === applicantType)
      applicantName = ' '.concat(applicationAnswers.jsonData.firstName);
    else if (SPOUSE === applicantType)
      applicantName = ' '.concat(applicationAnswers.jsonData.spouseFirstName);
    else if (applicantType.startsWith(CHILD_PREFIX)) {
      const childIndex = applicantType.substring(applicantType.length - 1, applicantType.length)
      applicantName = applicationAnswers.jsonData.childList ?
        ' '.concat(applicationAnswers.jsonData.childList[Number(childIndex) - 1]?.childFirstName) : '';
    }
    return applicantName;
  }

  private populateEarnedIncomes(applicationAnswers: ApplicationAnswers, form: FormGroup, applicantChoices: CheckboxItem[]) {
    const earnedIncome = [];
    applicantChoices?.filter(choice => NONE !== choice.value && choice.checked).forEach(choice => {
      const formArrayName = getIncomesFormArrayNameByApplicantType(choice.value);
      const incomesFormArray = form.get('incomes').get(formArrayName) as FormArray;
      const sourceList: EarnedIncomeSourceList = {
        applicantType: choice.value,
        applicantName: this.getApplicantNameByType(choice.value, applicationAnswers),
        earnedIncomeSourceList: []
      }
      incomesFormArray.controls.forEach(incomeFormGroup => {
        const fg = incomeFormGroup as FormGroup
        const source: IncomeSource = fg.value;

        if(applicationAnswers.jsonData.personWithDisability === 'no'
          || applicationAnswers.jsonData.personWithDisabilityInFamily === 'no'
          || (applicationAnswers.jsonData.personWithDisabilityInFamily === 'yes'
            && !applicationAnswers.jsonData.personWithDisabilityInFamilyCheckbox.includes(choice.value + ': ' + choice.labelParam.name))){
          delete source.disabilityWorkExpense;
          delete source.disabilityWorkExpenseMonthlyAmount;
        }
        if(!!source.disabilityWorkExpense && source.disabilityWorkExpense === 'no'){
          delete source.disabilityWorkExpenseMonthlyAmount;
        }

        sourceList.earnedIncomeSourceList.push(source);
      })
      earnedIncome.push(sourceList);
    })
    applicationAnswers.jsonData.earnedIncome = earnedIncome;

    if(!!applicationAnswers.jsonData.earnedIncome[0]){
      applicationAnswers.jsonData.earnedIncome[0].earnedIncomeMonth = getCurrentMonthAsString();
    }
  }

  private populateChildrenExpenses(applicationAnswers: ApplicationAnswers, form: FormGroup,
                                   childCareChildrenOptions: CheckboxItem[]) {
    if (getChildrenYoungerThan18(applicationAnswers).length) {
      if (form.get('anyChildCareExpense')?.value === 'yes') {
        const childCareExpenseList: ChildCare[] = [];
        childCareChildrenOptions?.filter(choice => choice.checked).forEach(choice => {
          const fgName = getChildCareExpenseFGName(choice.value);
          const formGroup = form.get('childCareExpenses').get(fgName);
          const childCare: ChildCare = formGroup.value;
          childCare.applicantType = choice.value;
          childCare.careRecipientName = this.getChildName(applicationAnswers, choice.value)
          childCareExpenseList.push(childCare);
        });

        applicationAnswers.jsonData.anyChildCareExpenseList = [{
          applicantType: APPLICANT,
          childCare: childCareExpenseList
        }];
      } else {
        delete applicationAnswers.jsonData.anyChildCareExpenseList;
      }
      applicationAnswers.jsonData.anyChildCareExpense = form.get('anyChildCareExpense')?.value;
    } else {
      delete applicationAnswers.jsonData.anyChildCareExpenseList;
      delete applicationAnswers.jsonData.anyChildCareExpense;
    }
  }

  getChildName(applicationAnswers: ApplicationAnswers, applicantType: string) {
    const childObj = applicationAnswers.jsonData.childList?.find((child, childIndex) => {
      return applicantType === CHILD_PREFIX + ' ' +(childIndex + 1);
    });
    return childObj ? childObj.childFirstName.concat(' ').concat(childObj.childLastName) : '';
  }
}

export function createIncomeFG(fb: FormBuilder): FormGroup {
  const fg = fb.group({});
  Object.keys(IncomeQuestionsFormNames).forEach(formNameKey => {
    fg.addControl(IncomeQuestionsFormNames[formNameKey], fb.control(''))
  })
  return fg;
}

export function createChildCareFG(fb: FormBuilder): FormGroup {
  const fg = fb.group({});
  Object.keys(ChildCareFormNames).forEach(formNameKey => {
    fg.addControl(ChildCareFormNames[formNameKey], fb.control(''))
  })
  return fg;
}

export function getIncomesFormArrayNameByApplicantType(applicantType: string): string {
  let formArrayName: string;
  if (APPLICANT === applicantType) {
    formArrayName = 'primaryApplicantIncomes';
  }

  if (SPOUSE === applicantType) {
    formArrayName = 'spouseIncomes';
  }

  if (applicantType.startsWith(CHILD_PREFIX)) {
    const withoutSpace = applicantType.replace(' ', '');
    formArrayName = withoutSpace + 'Incomes';
  }

  return formArrayName;
}

export function getChildCareExpenseFGName(applicantType: string): string {
  let formGroupName: string;

  if (applicantType.startsWith(CHILD_PREFIX)) {
    const withoutSpace = applicantType.replace(' ', '');
    formGroupName = withoutSpace + 'Expense';
  }

  return formGroupName;
}
