import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {ExternalRouter} from '../../external.router';
import {UrlInfo} from '../../models/url-map';
import {ConfigService} from '../../services/config.service';
import {PageInfo} from "../../models/page-map";
import { RouteStateService } from 'src/app/services/route-state.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit, OnDestroy {
  link:string
  pageId: string;

  constructor(private externalRouter: ExternalRouter,
              private routeStateService: RouteStateService,
              public translator: TranslateService,
              public configService: ConfigService) {
  }

  ngOnInit(): void {
      this.link = this.configService.getUrl(this.translator.currentLang, UrlInfo.ontarioWorks);
      this.pageId = PageInfo.unauthorized;
      this.routeStateService.setErrorOrInfoPage(true);
  }

  onGoHomeClicked() {
    this.externalRouter.navigate(this.link)
  }

  ngOnDestroy() {
    this.routeStateService.setErrorOrInfoPage(false);
  }
}
