<div class="d-flex" *ngIf="dialogHeader">
  <h2 class="ontario-h2 ontario-small-12 flex-fill dialog-title" [innerHTML]="dialogHeader | translate"></h2>
  <div class="close-button-div">
    <input type="image" *ngIf="showCloseButton" id="close-button" alt="close button" tabindex="0" class="flex-fill " (click)="onCloseButtonClicked()" role="img" src="assets/images/close-x.svg"/>
  </div>
</div>

<div class="dialog-body-container">
  <div class="d-flex pt-4">
    <div class="dialog-body flex-fill" [innerHTML]="dialogBody | translate: dialogBodyLink"></div>
  </div>

  <div *ngIf="alertInfoKey" class="d-flex pt-3">
    <div class="ontario-alert__header">
      <div class="ontario-alert__header-icon">
        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-alert-information"></use>
        </svg>
      </div>
      <div id="alertInfoKey-content-id" class="dialog-body flex-fill" [innerHTML]="alertInfoKey"></div>
    </div>
  </div>

  <div class="row pt-4">
    <div [ngClass]="{'multi-button': rightDialogButtonText}" id="dialog-button-container" class="col-12 d-flex">
      <button data-e2e="dialogBtn" class="dialog-button ontario-button ontario-button--primary"
              id="dialog-button" appPreventDoubleClick [throttleTime]="1000"
              (throttledClick)="onButtonClicked()">{{dialogButtonText | translate}}</button>
      <button data-e2e="dialogBtnRight" *ngIf="rightDialogButtonText"
              class="dialog-button ontario-button ontario-button--secondary" id="right-dialog-button"
              (click)="onRightButtonClicked()">{{rightDialogButtonText | translate}}</button>
    </div>
  </div>
</div>
<div>
  <a *ngIf="enableReturnToAppLink" id="return-to-app-link" href="#" (click)="handleReturnToApplication()">
    <span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4939 7.50658L10.9662 12.1482L15.5979 16.686L14.204 18.1119L8.13637 12.1803L14.068 6.11265L15.4939 7.50658Z" fill="#0066CC"/>
</svg>
      {{ 'return-to-application-link' | translate }}</span>
  </a>
</div>
