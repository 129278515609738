<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <div id="page-alerts-container">
    <sd-info-text header="children-information.fill.all"
                  contentId="childrenPageInfo"
                  content="children-information.page.info">
    </sd-info-text>
  </div>
  <form [formGroup]="form" sdDisableAutocomplete>
    <div formArrayName="children">
      <div *ngFor="let child of children().controls; let childIndex=index"  class="ontario-bg-greyscale-5 ontario-margin-top-16-! ontario-padding-right-16-! ontario-padding-left-16-! ontario-padding-bottom-16-!" [ngClass]="{'ontario-margin-top-40-!': childIndex>=1}">
          <div [formGroupName]="childIndex" class="ontario-padding-top-16-!">
            <h3 class="ontario-h3">{{'children-information.child.details' | translate: {index: childIndex + 1} }}</h3>
            <div class="ontario-bg-white grey-border ontario-padding-left-16-! ontario-padding-right-16-!" [ngClass]="{'ontario-padding-bottom-40-!': children().controls?.length === 1}">
              <sd-text-question [id]="'children-information.childFirstName'"
                                controlName="childFirstName"
                                [required]="true"
                                [labelParam]="{ index: childIndex + 1 }"
                                [validationFns]="firstNameValidationFunctions"
                                [capitalizeFirstLetter]= true
                                [minLength]= 1 [maxLength]= 65
                                [characterWidth]= 20 [truncateMultipleSpaces]="true"
                                [showError]="showError"
                                [hintText]="'children-information.childFirstName.hintText'"
                                elementIndex="{{childIndex}}">
              </sd-text-question>
              <sd-text-question [id]="'children-information.childLastName'"
                                controlName="childLastName"
                                [required]="!isNoLastNameSelected(childIndex)"
                                [labelParam]="{ index: childIndex + 1 }"
                                [validationFns]="lastNameValidationFunctions"
                                [capitalizeFirstLetter]= true
                                [minLength]= 1 [maxLength]= 65
                                [characterWidth]= 20 [truncateMultipleSpaces]="true"
                                [showError]="showError"
                                [hintText]="'children-information.childLastName.hintText'"
                                elementIndex="{{childIndex}}">
              </sd-text-question>
              <sd-checkbox-question [id]="'children-information.childNoLastName'"
                                    controlName="childNoLastName"
                                    [options]="getNoLastNameCheckBoxItems(childIndex)"
                                    [showLabel]=false
                                    [labelParam]="{ index: childIndex + 1 }"
                                    backgroundClass="sada-checkbox-border-grey"
                                    (toggle)="onNoLastNameCheckBoxChanges(childIndex, $event, true)">
              </sd-checkbox-question>
              <sd-date [showError]="showError"
                       [id]="'children-information-childDateOfBirth'+childIndex"
                       label="children-information.childDateOfBirth"
                       [labelParam]="{ index: childIndex + 1 }"
                       controlNameDate="childDateOfBirth"
                       [validationFns]="dobValidationFunctions"
                       (blurEvent)="onChildDateOfBirthBlur($event, childIndex)"
                       [needMarginTop] = true
                       [smallRightMargin] = true
                       [required]=true>
              </sd-date>
              <sd-radio-question [id]="'children-information.childSexAtBirth'"
                                 controlName="childSexAtBirth"
                                 [required]="true"
                                 [choices]="sexes"
                                 [labelParam]="{ index: childIndex + 1 }"
                                 [context]="'children-information.childSexAtBirth.context'"
                                 [showError]="showError"
                                 [hintText]="'children-information.childSexAtBirth.hintText'">
              </sd-radio-question>
              <sd-status-in-canada-questions [id]="'children-information.status'"
                                             [applicationAnswers]="applicationAnswers"
                                             [firstName]="form.value.children[childIndex].childFirstName || ''"
                                             [labelParam]="{ index: childIndex + 1,
                                             firstName: form.value.children[childIndex].childFirstName || '',
                                             lastName: form.value.children[childIndex].childLastName || ''}"
                                             [applicantType]="applicantType"
                                             [showError]="showError"
                                             parentFormGroupIndex="{{childIndex}}"
                                             [parentFormGroups]="children().controls"
                                             [hintText]="'true'" [hintContent]="'true'"
                                             [hintContentParam]="officeLocationLinkParam"
                                             [smallRightMargin]=true
                                             checkboxBackgroundClass="sada-checkbox-border-grey">
              </sd-status-in-canada-questions>
              <sd-text-question [id]="'children-information.childEmail'"
                                controlName="childEmail"
                                [required]= "isEmailRequired()"
                                [labelParam]="{ index: childIndex + 1 }"
                                [validationFns]="emailValidationFunctions"
                                [asyncValidationFns]="emailAsyncValidationFunctions"
                                [hintText]="'APPLICATION_OTHER' === applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse && !isAuthorizedUser ? 'emailHint' : 'emailHintWithDesc'"
                                [showError]="showError"
                                [characterWidth]=20
                                [showCustomRequiredErrorMessage]="customRequiredErrorMessage"
                                (keydown.enter)="preventDefaultAction($event)"
                                *ngIf="showEmailAddress(childIndex)"
                                elementIndex="{{childIndex}}">
              </sd-text-question>
              <sd-checkbox-question [id]="'children-information.childNoUniqueEmail'"
                                    [options]="getNoUniqueEmailCheckBoxItems(childIndex)"
                                    [showLabel]=false
                                    [labelParam]="{ index: childIndex + 1 }"
                                    backgroundClass="sada-checkbox-border-grey"
                                    (toggle)="onNoUniqueEmailCheckBoxChanges(childIndex, $event)"
                                    *ngIf="isAuthorizedUser && showEmailAddress(childIndex)">
              </sd-checkbox-question>
              <button id="children-information.removeChild-{{childIndex}}" type="button" class=" remove-button ontario-button ontario-button--tertiary" *ngIf="children().controls?.length > 1" (click)="removeChild(childIndex)">{{'children-information.remove.child' | translate}}</button>
            </div>
          </div>
        </div>
      <div class="ontario-bg-greyscale-5 ontario-padding-right-16-! ontario-padding-left-16-! small-add-button-padding-bottom">
        <button id="children-information.addChild" type="button" class="ontario-button ontario-button--secondary add-button-margin-bottom" (click)="addChild()">{{'children-information.add.another.child' | translate}}</button>
      </div>
    </div>
    <div class="ontario-padding-top-40-!"></div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
