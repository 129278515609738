import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {
  ApplicationProgramStatus,
  ApplicationStatusResponse,
  ApplicationStatusType,
  DisabilityApplicationPathwayType,
  DisabilityApplicationStatus
} from '../../models/application-status-response';
import {ConfigService} from '../../services/config.service';
import {dateFormatter} from "../../common/utils/formatters/formatter";

@Component({
  selector: '',
  template: '',
  styles: []
})
export abstract class ApplicationStatusBaseComponent implements OnInit {
  @Input()
  applicationStatus: ApplicationStatusResponse;

  // To indicate if the progress steps need to be rebuilt after the language is switched.
  protected hasLanguageDependentParam = false;

  protected constructor(protected translator: TranslateService,
                        protected configService: ConfigService) {
  }

  protected abstract buildProgressSteps();

  ngOnInit() {
    this.buildProgressSteps();
    if (this.hasLanguageDependentParam) {
      this.translator.onLangChange.subscribe(() => {
        this.buildProgressSteps();
      });
    }
  }

  protected isDecisionStatus(programLevelStatus: ApplicationStatusType) {
    return (!programLevelStatus ? false :
      [ApplicationStatusType.Approved, ApplicationStatusType.Denied, ApplicationStatusType.Withdrawn].includes(programLevelStatus));
  }

  protected isDauDdpReceivedNotNullExemptedStatus(programStatus: ApplicationProgramStatus): boolean{
    const pathway = programStatus.disabilityApplicationPathway;
    const statusInfo = programStatus.disabilityApplicationStatusInfo;
    const packageInfo = statusInfo?.disabilityDeterminationPackageInfo;
    const applicationStatus = statusInfo?.disabilityApplicationStatus;
    const exemptedStatuses = [DisabilityApplicationStatus.DisabilityDeterminationPackageIssued, DisabilityApplicationStatus.ReferralCancelled, DisabilityApplicationStatus.IncompleteDisabilityDeterminationPackageReceived
    , DisabilityApplicationStatus.DisabilityDeterminationPackageIssued];

    return pathway === DisabilityApplicationPathwayType.DisabilityAdjudicationUnit
      && ((applicationStatus === DisabilityApplicationStatus.ReferralCancelled)
        || (applicationStatus === DisabilityApplicationStatus.IncompleteDisabilityDeterminationPackageReceived)
        || (applicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageIssued)
        || (applicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageExpired && packageInfo?.ddpReceivedDate === null)
        || (!exemptedStatuses.includes(applicationStatus) && packageInfo?.ddpReceivedDate !== null));
  }

  protected catchAllStatusAndExemptions(programStatus: ApplicationProgramStatus)
  {
    const noDdpReceivedDate = programStatus.disabilityApplicationStatusInfo?.disabilityDeterminationPackageInfo?.ddpReceivedDate == null;
    const isNotReferralCancelled = programStatus.disabilityApplicationStatusInfo?.disabilityApplicationStatus !== DisabilityApplicationStatus.ReferralCancelled;
    const isNotIncompleteDdpReceived = programStatus.disabilityApplicationStatusInfo?.disabilityApplicationStatus !== DisabilityApplicationStatus.IncompleteDisabilityDeterminationPackageReceived;
    const isNotPrescribedClass = programStatus.disabilityApplicationPathway !== DisabilityApplicationPathwayType.DisabilityDeterminationEvidence_PrescribedClass;
    const isNotDdpIssued = programStatus.disabilityApplicationStatusInfo?.disabilityApplicationStatus !== DisabilityApplicationStatus.DisabilityDeterminationPackageIssued
    const isNotDdpExpiredWithNullCompletionDate = !(programStatus.disabilityApplicationStatusInfo?.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageExpired && programStatus.disabilityApplicationStatusInfo?.disabilityDeterminationPackageInfo?.ddpReceivedDate == null)
    // If the application pathway is null, or it is dau pathway and ddpReceivedDate is null and catch all scenarios with exemptions #47065 #65123
    return programStatus.disabilityApplicationPathway==null || (noDdpReceivedDate && isNotReferralCancelled && isNotPrescribedClass && isNotDdpExpiredWithNullCompletionDate && isNotIncompleteDdpReceived && isNotDdpIssued);
  }

  protected getUrl(key: string) {
    return this.configService.getUrl(this.translator.currentLang, key);
  }

  protected multiProgramsPersonWithDisability(odsProgramStatus, odsProgramProgressSteps){
    if(odsProgramStatus.disabilityApplicationStatusInfo?.disabilityApplicationStatus === DisabilityApplicationStatus.PersonWithDisability){
      odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'person-with-disability-disability-determination-package-received',
        name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED_WITH_DATE',
        nameParam: {
          link: odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpReceivedDate ?
            dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpReceivedDate, this.translator.currentLang) : '???'
        },
        completed: true,
        active: false,
        failed: false,
        hasStepFollowed: true
      });
      if(odsProgramStatus.applicationStatus === ApplicationStatusType.Assigned){
        odsProgramProgressSteps.push({
          programType: odsProgramStatus.programType,
          id: 'person-with-disability-disability-determination-package-decision',
          name: '.medical-eligibility.step3.name.APPROVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.dauDecisionDate, this.translator.currentLang),
          },
          text: '.medical-eligibility.step3.text.PERSON_WITH_DISABILITY',
          completed: false,
          active: true,
          failed: false,
          hasStepFollowed: true
        });
      }else if(odsProgramStatus.applicationStatus === ApplicationStatusType.Withdrawn
        || odsProgramStatus.applicationStatus === ApplicationStatusType.Approved
        || odsProgramStatus.applicationStatus === ApplicationStatusType.Denied){
        odsProgramProgressSteps.push({
          programType: odsProgramStatus.programType,
          id: 'complete-disability-determination-package-received',
          name: '.medical-eligibility.step3.name.APPROVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.dauDecisionDate, this.translator.currentLang),
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: true
        });
      }
    }
  }
}
