<sd-page-container [pageId]="pageId">
  <div id="page-alerts-container">
    <div *ngFor="let banner of banners" [class]="getBannerClass(banner.type)">
      <div class="ontario-alert__header">
        <div class="ontario-alert__header-icon">
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use [attr.href]="getBannerIcon(banner.type)"></use>
          </svg>
        </div>
        <h2 class="ontario-alert__header-title ontario-h4">
          <span>{{ translator.currentLang === 'en' ? banner.headerEn : banner.headerFr }}</span>
        </h2>
      </div>
      <div class="ontario-alert__body sm-custom-body">
        <p id="alert-banner-content" [innerHTML]="translator.currentLang === 'en' ? banner.messageEn : banner.messageFr"></p>
      </div>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" sdDisableAutocomplete>
    <div class="form-group required">
      <div class="ontario-margin-top-40-!">
        <hr class="divider" aria-hidden="true"/>
      </div>
      <sd-text-question [showError]="showError"
                        [id]="'app-status-landing.referenceCode'"
                        [validationFns]="refCodeLengthValidatorFn"
                        [required]=true
                        [showCustomError]="errorMsgKey"
                        [hintText]="'app-status-landing.hintText.refCode'"
                        [capitalize]=true
                        controlName="referenceCode">
      </sd-text-question>
    </div>
    <div>
      <sd-alert-err *ngIf="recaptchaV2Service.reCaptchaVerifyCallFailure===true"
                    [errorId]="'app-status-landing.recaptcha-verify-call-err'"
                    [errorMessage]="'error.reCaptchaVerifyCallError' | translate">
      </sd-alert-err>
      <sd-alert-err *ngIf="recaptchaV2Service.captchaSuccess === false"
                    [errorId]="'app-status-landing.recaptcha-verify-err'"
                    [errorMessage]="'error.reCaptchaVerifyFailedError' | translate">
      </sd-alert-err>
    </div>
    <br/>
    <app-mccss-submit-button dataE2E="continueBtn" buttonLabel="btn.continue" [preventDoubleClick]="true" (handleClick)="onSubmit()"></app-mccss-submit-button>
    <div *ngIf="enableRecaptcha">
      <ngx-invisible-recaptcha #captchaElem
                               [siteKey]="recaptchaV2Service.siteKey"
                               (reset)="recaptchaV2Service.handleReset()"
                               (expire)="recaptchaV2Service.handleExpire()"
                               (load)="recaptchaV2Service.handleLoad()"
                               (success)="handleRecaptchaV2Success($event)"
                               [useGlobalDomain]="recaptchaV2Service.useGlobalDomain"
                               [hl]="recaptchaV2Service.lang"
                               [theme]="recaptchaV2Service.scheme"
                               [type]="recaptchaV2Service.type"
                               [badge]="'inline'"
                               formControlName="recaptcha">
      </ngx-invisible-recaptcha>
    </div>
  </form>
</sd-page-container>
