import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {combineLatest} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {flatToObject, swapKeyValue} from '@shared/shared.util';

export const TRANSLATIONS_PATHS = new InjectionToken<string[]>('ngx translation file paths')

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public textMappings: any;

  constructor(private http: HttpClient, @Inject(TRANSLATIONS_PATHS) paths: string[]) {
    combineLatest(paths.map(path =>http.get(path))).pipe(map(transObjs => {
      const mappings = transObjs.map(o=> {
        return [
          ...swapKeyValue(o, ['household-income', 'other.income.desc.option']),
          ...swapKeyValue(o, ['financial-assets', 'other.asset.desc.option']),
          ...swapKeyValue(o, ['bank-details', 'names'])
        ]
      })
      return flatToObject(mappings)
    })).pipe(first()).subscribe(value => {
      this.textMappings = {...value}
    })

  }
}
