import {Observable} from 'rxjs';
import {ESignatureResponse} from '../models/esignature/esignature-response.model';

export abstract class ESignatureService {

  abstract startESignature(): Observable<ESignatureResponse>

  abstract isApplicationESignEligible(): Observable<boolean>
}

