import {Choice} from '../../../common/utils/questions/question-choices';

export const GenderIdentity: Choice[] = [
  {value: 'WOMEN_OR_GIRL', label: 'choices.genderIdentity.womanOrGirl'},
  {value: 'MAN_OR_BOY', label: 'choices.genderIdentity.manOrBoy'},
  {value: 'TRANSGENDER', label: 'choices.genderIdentity.transgender'},
  {value: 'TRANSGENDER_WOMAN_OR_GIRL', label: 'choices.genderIdentity.transgenderWomanOrGirl'},
  {value: 'TRANSGENDER_MAN_OR_BOY', label: 'choices.genderIdentity.transgenderManOrBoy'},
  {value: 'GENDER_NON_BINARY', label: 'choices.genderIdentity.nonBinary'},
  {value: 'TWO_SPIRIT', label: 'choices.genderIdentity.twoSpirit'},
  {value: 'DO_NOT_KNOW', label: 'choices.genderIdentity.doNotKnow'},
  {value: 'PREFER_NOT_TO_SAY', label: 'choices.preferNotToSay'}
];

export const YesOrNo: Choice[] = [
  {value: 'YES', label: 'choices.yesOrNo.yes'},
  {value: 'NO', label: 'choices.yesOrNo.no'},
  {value: 'PREFER_NOT_TO_SAY', label: 'choices.preferNotToSay'}
];

export const IndigenousIdentityGroup: Choice[] = [
  {value: 'FIRST_NATIONS', label: 'choices.indigenousIdentityGroup.firstNations'},
  {value: 'METIS', label: 'choices.indigenousIdentityGroup.metis'},
  {value: 'INUIT', label: 'choices.indigenousIdentityGroup.inuit'},
  {value: 'PREFER_NOT_TO_SAY', label: 'choices.preferNotToSay'}
];

export const RacialIdentity: Choice[] = [
  {value: 'BLACK', label: 'choices.racialIdentity.black'},
  {value: 'EAST_SOUTHEAST_ASIA', label: 'choices.racialIdentity.asian'},
  {value: 'INDIGENOUS', label: 'choices.racialIdentity.indigenous'},
  {value: 'LATINO', label: 'choices.racialIdentity.latino'},
  {value: 'MIDDLE_EASTERN', label: 'choices.racialIdentity.middleEastern'},
  {value: 'SOUTH_ASIAN', label: 'choices.racialIdentity.southAsian'},
  {value: 'WHITE', label: 'choices.racialIdentity.white'},
  {value: 'MIXED', label: 'choices.racialIdentity.mixedAncestry'},
  {value: 'ANOTHER', label: 'choices.racialIdentity.another'},
  {value: 'PREFER_NOT_TO_SAY', label: 'choices.preferNotToSay'}
];

export const LanguageOfChoice: Choice[] = [
  {value: 'ENGLISH', label: 'choices.language.english'},
  {value: 'FRENCH', label: 'choices.language.french'},
  {value: 'OTHER', label: 'choices.language.other'}
];

export const RacialIdentityExample: Map<string, string> = new Map<string, string>()
  .set('BLACK', 'demographic-information.racialIdentityExample.black')
  .set('EAST_SOUTHEAST_ASIA', 'demographic-information.racialIdentityExample.eastSoutheastAsia')
  .set('INDIGENOUS', 'demographic-information.racialIdentityExample.indigenous')
  .set('LATINO', 'demographic-information.racialIdentityExample.latino')
  .set('MIDDLE_EASTERN', 'demographic-information.racialIdentityExample.middleEastern')
  .set('SOUTH_ASIAN', 'demographic-information.racialIdentityExample.southAsian')
  .set('WHITE', 'demographic-information.racialIdentityExample.white')
  .set('MIXED', 'demographic-information.racialIdentityExample.mixed')
  .set('ANOTHER', 'demographic-information.racialIdentityExample.another');
