import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sd-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})
export class ProgressIndicatorComponent implements OnInit {

  @Input()
  value: number;

  constructor() { }

  ngOnInit(): void {
  }

}
