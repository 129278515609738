import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {Idle} from '@ng-idle/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

import {popup} from '../../dialog-constants';
import {ExternalRouter} from '../../external.router';
import {MccssDialogTitleComponent} from '../../mccss-dialog-title/mccss-dialog-title.component';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../services/config.service';
import {IntakeService} from '../../services/intake.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageService} from '../../services/page.service';
import {formatDateToLocaleString} from '../../utils/date-util';
import {PageBaseComponent} from '../PageBaseComponent';

@Component({
  selector: 'sd-inprogress-or-expired-application',
  templateUrl: './inprogress-or-expired-application.component.html',
  styleUrls: ['./inprogress-or-expired-application.component.scss']
})
export class InprogressOrExpiredApplicationComponent extends PageBaseComponent implements OnInit, OnDestroy {
  applicationExpired = false;
  applicationCreatedDate: Date;
  lastAccessedPage = PageInfo.home;

  constructor(private router: Router,
              public route: ActivatedRoute,
              public intakeService: IntakeService,
              public translator: TranslateService,
              private translate:TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              private title: Title,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.subscriptions$.push(this.route.queryParams.subscribe(params => {
      this.applicationExpired = !!params?.expired;
    }));
  }

  ngOnInit(): void {
    // initialize form with application data
    this.initializeForm();
    this.subscriptions$.push(this.intakeService.getLastAccessedInfo()
      .subscribe((response) => {
        this.applicationCreatedDate = new Date(response.applicationCreatedDate);
        this.lastAccessedPage = response.pageName;
      }
    ));
    this.title.setTitle(this.translate.instant(this.applicationExpired ? 'headerTitles.expiredapplication' : 'headerTitles.inprogressorapplication'));
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.title.setTitle(this.translate.instant(this.applicationExpired ? 'headerTitles.expiredapplication' : 'headerTitles.inprogressorapplication'));
    });
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  postInitializeForm(): void {}

  preSaveApplication(): void {}

  get pageName(): string {
    return this.applicationExpired ? 'expired-application' : 'inprogress-application';
  }

  get pageTitleKey(): string {
    return this.applicationExpired ? 'expired-application.page.title' : 'inprogress-application.page.title';
  }

  get dateParam(): {createdDate} {
    return {createdDate: formatDateToLocaleString(this.translator.currentLang, this.applicationCreatedDate)};
  }

  onSubmit(useExistingApplication?: boolean): void {
    if (useExistingApplication) {
      this.handleRedirectToIncompleteApplication();
    } else if (this.applicationExpired) {
      this.handleResetApplicationAndRedirect();
    } else {
      this.openConfirmNewApplicationDialog();
    }
  }

  private openConfirmNewApplicationDialog(): void {
    if (this.dialogVisible) {
      return;
    }
    this.dialogVisible = true;
    const dialogRef = this.dialog.open(MccssDialogTitleComponent, {
      panelClass: 'sada-dialog-class'
    });

    dialogRef.componentInstance.dialogHeader = popup.startNewApplication.title;
    dialogRef.componentInstance.dialogBody = popup.startNewApplication.body;
    dialogRef.componentInstance.rightDialogButtonText = popup.startNewApplication.right_button;
    dialogRef.componentInstance.dialogButtonText = popup.startNewApplication.left_button;
    dialogRef.componentInstance.rightButtonAction = () => {
      this.handleResetApplicationAndRedirect();
    }
    dialogRef.componentInstance.buttonAction = () => {
      dialogRef.close();
    }
    dialogRef.componentInstance.closeButtonAction = () => {
      dialogRef.close();
    }
    this.subscriptions$.push(dialogRef.afterClosed().subscribe(_ => this.dialogVisible = false));
  }

  private handleRedirectToIncompleteApplication(): void {
    this.router.navigate(this.pageService.findRouteUrl(this.lastAccessedPage), {queryParams: {action: 'resume'}});
  }

  private handleResetApplicationAndRedirect(): void {
    this.subscriptions$.push(this.authService.resetApplication(this.translator.currentLang).subscribe(() =>
      this.router.navigate(this.pageService.findRouteUrl(PageInfo.bya))));  // Authenticated applicant always lands on BYA page.
  }
}
