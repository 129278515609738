import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';

export class HHINoOtherIncomeFormToData implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  static readonly NONE_OF_ABOVE = 'noOtherIncomeType';

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // remove previous saved data and repopulate with latest form data
    delete appData.jsonData[HHINoOtherIncomeFormToData.NONE_OF_ABOVE];

    if (form.get('NoneOfAbove')?.value[0]) {
      appData.jsonData[HHINoOtherIncomeFormToData.NONE_OF_ABOVE] = 'yes';
    }
  }
}
