import {Observable} from 'rxjs';

/**
 * This class encapsulates service logic between the header and page container.
 */
export abstract class MenuService {
  abstract getShowPSRedirectError(): Observable<boolean>;

  abstract setShowPSRedirectError(showPSRedirectError: boolean): void;

}
