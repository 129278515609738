import {ProgramType} from './program-type';

export interface ApplicationStatusResponse {
  programStatuses: ApplicationProgramStatus[];
}

export interface ApplicationProgramStatus {
  programType?: ProgramType;
  applicationStatus: ApplicationStatusType;
  submittedDate?: string;
  assignedForReviewDate?: string;
  icaDecisionDate?: string;
  disabilityApplicationPathway?: DisabilityApplicationPathwayType;
  disabilityApplicationStatusInfo?: DisabilityApplicationStatusInfo;
}

export interface DisabilityApplicationStatusInfo {
  disabilityApplicationStatus?: DisabilityApplicationStatus;
  disabilityDeterminationPackageInfo?: DisabilityDeterminationPackageInfo;
}

export interface DisabilityDeterminationPackageInfo {
  ddpIssuedDate: string;
  ddpExpiryDate: string;
  ddpReceivedDate: string;  // For both complete and incomplete DDP.
  dauDecisionDate: string
}

export enum ApplicationStatusType {
  // Application-level Pending - For single OW or ODSP program application:
  // BIL status = Submitted into BIL or Pending Call back (awaiting eSignature) or
  // SAMS Submission Initiated up to 48 hours since the app. Creation – SADA
  // Duplicate submission up to 48 hours since the app. creation – SADA
  // SAMS submission failed - SADA
  ApplicationPending = 'APPLICATION_PENDING',
  Approved = 'APPROVED',  // Application has been submitted to SAMS, and has been approved.
  Assigned = 'ASSIGNED',  // Application has been submitted to SAMS, and assigned to be reviewed.
  Cancelled = 'CANCELLED',  // Application has been submitted to SAMS, and has been cancelled.
  Denied = 'DENIED',  // Application has been submitted to SAMS, and has been declined.
  Expired = 'EXPIRED',  // Application has not been completed and saved, and passed the max no. of days allowed.
  Incomplete = 'INCOMPLETE',  // Application hasn't completed, exists in Intake.
  StatusUnavailable = 'STATUS_UNAVAILABLE',  // MyB SadaUser lookup error or Intake lookup failed.
  Submitted = 'SUBMITTED',  // Application has been submitted to SAMS, and in pending status.
  Withdrawn = 'WITHDRAWN'  // Application has been submitted to SAMS, but being withdrawn.
}

export enum DisabilityApplicationPathwayType {
  DisabilityAdjudicationUnit = 'DAU',
  DisabilityDeterminationEvidence_PrescribedClass = 'DDE_PC'
}

export enum DisabilityApplicationStatus {
  CompleteDisabilityDeterminationPackageReceived = 'COMPLETE_DDP_RECEIVED',
  DisabilityAdjudicationNotRequired = 'ADJUDICATION_NOT_REQUIRED',
  DisabilityDeterminationPackageExpired = 'DDP_EXPIRED',
  DisabilityDeterminationPackageIssued = 'DDP_ISSUED',
  IncompleteDisabilityDeterminationPackageReceived = 'INCOMPLETE_DDP_RECEIVED',
  PersonWithDisability = 'PERSON_WITH_DISABILITY',
  PersonWithoutDisability = 'PERSON_WITHOUT_DISABILITY',
  ReferralCancelled = 'REFERRAL_CANCELLED'
}
