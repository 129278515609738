import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {dateFormatter} from '../../../common/utils/formatters/formatter';
import {ApplicationProgramStatus, ApplicationStatusType} from '../../../models/application-status-response';
import {ProgramType} from '../../../models/program-type';
import {ConfigService} from '../../../services/config.service';
import {ApplicationStatusBaseComponent} from '../ApplicationStatusBaseComponent';
import {AscStatusProgressStep} from '../asc-status-progress-bar/asc-status-progress-step';

@Component({
  selector: 'sd-onw-asc-status',
  templateUrl: './onw-asc-status.component.html',
  styleUrls: ['./onw-asc-status.component.scss']
})
export class OnwAscStatusComponent extends ApplicationStatusBaseComponent {
  public progressSteps: AscStatusProgressStep[];

  constructor(protected translator: TranslateService,
              protected configService: ConfigService) {
    super(translator, configService);
    this.hasLanguageDependentParam = true;
  }

  protected buildProgressSteps(): AscStatusProgressStep[] {
    if ((this.applicationStatus?.programStatuses?.length !== 1) ||
        (this.applicationStatus?.programStatuses[0].programType !== ProgramType.ONW)) {
      return;
    }

    this.progressSteps = [];
    if (this.isDecisionStatus(this.applicationStatus?.programStatuses[0].applicationStatus)) {
      this.progressSteps = this.buildDecisionSteps(this.applicationStatus?.programStatuses[0]);
    } else if (this.applicationStatus?.programStatuses[0].applicationStatus === ApplicationStatusType.Assigned) {
      this.progressSteps = this.buildAssignedForReviewSteps(this.applicationStatus?.programStatuses[0]);
    } else if (this.applicationStatus?.programStatuses[0].applicationStatus === ApplicationStatusType.Submitted) {
      this.progressSteps = this.buildSubmittedSteps(this.applicationStatus?.programStatuses[0]);
    }
  }

  private buildSubmittedSteps(programStatus: ApplicationProgramStatus): AscStatusProgressStep[] {
    const submittedSteps = [];
    submittedSteps.push({
      programType: programStatus.programType,
      id: 'submitted',
      name: '.step1.name.SUBMITTED',
      text: '.step1.text',
      textParam: {link: programStatus.submittedDate ? dateFormatter(programStatus.submittedDate, this.translator.currentLang) : '???'},
      completed: false,
      active: true,
      failed: false,
      hasStepFollowed: true,
    });
    submittedSteps.push({
      programType: programStatus.programType,
      id: 'assigned',
      name: '.step2.name.SUBMITTED',
      completed: false,
      active: false,
      hasStepFollowed: true,
    });
    submittedSteps.push({
      programType: programStatus.programType,
      id: 'decision-status',
      name: '.step3.name.SUBMITTED',
      completed: false,
      active: false,
      hasStepFollowed: false,
    });
    return submittedSteps;
  }

  private buildAssignedForReviewSteps(programStatus: ApplicationProgramStatus): AscStatusProgressStep[] {
    const inReviewStep = [];
    inReviewStep.push({
      programType: programStatus.programType,
      id: 'submitted',
      name: '.step1.name.ASSIGNED',
      nameParam: {link: programStatus.submittedDate ? dateFormatter(programStatus.submittedDate, this.translator.currentLang) : '???'},
      completed: true,
      active: false,
      hasStepFollowed: true,
    });
    inReviewStep.push({
      programType: programStatus.programType,
      id: 'assigned',
      name: '.step2.name.ASSIGNED',
      nameParam: {link: programStatus.assignedForReviewDate ?
          dateFormatter(programStatus.assignedForReviewDate, this.translator.currentLang) : '???'},
      text: '.step2.text',
      textParam: {link: programStatus.assignedForReviewDate ?
          dateFormatter(programStatus.assignedForReviewDate, this.translator.currentLang) : '???'},
      completed: false,
      active: true,
      failed: false,
      hasStepFollowed: true,
    });
    inReviewStep.push({
      programType: programStatus.programType,
      id: 'decision-status',
      name: '.step3.name.ASSIGNED',
      completed: false,
      active: false,
      hasStepFollowed: false,
    });
    return inReviewStep;
  }

  private buildDecisionSteps(programStatus: ApplicationProgramStatus): AscStatusProgressStep[] {
    const decisionSteps = [];
    decisionSteps.push({
      programType: programStatus.programType,
      id: 'submitted',
      name: '.step1.name.ASSIGNED',
      nameParam: {link: programStatus.submittedDate ? dateFormatter(programStatus.submittedDate, this.translator.currentLang) : '???'},
      completed: true,
      active: false,
      hasStepFollowed: true,
    });
    decisionSteps.push({
      programType: programStatus.programType,
      id: 'assigned',
      name: '.step2.name.DECISION',
      nameParam: {link: programStatus.assignedForReviewDate ?
          dateFormatter(programStatus.assignedForReviewDate, this.translator.currentLang) : '???'},
      completed: true,
      active: false,
      hasStepFollowed: true,
    });
    decisionSteps.push({
      programType: programStatus.programType,
      id: 'decision-status',
      name: '.step3.name.'.concat(programStatus.applicationStatus),
      text: '.step3.text.'.concat(programStatus.applicationStatus),
      textParam: {
        link: programStatus.icaDecisionDate ? dateFormatter(programStatus.icaDecisionDate, this.translator.currentLang) : '???',
      },
      completed: false,
      active: true,
      failed: programStatus.applicationStatus === ApplicationStatusType.Withdrawn,
      hasStepFollowed: false,
    });
    return decisionSteps;
  }
}
