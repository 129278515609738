import {Choice} from '../../common/utils/questions/question-choices';
import {ApplicationAnswers} from '../../models/data.model';

export function firstNationsNameChoices() {
  const firstNationsNames: Choice[] = [
    {
      value: 'INGR13003',
      label: 'Aamjiwnaang First Nation'
    },
    {
      value: 'INGR13004',
      label: 'Alderville First Nation'
    },
    {
      value: 'INGR13005',
      label: 'Algonquins of Pikwakanagan First Nation'
    },
    {
      value: 'INGR13006',
      label: 'Animbiigoo Zaagi\'igan Anishinaabek First Nation (Lake Nipigon Ojibway)'
    },
    {value: 'INGR13007', label: 'Anishinaabeg of Naongashiing (Big Island)'},
    {
      value: 'INGR13008',
      label: 'Aroland First Nation'
    },
    {value: 'INGR13009', label: 'Atikameksheng Anishnawbek (Whitefish Lake)'},
    {
      value: 'INGR13010',
      label: 'Attawapiskat First Nation'
    },
    {value: 'INGR13011', label: 'Aundeck Omni Kaning First Nation (Ojibways of Sucker Creek)'},
    {
      value: 'INGR13012',
      label: 'Bearskin Lake First Nation'
    },
    {value: 'INGR13013', label: 'Beausoleil First Nation (Christian Island)'},
    {
      value: 'INGR13014',
      label: 'Beaverhouse First Nation'
    },
    {value: 'INGR13015', label: 'Big Grassy First Nation'},
    {
      value: 'INGR13016',
      label: 'Biinjitiwaabik Zaaging Anishinaabek First Nation (Rocky Bay)'
    },
    {value: 'INGR13017', label: 'Bingwi Neyaashi Anishinaabek (Sand Point)'},
    {
      value: 'INGR13018',
      label: 'Bkejwanong Territory (Walpole Island)'
    },
    {value: 'INGR13019', label: 'Brunswick House First Nation'},
    {
      value: 'INGR13020',
      label: 'Caldwell First Nation'
    },
    {value: 'INGR13021', label: 'Cat Lake First Nation'},
    {
      value: 'INGR13022',
      label: 'Chapleau Cree First Nation'
    },
    {value: 'INGR13023', label: 'Chapleau Ojibwe First Nation'},
    {
      value: 'INGR13024',
      label: 'Chippewas of Georgina Island'
    },
    {value: 'INGR13025', label: 'Chippewas of Kettle & Stony Point'},
    {
      value: 'INGR13026',
      label: 'Chippewas of Rama'
    },
    {value: 'INGR13027', label: 'Chippewas of Nawash Unceded (Cape Croker)'},
    {
      value: 'INGR13028',
      label: 'Chippewas of Saugeen'
    },
    {value: 'INGR13029', label: 'Chippewas of the Thames'},
    {
      value: 'INGR13030',
      label: 'Constance Lake First Nation'
    },
    {value: 'INGR13031', label: 'Couchiching First Nation'},
    {
      value: 'INGR13032',
      label: 'Curve Lake First Nation'
    },
    {value: 'INGR13033', label: 'Deer Lake First Nation'},
    {
      value: 'INGR13034',
      label: 'Delaware Nation (Moravian of the Thames)'
    },
    {value: 'INGR13035', label: 'Dokis First Nation'},
    {
      value: 'INGR13036',
      label: 'Eabametoong First Nation'
    },
    {value: 'INGR13037', label: 'Eagle Lake First Nation'},
    {
      value: 'INGR13038',
      label: 'Flying Post First Nation'
    },
    {value: 'INGR13039', label: 'Fort Albany First Nation'},
    {
      value: 'INGR13040',
      label: 'Fort Severn First Nation'
    },
    {value: 'INGR13041', label: 'Fort William First Nation'},
    {
      value: 'INGR13042',
      label: 'Ginoogaming First Nation'
    },
    {value: 'INGR13043', label: 'Grassy Narrows First Nation'},
    {
      value: 'INGR13044',
      label: 'Hiawatha First Nation'
    },
    {value: 'INGR13045', label: 'Henvey Inlet First Nation'},
    {
      value: 'INGR13046',
      label: 'Hornepayne First Nation'
    },
    {value: 'INGR13047', label: 'Iskatewizaagegan No. 39 Independent First Nation'},
    {
      value: 'INGR13048',
      label: 'Kasabonika Lake First Nation'
    },
    {value: 'INGR13049', label: 'Kashechewan First Nation'},
    {
      value: 'INGR13050',
      label: 'Keewaywin First Nation'
    },
    {value: 'INGR13051', label: 'Kiashke Zaaging Anishinaabek First Nation(Gull Bay)'},
    {
      value: 'INGR13052',
      label: 'Kingfisher Lake First Nation'
    },
    {value: 'INGR13053', label: 'Kitchenuhmaykoosib Inninuwug (Big Trout Lake)'},
    {
      value: 'INGR13054',
      label: 'Koocheching First Nation'
    },
    {value: 'INGR13055', label: 'Lac Des Mille Lacs First Nation'},
    {
      value: 'INGR13056',
      label: 'Lac La Croix First Nation'
    },
    {value: 'INGR13057', label: 'Lac Seul First Nation'},
    {
      value: 'INGR13058',
      label: 'Long Lake #58 First Nation'
    },
    {value: 'INGR13059', label: 'Magnetawan First Nation'},
    {
      value: 'INGR13060',
      label: 'Marten Falls First Nation'
    },
    {value: 'INGR13061', label: 'Matachewan First Nation'},
    {
      value: 'INGR13062',
      label: 'Mattagami First Nation'
    },
    {value: 'INGR13063', label: 'MacDowell Lake First Nation'},
    {
      value: 'INGR13064',
      label: 'M’Chigeeng First Nation (West Bay)'
    },
    {value: 'INGR13065', label: 'Michipicoten First Nation'},
    {
      value: 'INGR13066',
      label: 'Mishkeegogamang First Nation (New Osnaburgh)'
    },
    {value: 'INGR13067', label: 'Missanabie Cree First Nation'},
    {
      value: 'INGR13068',
      label: 'Mississauga #8 First Nation'
    },
    {value: 'INGR13069', label: 'Mississaugas of the New Credit First Nation'},
    {
      value: 'INGR13070',
      label: 'Mississaugas of Scugog Island'
    },
    {value: 'INGR13071', label: 'Mocreebec Council of the Cree Nation'},
    {
      value: 'INGR13072',
      label: 'Mohawks of Akwesasne'
    },
    {value: 'INGR13073', label: 'Mohawks of the Bay of Quinte'},
    {
      value: 'INGR13074',
      label: 'Moose Cree First Nation'
    },
    {value: 'INGR13075', label: 'Moose Deer Point First Nation'},
    {
      value: 'INGR13076',
      label: 'Munsee-Delaware Nation'
    },
    {value: 'INGR13077', label: 'Muskrat Dam First Nation'},
    {
      value: 'INGR13078',
      label: 'Naicatchewenin First Nation'
    },
    {value: 'INGR13079', label: 'Namaygoosisagagun First Nation'},
    {
      value: 'INGR13080',
      label: 'Naotkamegwanning Anishinabe First Nation (Whitefish Bay)'
    },
    {value: 'INGR13081', label: 'Neskantaga First Nation (Lansdowne House)'},
    {
      value: 'INGR13082',
      label: 'Nibinamik First Nation'
    },
    {value: 'INGR13083', label: 'Nigigoonsiminikaaning First Nation'},
    {
      value: 'INGR13084',
      label: 'Nipissing First Nation'
    },
    {value: 'INGR13085', label: 'North Caribou Lake First Nation (Weagamow or Round Lake)'},
    {
      value: 'INGR13086',
      label: 'North Spirit Lake First Nation'
    },
    {value: 'INGR13087', label: 'Northwest Angle No. 33 First Nation'},
    {
      value: 'INGR13088',
      label: 'Northwest Angle No. 37 First Nation'
    },
    {value: 'INGR13089', label: 'Obashkaandagaang (Washagamis Bay)'},
    {
      value: 'INGR13090',
      label: 'Ochiichagwe’Babigo’ining Nation (Dalles)'
    },
    {value: 'INGR13091', label: 'Ojibways of Batchewana (Rankin)'},
    {
      value: 'INGR13092',
      label: 'Ojibways of Garden River'
    },
    {value: 'INGR13093', label: 'Ojibways of Onigaming (Sabaskong)'},
    {
      value: 'INGR13094',
      label: 'Ojibways of Pic River (Heron Bay)'
    },
    {value: 'INGR13095', label: 'Oneida Nation of the Thames'},
    {
      value: 'INGR13096',
      label: 'Pays Plat First Nation'
    },
    {value: 'INGR13097', label: 'Pic Mobert First Nation'},
    {
      value: 'INGR13098',
      label: 'Pikangikum First Nation'
    },
    {value: 'INGR13099', label: 'Poplar Hill First Nation'},
    {
      value: 'INGR13100',
      label: 'Poplar Point First Nation'
    },
    {value: 'INGR13101', label: 'Rainy River First Nation'},
    {
      value: 'INGR13102',
      label: 'Red Rock Indian Band'
    },
    {value: 'INGR13103', label: 'Sachigo Lake First Nation'},
    {
      value: 'INGR13104',
      label: 'Sagamok Anishnawbek First Nation'
    },
    {value: 'INGR13105', label: 'Sandy Lake First Nation'},
    {
      value: 'INGR13106',
      label: 'Saugeen First Nation (Savant Lake)'
    },
    {value: 'INGR13107', label: 'Seine River First Nation'},
    {
      value: 'INGR13108',
      label: 'Serpent River First Nation'
    },
    {value: 'INGR13109', label: 'Shawanaga First Nation'},
    {
      value: 'INGR13110',
      label: 'Sheguiandah First Nation'
    },
    {value: 'INGR13111', label: 'Sheshegwaning First Nation'},
    {
      value: 'INGR13112',
      label: 'Shoal Lake No. 40 First Nation'
    },
    {value: 'INGR13113', label: 'Six Nations of the Grand River Territory'},
    {
      value: 'INGR13114',
      label: 'Slate Falls First Nation'
    },
    {value: 'INGR13115', label: 'Stanjikoming First Nation'},
    {
      value: 'INGR13116',
      label: 'Taykwa Tagamou (New Post)'
    },
    {value: 'INGR13117', label: 'Temagami First Nation'},
    {
      value: 'INGR13118',
      label: 'Thessalon First Nation'
    },
    {value: 'INGR13119', label: 'Wabaseemoong First Nation'},
    {
      value: 'INGR13120',
      label: 'Wabauskang First Nation'
    },
    {value: 'INGR13121', label: 'Wabigoon First Nation'},
    {
      value: 'INGR13122',
      label: 'Wahgoshig First Nation (Abitibi#70)'
    },
    {value: 'INGR13123', label: 'Wahnapitae First Nation'},
    {
      value: 'INGR13124',
      label: 'Wahta Mohawks (Mohawks of Gibson)'
    },
    {value: 'INGR13125', label: 'Wapekeka First Nation'},
    {
      value: 'INGR13126',
      label: 'Wasauksing First Nation (Parry Island)'
    },
    {value: 'INGR13127', label: 'Wauzhushk Onigum First Nation (Rat Portage)'},
    {
      value: 'INGR13128',
      label: 'Wawakapewin First Nation C/o Shibogama First Nation Council'
    },
    {value: 'INGR13129', label: 'Webequie First Nation'},
    {
      value: 'INGR13130',
      label: 'Weenusk First Nation (Peawanuk)'
    },
    {value: 'INGR13131', label: 'Whitefish River First Nation'},
    {
      value: 'INGR13132',
      label: 'Whitesand First Nation'
    },
    {value: 'INGR13133', label: 'Whitewater Lake First Nation'},
    {
      value: 'INGR13134',
      label: 'Wikwemikong Unceded Indian Reserve'
    },
    {value: 'INGR13135', label: 'Wunnumin Lake First Nation'},
    {
      value: 'INGR13136',
      label: 'Zhiibaahaasing First Nation (Cockburn)'
    },
    {
      value: 'INGR13137',
      label: 'Mamaweswen the North Shore Tribal Council'
    },
    {
      value: 'INGR13139',
      label: 'Walpole Island First Nation'
    },
    {
      value: 'INGR13140',
      label: 'Bearfoot Onondaga'
    },
    {
      value: 'INGR13141',
      label: 'Delaware'
    },
    {
      value: 'INGR13142',
      label: 'Konadaha Seneca'
    },
    {
      value: 'INGR13143',
      label: 'Lower Cayuga'
    },
    {
      value: 'INGR13144',
      label: 'Lower Mohawk'
    },
    {
      value: 'INGR13145',
      label: 'Niharondasa Seneca'
    },
    {
      value: 'INGR13146',
      label: 'Oneida'
    },
    {
      value: 'INGR13147',
      label: 'Onondaga Clear Sky'
    },
    {
      value: 'INGR13148',
      label: 'Tuscarora'
    },
    {
      value: 'INGR13149',
      label: 'Upper Cayuga'
    },
    {
      value: 'INGR13150',
      label: 'Upper Mohawk'
    },
    {
      value: 'INGR13151',
      label: 'Walker Mohawk'
    }];
  return firstNationsNames
}

export const firstNationsODSPRedirectsNames = [
  'Atikameksheng Anishnawbek (Whitefish Lake)',
  'Mississauga #8 First Nation',
  'M’Chigeeng First Nation (West Bay)',
  'Ojibways of Batchewana (Rankin)',
  'Ojibways of Garden River',
  'Sagamok Anishnawbek First Nation',
  'Serpent River First Nation',
  'Thessalon First Nation',
  'Wahnapitae First Nation',
  'Mamaweswen the North Shore Tribal Council'
]

export function syncDataForAdditionalInfo(applicationAnswers: ApplicationAnswers, currentFieldValue: string,
                                          additionalInfoFieldName: string, familyInfoFieldName: string,
                                          checkBoxAttrName: any, subPanelAttrName?: string) {
  const data = applicationAnswers.jsonData;

  if (!!data.firstName) {
    const applicant = 'Applicant: ' + data.firstName;
    if (!!data[familyInfoFieldName]) { // Family
      const checkboxValues = data[checkBoxAttrName] ? data[checkBoxAttrName] : [];
      if (currentFieldValue === 'yes' && !checkboxValues.includes(applicant)) {
        data[familyInfoFieldName] = 'yes';
        data[checkBoxAttrName] = [applicant, ...checkboxValues];
      } else if (currentFieldValue === 'no' && data[familyInfoFieldName] &&
        !!checkboxValues?.find(recipient => recipient === applicant)) {

        // Remove applicant
        data[checkBoxAttrName].splice(data[checkBoxAttrName].findIndex(recipient => recipient === applicant), 1);

        // Remove applicant related data from sub panel obj
        if (!!data[subPanelAttrName]) {
          data[subPanelAttrName].splice(
            data[subPanelAttrName].findIndex(recipient => recipient.applicant === applicant), 1);
        }

        // Set answer to parent questions as no if no applicants selected
        if (data[checkBoxAttrName]?.length === 0) {
          data[familyInfoFieldName] = 'no';
          delete data[checkBoxAttrName];
          if (!!subPanelAttrName) {
            delete data[subPanelAttrName];
          }
        }
      }
    } else if (!!data[additionalInfoFieldName]) { // Single applicant
      data[additionalInfoFieldName] = currentFieldValue;
    }
  }
}

export function syncDisabilityData(applicationAnswers: ApplicationAnswers, currentFieldValue: string) {
  const additionalInfoFieldName = 'personWithDisability';
  const familyInfoFieldName = 'personWithDisabilityInFamily';
  const checkBoxAttrName = 'personWithDisabilityInFamilyCheckbox';
  const subPanelAttrName = 'developmentServicesOntarioList';
  syncDataForAdditionalInfo(applicationAnswers, currentFieldValue, additionalInfoFieldName, familyInfoFieldName,
    checkBoxAttrName, subPanelAttrName);

  const data = applicationAnswers.jsonData;
  // Additional cleanup specific to disability
  if (!!applicationAnswers.jsonData[additionalInfoFieldName] && currentFieldValue === 'no') { // Single applicant
    delete applicationAnswers.jsonData.developmentServicesOntario;
  } else if (!!data[familyInfoFieldName] && !!data.firstName) { // Family
    const applicant = 'Applicant: ' + data.firstName;
    const developmentServicesOntarioList: any[] = !!data.developmentServicesOntarioList ? data.developmentServicesOntarioList : [];
    const childPanel = developmentServicesOntarioList?.find(recipient => recipient.applicant === applicant)
    // Adding applicant details so that review page can display mandatory error for user to go and fill DSO question
    if (currentFieldValue === 'yes' && !childPanel) {
      const detail: any = {
        applicantType: 'Applicant',
        applicantName: ` ${data.firstName}`,
        applicant
      };
      developmentServicesOntarioList.unshift(detail)
      data.developmentServicesOntarioList = developmentServicesOntarioList;
    }
  }
}

export function syncReceivedSocialAssistanceData(applicationAnswers: ApplicationAnswers,
                                                 receivedSocialAssistanceInPastValue: string,
                                                 dateOfSocialAssistanceInPastValue: string,
                                                 additionalMemberIDValue: string,
                                                 receivedSocialAssistanceProgramValue: string) {
  const additionalInfoFieldName = 'additionalReceivedSocialAssistanceInPast';
  const familyInfoFieldName = 'familyReceivedSocialAssistanceInPast';
  const checkBoxAttrName = 'socialAssistanceRecipients';
  const subPanelAttrName = 'socialAssistanceDetailsList';

  syncDataForAdditionalInfo(applicationAnswers, receivedSocialAssistanceInPastValue, additionalInfoFieldName,
    familyInfoFieldName,
    checkBoxAttrName, subPanelAttrName);

  const data = applicationAnswers.jsonData;
  if (!!applicationAnswers.jsonData[additionalInfoFieldName]) {
    if (receivedSocialAssistanceInPastValue === 'yes') {
      data.additionalApplicationDate = dateOfSocialAssistanceInPastValue;
      data.additionalReceivedSocialAssistanceProgram = receivedSocialAssistanceProgramValue;
      if (!!additionalMemberIDValue) {
        data.additionalMemberID = additionalMemberIDValue;
      }
    } else if (receivedSocialAssistanceInPastValue === 'no') {
      delete applicationAnswers.jsonData.additionalApplicationDate;
      delete applicationAnswers.jsonData.additionalMemberID;
      delete applicationAnswers.jsonData.additionalReceivedSocialAssistanceProgram;
    }
  } else if (!!data[familyInfoFieldName]) { // Family
    if (!!data.firstName) {
      const applicant = 'Applicant: ' + data.firstName;
      const socialAssistanceDetailsList: any[] = !!data.socialAssistanceDetailsList ? data.socialAssistanceDetailsList : [];
      const childPanel = socialAssistanceDetailsList?.find(recipient => recipient.applicant === applicant)
      if (receivedSocialAssistanceInPastValue === 'yes') {
        if (!!childPanel) {
          childPanel.applicationDate = dateOfSocialAssistanceInPastValue;
          childPanel.receivedSocialAssistanceProgram = receivedSocialAssistanceProgramValue;
          if (!!additionalMemberIDValue) {
            childPanel.memberID = additionalMemberIDValue;
          }
        } else {
          const detail: any = {
            applicantType: 'Applicant',
            applicantName: ` ${data.firstName}`,
            applicantDisplayType: 'Applicant',
            applicationDate: dateOfSocialAssistanceInPastValue,
            applicant,
            receivedSocialAssistanceProgram: receivedSocialAssistanceProgramValue
          };
          if (!!additionalMemberIDValue) {
            detail.memberID = additionalMemberIDValue;
          }
          socialAssistanceDetailsList.unshift(detail)
          data.socialAssistanceDetailsList = socialAssistanceDetailsList;
        }
      }
    }
  }
}
