import {FormArray, ValidatorFn} from '@angular/forms';

export function validateAtLeastOneCheckboxSelected(): ValidatorFn {
  const minRequired = 1;

  return (formArray: FormArray): {[key: string]: any} | null => {
    let checked = 0;
    formArray.controls.forEach((control) => {
      if (control.value === true){
        checked++;
      }
    });
    return checked >= minRequired ? null : {required: true};
  };
}
