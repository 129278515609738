import { Injectable } from '@angular/core';
import {Platform} from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  private _window?: Window;

  constructor(private platform: Platform) {
    if (this.platform.isBrowser) {
      this._window = window;
    }
  }

  scrollToTop(){
    this._window?.scrollTo(0,0)
  }

  getLanguage() {
    return this._window?.location.pathname.startsWith('/fr/') ? 'fr' : 'en';
  }

  setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getLocalStorage(key: string) {
    return localStorage.getItem(key);
  }
}
