import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RequestInterceptor} from './request-interceptor';
import {Router} from '@angular/router';
import {ErrorInterceptor} from './error-interceptor.service';
import {HttpRequestInterceptor} from './http-request-interceptor';
import {AppStateService} from '../services/app-state.service';
import {LoadingSpinnerInterceptor} from './loading-spinner.interceptor';
import {InitializeService} from '../services/initialize.service';
import {AuthService} from '../services/auth.service';

export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
  {
    provide: HTTP_INTERCEPTORS,
    useFactory: (router: Router, appStateService, initializeService, authService) => new ErrorInterceptor(router,
      appStateService,
      initializeService, authService),
    multi: true,
    deps: [Router, AppStateService, InitializeService, AuthService]
  },
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingSpinnerInterceptor, multi: true }
]
