<div id="{{id}}-progress-bar" role="group" aria-label="progress" [ngClass]="withTopSpacing ? 'stepper-wrapper': 'stepper-wrapper-nospacing'">
  <ul class="listt-no-style">
    <ng-container *ngFor="let step of progressSteps">
      <li>
          <div id="{{id}}-stepper-item" [ngClass]="{'stepper-item-progress-line': step.hasStepFollowed}" class="stepper-item {{step.completed ? ' completed' : (step.failedIconWithX&&step.hasStepFollowed ? 'failedIconWithX' : '')}}" attr.aria-current="{{step.active ? true : false}}">
            <div *ngIf="!step.completed && !step.active" id="{{id}}-step-counter" class="step-counter incompleted-status">
              <div class="step-text" [innerHTML]="step.text | translate: step.textParam"></div>
            </div>
            <div *ngIf="step.completed" id="{{id}}-step-counter-img" class="accordion__content">
              <div class="green-circle-centre-container completed-status">
                <svg class="ontario-icon app-status-complete-icon" alt="check mark" id="{{id}}-checkmark-img-step" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use href="#ontario-icon-alert-success"></use>
                </svg>
              </div>
            </div>
            <div *ngIf="step.active && !step.failed && step.onhold" id="{{id}}-step-counter-img" class="green-circle-centre-container current-status">
              <img class="green-circle-centre-img" src="assets/images/orange_circle_with_center.svg">
            </div>
            <div *ngIf="step.active && !step.failed && !step.onhold" id="{{id}}-step-counter-img" class="green-circle-centre-container current-status">
              <img class="green-circle-centre-img" src="assets/images/green_circle_with_centre.svg">
            </div>
            <div *ngIf="step.active && step.failed && !step.failedIconWithX" id="{{id}}-step-counter-img" class="green-circle-centre-container current-status">
              <img class="green-circle-centre-img" src="assets/images/red_circle_with_centre.svg">
            </div>
            <div *ngIf="step.active && step.failed && step.failedIconWithX" id="{{id}}-step-counter-img" class="green-circle-centre-container current-status">
              <img class="green-circle-centre-img" src="assets/images/red_circle_with_x.svg">
            </div>
            <div id="{{id}}-step-name" class="step-name">
              <div class="{{step.normalizeHeading ? 'ontario-h4' : (step.active ? 'ontario-h3' : 'ontario-h4')}}">{{'app-status.'.concat(step.programType).concat(step.name) | translate: step.nameParam}}</div>
              <div id="{{id}}-application-status-text" class="step-status" *ngIf="step.text" [innerHTML]="'app-status.' + step.programType + step.text | translate: step.textParam"></div>
            </div>
          </div>
      </li>
    </ng-container>
  </ul>
</div>
