import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

declare function toggleExpander(item: any): any;

/**
 * This component could be used like the following cases:
 * 1. In dynamic question component:
 *   a. As expandable text:
 *        hintText: {
 *          text: 'start-one.postalCode',
 *          expandableContent: 'additional-information.hintText'
 *        },
 *   b. As plain text:
 *        hintText: {
 *          text: 'start-one.postalCode'
 *        },
 * 2. In non-dynamic pages:
 *   a. With drop-down component:
 *       <sd-dropdown-question [choices]="statusInCanada" [showError]="showError" [id]="'start-two.statusInCanada'"
 *                             [showCustomError]="invalidStatusInCanadaError"
 *                             [required]="true" [ignoreValidationSetup]=true
 *                             [hintText]="'start-two.statusInCanada.hintText'"
 *                             [hintExpandableContent]="'start-two.statusInCanada.hintContent'"
 *                             controlName="statusInCanada">
 *       </sd-dropdown-question>
 *   b. With text-question component:
 *       <sd-text-question *ngIf="hasReceivedSocialAssistanceInPast" [showError]="showError"
 *                         [id]="'start-two.memberId'"
 *                         [validationFns]="memberIdValidationFns"
 *                         [hintText]="'start-two.memberId.hintText'"
 *                         [hintExpandableContent]="'start-two.memberId.hintContent'"
 *                         controlName="memberID">
 *       </sd-text-question>
 *   c. With radio-button component:
 *       <sd-radio-question [showError]="showError" [id]="'start-two.receivedSocialAssistanceInPast'"
 *                          [required]="true" [ignoreValidationSetup]=true
 *                          [hintText]="'start-two.receivedSocialAssistanceInPast.hintText'"
 *                          controlName="receivedSocialAssistanceInPast">
 *       </sd-radio-question>
 */
@Component({
  selector: 'sd-hint-text',
  templateUrl: './hint-text.component.html',
  styleUrls: ['./hint-text.component.scss']
})
export class HintTextComponent {
  readonly EXPANDER_BUTTON_ID_SUFFIX = '-hint-expander-button-id';

  @Input()
  public id: string | undefined;
  @Input()
  public occurrence: number;  // To set up unique id for multiple hint-texts on the same page.
  @Input()
  public text: string | undefined;  // Translation reference-id of the text.
  @Input()
  public expandableContent: string | undefined;  // Translation reference-id of the expandable section content.
  @Input()
  public expandableContentParam: any;  // The expandable section content text params.
  @Input()
  public noMarginBottom: boolean | undefined; // To handle margins for dynamic question simple hint texts
  @Input()
  public ariaExpanded: boolean | undefined; // To handle keyboard user's keyboard enter to open/close expandableContent
  @Input()
  public boldHintText: string;
  
  constructor() {
    this.ariaExpanded = false;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // The original logic in ontario-expand-collapse of ODS add the listener in the 'DOMContentLoaded' event. However, this approach
    // does not work in Angular application.
    if (this.expandableContent) {
      const expandableItems = this.findElement(this.EXPANDER_BUTTON_ID_SUFFIX) ? [this.findElement(this.EXPANDER_BUTTON_ID_SUFFIX)] : [];
      toggleExpander(expandableItems);
    }
  }

  hintTextClick(){
    this.ariaExpanded = !this.ariaExpanded;
  }

  private findElement(elementIdSuffix: string) : HTMLElement {
    // ODS suggests to use document.querySelectorAll("[data-toggle='ontario-collapse']"), but that could return multiple elements in
    // multiple usage situation.
    return document.getElementById(this.occurrence !== null && this.occurrence !== undefined ?
       this.id + this.occurrence + elementIdSuffix : this.id + elementIdSuffix);
  }
}
