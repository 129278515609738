<div class="ontario-header__container">
  <header class="ontario-application-header ontario-header" id="ontario-header">
    <div class="ontario-row">

      <div class="ontario-application-header__logo ontario-columns ontario-small-6">
        <a tabindex="5">
          <img src="assets/ontario-design-system/logos/ontario-logo--desktop.svg"
               alt="{{'gov-of-ontario' | translate}}"/>
        </a>
      </div>

      <div class="ontario-header__nav-right-container ontario-application-header__lang-toggle
                  ontario-columns ontario-small-6 ontario-padding-right-0-!">

        <div *ngIf="!!caseWorkerDisplayName" class="caseworker-div">
          <img class="ontario-icon-account" id="ontario-icon-account" alt="ontario icon account"
               tabindex="6"
               src="assets/images/ontario-icon-account.png"/>
          <span id="userName" data-e2e="userName" class="user-name ontario-padding-left-8-!" ellipsis>
            {{caseWorkerDisplayName}}
          </span>
        </div>

        <div *ngIf="publicSecureUserName" class="myb-account-div ontario-padding-right-16-!">
          <img class="myb-account-icon" id="myb-account-icon" alt="mybenefits account icon"
               tabindex="6"
               src="assets/images/user-circle.png"/>
          <span id="myb-username" data-e2e="myb-username" class="myb-username ontario-padding-left-8-!" ellipsis>
            {{publicSecureUserName}}
          </span>
        </div>

        <a id="language" data-e2e="langToggleBtn" (click)="changeLanguage()"
           class="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline"
           tabindex="5">
          <span>
            {{currentLang === 'fr' ? ('choices.language.english' | translate) : ('choices.language.french' | translate)}}
          </span>
        </a>

      </div>

    </div>
  </header>

  <div class='ontario-application-subheader-menu__container'>
    <section class='ontario-application-subheader' [ngClass]="!!caseWorkerDisplayName || isErrorOrInfoPage ? 'ontario-application-subheader-background-caseworker' : 'ontario-application-subheader-background'">
      <div class='ontario-row'>
      <div class='ontario-columns ontario-small-12 ontario-application-subheader__container'>
        <p class="ontario-application-subheader__heading">
          {{'subheader.appTitle' | translate}}
        </p>

        <!-- Only show menu on the specified pages in the 'displayMenuPages' array-->
        <div [ngClass]="{'hide-menu': !displayMenu}" class='ontario-application-subheader__menu-container ontario-header__nav-right-container' id='ontario-header-menu-btn-container'>

          <!-- Menu with 3 lines (open) icon for regular form-factor -->
          <button class='ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline' [ngClass]="!!caseWorkerDisplayName ? 'ontario-header__menu-toggler-background-caseworker' : 'ontario-header__menu-toggler-background'" id='ontario-header-menu-toggler' aria-controls='ontario-navigation' [attr.aria-label]="'open.menu' | translate" type='button'>
            <svg class='ontario-icon' focusable='false' viewBox='0 0 24 24' preserveAspectRatio='xMidYMid meet'>
              <use xlink:href='#ontario-icon-menu'></use>
            </svg>
            <span>{{'subheader.menu' | translate}}</span>
          </button>

          <!-- Menu with x (close) icon for small form-factor -->
          <button class="ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline" [ngClass]="!!caseWorkerDisplayName ? 'ontario-header__menu-toggler-background-caseworker' : 'ontario-header__menu-toggler-background'" id="ontario-header-nav-toggler" aria-controls="ontario-navigation" aria-label="close" #menuButton>
            <svg class="ontario-icon" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use xlink:href="#ontario-icon-close"></use>
            </svg>
            <span>{{'subheader.menu' | translate}}</span>
          </button>
        </div>
      </div>
      </div>
    </section>

    <nav [ngClass]="{'hide-menu': !displayMenu}" class="ontario-navigation" id="ontario-navigation">
      <div *ngIf="menuType === 'sadaForm'" class="ontario-navigation__container">
        <ul>
          <!-- Show create an account button if user is not logged in (myB) -->
          <li *ngIf="!userAuthenticated()">
            <a id="create-an-account-menu-option" (click)="createAnAccount()">{{'subheader.createAnAccount' | translate}}</a>
          </li>
          <!-- Show save and exit button always on sada form pages -->
          <li>
            <a (click)="saveAndExit()">{{'subheader.saveAndExit' | translate}}</a>
          </li>
        </ul>
      </div>
      <div *ngIf="menuType === 'onlyLogout'" class="ontario-navigation__container">
        <ul>
          <!-- Show logout link if user is authenticated -->
          <li *ngIf="showLogoutOption()">
            <a id="logout-menu-option" (click)="logout()">{{'subheader.logout' | translate}}</a>
          </li>
        </ul>
      </div>
      <div *ngIf="menuType === 'landing'" class="ontario-navigation__container">
        <ul>
          <li>
            <a (click)="applyForAssistance()" (keydown.enter)="applyForAssistance()" tabindex="0">{{'subheader.startNewApp' | translate}}</a>
          </li>
          <li>
            <a href='{{mybLink}}'>{{'subheader.accessMyB' | translate}}</a>
          </li>
          <li>
            <a href='{{appStatusCheckerLink}}'>{{'subheader.checkAppStatus' | translate}}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
<div class='ontario-overlay'></div>


