<ng-container *ngIf="question.type !== 'paneldynamic' && question.isVisible && getDisplayValues(question) as result">
  <div class="my-4">
    <strong><div id="{{question.name}}-label" class="ontario-h5 my-1 question-label"
                 [innerHTML]="result.questionLabel | translate: result.questionLabelParams"></div></strong>
    <div id="{{question.name}}-label2" class="my-1" [innerHTML]="result.questionLabel2 | translate"></div>
    <sd-alert-err *ngIf="questionError"
                  errorId="{{question.name}}-err"
                  errorMessage="{{questionError | translate}}">
    </sd-alert-err>
    <div id="{{question.name}}-answer" *ngIf="!findApplicationValidationError(question, panelIndex)" class="answer my-1 prevent-link"
         [attr.aria-label]="result.ariaLabel | translate" [innerHTML]="result.skipValueTranslate ? result.answer : (result.answer | translate)"></div>
    <sd-alert-err *ngIf="findApplicationValidationError(question, panelIndex)"
                  errorId="{{question.name}}-required-err"
                  errorMessage="{{'error.required' | translate}}">
    </sd-alert-err>
  </div>
</ng-container>

<ng-container *ngIf="question.type === 'paneldynamic' && question.isVisible">
  <div *ngIf="question.name !== 'childList' && question.name !== 'anyChildCareExpenseList'" class="divider my-4" aria-hidden="true"></div>
  <ng-container *ngFor="let panel of question.panels; let panelIndex = index">
    <strong><div class="question-group-title my-4"
                 *ngIf="panel.label">{{panel.label | translate: getPanelLabelParams(panelIndex, panel.populatedLabelParams) }}</div></strong>
    <ng-container *ngFor="let question of panel.questions; let elementsIndex = index">
      <sd-app-review-question [question]="question" [panelIndex]="panelIndex" [applicationValidationErrors]="applicationValidationErrors"></sd-app-review-question>
    </ng-container>
    <div *ngIf="panelIndex !== question.panels.length - 1" class="divider my-4" aria-hidden="true"></div>
  </ng-container>
</ng-container>
