<div *ngIf="!secondaryButton">
  <div *ngIf="!!preventDoubleClick">
    <div *ngIf="!!hidden">
      <button [attr.id]="buttonId" [attr.data-e2e]="dataE2E" class="ontario-button ontario-button--primary" [ngClass]="{'remove-primary-btn-margin':removeMargin, 'remove-btn-margin-right':removeMarginRight}" type="submit" appPreventDoubleClick (throttledClick)="onClick()" [hidden]="hidden">{{buttonLabel | translate}}</button>
    </div>
    <div *ngIf="!!disabled">
      <button [attr.id]="buttonId" [attr.data-e2e]="dataE2E" class="ontario-button ontario-button--primary" [ngClass]="{'remove-primary-btn-margin':removeMargin, 'remove-btn-margin-right':removeMarginRight}" type="submit" appPreventDoubleClick (throttledClick)="onClick()" [disabled]="disabled">{{buttonLabel | translate}}</button>
    </div>
    <div *ngIf="!hidden && !disabled">
      <button [attr.id]="buttonId" [attr.data-e2e]="dataE2E" class="ontario-button ontario-button--primary" [ngClass]="{'remove-primary-btn-margin':removeMargin, 'remove-btn-margin-right':removeMarginRight}" type="submit" appPreventDoubleClick (throttledClick)="onClick()">{{buttonLabel | translate}}</button>
    </div>
  </div>
  <div *ngIf="!preventDoubleClick">
    <div *ngIf="!!hidden">
      <button [attr.id]="buttonId" [attr.data-e2e]="dataE2E" class="ontario-button ontario-button--primary" [ngClass]="{'remove-primary-btn-margin':removeMargin, 'remove-btn-margin-right':removeMarginRight}" type="submit" (click)="onClick()" [hidden]="hidden">{{buttonLabel | translate}}</button>
    </div>
    <div *ngIf="!!disabled">
      <button [attr.id]="buttonId" [attr.data-e2e]="dataE2E" class="ontario-button ontario-button--primary" [ngClass]="{'remove-primary-btn-margin':removeMargin, 'remove-btn-margin-right':removeMarginRight}" type="submit" (click)="onClick()" [disabled]="disabled">{{buttonLabel | translate}}</button>
    </div>
    <div *ngIf="!hidden && !disabled">
      <button [attr.id]="buttonId" [attr.data-e2e]="dataE2E" class="ontario-button ontario-button--primary" [ngClass]="{'remove-primary-btn-margin':removeMargin, 'remove-btn-margin-right':removeMarginRight}" type="submit" (click)="onClick()">{{buttonLabel | translate}}</button>
    </div>
  </div>
</div>

<div *ngIf="!!secondaryButton">
  <button [attr.id]="buttonId" [attr.data-e2e]="dataE2E" class="ontario-button ontario-button--secondary" [ngClass]="{'remove-btn-margin-right':removeMarginRight}" type="submit" appPreventDoubleClick (click)="onClick()">{{buttonLabel | translate}}</button>
</div>
