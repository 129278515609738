import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {IntakeService} from '../../../services/intake.service';
import {ApplicationAnswers} from '../../../models/data.model';
import {PageBaseComponent} from '../../PageBaseComponent';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {ExternalRouter} from '../../../external.router';
import {applicantType, WdaComponentUtil} from '../util/wda.component.util';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {PageService} from '../../../services/page.service';
import {LoadingSpinnerService} from '../../../services/loading-spinner.service';
import {PageInfo} from '../../../models/page-map';

const {isChildApplicant, buildNameString} = WdaComponentUtil;

@Component({
  selector: 'app-wda-landing',
  templateUrl: './wda-landing.component.html',
  styleUrls: ['./wda-landing.component.scss']
})
export class WdaLandingComponent extends PageBaseComponent implements OnInit, OnDestroy {
  loaded = false;
  applicants: Applicant[] = [];
  existingApplication: ApplicationAnswers;

  constructor(private router: Router,
              public route: ActivatedRoute,
              public intakeService: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone, public idle: Idle, public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.wda;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  populateApplicantList() {
    const jsonData = this.applicationAnswers.jsonData;
    this.applicants.push({
      name: buildNameString(jsonData.firstName, jsonData.lastName),
      type: applicantType.primary,
      wdaComplete: !!this.applicationAnswers.jsonData.applicantDemographicData
    })

    if (jsonData.spouseFirstName) {
      this.applicants.push({
        name: buildNameString(jsonData.spouseFirstName, jsonData.spouseLastName),
        type: applicantType.spouse,
        wdaComplete: !!this.applicationAnswers.jsonData.spouseDemographicData
      });
    }

    jsonData.childList?.forEach((child, index) => {
      this.applicants.push({
        name: buildNameString(child.childFirstName, child.childLastName),
        type: applicantType.child,
        childIndex: index,
        childDateOfBirth: child.childDateOfBirth,
        wdaComplete: !!child.demographicData
      });
    })
  }

  onStartClicked(applicant) {
    if (isChildApplicant(applicant.type)) {
      this.router.navigate(
        ['/', PageInfo.wda, 'questions', applicant.type, applicant.childIndex],
        {skipLocationChange: true})
    } else {
      this.router.navigate(['/', PageInfo.wda, 'questions', applicant.type],
        {skipLocationChange: true})
    }
  }

  onSubmit() {
    if (this.applicationAnswers.jsonData.spouseDemographicData && !this.applicationAnswers.jsonData.spouseFirstName) {
      // Remove spouse info if user went back and changed the marital status. Child should be automatically taken care
      // as the data is part of childList array.
      delete this.applicationAnswers.jsonData.spouseDemographicData;
      this.saveForm().subscribe(() => this.router.navigate(['/', PageInfo.consent]));
    } else {
      this.router.navigate(['/', PageInfo.consent])
    }
  }

  getApplicantLabel(type: string): string {
    return 'applicant.label.' + type.toLowerCase();
  }

  get hideContinueBtn(): boolean {
    return !!(this.applicants?.find(applicant => !applicant.wdaComplete));
  }

  postInitializeForm() {
    this.populateApplicantList();
  }

  preSaveApplication() {
  }
}

export class Applicant {
  name: string;
  type: string;
  childIndex?: number;
  childDateOfBirth?: string;
  wdaComplete: boolean;
}
