<sd-page-container [pageId]="pageId">

  <h2 class="ontario-h4 ontario-margin-bottom-32-!">{{'app-status-pending.sub-heading' | translate}}</h2>

  <div class="ontario-margin-bottom-24-! custom-size">
    <span [innerHTML]="'app-status-pending.body' | translate"></span>
  </div>

  <app-mccss-submit-button dataE2E="" buttonLabel="page-not-found.backToHomePageButton" [preventDoubleClick]="true" (handleClick)="onGoHomeClicked()"></app-mccss-submit-button>

</sd-page-container>
