import {FormArray, FormGroup} from '@angular/forms';
import {IntakeService} from '../services/intake.service';
import {ApplicationAnswers} from '../models/data.model';
import {Observable, of} from 'rxjs';
import {NgZone} from '@angular/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Question} from '../common/utils/questions/question';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {ExternalRouter} from '../external.router';
import {catchError} from 'rxjs/operators';
import {BaseComponent} from './BaseComponent';
import {ConfigService} from '../services/config.service';
import {FieldFormatUtil} from '../utils/field-format-util';
import {StringUtil} from '../utils/string-util';
import {PageScrollingUtil} from '../utils/page-scrolling-util';
import {AuthService} from '../services/auth.service';
import {PageService} from '../services/page.service';
import {LoadingSpinnerService} from '../services/loading-spinner.service';

/**
 * Implements common operations for a page component. If extending this class make sure to implement ngDestroy hook and
 * call super.onDestroy() method to make sure subscriptions$ are destroyed.
 */
export abstract class PageBaseComponent extends BaseComponent{
  form: FormGroup | undefined;
  applicationAnswers: ApplicationAnswers = {jsonData: {}}
  isAuthorizedUser = false;
  buttonContinueClick: boolean;

  protected constructor(public intake: IntakeService,
                        public translator: TranslateService,
                        public ngZone: NgZone,
                        public idle: Idle,
                        public dialog: MatDialog,
                        public route: ActivatedRoute,
                        public externalRouter: ExternalRouter,
                        protected configService: ConfigService,
                        protected authService: AuthService,
                        protected pageService: PageService,
                        protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
  }

  // initialize form with application data
  initializeForm(): void {
    this.route.data.subscribe((data: {appData: ApplicationAnswers}) => {
      if (data && data.appData?.jsonData) {
       this.applicationAnswers = data.appData;
        if (this.form) {
          // @ts-ignore
          Object.keys(this.form.controls).forEach(key => {
            // @ts-ignore
            // populate top level input fields
            if (data.appData.jsonData[key]) {
              this.form?.controls[key]?.setValue(data.appData.jsonData[key]);
            }
          });
        }

        this.postInitializeForm();
      }
    });
  }

  // to be implemented in sub class for additional form population logic
  abstract postInitializeForm()

  // to be implemented in sub class for additional JSON data population logic
  abstract preSaveApplication()

  // convert form data into JSON data format
  // call Intake service to save application data
  // form controls should follow naming convention that match JSON data elements expected on backend API.
  saveForm(toContinue = true): Observable<void>|undefined {
    const answers = {};

    this.buttonContinueClick = true
    if (this.form) {
      Object.keys(this.form.controls).forEach(key => {

        // handle fields from panels with array of 1, copy all fields from panel to top level
        if (this.form.get(key)?.value?.panels && this.form.get(key)?.value?.panels.length === 1) {
          for (const [k, v] of Object.entries(this.form.get(key)?.value?.panels[0])) {
            if (v) {
              answers[k] = v;
            }
          }
        } else { // handle top level input fields on form
          const value = this.form?.get(key)?.value;
          if (value === undefined || value === null || value === '') {  // When answer is being cleared or spaced out.
            // Need to remove the attr from the json data so that it will not be saved with previous value
            delete this.applicationAnswers.jsonData[key];
          } else if (FieldFormatUtil.needsToFormatValue(key)) {
            answers[key] = StringUtil.removeSeparators(this.form?.get(key).value);
          } else {
            answers[key] = this.form?.get(key).value;
          }
        }
      });
    }

    this.applicationAnswers.jsonData = { ...this.applicationAnswers.jsonData, ...answers};
    // console.log('data', JSON.stringify(this.applicationAnswers));

    this.preSaveApplication();

    // call Intake service to save application data
    return this.saveToIntake();
  }

  // Use as standalone method if form to JSON logic is handled externally
  saveToIntake(): Observable<void>|undefined {
    if (this.authService.isAuthorizedToSave()) {  // Invoke save-to-resume if applicant is authenticated.
      return this.saveApplicationToResume(this.applicationAnswers, this.pageId).pipe(catchError(err => {
        this.buttonContinueClick = false;
        return of(err);
      }));
    } else { // Save data without resume if applicant is unauthenticated
      return this.intakeService.saveApplication(this.applicationAnswers, this.pageId).pipe(catchError(err => {
        this.buttonContinueClick = false;
        return of(err);
      }));
    }
  }

  // update question values with application data
  updateQuestions(questions: Question<string>[], data: ApplicationAnswers): any {
    let updated = false;

    questions.forEach(q => {
      const value = data.jsonData[q.key];
      if (value){
        q.value = value;
        updated = true;
      }
    });

    return { updated, updatedValues: questions};
  }

  get isSingleApplicant(): boolean {
    return (this.applicationAnswers?.jsonData?.childrenLivingWithYou === 'no'
      && this.applicationAnswers?.jsonData?.maritalStatus !== 'Common Law'
      && this.applicationAnswers?.jsonData?.maritalStatus !== 'Married');
  }

  // used for debugging
  logFormControlsValidStatus() {
    Object.keys(this.form.controls).forEach(key => {
      console.log('control: '.concat(key).concat(', value: ').concat(JSON.stringify(this.form.get(key).value)).
      concat(', disabled status: ').concat(String(this.form.get(key).disabled)).
      concat(', valid status: '), this.form.get(key).valid);
    });
  }

  scrollToFirstInvalidControl(){
    const firstInvalidControlName = this.findFirstInvalidControlName();
    const invalidElement = this.locateFirstMatchFromBottom(firstInvalidControlName)
    PageScrollingUtil.scrollToElement(invalidElement)
  }

  findFirstInvalidControlName(): string {
    const names: string[] = Object.keys(this.form.controls)
    for (let i = 0, len = names.length; i < len; i++) {
      if (!this.form.get(names[i]).disabled && !this.form.get(names[i]).valid){
        if (this.form.get(names[i])?.value?.panels) {
          // search Invalid Control in Panels
          const panelName = names[i];
          const controlNameInPanel = this.findInvalidControlInPanels(names[i])
          return `${panelName}_${controlNameInPanel}`
        } else {
          return names[i];
        }
      }
    }
  }

  findInvalidControlInPanels(panelName: string): string {
    const formGroups: FormArray = this.form.get(panelName).get('panels') as FormArray
    let inValidControlName
    for (let i = 0; i < formGroups.length; i++){
      const fg: FormGroup = formGroups.at(i) as FormGroup
      if (!fg.valid) {
        inValidControlName = this.findInvalidControlNameInFormGroup(fg)
        if (!isNaN(inValidControlName?.substring(inValidControlName?.length-1))){
          // field index has been returned when panel in panel, no need to append index here.
          return `${inValidControlName}`
        } else {
          return `${inValidControlName}${i}`
        }
      }
    }
  }

  findInvalidControlNameInFormGroup(formGroup: FormGroup): string {
    const names: string[] = Object.keys(formGroup.controls)
    for (let i = 0, len = names.length; i < len; i++) {
      if (!formGroup.get(names[i]).disabled && !formGroup.get(names[i]).valid){
        if (formGroup.get(names[i]).get('panels')){
          // Panel in Panel: additional information page, house hold income page, financial asset page(Trust fund section)
          const subformGroups: FormArray = formGroup.get(names[i]).get('panels') as FormArray
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < subformGroups.length; j++){
            const fg = subformGroups.at(j) as FormGroup
            if (!fg.valid){
              const controlName = this.findInvalidControlNameInFormGroup(fg)
              return `${names[i]}_${controlName}${j}`
            }
          }
        } else {
          return `${names[i]}`
        }
      }
    }
  }

  scrollToInvalidControl(controlName: string, locateElementFromTop: boolean) {
    if (locateElementFromTop === true){
      PageScrollingUtil.scrollToElement(this.locateFirstMatchFromTop(controlName))
    } else {
      PageScrollingUtil.scrollToElement(this.locateFirstMatchFromBottom(controlName))
    }
  }

  private locateFirstMatchFromBottom(controlName: string){
    const allFormControls = document.querySelectorAll('form .ng-invalid')
    for (let i = allFormControls.length-1; i >= 0; i--) {
      if(allFormControls[i].querySelectorAll('[id$=' + controlName + ']').length !== 0
      || allFormControls[i].querySelectorAll('[name$=' + controlName + ']').length !== 0){
        return allFormControls[i] as HTMLElement;
      }
    }
  }

  private locateFirstMatchFromTop(controlName: string){
    const allFormControls = document.querySelectorAll('form .ng-invalid')
    for (let i = 0; i < allFormControls.length-1; i++) {
      if(allFormControls[i].querySelectorAll('[id$=' + controlName + ']').length !== 0
      || allFormControls[i].querySelectorAll('[name$=' + controlName + ']').length !== 0){
        return allFormControls[i] as HTMLElement;
      }
    }
  }

  scrollToInvalidFormControl(toContinue: boolean) {
    if (!toContinue) {
      this.showRequiredInfoBanner = true;
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setTimeout(() => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }, 500, { once: true })
    } else {
      // Set timeout so error messages are rendered first
      setTimeout(() => {
        // Add new selectors to the query string if new control types are added
        const queryString = 'input.ng-invalid, .ontario-input.ng-invalid, .ontario-checkboxes.ng-invalid,' +
          ' .ontario-input.ontario-input__error';
        const invalidFormControl = PageScrollingUtil.locateElement(queryString);
        PageScrollingUtil.scrollToElement(invalidFormControl, 150);
      },200);
    }
  }

  /**
   * when fields are in a panel of a panel, the control name does not contain the first panel name
   * when we locate the first invalid control from method findFirstInvalidControlName, the name would be "panelname_panelname_controlname"
   * We will need to remove the first panel name from the control name in order to locate the specific field.
   * This is for for addtional information page, household income page and financial asset page.
   * i.e. receivedSocialAssistanceInPastPanel_receivedSocialAssistanceInPastCheckBoxPanelApplicant_applicationDate0 becomes
   * receivedSocialAssistanceInPastCheckBoxPanelApplicant_applicationDate0
   * @param controlName The invalid form control name
   */
  removeFirstPanelName(controlName: string): string {
    const arr = controlName.split('_')
    if (arr.length > 2) {
      return arr[1].concat('_').concat(arr[2])
    } else {
      return controlName
    }
  }

  /**
   * cancels the event if it is cancelable, meaning that the default action that belongs to the event will not occur.
   * @param event the event of the action
   */
  preventDefaultAction(event:any) {
    event.preventDefault();
  }
}
