import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ScheduledMaintenance} from '../models/scheduled-maintenance';
import {ScheduledMaintenanceService} from './scheduled.maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduledMaintenanceServiceImpl implements ScheduledMaintenanceService {
  private Config: ScheduledMaintenance;

  constructor(private http: HttpClient) {
  }

  getBannerConfig(): Observable<ScheduledMaintenance> {
  return this.http.get<ScheduledMaintenance>(`/v1/maintenance-banners`);
  }
}
