import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveEnd, Router} from '@angular/router';
import {ApplicationInsights, ITelemetryPluginChain} from '@microsoft/applicationinsights-web';
import {AngularPlugin} from '@microsoft/applicationinsights-angularplugin-js';
import {ITelemetryPlugin} from '@microsoft/applicationinsights-core-js';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {ConfigService} from './config.service';
import {ErrorHandlerService} from './error.handler';

@Injectable({
  providedIn: 'root'
})
export class LoggingService implements OnDestroy {
  appInsights: ApplicationInsights;
  enabled: boolean;
  previousUrl: string = null;
  private onDestroy$ = new Subject<boolean>();
  constructor(private configService: ConfigService, private router: Router) {}

  initialize() {
    this.enabled = this.configService.getClientConfig()?.insightEnabled;
    if (this.enabled) {
      const angularPlugin: any = new AngularPlugin();  // Explicitly specified the base-interface type.
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.configService.getClientConfig()?.insightIkey,
          enableCorsCorrelation: true,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true,
          appId: this.configService.getClientConfig()?.traceId,
          enableAutoRouteTracking: false, // option to log all route changes
          autoTrackPageVisitTime: true,
          extensions: [angularPlugin],
          // Should not include 'router' to the config, otherwise duplicate PageView entries will be produced.
          extensionConfig: {
            [angularPlugin.identifier]: {
              errorServices: [new ErrorHandlerService(this)]
            }
          }
        }
      });
      this.appInsights.loadAppInsights();

      this.router.events
          .pipe(takeUntil(this.onDestroy$), filter(event => event instanceof ResolveEnd))
          .subscribe((event: ResolveEnd) => {
            const activatedComponent = this.getActivatedComponent(event.state.root);
            if (activatedComponent) {
              const url = this.removeParams(event.urlAfterRedirects);
              this.logPageView(activatedComponent.name, url, this.previousUrl);
              this.previousUrl = url;
            }
          });
      this.setupGlobalErrorHandler()
    } else {
      console.warn('APP Insights is not available.')
    }
  }
  private setupGlobalErrorHandler() {
    window.onerror = (message, source, lineno, colno, error) => {
      const errorDetails = {
        message: message as string,
        source: source as string,
        lineno: lineno as number,
        colno: colno as number,
        stack: error ? error.stack : null
      };
      const errorMessage = `${message} at ${source}:${lineno}:${colno}`;
      const errorObj = error || new Error(errorMessage);
      this.logException(errorObj, null, errorDetails);
    };
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  logPageView(name?: string, url?: string, refUri?: string) { // option to call manually
    if (this.enabled) {
      this.appInsights.trackPageView({
        name,
        uri: url,
        refUri
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.enabled) {
      this.appInsights.trackEvent({name}, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.enabled) {
      this.appInsights.trackMetric({name, average}, properties);
    }
  }

  logException(exception: Error, severityLevel?: number,errorDetails?: { message: string, source: string, lineno: number, colno: number,
      stack: string | null }) {
    if (this.enabled) {
      this.appInsights.trackException({exception, severityLevel,properties: errorDetails});
    } else if (!this.configService.getClientConfig()?.production) {
      console.error({exception, severityLevel,properties: errorDetails});
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.enabled) {
      this.appInsights.trackTrace({message}, properties);
    }
  }

  private removeParams(url: string):string {
    if (url.includes('token') || url.includes('code')) {
      url = url.split('?')[0]; // Remove query params
    }

    if (url.includes('#')){
      url = url.split('#')[0]; // Remove code after # in caseworkerlogin
    }

    return url;
  }

  ngOnDestroy() {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
