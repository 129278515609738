import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {StripHtmlTagsPipe} from './pipe/strip-html-tags.pipe';
import { MessageBoxComponent } from './message-box/message-box.component';
import {TranslationKeyFinderPipe} from './pipe/translation-key-finder.pipe';
import {TRANSLATIONS_PATHS} from './shared.service';

@NgModule({
  declarations: [StripHtmlTagsPipe, MessageBoxComponent, TranslationKeyFinderPipe],
  imports: [
    CommonModule
  ],
  exports: [StripHtmlTagsPipe, MessageBoxComponent, TranslationKeyFinderPipe]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {provide: TRANSLATIONS_PATHS, useValue: ['/assets/i18n/en.json', '/assets/i18n/fr.json']},
        StripHtmlTagsPipe, TranslationKeyFinderPipe
      ],
    }
  }
}
