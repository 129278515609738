import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AppSessionService} from './services/app-session.service';

@Injectable()
export class BrowseBackGuard  {
  constructor(private router: Router,
              private appSessionService: AppSessionService) {}

  canActivate(
      next: ActivatedRouteSnapshot,  // The route has the page info that is about to go to/back.
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const startUrl = '/intake/home-page';

    // To handles the browser back-button.
    if (this.router.getCurrentNavigation().trigger === 'popstate') {
      if (this.router.url === '/error-page-with-ref-number') {
        this.router.navigate([startUrl]);
      } else if (this.checkValidApplication(next)) {
        this.appSessionService.validAppExists().pipe(map(response => {
          const nullAppId = (response === null || !response.validAppExists);
          if (nullAppId) {
            this.router.navigate([startUrl]);
          }
          else if (this.preventBrowseBack(next)) {
            console.log('Missing application id, prevent browse-back');
            // push current state again to prevent further attempts.
            history.pushState(null, null, location.href);
            return false;
          } else {
            return true;
          }
        }))
      } else if (this.preventBrowseBack(next)) {
        console.log('Invalid application, prevent browse-back');
        // push current state again to prevent further attempts.
        history.pushState(null, null, location.href);
        return false;
      } else {
        return true;
      }
    }

    // To handle the normal pages progression.
    const navigatingToQuestions = state.url.startsWith('/intake/');
    return !(this.router.getCurrentNavigation().trigger === 'popstate' && !navigatingToQuestions);
  }

  /**
   * Determine if to validate the id is assigned to the application.
   * @param snapshot Path info of the page that is about to go back to.
   */
  private checkValidApplication(snapshot: ActivatedRouteSnapshot): boolean {
    return !!snapshot.data?.checkValidApp;
  }

  /**
   * Determine if it should prevent applicant from going back to previous page.
   * @param snapshot Path info of the page that is about to go back to.
   */
  private preventBrowseBack(snapshot: ActivatedRouteSnapshot): boolean {
    return !!snapshot.data?.preventBrowseBack;
  }
}
