import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {QuestionControlService} from '../../utils/questions/question-control.service';
import {Panel, Question} from '../../utils/questions/question';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sd-dynamic-question-panel',
  templateUrl: './dynamic-question-panel.component.html',
  styleUrls: ['./dynamic-question-panel.component.scss']
})
export class DynamicQuestionPanelComponent implements OnInit, OnDestroy, AfterViewChecked {
  private readonly _destroyed$ = new Subject<void>();

  alertMessageKeys: any[];  // To keep track of panel-level alert-message keys

  @Input()
  id!: string;

  @Input()
  showError!: boolean;

  @Input()
  panel!: Panel;

  @Input()
  panelQuestion: Question<string>;

  @Input()
  questions!: Question<string>[];

  @Input()
  form!: FormGroup;

  constructor(private qcs: QuestionControlService,
              private readonly changeDetectorRef: ChangeDetectorRef) {
    this.alertMessageKeys = [{}];
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.validateAlert();
    });
  }

  private validateAlert(): void {
    // @ts-ignore
    this.form.controls.panels.controls.forEach((formGroup, panelIndex) => {
      if (this.panel.alertValidator) {
        const alertMessageKey = this.panel.alertValidator.validatorFn(formGroup, this.panel.appData) ?
          undefined : this.panel.alertValidator.alertKey;
        if (alertMessageKey) {
          this.alertMessageKeys[panelIndex] = {key: alertMessageKey};
        } else if (this.alertMessageKeys[panelIndex]) {  // Only reset the key when the panel has been created
          this.alertMessageKeys[panelIndex] = {};
        }
      }
    })
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    // Unsubscribe to events when component is destroyed.
    this._destroyed$.next(undefined);
    this._destroyed$.complete();
  }

  trackByFn(index: number, item?: any): number{
    return index;
  }

  get panels(): FormArray {
    return this.form?.get('panels') as FormArray;
  }

  get panelsFormGroup(): FormGroup[] {
    return this.panels.controls as FormGroup[];
  }

  addPanel(): void {
    this.panels.push(this.createPanel());
    this.alertMessageKeys.push({});
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  removePanel(i: number): void {
    this.panels.removeAt(i);
    if (this.panel.updateValidityOnRemoval) {
      this.panels.controls.forEach((formGroup: FormGroup) => {  // Force Angular to execute the validator of the child components.
        this.updateTreeValidity(formGroup);
      })
    }
    this.alertMessageKeys.splice(i, 1);
    this.validateAlert();  // To force the triggering of the alert validator
    if (i < this.panels.length) {
      document.getElementById('remove-panel-' + i).blur();
    }
  }

  /**
   * Re-evaluates the validation status of the child controls tree.
   */
  private updateTreeValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];

      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        this.updateTreeValidity(abstractControl);
      } else {
        // Cannot use 'onlySelf: true', the validator does not being executed.
        abstractControl.updateValueAndValidity();
      }
    });
  }

  createPanel(): FormGroup {
    return this.qcs.toFormGroup(this.getQuestionsForCreatingPanel());
  }

  // check if question is visible based on some condition e.g. value of other question
  isVisible(question: Question<string>, panelIndex: number): boolean{
    let visible = true;

    if (question.visibleOnConditions){
      const visibleConditions:any = [];

      question.visibleOnConditions.forEach(q => {
        if (q.questionControlType === 'checkbox') {
          const formArray = this.panels.at(panelIndex)?.get(q.questionKey) as FormArray;
          const control = formArray?.controls[q.index];
          visibleConditions.push({visible: control?.value});

        } else {
          const control = this.panels.at(panelIndex)?.get(q.questionKey);
          if (q.value) {
            visibleConditions.push({visible: q.value.includes(control?.value)});
          } else if (q.valueNotEqual) {
            visibleConditions.push({visible: !q.valueNotEqual.includes(control?.value)});
          }
        }
      })

      const notVisibleCondition = visibleConditions.find(v => v.visible === false);
      visible = notVisibleCondition? notVisibleCondition?.visible : true;

    }else if (question.visible !== undefined) {
      visible = question.visible
    }
    if (question.visibleOnAnyConditions) {
      visible = false;
      question.visibleOnAnyConditions?.forEach(q => {
        // only needs to check for one condition that matches
        if (!visible) {
          if (q.questionControlType === 'checkbox') {
            const formArray = this.panels.at(panelIndex)?.get(q.questionKey) as FormArray;
            const control = formArray?.controls[q.index] as FormControl;
            visible = q.value.includes(control?.value);
          } else {
            const control = this.panels.at(panelIndex)?.get(q.questionKey);
            visible = q.value.includes(control?.value);
          }
        }
      });
    } else if (question.visible !== undefined) {
      visible = question.visible
    }
    if (!visible && this.panels.at(panelIndex)?.get(question.key)?.status !== 'DISABLED') {
      this.panels.at(panelIndex)?.get(question.key).disable()
    }
    return visible;
  }

  // check if question should be disabled based on configured conditions.
  isDisabled(question: Question<string>, panelIndex: number): boolean{
    let isDisabled = false;

    if (question.disabledOnAnyCondition) {
      question.disabledOnAnyCondition?.forEach(q => {
        // only needs to check for one condition that matches
        if (!isDisabled) {
          if (q.customFn) {
            isDisabled = q.customFn(this.form, panelIndex)
          } else if (q.questionControlType === 'checkbox') {
            const formArray = this.panels.at(panelIndex)?.get(q.questionKey) as FormArray;
            const control = formArray?.controls[q.index] as FormControl;
            isDisabled = q.value.includes(control?.value);
          } else {
            const control = this.panels.at(panelIndex)?.get(q.questionKey);
            isDisabled = q.value.includes(control?.value);
          }
        }
      });
    } else if (question.disabled !== undefined) {
      isDisabled = question.disabled
    }

    return isDisabled;
  }

  get isAddButtonVisible(): boolean {
    return this.panel?.showAddButton;
  }

  // return questions without previously populated values when creating new panel
  getQuestionsForCreatingPanel(): Question<string>[] {
    const questions = [...this.questions];
    questions.forEach(q => {
      const isVisible = q.visible !== undefined ? q.visible : true; // default to visible when indicator not specified

      // only reset value for visible questions otherwise retain value for questions that not visible.
      // invisible questions typically are used to hold data for business logic.
      if (isVisible) {
        if (q.controlType === 'checkbox') {
          q.options[0].value = false;
        } else {
          q.value = '';
        }
      }

    });
    return questions;
  }
}
