import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {FormToAppDataBase} from '../../../common/utils/converters/form-to-app-data-base';

export class FormToFAInvestments extends FormToAppDataBase implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  static readonly INVESTMENTS_CHECK_BOX = 'ownItemsOfValueInvestmentsCheckbox';
  static readonly INVESTMENTS_DETAILS_LIST = 'ownItemsOfValueDetailsListInvestment';
  static readonly APPLICANT_INVESTMENTS_CHECKBOX = 'ownItemsOfValueApplicantCheckboxInvestment';
  static readonly INVESTMENTS_PANEL_CHILD = 'FAInvestmentsWhoOwnsThisAssetPanelChild';
  static readonly WHO_OWN_THIS_ASSET = 'FAInvestmentsWhoOwnsThisAsset';

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // remove previous saved data and repopulate with latest form data
    delete appData.jsonData[FormToFAInvestments.INVESTMENTS_CHECK_BOX];
    delete appData.jsonData[FormToFAInvestments.INVESTMENTS_DETAILS_LIST];
    delete appData.jsonData[FormToFAInvestments.APPLICANT_INVESTMENTS_CHECKBOX];

    if ( form.get('FAInvestments')?.value[0]) {
      appData.jsonData[FormToFAInvestments.INVESTMENTS_CHECK_BOX] = ['yes'];

      // populate applicant check box list
      this.populateCheckBoxList(form,appData,questions);

      // populate cash details list
      this.populateDetailsList(form, appData, questions);
    }
  }

  populateCheckBoxList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const list = [];
    const question = questions.find(q => q.key === FormToFAInvestments.WHO_OWN_THIS_ASSET);

    if(appData.jsonData.spouseFirstName || appData.jsonData.childList){

      const applicantOption = question.options.find(o => o.label === 'financial-assets.applicant');

      if (this.isApplicantSelected(FormToFAInvestments.WHO_OWN_THIS_ASSET,form,questions) &&
        form.get('FAInvestmentsWhoOwnsThisAssetPanelApplicant')?.value.panels[0].investmentTotalValue) {
        list.push(applicantOption.alternateValue);
      }
    }

    if (this.isSpouseSelected(FormToFAInvestments.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FAInvestmentsWhoOwnsThisAssetPanelSpouse')?.value.panels[0].investmentTotalValue){
      const spouseOption = question.options.find(o => o.label === 'financial-assets.spouse');
      list.push(spouseOption.alternateValue);
    }

    if (appData.jsonData.childList){
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (form.get(FormToFAInvestments.INVESTMENTS_PANEL_CHILD.concat(String(i)))?.value.panels[0].investmentTotalValue) {
          const child = questions.find(q => q.key === FormToFAInvestments.INVESTMENTS_PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFAInvestments.WHO_OWN_THIS_ASSET,form,questions)) {
            list.push(child.value);
          }
        }
      }
    }

    if (list.length > 0) {
      appData.jsonData[FormToFAInvestments.APPLICANT_INVESTMENTS_CHECKBOX] = list;
    }

  }

  populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const detailsList: {
      applicant: string,
      applicantType: string,
      applicantDisplayType: string,
      applicantName: string,
      investmentsTotalValue: string
    }[] = [];

    if (this.isApplicantSelected(FormToFAInvestments.WHO_OWN_THIS_ASSET, form, questions) &&
      form.get('FAInvestmentsWhoOwnsThisAssetPanelApplicant').value.panels[0].investmentTotalValue) {
      detailsList.push({
        applicant: 'Applicant: '.concat(appData.jsonData.firstName),
        applicantType: 'Applicant',
        applicantDisplayType: 'Applicant',
        applicantName: ' '.concat(appData.jsonData.firstName),
        investmentsTotalValue: String(form.get('FAInvestmentsWhoOwnsThisAssetPanelApplicant').value.panels[0].investmentTotalValue)
      })
    }

    if (this.isSpouseSelected(FormToFAInvestments.WHO_OWN_THIS_ASSET, form, questions) &&
      form.get('FAInvestmentsWhoOwnsThisAssetPanelSpouse')?.value.panels[0].investmentTotalValue) {
      detailsList.push({
        applicant: 'Spouse: '.concat(appData.jsonData.spouseFirstName),
        applicantType: 'Spouse',
        applicantDisplayType: 'Spouse',
        applicantName: ' '.concat(appData.jsonData.spouseFirstName),
        investmentsTotalValue: String(form.get('FAInvestmentsWhoOwnsThisAssetPanelSpouse').value.panels[0].investmentTotalValue)
      })
    }

    // populate children data
    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (form.get(FormToFAInvestments.INVESTMENTS_PANEL_CHILD.concat(String(i)))?.value.panels[0].investmentTotalValue) {
          const child = questions.find(q => q.key === FormToFAInvestments.INVESTMENTS_PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFAInvestments.WHO_OWN_THIS_ASSET,form,questions)) {
            detailsList.push({
              applicant: child.value,
              applicantType: 'Child '.concat(String(i)),
              applicantDisplayType: 'Child '.concat(String(i)),
              applicantName: child.value.substr(child.value.indexOf(':') + 1),
              investmentsTotalValue: String(form.get(FormToFAInvestments.INVESTMENTS_PANEL_CHILD.concat(String(i)))
                .value.panels[0].investmentTotalValue)
            })
          }
        }
      }
    }

    if (detailsList.length > 0){
      appData.jsonData[FormToFAInvestments.INVESTMENTS_DETAILS_LIST] = detailsList;
    }
  }
}
