import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Idle} from '@ng-idle/core';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';
import {TranslateService} from '@ngx-translate/core';

import {ExternalRouter} from '../../external.router';
import {LoginConfirmationRequest} from '../../models/login-confirmation-request';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../services/config.service';
import {GoogleReCaptchaV2Service} from '../../services/google-recaptcha.service';
import {IntakeService} from '../../services/intake.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageService} from '../../services/page.service';
import {ValidationService} from '../../services/validation.service';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {BaseComponent} from '../BaseComponent';

@Component({
  selector: 'sd-login-confirmation',
  templateUrl: './login-confirmation.component.html',
  styleUrls: ['./login-confirmation.component.scss']
})
export class LoginConfirmationComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly attemptLimit = this.configService.getClientConfig()['attempt-limit'] || 5;
  private numberOfIllegalTries = 0;

  form: FormGroup;
  showValidationError = false;
  showProcessError = false;
  processErrorMessage: string;
  recaptcha: any = null;
  prevRecaptcha: any = null;
  emailSent: boolean;
  psSignInUrl: any;

  referenceIdLengthValidatorFns = [
    {
      validationFunction: (value) => {
        return SadaCustomValidator.validateRefCodeFormat(value);
      }, errorKey: 'app-status-landing.error.invalidRefCode'
    }
  ]
  emailValidationFns = [];

  /**
   * Reference to recaptcha v2 element
   */
  @ViewChild('captchaElem', { static: false })
  captchaElem: InvisibleReCaptchaComponent;

  constructor(private router: Router,
              public route: ActivatedRoute,
              private formBuilder: FormBuilder,
              public intakeService: IntakeService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              public translator: TranslateService,
              public configService: ConfigService,
              public authService: AuthService,
              public validationService: ValidationService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              public recaptchaV2Service: GoogleReCaptchaV2Service,
              private changeDetectorRef: ChangeDetectorRef) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.loginConfirmation;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({referenceId: [], email: [], recaptcha: ['']});
    this.emailValidationFns = this.getEmailValidationFunctions();
    this.prevRecaptcha = '';

    this.form.valueChanges.subscribe(val => {
      if (val.recaptcha === this.prevRecaptcha) { // Need to exclude recaptcha changes so the errors are maintained
        this.showProcessError = false;
        this.processErrorMessage = undefined;
        this.showValidationError = false;
      }
      this.prevRecaptcha = val.recaptcha;
    });

    if (this.enableRecaptcha) {
      this.translator.onLangChange.subscribe(() => {
        if (this.recaptchaV2Service.captchaIsLoaded) {
          this.recaptchaV2Service.changeLanguage(this.captchaElem);
        }
      })
      this.recaptchaV2Service.setChangeDetectorRef(this.changeDetectorRef);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
    document.getElementById('ontario-logo')?.focus();
  }

  onSubmit():void {
    if (this.form.valid) {
      const referenceId = this.form.controls.referenceId.value;
      const email = this.form.controls.email.value;
      const request: LoginConfirmationRequest = {email, referenceCode: referenceId, lang: this.translator.currentLang};

      // Execute invisible recaptcha v2
      // Confirm login request in success handler
      if (this.enableRecaptcha) {
        if (this.recaptchaV2Service.captchaIsLoaded) {
          if (this.captchaElem == null) {
            console.log('null captcha elem');
          }
          else {
            // console.log('Using the following reCAPTCHA v2 site key: '+this.recaptchaV2Service.siteKey)
            console.log('Executing invisible reCAPTCHA v2');
            this.captchaElem.execute();
          }
        }
        else {
          console.log('reCAPTCHA v2 has not loaded, try again.');
        }
      }
      else { // Confirm login request directly, since recaptcha disabled
        this.confirmLoginAndContinue(request);
      }
    } else {
      this.showValidationError = true;
    }
  }

  handleRecaptchaV2Success($event) {
    this.recaptchaV2Service.handleLoginConfirmationRecaptchaSuccess($event).subscribe(resp => {
      if (this.recaptchaV2Service.captchaSuccess && !this.recaptchaV2Service.reCaptchaVerifyCallFailure) {
        console.log('reCAPTCHA success on frontend');
        const referenceId = this.form.controls.referenceId.value;
        const email = this.form.controls.email.value;
        const request: LoginConfirmationRequest = {email, referenceCode: referenceId, lang: this.translator.currentLang};
        this.confirmLoginAndContinue(request);
      } else {
        console.log('reCAPTCHA failed on frontend');
      }
    });
  }

  disableContinueButton(): boolean {
    return (this.attemptLimit <= this.numberOfIllegalTries);
  }

  /**
   * Display ReCaptcha if the backend service is having it enabled.
   */
  get enableRecaptcha() {
    return this.recaptchaV2Service.enableRecaptcha;
  }

  private confirmLoginAndContinue(request: LoginConfirmationRequest): void {
    this.subscriptions$.push(this.validationService.confirmLogin(request).subscribe(
      () => {
        this.emailSent = true;
        this.authService.logout().subscribe();
      }, error => {
        if (error.status === 404) {
          this.showProcessError = true;
          this.numberOfIllegalTries++;
          if (this.attemptLimit > this.numberOfIllegalTries) {
            this.processErrorMessage = 'login-confirmation.error.application-not-found';
          } else {
            this.processErrorMessage = 'login-confirmation.error.too-many-attempts';
          }
        } else {
          this.handleError();
        }
        if (this.captchaElem != null) {
          this.captchaElem.resetCaptcha();
        }
      })
    )
  }

  private getEmailValidationFunctions() {
    return [
      {
        validationFunction: (value) => SadaCustomValidator.validateEmail(value),
        errorKey: 'error.invalid.email.address'
      },
      {
        validationFunction: (value) => value && SadaCustomValidator.validateRequiredLength([value, 1, 64, true]),
        errorKey: 'error.invalid.email.maxLength'
      }
    ]
  }

  handleError() {
    this.authService.setAuthorizedToSave(false);

    this.authService.getPSRedirectURL('', this.translator.currentLang)
      .subscribe(url => {
        this.showProcessError = true;
        this.processErrorMessage = 'error.AUTHEC01';
        this.psSignInUrl = {psSignInUrl: url};

        // Get the URL again if the language changes. This needs to be done because the new lang needs to be added to the state
        // in the PS URL.
        this.subscriptions$.push(this.translator.onLangChange.subscribe((lang) => {
          this.authService.getPSRedirectURL('', lang.lang)
            .subscribe(urlWithChangedLang => {
              this.psSignInUrl = {psSignInUrl: urlWithChangedLang};
            })
        }));
      }, error => {
        this.router.navigate(['/',PageInfo.globalError], { queryParams: { errorCode: 'AUTHEC01' }});
      })
  }
}
