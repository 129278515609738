import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Question} from '../../utils/questions/question';
import {ControlContainer, FormArray, FormGroup} from '@angular/forms';

@Component({
  selector: 'sd-dynamic-container',
  templateUrl: './dynamic-container.component.html',
  styleUrls: ['./dynamic-container.component.scss']
})
export class DynamicContainerComponent implements OnInit, AfterViewChecked{
  @Input()
  questions!: Question<string>[];

  form: FormGroup;

  @Input()
  showError!: boolean;

  constructor(public controlContainer: ControlContainer,
              private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.form = this.controlContainer?.control as FormGroup
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  trackByFn(index: number, item: any): number{
    return index;
  }

  isVisible(question: Question<string>): boolean{
    let visible = true;

    if (question.visibleOnConditions){
      const visibleConditions:any = [];
      question.visibleOnConditions.forEach(q => {
        if (q.questionControlType === 'checkbox') {
          const formArray = this.form.get(q.questionKey) as FormArray;
          // @ts-ignore
          const control = formArray?.controls[q.index];
          visibleConditions.push({visible: control?.value});
        }
        else {
          const control = this.form.get(q.questionKey);
          if (q.value) {
            visibleConditions.push({visible: q.value.includes(control?.value)});
          } else if (q.valueNotEqual) {
            visibleConditions.push({visible: !q.valueNotEqual.includes(control?.value)});
          }
          else if(q.hasOwnProperty('customFunction'))
          {
            visibleConditions.push({visible: q.customFunction});
          }
        }
      })
      const notVisibleCondition = visibleConditions.find(v => v.visible === false);
      visible = notVisibleCondition? notVisibleCondition?.visible : true;
    } else if (question.visible !== undefined) {
      visible = question.visible
    }

    if (question.visibleOnAnyConditions) {
      visible = false;
      question.visibleOnAnyConditions?.forEach(q => {
        // only needs to check for one condition that matches
        if (!visible) {
          if (q.questionControlType === 'checkbox') {
            const formArray = this.form.get(q.questionKey) as FormArray;
            // @ts-ignore
            const control = formArray?.controls[q.index];
            visible = q.value.includes(control?.value);
          } else {
            const control = this.form.get(q.questionKey);
            visible = q.value.includes(control?.value);
          }
        }
      });
    } else if (question.visible !== undefined) {
      visible = question.visible
    }

    if (!visible && this.form.get(question.key)?.status !== 'DISABLED'){
        this.form.get(question.key)?.disable()
    }

    return visible;
  }

  // check if question should be disabled based on configured conditions.
  isDisabled(question: Question<string>): boolean{
    let isDisabled = false;

    if (question.disabledOnAnyCondition) {
      question.disabledOnAnyCondition?.forEach(q => {
        // only needs to check for one condition that matches
        if (!isDisabled) {
          if (q.customFn) {
            isDisabled = q.customFn(this.form)
          } else if (q.questionControlType === 'checkbox') {
            const formArray = this.form.get(q.questionKey) as FormArray;
            // @ts-ignore
            const control = formArray?.controls[q.index];
            isDisabled = q.value.includes(control?.value);
          } else {
            const control = this.form.get(q.questionKey);
            isDisabled = q.value.includes(control?.value);
          }
        }
      });
    } else if (question.disabled !== undefined) {
      isDisabled = question.disabled
    }

    return isDisabled;
  }

  questionClasses(question: Question<string>): string[] {
    const classes = [];

    if (question.indentedRow) {
      classes.push('indented-form-row');
    }

    if (question.panelBackground) {
      classes.push('grey-background');
    }

    return classes;
  }
}
