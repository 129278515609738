import {Component, Input, OnInit} from '@angular/core';

import {AscStatusProgressStep} from './asc-status-progress-step';

@Component({
  selector: 'sd-asc-status-progress-bar',
  templateUrl: './asc-status-progress-bar.component.html',
  styleUrls: ['./asc-status-progress-bar.component.scss']
})
export class AscStatusProgressBarComponent implements OnInit {
  @Input()
  public id = 'progress-bar';
  @Input()
  public withTopSpacing: boolean;
  @Input()
  public progressSteps: AscStatusProgressStep[];

  constructor() { }

  ngOnInit(): void {
  }
}
