<!-- Warning -->
<div class="ontario-alert ontario-alert--warning">
  <div class="ontario-alert__header">
    <div class="ontario-alert__header-icon">
      <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
        <use href="#ontario-icon-alert-warning"></use>
      </svg>
    </div>
    <h2 [id]="id + '-warning-header'" class="ontario-alert__header-title ontario-h4">{{title | translate}}</h2>
  </div>
  <div class="ontario-alert__body">
    <ng-content></ng-content>
  </div>
</div>
