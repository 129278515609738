import {Injectable} from '@angular/core';
import {AppSessionService} from './app-session.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApplicationRefResponse} from '../models/application-ref-response';
import {ApplicationResponse} from '../models/application-response.model';

@Injectable({
  providedIn: 'root'
})

export class AppSessionServiceImpl implements AppSessionService {
  referenceNumber: string;

  constructor(private http: HttpClient) {
  }

  getAppReferenceNumber(): Observable<ApplicationRefResponse> {
    return this.http.get<ApplicationRefResponse>(`/v1/reference-number`, {withCredentials: true});
  }

  validAppExists(): Observable<ApplicationResponse> {
    return this.http.get<ApplicationResponse>(`/v1/valid-app`, {withCredentials: true});
  }

}
