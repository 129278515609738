import { formatDate, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';

// Register locale data
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

const DEFAULT_DATE_FORMAT = 'MMMM d, yyyy';

// map between languages and their respective date formats
const dateFormatMap: Record<string, string> = {
  en: DEFAULT_DATE_FORMAT,
  fr: 'd MMMM yyyy'
  // Add other languages and formats as needed
};

export function healthCardNumberFormatter(healthCardNumber: string): string {
  if (!healthCardNumber) {
    return '';
  }
  const value = healthCardNumber.toString().trim().replace(/^\+/, '');
  if (new RegExp('^[0-9]{10}$').test(value)) {
    const first4Digits = value.slice(0, 4);
    const second3Digits = value.slice(4, 7);
    const last3Digits = value.slice(7);
    return (first4Digits + '-' + second3Digits + '-' + last3Digits);
  }
  return healthCardNumber;
}

export function phoneNumberFormatter(phoneNumber: string): string {
  if (!phoneNumber) {
    return '';
  }
  const value = phoneNumber.toString().trim().replace(/^\+/, '');
  if (new RegExp('^[0-9]{10}$').test(value)) {
    const npa = value.slice(0, 3);
    const nxx = value.slice(3, 6);
    const last4Digits = value.slice(6);
    return ('(' + npa + ') ' + nxx + '-' + last4Digits);
  }
  return phoneNumber;
}

export function socialInsuranceNumberFormatter(socialInsuranceNumber: string): string {
  if (!socialInsuranceNumber) {
    return '';
  }
  const value = socialInsuranceNumber.toString().trim().replace(/^\+/, '');
  if (new RegExp('^[0-9]{9}$').test(value)) {
    const first3Digits = value.slice(0, 3);
    const second3Digits = value.slice(3, 6);
    const last3Digits = value.slice(6);
    return (first3Digits + '-' + second3Digits + '-' + last3Digits);
  }
  return socialInsuranceNumber;
}

export function dateFormatter(date: string, language = 'en', defaultFormat = ''): string {
  if (!date) {
    return '';
  }

  const format = defaultFormat || dateFormatMap[language] || DEFAULT_DATE_FORMAT;

  return formatDate(date, format, language);
}
