import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sd-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  @Input()
  elementId = 'message';

  @Input()
  msgType: 'success' | 'error' | 'warning' | 'informational' = 'success';

  @Input()
  title = '';

  @Input()
  message = '';

  constructor() {}

  ngOnInit(): void {}
}
