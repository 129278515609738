export class StringUtil {
  static capitalizeFirstLetter(value: string) {
    return value[0].toUpperCase() + value.slice(1);
  }

  static capitalizeFirstLetterOfAllWords(value: string) {
    const words: string[] = value.split(' ');
    let outStr = '';

    words.forEach((item) => {
      const n = item.indexOf('-');
      if (n !== -1) { // Deals with hyphenated names
        item = item.slice(0, n) + '-' + this.capitalizeFirstLetter(item.slice(n + 1));
      }
      outStr = outStr + this.capitalizeFirstLetter(item) + ' '; // Capitalizes each word
    })
    return outStr.trim();
  }

  static removeWhiteSpaces(value: string) {
    return value.replace(/\s/g, '');
  }

  static equalsIgnoreCase(value1: string, value2:string) {
    return value1.toLowerCase() === value2.toLowerCase();
  }

  /**
   * Remove the allowed separators (single '(', ')', '-', & ' ') from the provided string.
   */
  static removeSeparators(value: string): string {
    if (!value) {
      return undefined;
    }
    return value.replace(/[\(\)\s-]+/g,'');
  }

  /**
   * Remove the allowed separators (single '-', & ' ') from the provided string.
   */
  static removeSinHealthCardNumberSeparators(value: string): string {
    if (!value) {
      return undefined;
    }
    return value.replace(/[\s-]+/g,'');
  }
}
