import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ESignatureService} from './esignature.service';
import {ESignatureResponse} from '../models/esignature/esignature-response.model';

@Injectable({
  providedIn: 'root'
})
export class EsignatureImplService implements ESignatureService {
  constructor(private http: HttpClient) {
  }

  startESignature(): Observable<ESignatureResponse> {
    return this.http.post<ESignatureResponse>(`/v1/esignature/application`,
      {}, {withCredentials: true})
  }

  isApplicationESignEligible(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.http.get<void>(`/v1/esignature/validation`,
        {withCredentials: true}).subscribe(() => {
        observer.next(true)
        observer.complete()
      }, error => {
        observer.next(false)
        observer.complete()
      })
    })
  }
}
