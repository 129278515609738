import {ApplicationAnswers} from '../../models/data.model';
import {Converter, FormConverter1} from '../../common/utils/converters/converter';
import {FormArray, FormGroup} from '@angular/forms';
import {QuestionControlService} from '../../common/utils/questions/question-control.service';
import {Question} from '../../common/utils/questions/question';
import {HousingSituationQuestionService} from './housing-situation-question.service';

export class AppDataToQuestionsConverter implements FormConverter1<ApplicationAnswers, FormGroup,
  HousingSituationQuestionService, QuestionControlService> {

  convert(appData: ApplicationAnswers, form: FormGroup, questionService: HousingSituationQuestionService,
          qcs: QuestionControlService): void {
    this.convertJsonToCurrentHousingSituation(appData, form)
    this.covertJsonToHousingCost(appData,form)
    this.convertJsonToOtherPersonsLivingWithYouPanel(appData, form, questionService, qcs)
  }

  convertJsonToCurrentHousingSituation(appData: ApplicationAnswers, form: FormGroup) {
    if (appData.jsonData[`currentHousingSituation`] === 'i do not pay rent with meals provided') {
      form?.controls[`currentHousingSituation`]?.setValue('I do not pay rent')
      form?.controls[`areYourMealsProvided`]?.setValue('yes');
    } else if (appData.jsonData[`currentHousingSituation`] === 'i do not pay rent without meals provided') {
      form?.controls[`currentHousingSituation`]?.setValue('I do not pay rent')
      form?.controls[`areYourMealsProvided`]?.setValue('no');
    }
  }

  covertJsonToHousingCost(appData: ApplicationAnswers, form: FormGroup){
    if (appData.jsonData.otherHousingCostList) {
      const otherHousingCostList = appData.jsonData.otherHousingCostList
      if (otherHousingCostList.includes('Heat')) {
        form.get('otherHousingCostHeatCheckBox').setValue([true])
        form.get('otherHousingCostHeat').setValue(appData.jsonData.otherHousingCostHeat)
      }
      if (otherHousingCostList.includes('Hydro')) {
        form.get('otherHousingCostHydroCheckBox').setValue([true])
        form.get('otherHousingCostHydro').setValue(appData.jsonData.otherHousingCostHydro)
      }
      if (otherHousingCostList.includes('Utilities')) {
        form.get('otherHousingCostUtilitiesCheckBox').setValue([true])
        form.get('otherHousingCostUtilities').setValue(appData.jsonData.otherHousingCostUtilities)
      }
      if (otherHousingCostList.includes('Condo fees')) {
        form.get('otherHousingCostCondoFeeCheckBox').setValue([true])
        form.get('otherHousingCostCondoFee').setValue(appData.jsonData.otherHousingCostCondoFee)
      }
      if (otherHousingCostList.includes('Home or tenant insurance')) {
        form.get('otherHousingCostHomeOrTenantInsuranceCheckBox').setValue([true])
        form.get('otherHousingCostHomeOrTenantInsurance').setValue(appData.jsonData.otherHousingCostHomeOrTenantInsurance)
      }
      if (otherHousingCostList.includes('Property tax')) {
        form.get('otherHousingCostPropertyTaxCheckBox').setValue([true])
        form.get('otherHousingCostPropertyTax').setValue(appData.jsonData.otherHousingCostPropertyTax)
      }
    }
  }

  convertJsonToOtherPersonsLivingWithYouPanel(appData: ApplicationAnswers, form: FormGroup,
                                              questionService: HousingSituationQuestionService, qcs: QuestionControlService) {
    const otherPersonsLivingWithYouQuestions = this.prepareOtherPersonsLivingWithYouPanels(appData, form, questionService, qcs)
    if (appData.jsonData[`otherPersonsLivingWithYou`]) {
      const formGroups = form.get('OtherPersonsLivingWithYouPanel').get('panels') as FormArray
      appData.jsonData[`otherPersonsLivingWithYou`].forEach((d, index) => {
        const fg = formGroups.at(index) as FormGroup
        otherPersonsLivingWithYouQuestions.forEach(question =>{
          fg.get(`${question.key}`).setValue(d[`${question.key}`])
        })
      })
    }
  }

  prepareOtherPersonsLivingWithYouPanels(appData: ApplicationAnswers, form: FormGroup,
                                        questionService: HousingSituationQuestionService, qcs: QuestionControlService): Question<string>[] {
    const otherPersonsLivingWithYouQuestions: Question<string>[] = [...questionService.getOtherPersonsLivingWithYouQuestions
      (appData.jsonData.dateOfBirth)];
    const formGroups = form.get('OtherPersonsLivingWithYouPanel').get('panels') as FormArray;
    appData.jsonData[`otherPersonsLivingWithYou`]?.forEach((d, index) => {
      if (index >= 1) {
        formGroups.push(qcs.toFormGroup(otherPersonsLivingWithYouQuestions));
      }
    })
    return otherPersonsLivingWithYouQuestions;
  }
}

export class FormDataToAppDataConverter implements Converter<FormGroup, ApplicationAnswers> {
  convert(form: FormGroup, appData: ApplicationAnswers): void {
    this.convertByCurrentHousingSituation(form, appData)
    this.convertOtherHousingCostListToJson(form, appData)
    this.convertOtherPersonsLivingWithYouPanelToJson(form, appData)
  }

  convertByCurrentHousingSituation(form: FormGroup, appData: ApplicationAnswers){
    const currentHousingSituationValue = form?.get('currentHousingSituation')?.value;
    const areYourMealsProvidedValue = form?.get('areYourMealsProvided')?.value;
    if (currentHousingSituationValue === 'I do not pay rent') {
      if (areYourMealsProvidedValue === 'yes') {
        appData.jsonData[`currentHousingSituation`] = 'i do not pay rent with meals provided'
      } else if (areYourMealsProvidedValue === 'no') {
        appData.jsonData[`currentHousingSituation`] = 'i do not pay rent without meals provided'
      }
    }
  }

  convertOtherHousingCostListToJson(form: FormGroup, appData: ApplicationAnswers) {
    this.deleteRentOwnPanelElementsInJson(appData)
    const otherHousingCostList: Array<string> = []
    if(form.get('otherHousingCostHeatCheckBox') !== undefined && form.get('otherHousingCostHeatCheckBox').value[0] === true && form.get('otherHousingCostHeatCheckBox').disabled === false){
      otherHousingCostList.push('Heat')
      appData.jsonData[`otherHousingCostHeat`] = form.get('otherHousingCostHeat').value
    }
    if(form.get('otherHousingCostHydroCheckBox') !== undefined && form.get('otherHousingCostHydroCheckBox').value[0] === true && form.get('otherHousingCostHydroCheckBox').disabled === false){
      otherHousingCostList.push('Hydro')
      appData.jsonData[`otherHousingCostHydro`] = form.get('otherHousingCostHydro').value
    }
    if(form.get('otherHousingCostUtilitiesCheckBox') !== undefined && form.get('otherHousingCostUtilitiesCheckBox').value[0] === true && form.get('otherHousingCostUtilitiesCheckBox').disabled === false){
      otherHousingCostList.push('Utilities')
      appData.jsonData[`otherHousingCostUtilities`] = form.get('otherHousingCostUtilities').value
    }
    if(form.get('otherHousingCostCondoFeeCheckBox') !== undefined && form.get('otherHousingCostCondoFeeCheckBox').value[0] === true && form.get('otherHousingCostCondoFeeCheckBox').disabled === false){
      otherHousingCostList.push('Condo fees')
      appData.jsonData[`otherHousingCostCondoFee`] = form.get('otherHousingCostCondoFee').value
    }
    if(form.get('otherHousingCostHomeOrTenantInsuranceCheckBox') !== undefined && form.get('otherHousingCostHomeOrTenantInsuranceCheckBox').value[0] === true && form.get('otherHousingCostHomeOrTenantInsuranceCheckBox').disabled === false){
      otherHousingCostList.push('Home or tenant insurance')
      appData.jsonData[`otherHousingCostHomeOrTenantInsurance`] = form.get('otherHousingCostHomeOrTenantInsurance').value
    }
    if(form.get('otherHousingCostPropertyTaxCheckBox') !== undefined && form.get('otherHousingCostPropertyTaxCheckBox').value[0] === true && form.get('otherHousingCostPropertyTaxCheckBox').disabled === false){
      otherHousingCostList.push('Property tax')
      appData.jsonData[`otherHousingCostPropertyTax`] = form.get('otherHousingCostPropertyTax').value
    }
    appData.jsonData[`otherHousingCostList`] = otherHousingCostList
  }

  convertOtherPersonsLivingWithYouPanelToJson(form: FormGroup, appData: ApplicationAnswers) {
    const otherPersonsLivingWithYou = [];
    delete appData.jsonData[`otherPersonsLivingWithYou`]
    if (form.get('OtherPersonsLivingWithYouPanel').disabled === false) {
      form.get('OtherPersonsLivingWithYouPanel').value.panels.forEach(p => {
        otherPersonsLivingWithYou.push({
          livingWithYouRelationship: p.livingWithYouRelationship,
          livingWithYouFirstName: p.livingWithYouFirstName,
          livingWithYouLastName: p.livingWithYouLastName,
          livingWithYouDate: p.livingWithYouDate,
          livingWithYouPaymentAmount: p.livingWithYouPaymentAmount,
          livingWithYouProvideMeals: p.livingWithYouProvideMeals
        })
      })
      appData.jsonData[`otherPersonsLivingWithYou`] = otherPersonsLivingWithYou
    }
  }

  deleteRentOwnPanelElementsInJson(appData: ApplicationAnswers){
    delete appData.jsonData[`otherHousingCostList`]
    delete appData.jsonData[`otherHousingCostHeat`]
    delete appData.jsonData[`otherHousingCostHydro`]
    delete appData.jsonData[`otherHousingCostUtilities`]
    delete appData.jsonData[`otherHousingCostCondoFee`]
    delete appData.jsonData[`otherHousingCostHomeOrTenantInsurance`]
    delete appData.jsonData[`otherHousingCostPropertyTax`]
  }
}
