import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup} from '@angular/forms';
import {Choice, IncomeTypes} from '../../../common/utils/questions/question-choices';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';
import {amountMaxLength, nameCharacterPattern, positiveCurrencyPattern} from '../../../common/utils/validators/custom-validators';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {IncomeQuestionsFormNames} from '../earned-income.model';

@Component({
  selector: 'sd-income-question-panel',
  templateUrl: './income-question-panel.component.html',
  styleUrls: ['./income-question-panel.component.scss']
})
export class IncomeQuestionPanelComponent implements OnInit, OnDestroy {

  @Input()
  parentFormIndex: number;

  @Input()
  applicantType: string;

  @Input()
  applicantName: string;

  @Input()
  showError = false;

  @Input()
  data: any;

  @Input()
  monthAndYear: any;

  @Input()
  isEarnedIncomeMonthSame: boolean;

  @Input()
  isSingleApplicant;

  protected valueChangeSubjects$ = new Subject<void>();

  form: FormGroup;
  incomeTypes: Choice[] = IncomeTypes;
  accountValuePattern = positiveCurrencyPattern;
  nameCharacterPattern = nameCharacterPattern;
  accountValueValidationFns: { validationFunction: (params: any) => boolean; errorKey: string; }[];
  disabilityWorkExpenseValueValidationFns: { validationFunction: (params: any) => boolean; errorKey: string; }[];
  dateValidationFns: { validationFunction: (params: any) => boolean; errorKey: string; }[];
  formControlNames = IncomeQuestionsFormNames;
  applicantTypeNameLabelParam;

  constructor(public controlContainer: ControlContainer, public fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.controlContainer?.control as FormGroup
    this.form.controls.earnedIncomeType?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      if (value === 'fullTime' || value === 'partTime') {
        this.disableFields([IncomeQuestionsFormNames.BUSINESS_NAME, IncomeQuestionsFormNames.PROGRAM_NAME]);
        this.enableFields([IncomeQuestionsFormNames.EMPLOYER_NAME]);
      } else if (value === 'selfEmployed') {
        this.disableFields([IncomeQuestionsFormNames.EMPLOYER_NAME, IncomeQuestionsFormNames.PROGRAM_NAME]);
        this.enableFields([IncomeQuestionsFormNames.BUSINESS_NAME]);
      } else if (value === 'training') {
        this.disableFields([IncomeQuestionsFormNames.EMPLOYER_NAME, IncomeQuestionsFormNames.BUSINESS_NAME]);
        this.enableFields([IncomeQuestionsFormNames.PROGRAM_NAME]);
      }
    });

    this.form.controls.disabilityWorkExpense?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      if (value === 'yes') {
        this.enableFields([IncomeQuestionsFormNames.DISABILITY_WORK_EXPENSE_MONTHLY_AMOUNT]);
      } else if (value === 'no') {
        this.disableFields([IncomeQuestionsFormNames.DISABILITY_WORK_EXPENSE_MONTHLY_AMOUNT]);
      }
    });

    if(!this.isDisability){
      this.disableFields([IncomeQuestionsFormNames.DISABILITY_WORK_EXPENSE,
        IncomeQuestionsFormNames.DISABILITY_WORK_EXPENSE_MONTHLY_AMOUNT]);
    }


    this.accountValueValidationFns = [
      {
        validationFunction: (value: any[]) => {
          return isNaN(value[0]) || (!isNaN(value[0]) && value[0] >= 0);
        },
        errorKey: 'error.invalid.min.amount'
      },
      {
        validationFunction: (value: []) => {
          return SadaCustomValidator.validateMaxNumericValueLength([...value, amountMaxLength]);
        },
        errorKey: 'error.invalid.max.amount'
      },
    ];

    this.disabilityWorkExpenseValueValidationFns = [
      {
        validationFunction: (value: any[]) => {
          return isNaN(value[0]) || (!isNaN(value[0]) && value[0] > 0);
        },
        errorKey: 'error.invalid.min.amount.detailed'
      },
      {
        validationFunction: (value: []) => {
          return SadaCustomValidator.validateMaxNumericValueLength([...value, amountMaxLength]);
        },
        errorKey: 'error.invalid.max.amount'
      },
    ]

    this.dateValidationFns = [
      {
        // If the date is in future then it should be in current month
        validationFunction: (value: any[]) => (!(SadaCustomValidator.isFutureDate(value[0])
          && !SadaCustomValidator.isCurrentMonth(value[0]))),
        errorKey: 'error.invalid.date'
      },
      {
        validationFunction: (value: []) => SadaCustomValidator.validateDateWithMin(
          [...value, this.getDateOfBirth(this.applicantType)]),
        errorKey: 'error.invalid.date'
      }
    ];
    this.applicantTypeNameLabelParam = {
      type: this.applicantType,
      name: this.applicantName,
      index: this.applicantType.startsWith('Child') ? this.getChildIndex(this.applicantType) : null
    }
  }

  ngOnDestroy() {
    this.valueChangeSubjects$.next()
    this.valueChangeSubjects$.complete()
  }

  get incomeType() {
    return this.form.get('earnedIncomeType')?.value;
  }

  get isDisabilityWorkExpense(){
    return this.form.get('disabilityWorkExpense')?.value === 'yes';
  }

  get isDisability(): boolean{
    return this.data.personWithDisability === 'yes'
      || (this.data.personWithDisabilityInFamily === 'yes' && this.data.personWithDisabilityInFamilyCheckbox.includes(this.applicantType + ': ' + this.applicantName));
  }

  private disableFields(fields: string[]) {
    fields.forEach(field => this.form.get(field).disable());
  }

  private enableFields(fields: string[]) {
    fields.forEach(field => this.form.get(field).enable());
  }

  private getDateOfBirth(applicantType:string): string {
    let dateOfBirth:string = this.data.dateOfBirth; // default to primary applicant's date of birth

    if (applicantType.startsWith('Spouse')) {
      dateOfBirth = this.data.spouseDateOfBirth;
    } else if (applicantType.startsWith('Child')) {
      if (this.data.childList) {
        dateOfBirth = this.data.childList[this.getChildIndex(applicantType) -1].childDateOfBirth;
      }
    }
    return dateOfBirth;
  }

  private getChildIndex(applicantType:string): number {
    return Number(applicantType.substring('Child'.length).trim()); // obtain index value after 'Child'
  }

  get continueThisIncomeContextLabel(): string{
    if(this.applicantType === 'Applicant'){
      return 'earned-income.continue.this.income.context.applicant';
    }else if(this.applicantType === 'Spouse'){
      return 'earned-income.continue.this.income.context.spouse';
    }else{
      return 'earned-income.continue.this.income.context.child';
    }
  }
  get incomeTypeContext()
  {
    const cleanApplicantType = this.applicantType.replace(/[\s\d]+/g, '');
    return `earned-income.income.type.context.${cleanApplicantType}`
  }

  get disabilityWorkExpenseContext(){
    let disabilityWorkExpenseQuestionLabel;
    if(this.isSingleApplicant){
      disabilityWorkExpenseQuestionLabel = 'earned-income.disability.work.expense'
    }else{
      const cleanApplicantType = this.applicantType.replace(/[\s\d]+/g, '');
      disabilityWorkExpenseQuestionLabel = `earned-income.disability.work.expense.context.${cleanApplicantType.toLowerCase()}`
    }

    return disabilityWorkExpenseQuestionLabel;
  }
}
