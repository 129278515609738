import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stripHtmlTags'
})
/**
 * This pipe removes all the HTML tags from the given text.
 */
export class StripHtmlTagsPipe implements PipeTransform {
  transform(text: string): any {
    return ((text && text.length) ? text.replace(/<[^>]+>/g, '') : text);
  }
}
