import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sd-alert-err',
  templateUrl: './alert-err.component.html',
  styleUrls: ['./alert-err.component.scss']
})
export class AlertErrComponent implements OnInit {

  @Input()
  errorId: string | undefined

  @Input()
  errorMessage: string | undefined

  @Input()
  isInnerHtml: string | undefined

  constructor() {
  }

  ngOnInit(): void {
  }

}
