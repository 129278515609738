import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {HHIDataConversionConfig} from '../model/hhi-data-conversion-config';
import {HouseholdIncomeQuestionHelper} from '../service/household-income-question.helper';
import {BaseAppDataToHHIQuestions} from './base-app-data-to-hhi-questions';
import {addDynamicPanels} from '../../../common/utils/dynamic-panel/dynamic-panel.util';

/**
 * Converter to create house hold income questions with only trying or receiving payment questions
 */
export class AppDataToStandardHHIQuestions extends BaseAppDataToHHIQuestions {

  convert(appData: ApplicationAnswers, question: Question<string>,
          dataConversionConfig: HHIDataConversionConfig): void {

    const whoGetsThePaymentQKey = dataConversionConfig.questionKey.concat('WhoGetsThePayment')
    this.populatePaymentCheckBoxValue(appData, question, dataConversionConfig)
    this.addSingleOrFamilyOptions(appData, question, dataConversionConfig)

    // Add dynamic panels for applicant, spouse and children
    let index = question.panels[0].questions.findIndex(
      q => q.key === whoGetsThePaymentQKey);
    addDynamicPanels(dataConversionConfig.questionKey,
      whoGetsThePaymentQKey, index, question.panels[0].questions,
      this.populateIncomeDetailQuestions(appData, question.panels[0].questions, dataConversionConfig),
      [
        {
          questionKey: dataConversionConfig.questionKey,
          questionControlType: 'checkbox',
          value: [true],
          index: 0
        }
      ], {showAddButton: false, indented: true, showLineSeparator: true});
  }

  populateIncomeDetailQuestions(appData: ApplicationAnswers, questions: Question<string>[],
                                dataConversionConfig: HHIDataConversionConfig): Map<string, Map<number, Question<string>[]>> {

    const map: Map<string, Map<number, Question<string>[]>> = new Map();
    const question = questions.find(q => q.key === dataConversionConfig.questionKey.concat('WhoGetsThePayment'))

    question.options.forEach(o => {
      let incomeDetailQuestions;
      if (o.alternateValue.startsWith('Applicant')) {
        incomeDetailQuestions = [...HouseholdIncomeQuestionHelper.getIncomeDetailQuestions([o.labelParam?.name],'household-income.applicant')];
      } else if (o.alternateValue.startsWith('Spouse')) {
        incomeDetailQuestions = [...HouseholdIncomeQuestionHelper.getIncomeDetailQuestions([o.labelParam?.name],'household-income.spouse')];
      } else if (o.alternateValue.startsWith('Child')) {
        incomeDetailQuestions = [...HouseholdIncomeQuestionHelper.getIncomeDetailQuestions([o.labelParam.index, o.labelParam?.name],'household-income.child')];
      }
      const applicantValue = appData.jsonData[dataConversionConfig.incomeDetailAttributeName]?.find(
        a => a.applicant === o.alternateValue);

      if (applicantValue && applicantValue[dataConversionConfig.incomeStateAttributeName]) {
        incomeDetailQuestions[0].value = applicantValue[dataConversionConfig.incomeStateAttributeName];
      }

      if (applicantValue && applicantValue[dataConversionConfig.incomeMonthlyAmountAttributeName]) {
        incomeDetailQuestions[1].value = applicantValue[dataConversionConfig.incomeMonthlyAmountAttributeName];
      }
      const panelMap: Map<number, Question<string>[]> = new Map();
      panelMap.set(0, incomeDetailQuestions);
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }
}
