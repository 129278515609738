import {LoadingSpinnerService} from '../services/loading-spinner.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {

  // If EID/ESign loading spinners can follow the same pattern as other pages then instead of urls to display loading spinner,
  // urls to ignore loading spinner can be used which would be very few (Just async validation URLs)
  private static readonly urls = new Map([
    ['/postal-code/', 'GET'],
    ['/address-choices', 'POST'],
    ['/application/', 'PUT'],
    ['/v1/application/validate', 'GET'],
    ['/submit-questionnaire', 'POST'], // Consent page
    ['/v1/eid/application/', 'GET'], // Consent page
    ['/v1/esignature/validation', 'GET'], // Consent page
    ['/v1/recaptcha/verify/application', 'POST'],
    ['/v1/recaptcha/verify/login-confirmation', 'POST'],
    ['/v1/recaptcha/verify/app-status', 'POST'],
    ['/v1/auth/url', 'POST'],
    ['/v1/program-type', 'GET'],
    ['/v1/update-program-type', 'POST']
  ]);

  constructor(private loadingSpinnerService: LoadingSpinnerService) {
  }

  private static showSpinner(method: string, url: string): boolean {
    let showSpinner =false;

    this.urls.forEach((httpType, urlToIntercept) => {
      if (url.includes(urlToIntercept) && method === httpType) {
        showSpinner = true;
      }
    });

    return showSpinner;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let finished = false;

    if (LoadingSpinnerInterceptor.showSpinner(request.method, request.url)) {
      this.loadingSpinnerService.show();
    }

    setTimeout(()=> {
      if (!finished && LoadingSpinnerInterceptor.showSpinner(request.method, request.url)) {
        this.loadingSpinnerService.show(true);
      }
    }, 8000)

    return next.handle(request)
      .pipe(tap((event: HttpEvent<any>) => {
          if ((event instanceof HttpResponse &&
               LoadingSpinnerInterceptor.showSpinner(request.method, request.url))) {
            finished = true;
            this.loadingSpinnerService.hide();
          }
        }, (error) => {
            this.loadingSpinnerService.hide();
            finished = true;
        }
      ));
  }
}
