/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import {LogLevel, Configuration, BrowserCacheLocation} from '@azure/msal-browser';

import {ConfigService} from './services/config.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = (configService: ConfigService): Configuration => {
  return {
    auth: {
      // client Id of registered SADA app on Azure portal.
      clientId: configService.getClientConfig()?.clientId,
      authority: `https://login.microsoftonline.com/${configService.getClientConfig()?.tenantId}`,
      // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
      redirectUri: configService.getClientConfig()?.redirectUrl,
      postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
      // If "true", will navigate back to the original request location before processing the auth code response.
      navigateToLoginRequestUrl: true,
    },
    cache: {
      // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          // console.log(message);
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      },
      allowNativeBroker: false, // Disables WAM Broker
    }
  }
}
