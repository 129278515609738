import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {IntakeService} from '../services/intake.service';
import {Observable} from 'rxjs';
import {ApplicationAnswers} from '../models/data.model';
import {SadaCustomValidator} from '../validator/sada-custom-validator';
import {SharedUtil} from '@shared/shared.util';

@Injectable({
  providedIn: 'root'
})
export class AppDataResolver  {
  constructor(private intakeService: IntakeService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApplicationAnswers> {
    let appAnswers;
    if (route.queryParams?.action === 'resume') {  // To handle the resuming of the application
      appAnswers = this.intakeService.getSavedApplication();

      appAnswers.subscribe(data => {
        const applicantSponsored = data?.jsonData?.applicantSponsored;
        const spouseSponsored = data?.jsonData?.spouseSponsored;
        const childList = data?.jsonData?.childList;
        const maritalStatus = data?.jsonData?.maritalStatus;
        const childrenLivingWithYou = data?.jsonData?.childrenLivingWithYou;
        const shouldExcludeDbd = SharedUtil.checkIfDbdShouldBeExcluded(data);

        if (applicantSponsored === 'yes' || SadaCustomValidator.isFamilySponsored([spouseSponsored, childList])) {
          this.intakeService.setSponsorshipRequired(true)
        }

        if (maritalStatus === 'Married' || maritalStatus === 'Common Law') {
          this.intakeService.setAppSpouseRequired(true)
        }

        if (childrenLivingWithYou === 'yes') {
          this.intakeService.setAppChildRequired(true)
        }

        if (shouldExcludeDbd) {
          this.intakeService.setShouldExcludeDbd(true)
        }
      });

      return appAnswers;
    } else {
      return this.intakeService.getApplication(route.data.pageId);
    }
  }
}
