
  <div *ngFor="let question of questions; let index = index; trackBy: trackByFn" [ngClass]="questionClasses(question)">

    <sd-dynamic-question *ngIf="isVisible(question)"
                         [showError]="showError"
                         [question]="question"
                         [pageQuestions]="questions"
                         [disabled]="isDisabled(question)"
                         [form]="form">
    </sd-dynamic-question>

  </div>



