import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {FormToAppDataBase} from '../../../common/utils/converters/form-to-app-data-base';


export class FormToFAVehicles extends FormToAppDataBase implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  static readonly CHECK_BOX = 'ownItemsOfValueVehiclesCheckbox';
  static readonly DETAILS_LIST = 'ownItemsOfValueDetailsListVehicle';
  static readonly APPLICANT_CHECKBOX = 'ownItemsOfValueApplicantCheckboxVehicle';
  static readonly PANEL_CHILD = 'FAVehiclesWhoOwnsThisAssetPanelChild';
  static readonly WHO_OWN_THIS_ASSET = 'FAVehiclesWhoOwnsThisAsset';

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // remove previous saved data and repopulate with latest form data
    delete appData.jsonData[FormToFAVehicles.CHECK_BOX];
    delete appData.jsonData[FormToFAVehicles.DETAILS_LIST];
    delete appData.jsonData[FormToFAVehicles.APPLICANT_CHECKBOX];

    if (form.get('FAVehicles')?.value[0]) {
      appData.jsonData[FormToFAVehicles.CHECK_BOX] = ['yes'];

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, questions);

      // populate details list
      this.populateDetailsList(form, appData, questions);
    }
  }

  populateCheckBoxList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const list = [];
    const question = questions.find(q => q.key === 'FAVehiclesWhoOwnsThisAsset');

    if(appData.jsonData.spouseFirstName || appData.jsonData.childList){

      const applicantOption = question.options.find(o => o.label === 'financial-assets.applicant');

      if (this.isApplicantSelected(FormToFAVehicles.WHO_OWN_THIS_ASSET,form,questions) &&
        form.get('FAVehiclesWhoOwnsThisAssetPanelApplicant')?.value.panels[0].vehicleValue) {
        list.push(applicantOption.alternateValue);
      }
    }

    if (this.isSpouseSelected(FormToFAVehicles.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FAVehiclesWhoOwnsThisAssetPanelSpouse')?.value.panels[0].vehicleValue){
      const spouseOption = question.options.find(o => o.label === 'financial-assets.spouse');
      list.push(spouseOption.alternateValue);
    }

    if (appData.jsonData.childList){
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (form.get(FormToFAVehicles.PANEL_CHILD.concat(String(i)))?.value.panels[0].vehicleValue) {
          const child = questions.find(q => q.key === FormToFAVehicles.PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFAVehicles.WHO_OWN_THIS_ASSET,form,questions)) {
            list.push(child.value);
          }
        }
      }
    }

    if (list.length > 0) {
      appData.jsonData[FormToFAVehicles.APPLICANT_CHECKBOX] = list;
    }

  }

  populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const detailsList: {
      applicant: string,
      applicantType: string,
      applicantDisplayType: string,
      applicantName: string,
      vehicleList: any[]
    }[] = [];

    if (this.isApplicantSelected(FormToFAVehicles.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FAVehiclesWhoOwnsThisAssetPanelApplicant').value.panels[0].vehicleValue) {
      const applicant = {
        applicant: 'Applicant: '.concat(appData.jsonData.firstName),
        applicantType: 'Applicant',
        applicantDisplayType: 'Applicant',
        applicantName: ' '.concat(appData.jsonData.firstName),
        vehicleList: []
      };

      form.get('FAVehiclesWhoOwnsThisAssetPanelApplicant').value.panels.forEach(p => {
        applicant.vehicleList.push({
          make: p.vehicleMake,
          model: p.vehicleModel,
          year: p.vehicleYear,
          value: p.vehicleValue,
          usedForWork: p.vehicleForWork
        });
      });

      detailsList.push(applicant);
    }

    if (this.isSpouseSelected(FormToFAVehicles.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FAVehiclesWhoOwnsThisAssetPanelSpouse')?.value.panels[0].vehicleValue) {
      const spouse = {
        applicant: 'Spouse: '.concat(appData.jsonData.spouseFirstName),
        applicantType: 'Spouse',
        applicantDisplayType: 'Spouse',
        applicantName: ' '.concat(appData.jsonData.spouseFirstName),
        vehicleList: []
      };

      form.get('FAVehiclesWhoOwnsThisAssetPanelSpouse').value.panels.forEach(p => {
        spouse.vehicleList.push({
          make: p.vehicleMake,
          model: p.vehicleModel,
          year: p.vehicleYear,
          value: p.vehicleValue,
          usedForWork: p.vehicleForWork
        });
      });

      detailsList.push(spouse);
    }

    // populate children data
    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (this.isChildSelected(i,FormToFAVehicles.WHO_OWN_THIS_ASSET,form,questions) &&
          form.get(FormToFAVehicles.PANEL_CHILD.concat(String(i)))?.value.panels[0].vehicleValue) {
          const childPanel = questions.find(q => q.key === FormToFAVehicles.PANEL_CHILD.concat(String(i)));
          const child =
            {
              applicant: childPanel.value,
              applicantType: 'Child '.concat(String(i)),
              applicantDisplayType: 'Child '.concat(String(i)),
              applicantName: childPanel.value.substr(childPanel.value.indexOf(':') + 1),
              vehicleList: []
            }

          form.get(FormToFAVehicles.PANEL_CHILD.concat(String(i)))?.value.panels.forEach(p => {
            child.vehicleList.push(
              {
                make: p.vehicleMake,
                model: p.vehicleModel,
                year: p.vehicleYear,
                value: p.vehicleValue,
                usedForWork: p.vehicleForWork
              }
            );
          });

          detailsList.push(child);
        }
      }
    }

    if (detailsList.length > 0){
      appData.jsonData[FormToFAVehicles.DETAILS_LIST] = detailsList;
    }
  }
}
