import {SadaCustomValidator} from '../validator/sada-custom-validator';


export class ValidationFnsUtil {

  static emailValidationFns(applyingForYourselfOrSomeoneElse: string, isAuthorizedUser: boolean): {
    validationFunction: (value: any) => boolean,
    errorKey: string
  }[] {
    return [
      {
        validationFunction: (value) => SadaCustomValidator.validateSpecialCharactersInEmail(value),
        errorKey: 'error.invalid.email.specialCharacters'
      },
      {
        validationFunction: (value) => SadaCustomValidator.validateEmail(value),
        errorKey: 'error.invalid.email.address'
      },
      // { 66437-Functionality might be used in future if SAMS wants to support french characters for the email field
      //   validationFunction: (value) => SadaCustomValidator.validateFrenchCharactersInEmail(value),
      //   errorKey: 'error.invalid.email.invalidFrenchCharacters'
      // },
      {
        validationFunction: (value) => SadaCustomValidator.validateBasicEmailFormat(value),
        errorKey: 'error.invalid.email.domain'
      },
      {
        validationFunction: (value) => value && SadaCustomValidator.validateRequiredLength([value, 1, 64,
          ('APPLICATION_SELF' === applyingForYourselfOrSomeoneElse || isAuthorizedUser).toString()]),
        errorKey: 'error.invalid.email.maxLength'
      },
      {  // Has to put this last to show the message if the prohibited top-level domain is entered
        validationFunction: (value) => SadaCustomValidator.validateEmailDomainAllowed(value),
        errorKey: 'error.invalid.email.domain.ru'
      }
    ];
  }
}
