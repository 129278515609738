<div class="button-container">
  <div *ngIf="isPageAllowedToSave && authService.isAuthorizedToSave()" class="ontario-margin-right-32-!">
    <app-mccss-submit-button buttonId="{{pageId}}-save-continue-button" dataE2E="saveContinueBtn" [removeMarginRight]="true"
                             buttonLabel="btn.saveContinue" [preventDoubleClick]="true" [hidden]="hidden"
                             [disabled]="disabled" (handleClick)="onHandlePrimaryButton()"></app-mccss-submit-button>
  </div>
  <div *ngIf="!isPageAllowedToSave || !authService.isAuthorizedToSave()" class="ontario-margin-right-32-!">
    <app-mccss-submit-button buttonId="{{pageId}}-continue-button" dataE2E="continueBtn" [removeMarginRight]="true"
                             buttonLabel="{{buttonLabel ? buttonLabel : 'btn.continue'}}" [preventDoubleClick]="true"
                             [hidden]="hidden" [disabled]="disabled"
                             (handleClick)="onHandlePrimaryButton()"></app-mccss-submit-button>
  </div>
  <div *ngIf="isPageAllowedToSave">
    <app-mccss-submit-button buttonId="{{pageId}}-save-exit-button" dataE2E="saveExitBtn" buttonLabel="btn.saveExit"
                             [preventDoubleClick]="true" [secondaryButton]="true" [hidden]="hidden" [disabled]="disabled"
                             (handleClick)="onHandleSecondaryButton()"></app-mccss-submit-button>
  </div>
</div>
