import {AfterViewInit, Component, ContentChildren, ElementRef, Input, QueryList} from '@angular/core';

declare function toggleExpander(item: any): any;

/**
 *  This is the wrapper (with slight modifications) of ODS Accordions component
 *  (https://designsystem.ontario.ca/components/detail/accordions.html).
 *
 *  <sd-accordion [id]="'according.id'"
 *                [heading]="'some.heading'"
 *                [toExpand]="true/false">
 *    <div #content>
 *      The HTML to display when the accordion is expanded.
 *    </div>
 *  </sd-accordion>
 */
@Component({
  selector: 'sd-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements AfterViewInit {
  readonly ACCORDION_BUTTON_ID_SUFFIX = '-accordion-expander-button-id';

  @ContentChildren('content')
  content: QueryList<ElementRef>;

  @Input()
  public id = 'accordion';
  @Input()
  public occurrence = 1;  // To set up unique id for multiple accordions on the same page.
  @Input()
  public heading: string;  // Translation reference-id of the heading-text.
  @Input()
  public toExpand: boolean; // To expand the accordion after it is rendered.

  public expanded: boolean; // To keep track if the accordion is expanded.

  constructor() {
    this.expanded = false;
  }

  ngAfterViewInit() {
    // The original logic in ontario-expand-collapse of ODS add the listener in the 'DOMContentLoaded' event. However,
    // this approach does not work in Angular application.
    if (this.content.length) {  // Only allow to expand if there is element being projected.
      const expandableItems = this.findElement(this.ACCORDION_BUTTON_ID_SUFFIX) ?
        [this.findElement(this.ACCORDION_BUTTON_ID_SUFFIX)] : [];
      toggleExpander(expandableItems);
      if (this.toExpand) {
        this.expanded = !this.expanded;
        this.expandElement(this.ACCORDION_BUTTON_ID_SUFFIX);
      }
    }
  }

  onHeadingClicked(){
    this.expanded = !this.expanded;
  }

  private findElement(elementIdSuffix: string) : HTMLElement {
    // ODS suggests to use document.querySelectorAll("[data-toggle='ontario-collapse']"), but that could return
    // multiple elements in multiple usage situation.
    return document.getElementById(this.occurrence !== null && this.occurrence !== undefined ?
       this.id + this.occurrence + elementIdSuffix : this.id + elementIdSuffix);
  }

  private expandElement(elementSuffix: string): void {
    const element = this.findElement(elementSuffix);
    if (element) {
      element.click();
    }
  }
}
