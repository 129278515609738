export function isPastDateCheck(startDate: string, endDate: string): boolean {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (start.toString() === 'Invalid Date' || end.toString() === 'Invalid Date') {
    return false;
  }

  return now <= end && now >= start && start < end;
}

export function getBannerClass(type: string): string {
  switch(type) {
    case 'warning': return 'ontario-alert ontario-alert--warning sm-custom';
    case 'information': return 'ontario-alert ontario-alert--informational sm-custom';
    case 'error': return 'ontario-alert ontario-alert--error sm-custom';
    default: return 'ontario-alert ontario-alert--informational sm-custom';
  }
}

export function getBannerIcon(type: string): string {
  switch(type) {
    case 'warning': return '#ontario-icon-alert-warning';
    case 'information': return '#ontario-icon-alert-information';
    case 'error': return '#ontario-icon-alert-error';
    default: return '#ontario-icon-alert-information';
  }
}
