import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {dateFormatter} from '../../../common/utils/formatters/formatter';
import {
  ApplicationProgramStatus,
  ApplicationStatusResponse,
  ApplicationStatusType,
  DisabilityApplicationPathwayType, DisabilityApplicationStatus
} from '../../../models/application-status-response';
import {ProgramType} from '../../../models/program-type';
import {UrlInfo} from '../../../models/url-map';
import {ConfigService} from '../../../services/config.service';
import {ApplicationStatusBaseComponent} from '../ApplicationStatusBaseComponent';
import {AscStatusProgressStep} from '../asc-status-progress-bar/asc-status-progress-step';

@Component({
  selector: 'sd-multi-programs-asc-status',
  templateUrl: './multi-programs-asc-status.component.html',
  styleUrls: ['./multi-programs-asc-status.component.scss']
})
export class MultiProgramsAscStatusComponent extends ApplicationStatusBaseComponent {
  public onwProgramProgressSteps: AscStatusProgressStep[];
  public odsProgramProgressSteps: AscStatusProgressStep[];

  constructor(protected translator: TranslateService,
              protected configService: ConfigService) {
    super(translator, configService);
    this.hasLanguageDependentParam = true;
  }

  protected buildProgressSteps(): void {
    if (this.applicationStatus?.programStatuses?.length < 2) {
      return;
    }

    // Can only handle ONW & ODS programs for now.
    const onwProgramStatus = this.getProgramStatus(this.applicationStatus, ProgramType.ONW);
    const odsProgramStatus = this.getProgramStatus(this.applicationStatus, ProgramType.ODS);
    if (!onwProgramStatus || !odsProgramStatus) {
      return;
    }

    if (this.isDecisionStatus(onwProgramStatus.applicationStatus)) {
      this.buildDecisionSteps(onwProgramStatus, odsProgramStatus);
    } else if (onwProgramStatus.applicationStatus === ApplicationStatusType.Assigned) {
      this.buildAssignedForReviewSteps(onwProgramStatus, odsProgramStatus);
    } else if (onwProgramStatus.applicationStatus === ApplicationStatusType.Submitted) {
      this.buildSubmittedSteps(onwProgramStatus, odsProgramStatus);
    }
  }

  private getProgramStatus(statusResponse:  ApplicationStatusResponse, type: ProgramType): ApplicationProgramStatus {
    return statusResponse.programStatuses.find((program) => program.programType === type);
  }

  private buildSubmittedSteps(onwProgramStatus: ApplicationProgramStatus, odsProgramStatus: ApplicationProgramStatus) {
    this.onwProgramProgressSteps = [];
    this.onwProgramProgressSteps.push({
      programType: onwProgramStatus.programType,
      id: 'submitted',
      name: '.multi-programs.step1.name.SUBMITTED',
      text: '.multi-programs.step1.text.SUBMITTED',
      textParam: {link: dateFormatter(onwProgramStatus.submittedDate, this.translator.currentLang)},
      completed: false,
      active: true,
      failed: false,
      hasStepFollowed: true,
    });
    this.onwProgramProgressSteps.push({
      programType: onwProgramStatus.programType,
      id: 'review',
      name: '.multi-programs.step2.name.SUBMITTED',
      completed: false,
      active: false,
      hasStepFollowed: true,
    });
    this.onwProgramProgressSteps.push({
      programType: onwProgramStatus.programType,
      id: 'application-decision',
      name: '.multi-programs.step3.name.SUBMITTED',
      completed: false,
      active: false,
      hasStepFollowed: false,
    });

    this.odsProgramProgressSteps = [];
    this.odsProgramProgressSteps.push({
      programType: odsProgramStatus.programType,
      id: 'submitted',
      name: '.multi-programs.step1.name.SUBMITTED',
      text: '.multi-programs.step1.text.SUBMITTED',
      textParam: {link: dateFormatter(odsProgramStatus.submittedDate, this.translator.currentLang)},
      completed: false,
      active: true,
      failed: false,
      hasStepFollowed: true,
    });
    this.odsProgramProgressSteps.push({
      programType: odsProgramStatus.programType,
      id: 'medical-review',
      name: '.multi-programs.step2.name.SUBMITTED',
      completed: false,
      active: false,
      hasStepFollowed: true,
    });
    this.odsProgramProgressSteps.push({
      programType: odsProgramStatus.programType,
      id: 'application-decision',
      name: '.multi-programs.step3.name.SUBMITTED',
      completed: false,
      active: false,
      hasStepFollowed: false,
    });
  }

  private buildAssignedForReviewSteps(onwProgramStatus: ApplicationProgramStatus, odsProgramStatus: ApplicationProgramStatus) {
    this.onwProgramProgressSteps = [];
    this.onwProgramProgressSteps.push({
      programType: onwProgramStatus.programType,
      id: 'submitted',
      name: '.multi-programs.step1.name.ASSIGNED',
      nameParam: {link: dateFormatter(onwProgramStatus.submittedDate, this.translator.currentLang)},
      completed: true,
      active: false,
      failed: false,
      hasStepFollowed: true,
    });
    this.onwProgramProgressSteps.push({
      programType: onwProgramStatus.programType,
      id: 'review',
      name: '.multi-programs.step2.name.ASSIGNED',
      text: '.multi-programs.step2.text',
      textParam: {link: dateFormatter(onwProgramStatus.assignedForReviewDate, this.translator.currentLang)},
      completed: false,
      active: true,
      hasStepFollowed: true,
    });
    this.onwProgramProgressSteps.push({
      programType: onwProgramStatus.programType,
      id: 'application-decision',
      name: '.multi-programs.step3.name.ASSIGNED',
      completed: false,
      active: false,
      hasStepFollowed: false,
    });

    this.odsProgramProgressSteps = [];
    this.odsProgramProgressSteps.push({
      programType: odsProgramStatus.programType,
      id: 'submitted',
      name: '.multi-programs.step1.name.SUBMITTED.ongoing',
      nameParam: {link: dateFormatter(odsProgramStatus.submittedDate, this.translator.currentLang)},
      completed: true,
      active: false,
      failed: false,
      hasStepFollowed: true,
    });
    this.odsProgramProgressSteps.push({
      programType: odsProgramStatus.programType,
      id: 'medical-review',
      name: '.multi-programs.step2.name.SUBMITTED',
      completed: false,
      active: false,
      hasStepFollowed: true,
    });
    this.odsProgramProgressSteps.push({
      programType: odsProgramStatus.programType,
      id: 'application-decision',
      name: '.multi-programs.step3.name.SUBMITTED',
      completed: false,
      active: false,
      hasStepFollowed: false,
    });
  }

  private buildDecisionSteps(onwProgramStatus: ApplicationProgramStatus, odsProgramStatus: ApplicationProgramStatus) {
    this.onwProgramProgressSteps = [];
    this.onwProgramProgressSteps.push({
      programType: onwProgramStatus.programType,
      id: 'submitted',
      name: '.multi-programs.step1.name.DECISION',
      nameParam: {link: dateFormatter(onwProgramStatus.submittedDate, this.translator.currentLang)},
      completed: true,
      active: false,
      failed: false,
      hasStepFollowed: true,
    });
    this.onwProgramProgressSteps.push({
      programType: onwProgramStatus.programType,
      id: 'review',
      name: '.multi-programs.step2.name.DECISION',
      nameParam: {link: dateFormatter(onwProgramStatus.assignedForReviewDate, this.translator.currentLang)},
      completed: true,
      active: false,
      hasStepFollowed: true,
    });
    this.onwProgramProgressSteps.push({
      programType: onwProgramStatus.programType,
      id: 'application-decision',
      name: '.multi-programs.step3.name.'.concat(onwProgramStatus.applicationStatus),
      // To display different texts for ODSP-pending scenario.
      text: (!this.isDecisionStatus(odsProgramStatus.applicationStatus) ? '.multi-programs.step3.text.'.
      concat(onwProgramStatus.applicationStatus).concat('.onwOnly') : '.multi-programs.step3.text.'.
      concat(onwProgramStatus.applicationStatus)),
      textParam: {
        link: onwProgramStatus.icaDecisionDate ? dateFormatter(onwProgramStatus.icaDecisionDate, this.translator.currentLang)  : '???',
      },
      completed: false,
      active: true,
      failed: onwProgramStatus.applicationStatus === ApplicationStatusType.Withdrawn,
      hasStepFollowed: false,
    });

    this.odsProgramProgressSteps = [];
    if (this.isDecisionStatus(odsProgramStatus.applicationStatus)) {
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'submitted',
        name: '.multi-programs.step1.name.DECISION',
        nameParam: {link: dateFormatter(odsProgramStatus.submittedDate, this.translator.currentLang)},
        completed: true,
        active: false,
        hasStepFollowed: true,
      });
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'review',
        name: '.multi-programs.step2.name.ASSIGNED',
        nameParam: {link: dateFormatter(odsProgramStatus.assignedForReviewDate, this.translator.currentLang)},
        completed: true,
        active: false,
        hasStepFollowed: true,
      });
      if(odsProgramStatus.disabilityApplicationPathway === DisabilityApplicationPathwayType.DisabilityDeterminationEvidence_PrescribedClass) {
        if (odsProgramStatus.disabilityApplicationStatusInfo?.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityAdjudicationNotRequired) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'review_complete',
            name: '.multi-programs.step3.name.ADJUDICATION_NOT_REQUIRED',
            completed: true,
            active: false,
            failed: false,
            hasStepFollowed: true,
          });
        }
      }
      if(this.isDauDdpReceivedNotNullExemptedStatus(odsProgramStatus))
      {
        this.odsProgramProgressSteps.push({
          programType: odsProgramStatus.programType,
          id: 'assigned',
          name: '.financial-eligibility.step3.name.DECISION',
          nameParam: {
            link: odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate ?
              dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang) : '???'
          },
          completed: true,
          active: false,
          hasStepFollowed: true,
        });
        this.odsProgramProgressSteps.push({
          programType: odsProgramStatus.programType,
          id: 'assigned',
          name: '.medical-eligibility.step2.name.DECISION',
          nameParam: {
            link: odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate ?
              dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang) : '???'
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: true,
        });
        this.multiProgramsPersonWithDisability(odsProgramStatus, this.odsProgramProgressSteps);
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.IncompleteDisabilityDeterminationPackageReceived) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'no-additional-steps-required',
            name: '.medical-eligibility.step3.name.INCOMPLETE_DDP_RECEIVED',
            completed: false,
            active: true,
            failed: true,
            failedIconWithX:true,
            hasStepFollowed: true,
            normalizeHeading: true
          });
        }
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.PersonWithoutDisability) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'person-without-disability',
            name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED_WITH_DATE',
            nameParam: {
              link:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpReceivedDate, this.translator.currentLang),
            },
            completed: true,
            active: false,
            failed: false,
            hasStepFollowed: true
          });
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'person-without-disability',
            name: '.medical-eligibility.step3.name.APPROVED_WITH_DATE',
            nameParam: {
              link:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.dauDecisionDate, this.translator.currentLang),
            },
            completed: true,
            active: false,
            failed: false,
            hasStepFollowed: true
          });
        }
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageIssued) {
          this.odsProgramProgressSteps.push(
            {
              programType: odsProgramStatus.programType,
              id: 'disability-determination-package-issued-review',
              name: '.medical-eligibility.step3.name.REVIEW',
              completed: false,
              active: false,
              failed: false,
              hasStepFollowed: true,
              normalizeHeading: true
            }
          )
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'disability-determination-package-issued-outcome',
            name: '.medical-eligibility.step3.name.OUTCOME',
            completed: false,
            active: false,
            failed: false,
            hasStepFollowed: true,
          });
        }
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.CompleteDisabilityDeterminationPackageReceived) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'complete-disability-determination-package-received',
            name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED_WITH_DATE',
            nameParam: {
              link:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpReceivedDate, this.translator.currentLang),
            },
            completed: true,
            active: false,
            failed: false,
            hasStepFollowed: true
          });
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'disability-determination-package-issued-outcome',
            name: '.medical-eligibility.step3.name.OUTCOME',
            completed: false,
            active: false,
            failed: false,
            hasStepFollowed: true,
          });
        }
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageExpired) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'disability-determination-package-expired',
            name: '.medical-eligibility.step2.name.EXPIRED',
            nameParam: { link: dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpExpiryDate, this.translator.currentLang) },
            completed: false,
            active: true,
            failed: true,
            failedIconWithX: true,
            hasStepFollowed: true,
            normalizeHeading: true
          });
        }
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.ReferralCancelled) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'incomplete-disability-determination-package-received',
            name: '.medical-eligibility.step3.name.REFERRAL_CANCELLED',
            completed: false,
            active: true,
            failed: true,
            failedIconWithX: true,
            hasStepFollowed: true,
            normalizeHeading: true
          });
        }
      }
      if(this.catchAllStatusAndExemptions(odsProgramStatus)) {
        if (odsProgramStatus.applicationStatus === ApplicationStatusType.Approved) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'medical-review',
            name: '.multi-programs.step2.name.DECISION',
            completed: true,
            active: false,
            hasStepFollowed: true,
          });
        }
      }
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'application-decision',
        name: '.multi-programs.step3.name.'.concat(odsProgramStatus.applicationStatus),
        text: odsProgramStatus.disabilityApplicationStatusInfo?.disabilityApplicationStatus === DisabilityApplicationStatus.PersonWithDisability ?
          '.multi-programs.step3.text.'.concat(odsProgramStatus.applicationStatus).concat('.hasDisability'): '.multi-programs.step3.text.'.concat(odsProgramStatus.applicationStatus),
        textParam: {
          link: odsProgramStatus.icaDecisionDate ? dateFormatter(odsProgramStatus.icaDecisionDate, this.translator.currentLang)  : '???',
        },
        completed: false,
        active: true,
        failed: odsProgramStatus.applicationStatus === ApplicationStatusType.Withdrawn,
        hasStepFollowed: false,
      });
    } else if (odsProgramStatus.applicationStatus === ApplicationStatusType.Assigned) {  // ODSP pending scenario
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'submitted',
        name: '.multi-programs.step1.name.SUBMITTED.ongoing',
        nameParam: {link: dateFormatter(odsProgramStatus.submittedDate, this.translator.currentLang)},
        completed: true,
        active: false,
        failed: false,
        hasStepFollowed: true,
      });
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'ods-assigned',
        name: '.multi-programs.ods.step2.name.ASSIGNED',
        text: '.multi-programs.ods.step2.text',
        textParam: {
          assignedDate: odsProgramStatus.assignedForReviewDate
            ? dateFormatter(odsProgramStatus.assignedForReviewDate, this.translator.currentLang)
            : '???',
          link: this.getUrl(UrlInfo.odspMedicalCheckExemptions)
        },
        completed: false,
        active: true,
        hasStepFollowed: true,
      });
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'medical-review',
        name: '.multi-programs.step2.name.SUBMITTED',
        completed: false,
        active: false,
        hasStepFollowed: true,
      });
      if(odsProgramStatus.disabilityApplicationPathway === DisabilityApplicationPathwayType.DisabilityAdjudicationUnit)
      {
        this.odsProgramProgressSteps.pop();
        this.odsProgramProgressSteps.pop();
        this.odsProgramProgressSteps.push({
          programType: odsProgramStatus.programType,
          id: 'review',
          name: '.multi-programs.step2.name.ASSIGNED',
          nameParam: {link: dateFormatter(odsProgramStatus.assignedForReviewDate, this.translator.currentLang)},
          completed: true,
          active: false,
          hasStepFollowed: true,
        });
        this.odsProgramProgressSteps.push({
          programType: odsProgramStatus.programType,
          id: 'assigned',
          name: '.financial-eligibility.step3.name.DECISION',
          nameParam: {
            link: odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate ?
              dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang) : '???'
          },
          completed: true,
          active: false,
          hasStepFollowed: true,
        });
        this.odsProgramProgressSteps.push({
          programType: odsProgramStatus.programType,
          id: 'assigned',
          name: '.medical-eligibility.step2.name.DECISION',
          nameParam: {
            link: odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate ?
              dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang) : '???'
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: true,
        });
        this.multiProgramsPersonWithDisability(odsProgramStatus, this.odsProgramProgressSteps);
        if(odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageIssued){
          this.odsProgramProgressSteps.pop();
          this.odsProgramProgressSteps.push(
            {
              programType: odsProgramStatus.programType,
              id: 'disability-determination-package-issued',
              name: '.medical-eligibility.step3.name.ASSIGNED.DDP_ISSUED',
              text: '.medical-eligibility.step3.text.DDP_ISSUED',
              textParam: {
                link1:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang),
                link2:this.getUrl(UrlInfo.disabilityAdjudicationUnit),
                link3:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpExpiryDate, this.translator.currentLang)
              },
              completed: false,
              active: true,
              failed: false,
              onhold:true,
              hasStepFollowed: true,
            });
          this.odsProgramProgressSteps.push(
            {
              programType: odsProgramStatus.programType,
              id: 'disability-determination-package-issued-review',
              name: '.medical-eligibility.step3.name.REVIEW',
              completed: false,
              active: false,
              failed: false,
              hasStepFollowed: true,
              normalizeHeading: true
            }
          )
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'disability-determination-package-issued-outcome',
            name: '.medical-eligibility.step3.name.OUTCOME',
            completed: false,
            active: false,
            failed: false,
            hasStepFollowed: true,
          });
        }
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.IncompleteDisabilityDeterminationPackageReceived) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'no-additional-steps-required',
            name: '.medical-eligibility.step3.name.INCOMPLETE_DDP_RECEIVED',
            text: '.medical-eligibility.step3.text.INCOMPLETE_DDP_RECEIVED',
            completed: false,
            active: true,
            failed: true,
            hasStepFollowed: true,
          });
        }
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.CompleteDisabilityDeterminationPackageReceived) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'complete-disability-determination-package-received',
            name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED',
            text:'.medical-eligibility.step3.text.COMPLETE_DDP_RECEIVED',
            textParam: {
              link:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo?.ddpReceivedDate, this.translator.currentLang),
            },
            completed: false,
            active: true,
            failed: false,
            hasStepFollowed: true
          });
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'disability-determination-package-issued-outcome',
            name: '.medical-eligibility.step3.name.OUTCOME',
            completed: false,
            active: false,
            failed: false,
            hasStepFollowed: true,
            normalizeHeading: true
          });
        }
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.PersonWithoutDisability) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'person-without-disability',
            name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED_WITH_DATE',
            nameParam: {
              link:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo?.ddpReceivedDate, this.translator.currentLang),
            },
            completed: true,
            active: false,
            failed: false,
            hasStepFollowed: true
          });
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'person-without-disability',
            name: '.medical-eligibility.step3.name.APPROVED_WITH_DATE',
            nameParam: {
              link:dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.dauDecisionDate, this.translator.currentLang),
            },
            text: '.medical-eligibility.step3.text.PERSON_WITHOUT_DISABILITY',
            completed: false,
            active: true,
            failed: false,
            hasStepFollowed: true,
          });
        }
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.ReferralCancelled) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'incomplete-disability-determination-package-received',
            name: '.medical-eligibility.step3.name.REFERRAL_CANCELLED',
            text: '.medical-eligibility.step3.text.REFERRAL_CANCELLED',
            completed: false,
            active: true,
            failed: true,
            hasStepFollowed: true,
            normalizeHeading: true
          });
        }
        // if ddp expired
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageExpired) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'expired',
            name: '.medical-eligibility.step2.name.EXPIRED.nodate',
            text: '.medical-eligibility.step2.text',
            textParam: {
              link: odsProgramStatus.disabilityApplicationStatusInfo?.disabilityDeterminationPackageInfo?.ddpExpiryDate ?
                dateFormatter(odsProgramStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpExpiryDate, this.translator.currentLang) : '???'
            },
            completed: false,
            active: true,
            failed: true,
            hasStepFollowed: true,
          });
        }
      }
      if(odsProgramStatus.disabilityApplicationPathway === DisabilityApplicationPathwayType.DisabilityDeterminationEvidence_PrescribedClass) {
        this.odsProgramProgressSteps.pop();
        this.odsProgramProgressSteps.pop();
        this.odsProgramProgressSteps.push({
          programType: odsProgramStatus.programType,
          id: 'review',
          name: '.multi-programs.step2.name.ASSIGNED',
          nameParam: {link: dateFormatter(odsProgramStatus.assignedForReviewDate, this.translator.currentLang)},
          completed: true,
          active: false,
          hasStepFollowed: true,
        });
        if (odsProgramStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityAdjudicationNotRequired) {
          this.odsProgramProgressSteps.push({
            programType: odsProgramStatus.programType,
            id: 'review_complete',
            name: '.multi-programs.step3.name.ASSIGNED.REVIEW_COMPLETE',
            text:'.multi-programs.step3.text.ASSIGNED.REVIEW_COMPLETE',
            completed: false,
            active: true,
            failed: false,
            hasStepFollowed: true,
          });
        }
      }
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'application-decision',
        name: '.multi-programs.step3.name.SUBMITTED',
        completed: false,
        active: false,
        hasStepFollowed: false,
      });
    } else {
      this.odsProgramProgressSteps = [];
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'submitted',
        name: '.multi-programs.step1.name.SUBMITTED.ongoing',
        nameParam: {link: dateFormatter(onwProgramStatus.submittedDate, this.translator.currentLang)},
        completed: true,
        active: false,
        failed: false,
        hasStepFollowed: true,
      });
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'medical-review',
        name: '.multi-programs.step2.name.SUBMITTED',
        completed: false,
        active: false,
        hasStepFollowed: true,
      });
      this.odsProgramProgressSteps.push({
        programType: odsProgramStatus.programType,
        id: 'application-decision',
        name: '.multi-programs.step3.name.SUBMITTED',
        completed: false,
        active: false,
        hasStepFollowed: false,
      });
    }
  }
}
