<sd-page-container [pageId]="pageId">
  <div id="page-alerts-container">
    <sd-message-box *ngIf="emailSent" [elementId]="'login-confirmation.info.email-sent-key'"
                    [title]="'login-confirmation.info.email.sent.title' | translate"
                    [message]="'login-confirmation.info.email.sent.message' | translate"></sd-message-box>
    <sd-alert-error-box *ngIf="!emailSent && showProcessError"
                        [errorHeader]="'error.service.down.title' | translate"
                        [errorBody]="processErrorMessage | translate:psSignInUrl">
    </sd-alert-error-box>
  </div>


  <form *ngIf="!emailSent" [formGroup]="form" sdDisableAutocomplete>
    <div  class="form-group required">
      <div class="ontario-margin-top-40-!">
        <hr class="divider" aria-hidden="true"/>
      </div>

      <sd-text-question [id]="'login-confirmation.referenceId'"
                        controlName="referenceId"
                        [required]="true"
                        [showError]="showValidationError"
                        [showCustomRequiredErrorMessage]="'error.required'"
                        [validationFns]="referenceIdLengthValidatorFns"
                        [validateOnlyOnBlur]=true
                        [hintText]="'login-confirmation.referenceId.hintText'"
                        [hintExpandableContent]="'login-confirmation.referenceId.hintContent'">
      </sd-text-question>
      <sd-text-question [id]="'login-confirmation.email'"
                        controlName="email"
                        [required]="true"
                        [showError]="showValidationError"
                        [showCustomRequiredErrorMessage]="'error.required'"
                        [validationFns]="emailValidationFns"
                        [validateOnlyOnBlur]=true
                        [hintText]="'login-confirmation.email.hintText'">
      </sd-text-question>
    </div>
    <app-mccss-submit-button dataE2E="continueBtn" buttonLabel="btn.submit" [disabled]="disableContinueButton()" [preventDoubleClick]="true" (handleClick)="onSubmit()"></app-mccss-submit-button>
    <div>
      <sd-alert-err *ngIf="recaptchaV2Service.reCaptchaVerifyCallFailure===true"
                    [errorId]="'login-confirmation.recaptcha-verify-call-err'"
                    [errorMessage]="'error.reCaptchaVerifyCallError' | translate">
      </sd-alert-err>
      <sd-alert-err *ngIf="recaptchaV2Service.captchaSuccess === false"
                    [errorId]="'login-confirmation.recaptcha-verify-err'"
                    [errorMessage]="'error.reCaptchaVerifyFailedError' | translate">
      </sd-alert-err>
    </div>
    <br>

    <div *ngIf="enableRecaptcha">
      <ngx-invisible-recaptcha #captchaElem
                               [siteKey]="recaptchaV2Service.siteKey"
                               (reset)="recaptchaV2Service.handleReset()"
                               (expire)="recaptchaV2Service.handleExpire()"
                               (load)="recaptchaV2Service.handleLoad()"
                               (success)="handleRecaptchaV2Success($event)"
                               [useGlobalDomain]="recaptchaV2Service.useGlobalDomain"
                               [hl]="recaptchaV2Service.lang"
                               [theme]="recaptchaV2Service.scheme"
                               [type]="recaptchaV2Service.type"
                               [badge]="'inline'"
                               formControlName="recaptcha">
      </ngx-invisible-recaptcha>
    </div>
  </form>
</sd-page-container>
