import {Address} from '../models/address';

export class AddressUtil {
  static constructHomeAddressFromAnswers(answers: any): Address {
    return {
      type: answers.deliveryType,
      routeNumber: answers.ruralRoute,
      postalCode: answers.postalCode,
      streetDirection: answers.direction,
      streetDirectionDisplay: answers.direction,
      municipality: answers.cityOrTown,
      province: answers.province,
      streetName: answers.streetName,
      streetNumber: answers.streetNumber,
      streetNumberSuffix: answers.streetNumberSuffix,
      streetType: answers.streetType,
      streetDisplayType: answers.streetType,
      unitNumber: answers.apartmentNumber
    }
  }

  static constructMailingAddressFromAnswers(answers: any): Address {
    if (!answers.addressSame || answers.addressSame[0] !== 'yes') {
      return {
        type: answers.mDeliveryType,
        deliveryInstallationArea: answers.mStation,
        deliveryInstallationName: answers.mGeneralDelivery,
        routeNumber: answers.mRuralRoute,
        boxNumber: answers.mPoBox,
        unitNumber: answers.mApartmentNumber,
        streetName: answers.mStreetName,
        streetNumber: answers.mStreetNumber,
        streetNumberSuffix: answers.mStreetNumberSuffix,
        streetType: answers.mStreetType,
        streetDisplayType: answers.mStreetType,
        streetDirection: answers.mDirection,
        streetDirectionDisplay: answers.mDirection,
        municipality: answers.mCityOrTown,
        province: answers.mProvince,
        postalCode: answers.mPostalCode
      }
    }
  }

  static populateHomeAddressAnswers(address: Address): any {
    return {
      deliveryType: address.type,
      ruralRoute: address.routeNumber,
      apartmentNumber: address.unitNumber,
      streetNumber: address.streetNumber,
      streetNumberSuffix: address.streetNumberSuffix,
      streetName: address.streetName,
      streetType: address.streetType,
      direction: address.streetDirection,
      cityOrTown: address.municipality,
      province: address.province,
      postalCode: address.postalCode
    }
  }

  static populateMailingAddressAnswers(address: Address): any {
    return {
      mDeliveryType: address.type,
      mPoBox: address.boxNumber,
      mGeneralDelivery: AddressUtil.constructGeneralDelivery(
        [address.deliveryInstallationType, address.deliveryInstallationName, address.deliveryInstallationArea]),
      mRuralRoute: address.routeNumber,
      mApartmentNumber: address.unitNumber,
      mStreetNumber: address.streetNumber,
      mStreetNumberSuffix: address.streetNumberSuffix,
      mStreetName: address.streetName,
      mStreetType: address.streetType,
      mDirection: address.streetDirection,
      mCityOrTown: address.municipality,
      mProvince: address.province,
      mPostalCode: address.postalCode
    }
  }

  static resetMailingAddressValidators(form: any): void {
    form.controls.mDeliveryType.clearValidators()
    form.controls.mDeliveryType.updateValueAndValidity()

    this.resetValidatorsForTypeRuralRoute(form)
    this.resetValidatorsForTypePOBox(form)
    this.resetValidatorsForTypeGeneralDelivery(form)
    this.resetValidatorsForTypeStandStreetAddress(form)
    this.resetValidatorsForMailingAddressCommonFields(form)
  }

  static resetValidatorsForTypePOBox(form: any) {
    form.controls.mPoBox.clearValidators()
    form.controls.mPoBox.updateValueAndValidity()
    form.controls.mStation.clearValidators()
    form.controls.mStation.updateValueAndValidity()
  }

  static resetValidatorsForTypeGeneralDelivery(form: any) {
    form.controls.mGeneralDelivery.clearValidators()
    form.controls.mGeneralDelivery.updateValueAndValidity()
  }

  static resetValidatorsForTypeRuralRoute(form: any) {
    form.controls.mRuralRoute.clearValidators()
    form.controls.mRuralRoute.updateValueAndValidity()
  }

  static resetValidatorsForTypeStandStreetAddress(form: any) {
    form.controls.mApartmentNumber.clearValidators()
    form.controls.mApartmentNumber.updateValueAndValidity()
    form.controls.mStreetNumber.clearValidators()
    form.controls.mStreetNumber.updateValueAndValidity()
    form.controls.mStreetNumberSuffix.clearValidators()
    form.controls.mStreetNumberSuffix.updateValueAndValidity()
    form.controls.mStreetName.clearValidators()
    form.controls.mStreetName.updateValueAndValidity()
    form.controls.mStreetType.clearValidators()
    form.controls.mStreetType.updateValueAndValidity()
    form.controls.mDirection.clearValidators()
    form.controls.mDirection.updateValueAndValidity()
  }

  static resetValidatorsForMailingAddressCommonFields(form: any){
    form.controls.mCityOrTown.clearValidators()
    form.controls.mCityOrTown.updateValueAndValidity()
    form.controls.mProvince.clearValidators()
    form.controls.mProvince.updateValueAndValidity()
    form.controls.mPostalCode.clearValidators()
    form.controls.mPostalCode.updateValueAndValidity()
  }

  private static constructGeneralDelivery(params: string[]): string {
    const filteredParams = params.filter(Boolean)
    return filteredParams.length > 0 ? filteredParams.join(' ') : undefined;
  }

  static removeAllFieldsFromMailingAddressJson(json: any){
    this.removeStandardStreetAddressFieldsFromMailingAddressJson(json)
    this.removePoBoxFieldsFromMailingAddressJson(json)
    this.removeRuralRouteFieldsFromMailingAddressJson(json)
    this.removeGeneralDeliveryFieldsFromMailingAddressJson(json)
    this.removeCommonFieldsFromMailingAddressJson(json)
  }

  static removeCommonFieldsFromMailingAddressJson(json: any){
    delete json?.mCityOrTown
    delete json?.mProvince
    delete json?.mPostalCode
  }

  static removePoBoxFieldsFromMailingAddressJson(json: any){
    delete json?.mPoBox;
    delete json?.mStation
  }

  static removeStandardStreetAddressFieldsFromMailingAddressJson(json: any){
    delete json?.mApartmentNumber
    delete json?.mStreetNumber
    delete json?.mStreetNumberSuffix
    delete json?.mStreetName
    delete json?.mStreetType
    delete json?.mDirection
  }

  static removeGeneralDeliveryFieldsFromMailingAddressJson(json: any){
    delete json?.mGeneralDelivery
  }

  static removeRuralRouteFieldsFromMailingAddressJson(json: any){
    delete json?.mRuralRoute;
  }

}
