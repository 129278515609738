import {Observable} from 'rxjs';

export abstract class CaseWorkerAuthorizeService {

  abstract setClaims(claims: any): void

  abstract isIBauUser(): boolean

  abstract isAuthorized():boolean

  abstract getCaseWorker(): any

  abstract getDisplayName(): Observable<string>

  abstract syncAuthorization(): void;
}
