import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Idle} from '@ng-idle/core';
import {TranslateService} from '@ngx-translate/core';

import {BaseComponent} from '../../BaseComponent';
import {ExternalRouter} from '../../../external.router';
import {
  ApplicationProgramStatus,
  ApplicationStatusResponse,
  ApplicationStatusType,
  DisabilityApplicationPathwayType,
  DisabilityApplicationStatus
} from '../../../models/application-status-response';
import {ProgramType} from '../../../models/program-type';
import {UrlInfo} from '../../../models/url-map';
import {ApplicationStatusService} from '../../../services/application-status.service';
import {AuthService} from '../../../services/auth.service';
import {ConfigService} from '../../../services/config.service';
import {IntakeService} from '../../../services/intake.service';
import {LoadingSpinnerService} from '../../../services/loading-spinner.service';
import {PageService} from '../../../services/page.service';

@Component({
  selector: 'sd-asc-results',
  templateUrl: './asc-results.component.html',
  styleUrls: ['./asc-results.component.scss']
})
export class AscResultsComponent extends BaseComponent implements OnInit, OnDestroy {
  public readonly AscProgressStatusType = ProgramType;

  public alertWarningParam: any;
  public referenceCode: string;
  public applicationProgramStatus: ApplicationStatusResponse;
  public showResult: boolean;
  public alertWarningCode: string;
  public statusType: ProgramType;
  public contactInfoToDisplay: ContactInfo;

  constructor(private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public appStatusService: ApplicationStatusService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
  }

  ngOnInit(): void {
    this.populateMessageParam();
    this.translator.onLangChange.subscribe(() => {
      this.populateMessageParam();
    });

    this.referenceCode = this.appStatusService.getReferenceCode();
    this.applicationProgramStatus = this.appStatusService.getStatusResponse();
    if (this.applicationProgramStatus?.programStatuses?.length > 0) {
      this.alertWarningCode = this.getAlertWarningCode();
      this.showResult = !this.alertWarningCode && !this.isPendingApplication(this.applicationProgramStatus);
      const isCancelled
        = this.applicationProgramStatus.programStatuses.some(program => program.applicationStatus === ApplicationStatusType.Cancelled);
      this.statusType = this.determineStatusType(this.applicationProgramStatus)
      if (isCancelled) {
        this.pageId = 'app-status.results.'.concat(this.statusType);
      } else {
        this.pageId = this.showResult ? 'app-status.results.'.concat(this.statusType) : 'app-status.results.with.warning';
      }
      this.contactInfoToDisplay = this.determineContactInfoToDisplay(this.applicationProgramStatus);
    } else {
      this.router.navigate(['/', 'applicationstatuschecker'])
    }
  }

  getAlertWarningCode(): string {
    const programStatus =
      this.applicationProgramStatus.programStatuses.find((program) =>
        program.applicationStatus === ApplicationStatusType.Cancelled) ||
      this.applicationProgramStatus.programStatuses.find((program) =>
        program.applicationStatus === ApplicationStatusType.Incomplete) ||
      this.applicationProgramStatus.programStatuses.find((program) =>
        program.applicationStatus === ApplicationStatusType.StatusUnavailable);
    return programStatus ? programStatus.applicationStatus : null;
  }

  onSubmit() {
    if (this.configService.getClientConfig()?.production) {
        return this.externalRouter.navigate(this.ontarioWorksLink)
    }

    return this.externalRouter.navigate(this.configService.getUrl(this.translator.currentLang || this.translator.defaultLang,
      UrlInfo.socialAssistance));
  }

  private populateMessageParam() {
    // Set MyBenefits landing link
    this.alertWarningParam = this.configService.getUrl(this.translator.currentLang, UrlInfo.myBenefitsLogin);
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  private isPendingApplication(statusResponse: ApplicationStatusResponse): boolean {
    return !!statusResponse.programStatuses.find((program) =>
      program.applicationStatus === ApplicationStatusType.ApplicationPending);
  }

  private determineStatusType(statusResponse: ApplicationStatusResponse): ProgramType {
    if (!statusResponse || !statusResponse.programStatuses.length) {
      return null;
    }
    if (statusResponse.programStatuses.length === 1) {  // Could be ONW, ODS or ONWODS
      return statusResponse.programStatuses[0].programType;
    } else {
      return ProgramType.ONWODS;
    }
  }

  private determineContactInfoToDisplay(statusResponse: ApplicationStatusResponse): ContactInfo {
    const  programStatus = this.findProgramStatus(statusResponse, ProgramType.ODS);
    if (!!programStatus) {
      return ((this.isAssignedStatus(programStatus) && (this.isInDauPath(programStatus)) ? ContactInfo.OdsDau : ContactInfo.General));
    }
    return ContactInfo.General;
  }

  private findProgramStatus(statusResponse: ApplicationStatusResponse, programType: ProgramType): ApplicationProgramStatus {
    return statusResponse.programStatuses.find((program) => programType === program.programType)
  }

  private isAssignedStatus(programStatus: ApplicationProgramStatus): boolean {
    return (ApplicationStatusType.Assigned === programStatus.applicationStatus);
  }

  private isInDauPath(programStatus: ApplicationProgramStatus): boolean {
    return (DisabilityApplicationPathwayType.DisabilityAdjudicationUnit
      === programStatus.disabilityApplicationPathway);
  }
}

export enum ContactInfo {
  General = 'GENERAL',
  OdsDau = 'ODS_DAU',
}
