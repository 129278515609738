<div [class]="'ontario-alert' + ' ontario-alert--' + msgType">
  <div class="ontario-alert__header">
    <div class="ontario-alert__header-icon">
      <svg
        class="ontario-icon"
        alt=""
        aria-hidden="true"
        focusable="false"
        sol:category="primary"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet">
        <use [attr.href]="'#ontario-icon-alert-' + msgType"></use>
      </svg>
    </div>
    <div class="ontario-alert__header-title ontario-h4">
      {{title}}
    </div>
  </div>
  <div id="{{elementId}}" class="ontario-alert__body">
    <p>{{message}}</p>
  </div>
</div>
