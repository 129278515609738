<div [ngClass]="{'ontario-alert ontario-alert--informational': !isInfoTextOnly}">
  <div *ngIf="isInfoTextOnly" class="ontario-alert__header">
    <div class="ontario-alert__header-icon">
      <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
        <use href="#ontario-icon-alert-information"></use>
      </svg>
    </div>
    <div id="{{contentId}}--infoMessage">
      <span [innerHTML]=" content | translate"></span>
    </div>
  </div>
  <div *ngIf="!isInfoTextOnly">
    <div class="ontario-alert__header">
      <div class="ontario-alert__header-icon">
        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-alert-information"></use>
        </svg>
      </div>
      <h2 *ngIf="header" id="{{contentId}}-header" class="ontario-alert__header-title ontario-h4">
        <span *ngIf="!headerParam" [innerHTML]="header | translate"></span>
        <span *ngIf="!!headerParam" [innerHTML]="header | translate: headerParam"></span>
      </h2>
    </div>
    <div *ngIf="!link" class="ontario-alert__body">
      <p *ngIf="!contentParam" id="{{contentId}}-content" [innerHTML]="content | translate"></p>
      <p *ngIf="!!contentParam" id="{{contentId}}-content" [innerHTML]="content | translate: contentParam"></p>
    </div>
    <div *ngIf="link" class="ontario-alert__body">
      <span id="{{contentId}}-content">{{ content | translate}}</span>
      <a href="{{link}}" id='{{linkId}}' target="_blank">{{linkLabel | translate}}</a>
    </div>
</div>
