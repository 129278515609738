import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PageInfo} from '../models/page-map';
import {IntakeService} from '../services/intake.service';

import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-mccss-save-continue-button',
  templateUrl: './mccss-save-continue-button.component.html',
  styleUrls: ['./mccss-save-continue-button.component.scss']
})
export class MccssSaveContinueButtonComponent implements OnInit {
  isPageAllowedToSave = false;

  @Input()
  pageId: string;
  @Input()
  buttonLabel: string;
  @Input()
  hidden: boolean;
  @Input()
  disabled: boolean;
  @Output()
  handlePrimaryButtonClick = new EventEmitter<string>();
  @Output()
  handleSecondaryButtonClick = new EventEmitter<string>();

  constructor(private route: ActivatedRoute, public authService: AuthService,  public intake: IntakeService) {
    this.route.data?.subscribe((value) => (this.isPageAllowedToSave = (value?.allowToSave)));
  }

  ngOnInit(): void {
    if (this.pageId && (this.pageId === PageInfo.bya || this.pageId === PageInfo.personalInfo) && !this.authService.isAuthorizedToSave()) {
      this.isPageAllowedToSave = false;
    }

    if (this.isPageAllowedToSave && this.intake.getIsApplyingForSomeoneElse()) {
      this.isPageAllowedToSave = false;
    }
  }

  onHandlePrimaryButton() {
    this.handlePrimaryButtonClick.emit();
  }
  onHandleSecondaryButton() {
    this.handleSecondaryButtonClick.emit();
  }
}
