import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ControlContainer, FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

import {Choice, DefaultRadio} from '../../utils/questions/question-choices';
import {functionValidator} from '../../utils/validators/custom-validators';
import {QuestionBaseComponent} from '../QuestionBaseComponent';

@Component({
  selector: 'sd-radio-question',
  templateUrl: './radio-question.component.html',
  styleUrls: ['./radio-question.component.scss']
})
export class RadioQuestionComponent extends QuestionBaseComponent implements OnChanges, OnInit, OnDestroy{
  @Input()
  public controlName: string;

  @Input()
  public showError = false;

  @Input()
  public showCustomError: string | undefined;

  @Input()
  public customErrorParam: object | undefined;

  @Input()
  public id: string | undefined;

  @Input()
  public label: string | undefined;

  @Input()
  public labelParam: any | {};

  @Input()
  public extraLabelDescription: string | undefined;

  @Input()
  public choices: Choice[] = DefaultRadio;

  @Input()
  tooltip: string | undefined;

  @Input()
  public required = true;

  @Input()
  public displayQuestionLabel = true;

  @Input()
  public validationFunctions: [{validationFunction: any, errorKey: string, errorParam?: any}] | undefined;

  // If the validators are set at parent level set this to true so that the original validators or not overridden. If this
  // components validation setup is used then don't set up any validators at parent level
  @Input()
  public ignoreValidationSetup: boolean | false;

  // The text key is used to describe or annotate the label of the radio-button element providing greater context for assistive
  // technology users.
  @Input()
  public context: string;

  @Input()
  public boldHintLabel: string;

  @Input()
  public removeLabelMargin: boolean | false;

  @Input()
  public removeHintLabelMargin: boolean | false;

  constructor(public formBuilder: FormBuilder, public translator: TranslateService, public controlContainer: ControlContainer) {
    super(controlContainer, formBuilder);
  }

  ngOnInit(): void {
    // Ideally using label would be good however previously used places used id to pass label key
    this.label = this.label ? this.label : this.id;
    this.setUpForm(this.controlName);
    this.setupValidators();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.required && !changes.required.isFirstChange()){
      this.setupValidators()
    }
  }

  setupValidators() {
    if(!this.ignoreValidationSetup) {
      this.form.clearValidators()
      const validators: ValidatorFn[] = []

      if (this.required) {
        validators.push(Validators.required)
      }

      if (this.validationFunctions) {
        this.validationFunctions.forEach(v => {
          validators.push(functionValidator(v.validationFunction, v.errorKey, v.errorParam))
        })
      }

      this.formControl.setValidators(validators)
      this.formControl.updateValueAndValidity()
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get fieldName(): string {
    return this.getFieldName(this.id);
  }

  get shouldShowErrorStyle(): boolean {
    return (this.showError && this.formControl.hasError('required'))
      || (this.showCustomError !== undefined && this.showCustomError !== '')
      || (!this.formControl.hasError('required') && this.formControl.errors?.customErrorKey && this.formControl.touched)
  }

  getAriaLabel(): string {
    let ariaLabel: string = null;
    if (this.context) {
      ariaLabel = this.translator.instant(this.context, this.labelParam);
    } else if (this.label) {
      ariaLabel = this.translator.instant(this.label, this.labelParam);
    } else {
      return null;
    }

    if (this.required) {
      ariaLabel = ariaLabel.concat(' ', this.translator.instant('Required'));
    } else {
      ariaLabel = ariaLabel.concat(' ', this.translator.instant('Optional'));
    }

    return ariaLabel;
  }
}
