import {ApplicationAnswers} from '../models/data.model';
import {SadaCustomValidator} from '../validator/sada-custom-validator';

export abstract class EligibilityCheckUtil {
  static acsdEligilityChecking(data: ApplicationAnswers): boolean {
    if (!data?.jsonData) { return false}

    let acsdEligilityIndicator = false;
    if (data.jsonData.childrenLivingWithYou
      && data.jsonData.childrenLivingWithYou === 'yes'
      && data.jsonData.personWithDisabilityInFamily
      && data.jsonData.personWithDisabilityInFamily === 'yes'
      && data.jsonData.personWithDisabilityInFamilyCheckbox) {
      const childWithDisabilityAndUnder18 = data.jsonData.personWithDisabilityInFamilyCheckbox.filter(p => {
        if (p.toString().indexOf('Child') >= 0) {
          const childFirstNameWithDisability = p.toString().substring(9);
          for (const child of data.jsonData.childList) {
            if (child.childFirstName === childFirstNameWithDisability
              && !SadaCustomValidator.validateOverAge(child.childDateOfBirth, 18)){
              return true;
            }
          }
        }
      })
      if (childWithDisabilityAndUnder18.length > 0) {
        acsdEligilityIndicator = true;
      }
    }
    return acsdEligilityIndicator;
  }
}
