import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export abstract class ButtonUtil {
  static toggleContinueButton(enabled: boolean, buttonSelector: string = '.btn-navigation') {
    const nextBtn = document.querySelector(buttonSelector);
    if (enabled) {
      nextBtn.removeAttribute('disabled')
    } else {
      nextBtn.setAttribute('disabled', 'true');
    }
  }

  /**
   * This listener to the popstate event issued by browser to ignore the user's intent to go back to previous page with the Back button.
   */
  static preventBackButton(subject: Subject<void>): void {
    history.pushState(null, '');  // Makes the current page the last entry in the browser history, and clears any forward history.
    fromEvent(window, 'popstate').pipe(
      takeUntil(subject)
    ).subscribe(() => {
      history.go(1);  // Since the forward history is cleared, it remains on the same page.
    });
  }
}
