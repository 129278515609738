import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {FormToAppDataBase} from '../../../common/utils/converters/form-to-app-data-base';

export class FormToFACash extends FormToAppDataBase implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  static readonly CASH_CHECK_BOX = 'ownItemsOfValueCashCheckbox';
  static readonly DETAILS_CASH_LIST = 'ownItemsOfValueDetailsListCash';
  static readonly APPLICANT_CASH_CHECKBOX = 'ownItemsOfValueApplicantCheckboxCash';
  static readonly CASH_PANEL_CHILD = 'FACashWhoOwnsThisAssetPanelChild';
  static readonly WHO_OWN_THIS_ASSET = 'FACashWhoOwnsThisAsset';

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // remove previous saved data and repopulate with latest form data
    delete appData.jsonData[FormToFACash.CASH_CHECK_BOX];
    delete appData.jsonData[FormToFACash.DETAILS_CASH_LIST];
    delete appData.jsonData[FormToFACash.APPLICANT_CASH_CHECKBOX];

    if ( form.get('FACash')?.value[0]) {
      appData.jsonData[FormToFACash.CASH_CHECK_BOX] = ['yes'];

      // populate applicant check box list
      this.populateCheckBoxList(form,appData,questions);

      // populate cash details list
      this.populateDetailsList(form, appData, questions);
    }
  }

  populateCheckBoxList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const list = [];
    const question = questions.find(q => q.key === FormToFACash.WHO_OWN_THIS_ASSET);

    if (appData.jsonData.spouseFirstName || appData.jsonData.childList){

      const applicantOption = question.options.find(o => o.label === 'financial-assets.applicant');

      if (this.isApplicantSelected(FormToFACash.WHO_OWN_THIS_ASSET,form,questions) &&
          form.get('FACashWhoOwnsThisAssetPanelApplicant')?.value.panels[0].financialAssetCashAmount) {
           list.push(applicantOption.alternateValue);
      }
    }

    if (this.isSpouseSelected(FormToFACash.WHO_OWN_THIS_ASSET,form,questions) &&
        form.get('FACashWhoOwnsThisAssetPanelSpouse')?.value.panels[0].financialAssetCashAmount ) {
         const spouseOption = question.options.find(o => o.label === 'financial-assets.spouse');
         list.push(spouseOption.alternateValue);
    }

    if (appData.jsonData.childList){
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (form.get(FormToFACash.CASH_PANEL_CHILD.concat(String(i)))?.value.panels[0].financialAssetCashAmount) {
          const childPanel = questions.find(q => q.key === FormToFACash.CASH_PANEL_CHILD.concat(String(i)));
          if (this.isChildSelected(i,FormToFACash.WHO_OWN_THIS_ASSET,form,questions)) {
            list.push(childPanel.value);
          }
        }
      }
    }

    if (list.length > 0) {
      appData.jsonData[FormToFACash.APPLICANT_CASH_CHECKBOX] = list;
    }
  }

  populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const cashDetailsList = [];

    if (this.isApplicantSelected(FormToFACash.WHO_OWN_THIS_ASSET,form,questions) &&
        form.get('FACashWhoOwnsThisAssetPanelApplicant').value.panels[0].financialAssetCashAmount) {
         cashDetailsList.push({
         applicant: 'Applicant: '.concat(appData.jsonData.firstName),
         applicantType: 'Applicant',
         applicantDisplayType: 'Applicant',
         applicantName: ' '.concat(appData.jsonData.firstName),
         ownItemsOfValueCashAmount: String(form.get('FACashWhoOwnsThisAssetPanelApplicant').value.panels[0].financialAssetCashAmount)
        })
    }

    if (this.isSpouseSelected(FormToFACash.WHO_OWN_THIS_ASSET, form, questions) &&
      form.get('FACashWhoOwnsThisAssetPanelSpouse')?.value.panels[0].financialAssetCashAmount) {
      cashDetailsList.push({
        applicant: 'Spouse: '.concat(appData.jsonData.spouseFirstName),
        applicantType: 'Spouse',
        applicantDisplayType: 'Spouse',
        applicantName: ' '.concat(appData.jsonData.spouseFirstName),
        ownItemsOfValueCashAmount: String(form.get('FACashWhoOwnsThisAssetPanelSpouse').value.panels[0].financialAssetCashAmount)
      })
    }

    // populate children data
    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (form.get(FormToFACash.CASH_PANEL_CHILD.concat(String(i)))?.value.panels[0].financialAssetCashAmount) {
          const child = questions.find(q => q.key === FormToFACash.CASH_PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFACash.WHO_OWN_THIS_ASSET,form,questions)) {
            cashDetailsList.push({
              applicant: child.value,
              applicantType: 'Child '.concat(String(i)),
              applicantDisplayType: 'Child '.concat(String(i)),
              applicantName: child.value.substr(child.value.indexOf(':') + 1),
              ownItemsOfValueCashAmount: String(form.get(FormToFACash.CASH_PANEL_CHILD.concat(String(i)))
                .value.panels[0].financialAssetCashAmount)
            })
          }
        }
      }
    }

    if (cashDetailsList.length > 0){
      appData.jsonData[FormToFACash.DETAILS_CASH_LIST] = cashDetailsList;
    }
  }
}
