import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {STARTUP_CONFIG} from './app/startup.config';

fetch('/v1/loadStartupConfig')
    .then((response) => response.json())
    .then((startupConfig) => {
        if (startupConfig.production) {
            enableProdMode();
            if (window) {
                window.console.log = () => {
                };  // Disable all console.log
            }
        }

        platformBrowserDynamic([{provide: STARTUP_CONFIG, useValue: startupConfig}])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err))

        addGTM(startupConfig.gtagMeasurementId);
    })

function addGTM(gtagId: string) {
    addGtagJs(gtagId);
    const script = document.createElement('script');
    script.src = '../assets/analytics/gtm.js'; // Need to be included as js or CSP will give issues
    script.setAttribute('gtagId', gtagId);
    document.head.appendChild(script);
}

function addGtagJs(gtagId: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + gtagId;
    document.head.appendChild(script);
}
