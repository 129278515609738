<div class="ontario-hint-expander__container" *ngIf="!expandableContent">
  <p id="{{id}}{{occurrence}}-hint-text" class="ontario-hint {{noMarginBottom ? 'no-bottom-margin' : ''}}" [innerHTML]="text | translate"></p>
</div>

<div *ngIf="expandableContent" class="ontario-hint-expander__container hint-margin" [ngClass]="{'ontario-expander--active' : ariaExpanded}">
  <button tabindex="0" (keyup.enter)="hintTextClick()" id="{{id}}{{occurrence}}{{EXPANDER_BUTTON_ID_SUFFIX}}" class="ontario-hint-expander__button no-bottom-margin" [attr.aria-controls]="id + (occurrence || occurrence === 0 ? occurrence : '') + '-hint-expander-content'"  attr.aria-expanded="{{ariaExpanded}}" data-toggle="ontario-collapse">
    <span class="ontario-hint-expander__button-icon--close">
      <svg class="ontario-icon" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
        <use href="#ontario-icon-chevron-up"></use>
      </svg>
    </span>
    <span class="ontario-hint-expander__button-icon--open">
      <svg class="ontario-icon" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
        <use href="#ontario-icon-chevron-down"></use>
      </svg>
    </span>
    {{text | translate}}
  </button>
  <div id="{{id}}{{occurrence}}-hint-expander-content" class="ontario-hint-expander__content green-border-left hint-margin" [ngClass]="{'ontario-expander__content--opened' : ariaExpanded}" aria-labelledby="hint-expander-button-id" attr.aria-hidden="{{!ariaExpanded}}" data-toggle="ontario-expander-content">
    <div *ngIf="!!expandableContent && !expandableContentParam" [innerHTML]="expandableContent | translate" tabindex="0"></div>
    <div *ngIf="!!expandableContent && !!expandableContentParam" [innerHTML]="expandableContent | translate: expandableContentParam" tabindex="0"></div>
  </div>
</div>
