import {ApplicationAnswers} from './models/data.model';
import {SadaCustomValidator} from './validator/sada-custom-validator';
import moment from 'moment';

export class EsignUtil {

  parseSigners(data: ApplicationAnswers, spouseTitle: string, childTitle: string) {
    if (!data || !data.jsonData) { return []}

    const spouseFirstName = data.jsonData.spouseFirstName;
    const spouseEmail = data.jsonData.spouseEmail;
    const people = []

    const spouseDateOfBirth = data.jsonData.spouseDateOfBirth;
    if (spouseFirstName && spouseEmail && spouseDateOfBirth && SadaCustomValidator.validateOverAge(spouseDateOfBirth, 18)) {
      people.push(
        {type: spouseTitle, name: spouseFirstName, email: spouseEmail}
      )
    }

    const children = [];
    if (data.jsonData.childList) {
      for (const child of data.jsonData.childList) {
        const childFirstName = child.childFirstName;
        const childEmail = child.childEmail;
        const childDateOfBirth = child.childDateOfBirth;

        if (childFirstName && childEmail && childDateOfBirth && SadaCustomValidator.validateOverAge(childDateOfBirth, 18)) {
          children.push({
              type: childTitle,
              name: childFirstName,
              email: childEmail,
              dateOfBirth: moment(childDateOfBirth, 'YYYY/MM/DD')
            })
        }
      }
    }
    children.sort((a, b) => a.dateOfBirth - b.dateOfBirth);
    return [...people, ...children];
  }

}
