import {Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../../services/intake.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
  GenderIdentity,
  IndigenousIdentityGroup,
  LanguageOfChoice,
  RacialIdentity,
  RacialIdentityExample,
  YesOrNo
} from './wda-question-choices';
import {PageBaseComponent} from '../../PageBaseComponent';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../../external.router';
import {takeUntil} from 'rxjs/operators';
import {StringUtil} from '../../../utils/string-util';
import {WdaComponentUtil} from '../util/wda.component.util';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {PageService} from '../../../services/page.service';
import {LoadingSpinnerService} from '../../../services/loading-spinner.service';
import {PageInfo} from '../../../models/page-map';
import {WindowService} from '../../../services/window.service';

const {equalsIgnoreCase} = StringUtil;
const {isChildApplicant, isPrimaryApplicant, isSpouseApplicant, buildNameString} = WdaComponentUtil;

@Component({
  selector: 'app-wda',
  templateUrl: './wda.component.html',
  styleUrls: ['./wda.component.scss']
})
export class WdaComponent extends PageBaseComponent implements OnInit, OnDestroy {
  form: FormGroup;
  genderIdentityChoices = GenderIdentity;
  indigenousIdentityChoices = YesOrNo;
  indigenousIdentityGroupChoices = IndigenousIdentityGroup;
  racialIdentityChoices = RacialIdentity;
  visibleMinorityChoices = YesOrNo;
  languageChoices = LanguageOfChoice;
  showError = false;
  failedSubmit: boolean;

  applicantType: string;
  childIndex: string;

  constructor(private router: Router,
              public route: ActivatedRoute,
              public intakeService: IntakeService,
              @Inject(LOCALE_ID) protected localeId: string,
              public formBuilder: FormBuilder,
              public translator: TranslateService,
              public ngZone: NgZone, public idle: Idle, public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              private windowService: WindowService) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.wda;
    this.form = this.formBuilder.group({
      genderIdentity: [''],
      indigenousIdentity: [''],
      indigenousIdentityGroup: [''],
      racialIdentity: [''],
      visibleMinority: [''],
      languageOfChoice: [''],
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.route.params.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(params => {
      this.applicantType = params.applicantType;
      if (params.childIndex !== undefined) {
        this.childIndex = params.childIndex;
      }
    });

    this.form.controls.indigenousIdentity.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      if (value && equalsIgnoreCase('yes', value)) {
        this.form.controls.indigenousIdentityGroup.enable();
      } else {
        this.form.controls.indigenousIdentityGroup.disable();
      }
    })
  }

  onSubmit() {
    if (this.form.valid) {
      this.buttonContinueClick = true
      const demographicData: DemographicData = this.constructDataFromForm();

      if (isPrimaryApplicant(this.applicantType)) {
        this.applicationAnswers.jsonData.applicantDemographicData = demographicData;
      } else if (isSpouseApplicant(this.applicantType)) {
        this.applicationAnswers.jsonData.spouseDemographicData = demographicData;
      } else {
        this.applicationAnswers.jsonData.childList[this.childIndex].demographicData = demographicData;
      }

      this.intakeService.saveApplication(this.applicationAnswers, this.pageId).subscribe(
        () => this.router.navigate(['/', PageInfo.wda]),
        (err) => {
          if (err.status >= 500 && err.status < 600) {
            this.failedSubmit = true;
          } else {
            this.buttonContinueClick = false;
          }
        }
      );
    } else {
      this.showError = true;
      this.buttonContinueClick = false;
      this.scrollToInvalidFormControl(false);
    }
    this.windowService.scrollToTop()
  }

  private constructDataFromForm(): DemographicData {
    const demographicData: DemographicData = new DemographicData();
    Object.keys(this.form.controls).forEach(key => {
      if (this.form?.get(key).enabled) {
        demographicData[key] = this.form?.get(key)?.value;
      }
    });
    return demographicData;
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get applicantName() {
    if (isPrimaryApplicant(this.applicantType)) {
      return buildNameString(this.applicationAnswers.jsonData.firstName, this.applicationAnswers.jsonData.lastName);
    } else if (isSpouseApplicant(this.applicantType)) {
      return buildNameString(this.applicationAnswers.jsonData.spouseFirstName,
        this.applicationAnswers.jsonData.spouseLastName);
    } else { // Child
      const childInfo = this.applicationAnswers.jsonData.childList[this.childIndex];
      return buildNameString(childInfo?.childFirstName, childInfo?.childLastName);
    }
  }

  get applicantTypeStr() {
    if (isChildApplicant(this.applicantType)) {
      return StringUtil.capitalizeFirstLetter(this.applicantType.toLowerCase()) + ' ' + (Number(this.childIndex) + 1);
    } else {
      return StringUtil.capitalizeFirstLetter(this.applicantType.toLowerCase());
    }
  }

  get indigenousIdentity() {
    return this.form.controls.indigenousIdentity.value;
  }

  get racialIdentity() {
    return this.form.controls.racialIdentity.value;
  }

  get racialIdentityEgText() {
    return RacialIdentityExample.get(this.form.controls.racialIdentity.value);
  }

  get titleLabel(): string {
    if (isPrimaryApplicant(this.applicantTypeStr)) {
      return 'demographic-information.applicantTitle';
    } else if (isSpouseApplicant(this.applicantTypeStr)) {
      return 'demographic-information.spouseTitle';
    } else {
      return 'demographic-information.childTitle';
    }
  }

  get parseChildIndex() {
    return Number(this.childIndex) + 1;
  }

  postInitializeForm() {
  }

  preSaveApplication() {
  }
}

export class DemographicData {
  racialIdentity: string;
  genderIdentity: string;
  visibleMinority: string;
  indigenousIdentity: string;
  languageOfChoice: string;
  indigenousIdentityGroup: string;
  applicantType: string;
  name: string;
  dateOfBirth?: string;
}
