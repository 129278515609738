import {NgZone} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute} from '@angular/router';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {concatMap, map, switchMap} from 'rxjs/operators';

import {CreateAccountReminderDialogComponent} from '../common/ui/create-account-reminder-dialog/create-account-reminder-dialog.component';
import {CreateAccountReminderParameter} from '../common/ui/create-account-reminder-dialog/create-account-reminder-parameter';
import {DialogConfig, popup} from '../dialog-constants';
import {StartTwoPopup} from './before-you-start-two/before-you-start-two-question.service';
import {ExternalRouter} from '../external.router';
import {MccssDialogTitleComponent} from '../mccss-dialog-title/mccss-dialog-title.component';
import {ApplicationAnswers} from '../models/data.model';
import {UrlInfo} from '../models/url-map';
import {AuthService} from '../services/auth.service';
import {ConfigService} from '../services/config.service';
import {IntakeService} from '../services/intake.service';
import {PageService} from '../services/page.service';
import {LoadingSpinnerService} from '../services/loading-spinner.service';

export abstract class BaseComponent {
  private readonly SIMPLE_DIALOG = 'simple_dialog';
  private readonly CREATE_ACCOUNT_REMINDER_DIALOG = 'create_account_reminder_dialog';
  private readonly SAVE_EXIT_DIALOG = 'save_exit_dialog';

  private sessionTimeoutRedirectToUrl: string;
  private currentOpenedDialog: string;
  private previouslyOpenedDialogInfo: {
    methodToCreateDialog?: string;
    methodParameters?: any[];
  };
  private displayClosedDialog = false;

  protected exitWithoutSavingUrl: string;
  protected logOutUrl: string;
  protected myBUrl: string;
  protected showPsRedirectError: boolean;
  protected readonly _destroyed$ = new Subject<void>();
  protected valueChangeSubjects$ = new Subject<void>();
  protected termAndConditionItems: any[];
  protected ontarioWorksLink: string;

  pageId: string; // Define using PageInfo constants in each page
  dialogVisible = false;
  subscriptions$: Subscription[] = [];
  intakeService: IntakeService;
  showResumeBanner = false;
  showRequiredInfoBanner = false;

  protected constructor(public intake: IntakeService,
                        public translator: TranslateService,
                        public ngZone: NgZone,
                        public idle: Idle,
                        public dialog: MatDialog,
                        public route: ActivatedRoute,
                        public externalRouter: ExternalRouter,
                        protected configService: ConfigService,
                        protected authService: AuthService,
                        protected pageService: PageService,
                        protected loadingSpinnerService: LoadingSpinnerService) {
    this.intakeService = intake;
    // initialize ontario works link
    this.ontarioWorksLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.ontarioWorks);
    this.initializeOntarioWorksLink();
    ngZone.runOutsideAngular(() => {
      idle.setIdle(840);
      idle.setTimeout(60);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    })
    this.sessionTimeoutRedirectToUrl = this.ontarioWorksLink
    this.subscriptions$.push(this.route.queryParams.subscribe(params => {
      this.showResumeBanner = params?.showResumeBanner && this.authService.isAuthorizedToSave()
    }))

    this.subscriptions$.push(
      idle.onIdleStart.subscribe(() => this.ngZone.run(() => {
        if (!!this.dialog && this.currentOpenedDialog !== popup.inactiveTimeout.title) {
          if (this.dialogVisible) {
            this.dialog.closeAll();  // Close the existing-displaying dialog.
            this.displayClosedDialog = true;
          }
          this.dialogVisible = false;
        }
        this.openDialog(popup.inactiveTimeout);  // Display the timeout dialog.
      })));
    const envName = this.configService.getClientConfig()?.envName;
    this.subscriptions$.push(idle.onTimeout.subscribe(() => this.ngZone.run(() =>
      this.pageService.logout(this.sessionTimeoutRedirectToUrl, envName)
        .subscribe(
          (redirectUrl) => {
          idle.stop()
          idle.ngOnDestroy();
          localStorage.clear();
          this.authService.resetAuthValues();
          this.externalRouter.navigate(redirectUrl, false)
        },
        () => this.externalRouter.navigate(popup.absoluteTimeout.link,
          false)))))

    ngZone.runOutsideAngular(() => {
      idle.watch();
    });
  }

  private initializeOntarioWorksLink() {
    StartTwoPopup.notEligible.link = this.ontarioWorksLink;
    StartTwoPopup.dateOfRelease.link = this.ontarioWorksLink;
    //
    popup.locationInEmergency.link = this.ontarioWorksLink;
    popup.inactiveTimeout.link = this.ontarioWorksLink;
    popup.absoluteTimeout.link = this.ontarioWorksLink;
  }

  onDestroy() {
    this.idle.stop()
    this.idle.ngOnDestroy()
    this.idle.onTimeout.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.subscriptions$.forEach(s => s.unsubscribe());
    this.dialog.closeAll()
    this.valueChangeSubjects$.next()
    this.valueChangeSubjects$.complete()
    // Unsubscribe to events when component is destroyed.
    this._destroyed$.next(undefined);
    this._destroyed$.complete();
  }

  abstract onSubmit(toContinue?: boolean):void;

  resolveLink(link: any): string {
    let resolvedLink;

    if (typeof link === 'object' && link) {
      resolvedLink = link[this.configService.getClientConfig()?.envName] || link.dev
    } else {
      resolvedLink = link
    }
    return resolvedLink;
  }

  /**
   * To display CreateAccountReminder dialog.
   */
  protected openCreateAccountReminderDialog(pageNameToSave: string, applicantEmail: string): void {
    if (this.dialogVisible) {
      return
    }

    this.populateTermAndConditionItems();
    this.exitWithoutSavingUrl = this.ontarioWorksLink
    const parameter: CreateAccountReminderParameter = {
      dialogName: 'create-account-reminder-dialog',
        pageNameToSave, applicantEmail,
        termAndConditionItems: this.termAndConditionItems
    };
    const dialogRef = this.displayDialog({
      component: CreateAccountReminderDialogComponent,
      config: {panelClass: 'sada-dialog-class'}});
    dialogRef.componentInstance.parameter = parameter;
    dialogRef.componentInstance.handleCreateAccount = () => {
      this.onCreateAccount(dialogRef, parameter);
    }
    dialogRef.componentInstance.handleContinueWithoutAccount = () => {
      this.redirectTo(this.exitWithoutSavingUrl);
    }
    dialogRef.componentInstance.handleReturnToApplication = () => {
      dialogRef.close();
    }
    dialogRef.componentInstance.handleMockLogin = () => {
      this.onMockLogin(dialogRef, parameter);
    }
    dialogRef.componentInstance.handleClose = () => {
      dialogRef.close();
    }

    this.previouslyOpenedDialogInfo = {  // Saving the dialog configs.
      methodToCreateDialog: this.CREATE_ACCOUNT_REMINDER_DIALOG,
      methodParameters: [pageNameToSave, applicantEmail]
    };
  }

  protected populateTermAndConditionItems(): void {
    this.termAndConditionItems = [{value: 'yes', label: 'create-account.termAndCondition',
      labelParam: {linkUrl:  this.configService.getUrl(this.translator.currentLang, UrlInfo.myBenefitsTerms)}, checked: false}]
  }

  private onCreateAccount(dialogRef: any, parameter: CreateAccountReminderParameter): void {
    this.redirectToPublicSecure(parameter.pageNameToSave, parameter.applicantEmail);
    dialogRef.componentInstance.handleClose();
  }

  protected redirectToPublicSecure(pageNameToSave: string, applicantEmail: string): void {
    if (!applicantEmail) {
      this.showPsRedirectError = true;
      return;
    }

    this.showPsRedirectError = false;
    this.loadingSpinnerService.show(true);

    const lang = this.translator.currentLang ? this.translator.currentLang : 'en';

    this.subscriptions$.push(this.authService.getPSRedirectURL(applicantEmail, lang)
        .pipe(switchMap((url) => {
              return this.intakeService.saveProgress(pageNameToSave)
                  .pipe(switchMap(() => {
                    this.loadingSpinnerService.show();
                    return this.authService.logout()
                        .pipe(map(() => url));
                  }));
            })
        )
        .subscribe(url => {
          this.externalRouter.navigate(url, false);
        }, () => {
          this.loadingSpinnerService.hide()
          this.showPsRedirectError = true
        }));
  }

  protected onMockLogin(dialogRef: any, parameter: CreateAccountReminderParameter): void {
    this.doMockLogin(parameter.pageNameToSave, parameter.applicantEmail);
    dialogRef.componentInstance.handleClose();
  }

  protected doMockLogin(pageNameToSave: string, applicantEmail: string) {
    this.subscriptions$.push(this.authService.mockLogin(applicantEmail).subscribe(url => {
      this.subscriptions$.push(this.intakeService.saveProgress(pageNameToSave).subscribe(() => {
        this.externalRouter.navigate(url.toString(), false);
      }, error => console.log('Error in mock login: ' + error?.message)))
    }, error => console.log('Error in mock login: ' + error?.message)));
  }

  protected saveApplicationToResume(applicationAnswers: ApplicationAnswers, page: string): Observable<void>|undefined {
    return this.intakeService.saveToResume(applicationAnswers, page);
  }

  protected saveToResumeAndLogout(applicationAnswers: ApplicationAnswers, page: string) {
    return this.saveApplicationToResume(applicationAnswers, page)
      .subscribe(() => this.handleSaveAndExit(page, applicationAnswers.jsonData.email))
  }

  /**
   * To handle redirection to external URL.
   * @param url Location to redirect to.
   */
  protected redirectTo(url: string): void {
    return this.externalRouter.navigate(url, false);
  }

  protected openSaveExitDialog() {
    if (this.dialogVisible) {
      return
    }

    const dialogRef = this.displayDialog({
      component: MccssDialogTitleComponent,
      config: {panelClass: 'sada-dialog-class'}});
    dialogRef.componentInstance.dialogHeader = popup.applicationSaved.title;
    dialogRef.componentInstance.dialogBody = popup.applicationSaved.body;
    dialogRef.componentInstance.rightDialogButtonText = popup.applicationSaved.rightButtonText;
    dialogRef.componentInstance.dialogButtonText = popup.applicationSaved.button;
    this.myBUrl = this.configService.getUrl(this.translator.currentLang, UrlInfo.myBenefitsLogin);
    dialogRef.componentInstance.dialogBodyLink = {
      link1: this.myBUrl
    }
    dialogRef.componentInstance.rightButtonAction = () => {
      this.logout(this.myBUrl).subscribe();
    }
    this.logOutUrl = this.ontarioWorksLink
    dialogRef.componentInstance.buttonAction = () => {
      this.logout(this.logOutUrl).subscribe();
    }
    dialogRef.componentInstance.closeButtonAction = () => {
      dialogRef.close();
    }
    this.previouslyOpenedDialogInfo = {  // Saving the dialog configs.
      methodToCreateDialog: this.SAVE_EXIT_DIALOG,
    };
  }

  protected handleSaveAndExit(page: string, email: string) {
    if (!this.authService.isAuthorizedToSave()) {  // 'Save & Exit' is requested for unauthenticated applicant.
      this.openCreateAccountReminderDialog(page, email);
    } else {  // Save & Exit is clicked for authenticated applicant.
      this.openSaveExitDialog();
    }
  }

  // open dialog box
  openDialog(content: any, hideCloseButton?: boolean, buttonAction?: () => void, rightButtonAction?: () => void,
             enableReturnToAppLink?: boolean) {
    if (this.currentOpenedDialog === content.title) {
      return;
    }
    const link = this.resolveLink(content.link);
    this.translator.get([content.title, content.body, content.button, content.rightButton, link, content.isInactive, content.alertInfoKey],
        {...content.bodyLink, ...content.dialogBodyParam}).subscribe(m => {
      this.currentOpenedDialog = content.title;
      const config = {
        panelClass: 'sada-dialog-class',
        autoFocus: false,
        disableClose: hideCloseButton
      };
      const translatedContent = {
        title: m[content.title],
        body: m[content.body],
        button: m[content.button],
        rightButton: m[content.rightButton],
        link: m[link],
        isInactive: m[content.isInactive],
        alertInfoKey: m[content.alertInfoKey]
      }
      if (content.title !== popup.inactiveTimeout.title) {
        this.previouslyOpenedDialogInfo = {  // Saving the dialog configs.
          methodToCreateDialog: this.SIMPLE_DIALOG,
          methodParameters: [{
            component: MccssDialogTitleComponent, config, content: translatedContent,
            hideCloseButton, buttonAction, rightButtonAction, enableReturnToAppLink
          }]
        };
      }
      this.displayDialog({
        component: MccssDialogTitleComponent,
        config, content: translatedContent,
        hideCloseButton, buttonAction, rightButtonAction, enableReturnToAppLink
      });
    })
  }

  /**
   * This method resets the auth-token from local-storage, the auth values in Auth-Service, invalidates the Spring Session and clears
   * both local-storage & session-storage, then logs out all sessions form PSE.
   * @protected
   */
  protected logout(redirectToUrl: string): Observable<void> {
    return this.authService.psLogout(redirectToUrl)
      .pipe(
        concatMap(url => this.intakeService.logout().pipe(
            map(() => url)
          )
        ),
        map((redirectUrl) => {
          localStorage.clear();
          sessionStorage.clear();
          this.externalRouter.navigate(redirectUrl, false);
        })
      )
  }

  /**
   *  This method displays the modal dialog, and returns its reference.
   */
  private displayDialog(dialogConfig: DialogConfig):  MatDialogRef<any> {
    if (this.dialogVisible) {
      return null;
    }

    this.dialogVisible = true
    const dialogRef = this.dialog.open(dialogConfig.component, dialogConfig.config);

    if (dialogConfig.content) {
      dialogRef.componentInstance.dialogHeader = dialogConfig.content.title;
      dialogRef.componentInstance.dialogBody = dialogConfig.content.body;
      dialogRef.componentInstance.dialogBodyLink = dialogConfig.content.bodyLink ? dialogConfig.content.bodyLink : null;
      dialogRef.componentInstance.dialogButtonText = dialogConfig.content.button;
      dialogRef.componentInstance.rightDialogButtonText = dialogConfig.content.rightButton;
      dialogRef.componentInstance.enableReturnToAppLink = dialogConfig.enableReturnToAppLink;
      dialogRef.componentInstance.alertInfoKey = dialogConfig.content.alertInfoKey
      if (dialogConfig.content.isInactive) {
        dialogRef.componentInstance.buttonAction = () => {
          dialogRef.close()
        }
        this.ngZone.runOutsideAngular(() => {
          this.idle.watch();
        });
      } else if (dialogConfig.buttonAction) {
        dialogRef.componentInstance.buttonAction = () => {
          dialogConfig.buttonAction();
          dialogRef.close()
        }

        if (dialogConfig.content.rightButton) {
          dialogRef.componentInstance.rightButtonAction = () => {
            if (dialogConfig.rightButtonAction) {
              dialogConfig.rightButtonAction();
            }
            dialogRef.close()
          }
        }
      } else {
        dialogRef.componentInstance.buttonAction = () => {
          window.location.href = dialogConfig.content.link
        }
      }

      if (dialogConfig.hideCloseButton) {
        dialogRef.componentInstance.showCloseButton = false;
      } else {
        dialogRef.componentInstance.closeButtonAction = () => {
          dialogRef.close();
        }
      }
    }
    this.subscriptions$.push(dialogRef.afterClosed().subscribe(_ => {
      this.dialogVisible = false;
      if (this.displayClosedDialog && !!this.previouslyOpenedDialogInfo  // Check if reopening of the previously closed dialog is needed.
        && (!this.currentOpenedDialog || this.currentOpenedDialog !== popup.inactiveTimeout.title)) {
        this.invokeDialogCreationMethod(this.previouslyOpenedDialogInfo?.methodToCreateDialog,
          this.previouslyOpenedDialogInfo?.methodParameters);
        this.previouslyOpenedDialogInfo = null;
        this.displayClosedDialog = false;
      }
      this.currentOpenedDialog = null;
    }))

    return dialogRef;
  }

  /**
   * Based on the provided method-name to determine the proper method to invoke.
   * Note: Wasn't able to invoke a saved function reference to create the dialog.
   */
  private invokeDialogCreationMethod(methodName: string, parameters: any[]) {
    switch (methodName) {
      case this.SIMPLE_DIALOG.toString():
        return this.displayDialog(parameters[0]);
      case this.CREATE_ACCOUNT_REMINDER_DIALOG.toString():
        return this.openCreateAccountReminderDialog(parameters[0], parameters[1]);
      case this.SAVE_EXIT_DIALOG.toString():
        return this.openSaveExitDialog();
    }
  }
}
