import {AfterViewChecked, ChangeDetectorRef, Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Languages, Sexes} from '../../common/utils/questions/question-choices';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {EmailService} from '../../services/email.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {PageBaseComponent} from '../PageBaseComponent';
import {NoLastNamePopup} from '../personal-information/personal-information.component';
import {ApplicantType} from '../../models/applicant-type';
import {NameUpdateUtil} from '../../utils/name-update-util';
import {ExternalRouter} from '../../external.router';
import {CaseWorkerAuthorizeService} from '../../services/caseWorkerAuthorizeService';
import {ConfigService} from '../../services/config.service';
import {AdditionalInfoMappingUtil} from '../additional-information/converters/additional-info-mapping.util';
import {AuthService} from '../../services/auth.service';
import {PageInfo} from '../../models/page-map';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {distinctUntilChanged, filter, startWith, take} from 'rxjs/operators';
import {FieldsCheckUtil} from '../../utils/fields-check-util';
import {UrlInfo} from '../../models/url-map';
import {ValidationFnsUtil} from '../../utils/validation-fns-util';

@Component({
  selector: 'sd-spouse-information',
  templateUrl: './spouse-information.component.html',
  styleUrls: ['./spouse-information.component.scss']
})
export class SpouseInformationComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  toContinue: boolean;
  customRequiredErrorMessage = 'error.empty.email.address';
  showError = false;
  sexes = Sexes;
  languages = Languages;
  applicantType = ApplicantType.SPOUSE;
  noLastNameCheckBoxItems;
  officeLocationLinkParam: any;
  firstNameValidationFunction = [
    {
      validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
      errorKey: 'error.invalid.firstNameWithOnlyCharacters'
    },
    {
      validationFunction: SadaCustomValidator.validateName,
      errorKey: 'error.invalid.firstName'
    },
    {
      validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
      errorKey: 'error.invalid.fr.character'
    }];
  lastNameValidationFunction = [
    {
      validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
      errorKey: 'error.invalid.lastNameWithOnlyCharacters'
    },
    {
    validationFunction: SadaCustomValidator.validateName,
    errorKey: 'error.invalid.lastName'
    },
    {
      validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
      errorKey: 'error.invalid.fr.character'
    }
  ];
  dobValidationFunctions;
  emailValidationFunctions;
  emailAsyncValidationFns;
  noUniqueEmailCheckBoxItem;
  anyPrevFemaleOfAgeOnApplication;

  constructor(private formBuilder: FormBuilder, private router: Router, public route: ActivatedRoute,
              public intake: IntakeService, private emailService: EmailService,
              @Inject(LOCALE_ID) protected localeId: string,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              public authorizeService: CaseWorkerAuthorizeService,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.spouseInfo;
  }

  ngOnInit(): void {
    this.isAuthorizedUser = this.authorizeService.isAuthorized();


    this.setupForm();
    this.setupLinks();
    this.setupValidators();
    this.form.controls.spouseFirstName.valueChanges.subscribe(value => {
      this.noLastNameCheckBoxItems = this.getNoLastNameCheckBoxValue(!!this.form.controls.spouseNoLastName.value);
      this.noUniqueEmailCheckBoxItem = this.getNoUniqueEmailCheckBoxValue(!!this.form.controls.spouseNoUniqueEmail.value);
    });
    this.initializeForm();

    this.anyPrevFemaleOfAgeOnApplication = AdditionalInfoMappingUtil.getFemaleApplicantsAboveAgeThreshold(
      this.applicationAnswers, 12).length > 0

    this.translator.onLangChange.subscribe((lang) => {
      this.setupLinks();
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  postInitializeForm() {
    if (this.applicationAnswers.jsonData?.spouseNoLastName?.length) {
      this.noLastNameCheckBoxItems = this.getNoLastNameCheckBoxValue(true);
      this.form.controls.spouseLastName.reset();
      this.form.controls.spouseLastName.disable();
    }

    if (this.applicationAnswers.jsonData?.spouseNoUniqueEmail?.length) {
      this.noUniqueEmailCheckBoxItem = this.getNoUniqueEmailCheckBoxValue(true);
      this.form.controls.spouseEmail.reset();
      this.form.controls.spouseEmail.disable();
    }
  }

  setupForm(): void {
    this.form = this.formBuilder.group({
      spouseFirstName: [],
      spouseLastName: [],
      spouseNoLastName: [],
      spouseDateOfBirth: [],
      spouseSexAtBirth: [],
      spouseEmail: [],
      spouseNoUniqueEmail: [],
    });
  }

  private setupLinks(): void {
    this.officeLocationLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)
    };
  }

  setupValidators() {
    this.noLastNameCheckBoxItems = this.getNoLastNameCheckBoxValue(false);
    this.emailValidationFunctions = this.getEmailValidationFunctions();
    this.emailAsyncValidationFns = [
      {
        validationFunction: (value) => this.emailService.validateEmailAddress(value),
        errorKey: 'error.invalid.email.domain'
      }
    ];
    this.dobValidationFunctions = this.getDOBValidationFunctions();
  }

  onSubmit(toContinue: boolean): void {
    this.showRequiredInfoBanner = false;
    let notSubmitForm = false; // prevent submitting the form when the user changes the field value while it's handling ASYNC validators

    if (this.form.valid) {
      this.saveAndContinueOrExit(toContinue);
    } else if (this.form.invalid || FieldsCheckUtil.findInvalidField(this.form.controls)) {
      this.showError = true
      this.scrollToInvalidFormControl(toContinue);
    } else {
      // Below handling is required to handle ASYNC validators. Submission should be delayed until all async validators are executed.
      // Until async validators are done, status would be 'PENDING'
      this.subscriptions$.push(
        this.form.statusChanges.pipe(
          distinctUntilChanged(),
          startWith(this.form.status),
          filter(status => status !== 'PENDING'),
          take(1)).subscribe(status => {
          this.form.updateValueAndValidity();

          // Until async validators are done status would be 'PENDING'
          if (!notSubmitForm && this.form.valid && status === 'VALID') {
            this.saveAndContinueOrExit(toContinue)
          } else if(!notSubmitForm && status !== 'PENDING') {
            notSubmitForm = true;
            this.showError = true
            this.scrollToInvalidFormControl(toContinue);
          }
      }))
    }
  }

  saveAndContinueOrExit(toContinue: boolean) {
    if (this.form.controls.spouseFirstName?.value && this.applicationAnswers.jsonData.spouseFirstName) {
      NameUpdateUtil.updateFirstNameInSavedData(this.form.controls.spouseFirstName.value,
        this.applicationAnswers.jsonData, this.localeId, ApplicantType.SPOUSE, 0);
    }
    this.updateAppDataIfNotSponsored()

    // Delete pregnancy related appdata if net change in sex from male to female within household as result of spouse data
    if (!this.anyPrevFemaleOfAgeOnApplication) {
      if (this.applicationAnswers.jsonData.hasOwnProperty('pregnantOrBreastFeedingInFamily') &&
        this.form.controls.spouseSexAtBirth.value === 'female') {
        delete this.applicationAnswers.jsonData.pregnantOrBreastFeedingInFamily
      }
    }

    this.saveForm()?.subscribe(x => {
      if (toContinue) {
        if ('yes' === this.applicationAnswers.jsonData.childrenLivingWithYou) {
          this.router.navigate(['/', 'intake', PageInfo.childrenInfo])
        } else {
          this.router.navigate(['/', 'intake', PageInfo.additionalInfo])
        }
      } else {
        this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
      }
    });
  }

  private updateAppDataIfNotSponsored() {
    if(!this.form.get('spouseSponsored')){
      delete this.applicationAnswers.jsonData.spouseSponsored
      delete this.applicationAnswers.jsonData.spouseImmigrationFileNumber
      this.removeSponsorListInfo()
    }
    if(!this.form.get('spouseArrivalDateToCanada')){
      delete this.applicationAnswers.jsonData.spouseArrivalDateToCanada
    }

    if(this.form.get('spouseSponsored')?.value === 'no'){
      this.removeSponsorListInfo()
    }

  }
  isEmailRequired():boolean
  {
    return this.isAuthorizedUser || this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse === 'APPLICATION_SELF'
      || this.intakeService.getIsRelatedToLocalOfficeAssistance()
  }
  private removeSponsorListInfo() {
    const {anyoneSponsoredList, anyoneSponsoredCheckbox} = this.applicationAnswers.jsonData
    let newSponsoredList = anyoneSponsoredList?.filter((app: {applicantType: string }) => app.applicantType !== 'Spouse') || [];
    const newSponsoredCheckbox = anyoneSponsoredCheckbox?.filter((title: string) => !title.startsWith('Spouse') && !title.startsWith('Conjoint')) || []

    function getSponsorInfoForTheOnlyChild(lastName: string|undefined, firstName: string) {
      return newSponsoredList.map((app: {applicantType: string, sponsorFirstName: string, sponsorLastName: string }) => {
        if(app.applicantType.startsWith('Child')){
          if(!lastName){
            return ({...app, sponsorFirstName: firstName})
          }else{
            return ({...app,
              sponsorFirstName: firstName,
              sponsorLastName: lastName
            })

          }
        }
        return {...app}
      })
    }

    if(newSponsoredCheckbox.length < 2 && this.applicationAnswers.jsonData.commonSponsorFirstName){
      newSponsoredList = this.updateSponsorInfoForApplicant(newSponsoredList)
      newSponsoredList = getSponsorInfoForTheOnlyChild(this.applicationAnswers.jsonData.commonSponsorLastName,
        this.applicationAnswers.jsonData.commonSponsorFirstName)

      delete this.applicationAnswers.jsonData.commonSponsorFirstName
      delete this.applicationAnswers.jsonData.commonSponsorLastName
      delete this.applicationAnswers.jsonData.allHaveSameSponsor
    }
    if(newSponsoredList.length){
      this.applicationAnswers.jsonData = {
        ...this.applicationAnswers.jsonData,
        anyoneSponsoredList: [...newSponsoredList],
        anyoneSponsoredCheckbox: [...newSponsoredCheckbox]
      };
    }else{
      delete this.applicationAnswers.jsonData.anyoneSponsoredList
      delete this.applicationAnswers.jsonData.anyoneSponsoredCheckbox
    }

  }

  private updateSponsorInfoForApplicant(sponsoredList: { applicantType: string, sponsorSupportAmount: string,
        sponsorLivesWith: string }[]) {
    const applicantSponsor = sponsoredList.find((app: {applicantType: string }) => app.applicantType === 'Applicant');
    if(applicantSponsor){
      const {sponsorSupportAmount, sponsorLivesWith} = applicantSponsor;
      if(this.applicationAnswers.jsonData.commonSponsorLastName){
        this.applicationAnswers.jsonData.sponsorLastName = this.applicationAnswers.jsonData.commonSponsorLastName
      }
      this.applicationAnswers.jsonData.sponsorFirstName = this.applicationAnswers.jsonData.commonSponsorFirstName
      this.applicationAnswers.jsonData.sponsorLivesWith = sponsorLivesWith
      this.applicationAnswers.jsonData.sponsorSupportAmount = sponsorSupportAmount;
      return [];
    }
    return [...sponsoredList];
  }


  onNoLastNameCheckBoxChanges(event: any): void {

    if (event && event.length) {
      this.form.controls.spouseNoLastName.setValue(event)
      this.form.controls.spouseLastName.reset();
      this.form.controls.spouseLastName.disable();
      this.openDialog(NoLastNamePopup.noLastName)
    } else {
      delete this.applicationAnswers.jsonData.spouseNoLastName
      this.form.controls.spouseNoLastName.reset()
      this.form.controls.spouseLastName.enable()
    }
  }

  onNoUniqueEmailCheckBoxChanges(event: any): void {

    if (event && event.length) {
      this.form.controls.spouseNoUniqueEmail.setValue(event)
      this.form.controls.spouseEmail.reset();
      this.form.controls.spouseEmail.disable();
    } else {
      delete this.applicationAnswers.jsonData.spouseNoUniqueEmail
      this.form.controls.spouseNoUniqueEmail.reset()
      this.form.controls.spouseEmail.enable()
    }
  }

  private getDOBValidationFunctions() {
    return [
      {
        validationFunction: (value: []) => {
          return SadaCustomValidator.validateDateWithMinAndFuture([...value, '1900/01/01']);
        },
        errorKey: 'error.invalid.year'
      }
    ]
  }

  private getEmailValidationFunctions() {
    return [ ...ValidationFnsUtil.emailValidationFns(
      this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse, this.isAuthorizedUser),
      {
      validationFunction: (value: []) => {
        const applicantEmail = this.applicationAnswers.jsonData?.email ?
          this.applicationAnswers.jsonData.email : null;
        const childList = this.applicationAnswers.jsonData?.childList ? this.applicationAnswers.jsonData.childList : [];
        const applyingForYourselfOrSomeoneElse = this.applicationAnswers.jsonData?.applyingForYourselfOrSomeoneElse
          ? this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse : null;
        return SadaCustomValidator.validateUniqueSpouseEmail(
          [...value, applicantEmail, childList, applyingForYourselfOrSomeoneElse, this.isAuthorizedUser]);
      },
      errorKey: 'error.notUnique.email.address'
    }]
  }

  isNoLastNameSelected() {
    return this.form.controls.spouseNoLastName?.value?.length
  }

  private getNoLastNameCheckBoxValue(checked: boolean) {
    return [{
      value: 'yes',
      label: 'spouse-information.spouseNoLastName',
      labelParam: {param: this.spouseFirstName},
      checked
    }];
  }

  private getNoUniqueEmailCheckBoxValue(checked: boolean) {
    return [{
      value: 'yes',
      label: 'spouse-information.spouseNoUniqueEmail',
      labelParam: {param: this.spouseFirstName},
      checked
    }];
  }

  get emailAddressValue(): string {
    return this.form.controls.spouseEmail?.value;
  }

  get spouseFirstName() {
    if (this.form.controls.spouseFirstName?.value) {
      return this.form.controls.spouseFirstName?.value;
    } else {
      return '';
    }
  }

  get spouseLastName() {
    if (this.form.controls.spouseLastName?.value) {
      return this.form.controls.spouseLastName?.value;
    } else {
      return '';
    }
  }

  get spouseDateOfBirth() {
    if (this.form.controls.spouseDateOfBirth?.value) {
      return this.form.controls.spouseDateOfBirth.value;
    } else {
      return '';
    }
  }

  preSaveApplication() {
  }

  private convertStatusInCanadaField(controlName: string): string {
    const statusInCanadaFields = {
      spouseStatusInCanada: 'statusInCanada',
      spouseArrivalDateToCanada: 'arrivalDateToCanada',
      spouseSponsored: 'sponsored',
      spouseSocialInsuranceNumber: 'sinNumber',
      spouseImmigrationFileNumber: 'arrivalDateToCanada',
      spouseHealthCardNumber: 'healthCardNumber'
    }
    const keys = Object.keys(statusInCanadaFields);
    for (const key of keys) {
      if (key === controlName){
        return statusInCanadaFields[key]
      }
    }
    return controlName
  }
}
