import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[sdDisableAutocomplete]'
})
export class DisableAutocompleteDirective {

  // If needed in future this directive can be enhanced to disable autocorrect, spellcheck etc..
  constructor(private _el: ElementRef) {
    this._el.nativeElement.setAttribute('autocomplete', 'off');
  }
}
