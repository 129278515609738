<sd-page-container [pageId]="pageName">
  <div id="page-alerts-container">
    <sd-warning-text *ngIf="applicationExpired" id="expired-application" [title]="'expired-application.info.header'">
    <span>
      <div id="expired-application-warning-content">
        {{'expired-application.info.text' | translate: dateParam}}
      </div>
    </span>
    </sd-warning-text>
    <sd-info-text *ngIf="!applicationExpired"
                  header="inprogress-application.info.header"
                  [headerParam]="dateParam"
                  [contentId]="pageName"
                  content="inprogress-application.info.text">
    </sd-info-text>
  </div>
  <div class="button-container">
    <app-mccss-submit-button *ngIf="!applicationExpired" dataE2E="useExistingApplicationBtn" buttonLabel="{{'inprogress-application.existing.application.button' | translate}}" [preventDoubleClick]="true" (handleClick)="onSubmit(true)"></app-mccss-submit-button>
    <app-mccss-submit-button dataE2E="useNewApplicationBtn" buttonLabel="{{'inprogress-or-expired-application.new.application.button' | translate}}" [secondaryButton]="!applicationExpired" [preventDoubleClick]="true" (handleClick)="onSubmit(false)"></app-mccss-submit-button>
  </div>
</sd-page-container>
