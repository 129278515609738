import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SadaErrorCodes} from '../../utils/sada-error-codes';
import {MyBErrorCodes} from '../../utils/myb-error-codes';
import {RedirectService} from '../../services/redirect.service';

@Component({
  selector: 'sd-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit, OnDestroy {

  private subscriptions$: Subscription[] = [];

  constructor(private route: ActivatedRoute, private router: Router, private redirectService: RedirectService) {
  }

  ngOnInit(): void {

    this.subscriptions$.push(this.route.queryParams?.subscribe(params => {
      const errorCode = params?.errorCode;
      if (errorCode) {
        this.handleRedirectionBasedOnErrorCode(errorCode)
      } else {
        this.redirectService.redirectToLandingPage(SadaErrorCodes.AUTHEC01);
      }
    }));
  }

  private handleRedirectionBasedOnErrorCode(errorCode: string) {
    if (errorCode === SadaErrorCodes.SADAERR101) { // From PAAccess - error during registration
      this.redirectService.redirectToGlobalErrorPage(errorCode);
    } else if (errorCode === SadaErrorCodes.SADAERR104) { // From PAAccess - call back error
      this.redirectService.redirectToLandingPage(SadaErrorCodes.AUTHEC01);
    } else if (errorCode === SadaErrorCodes.SADAERR102 || errorCode === SadaErrorCodes.SADAERR103) {
      // From PAAccess - Error during resume app from myb
      this.redirectService.redirectToMyB(MyBErrorCodes.EC00454);
    } else if (errorCode === SadaErrorCodes.AUTHEC08) { // Invalid PS token
      this.redirectService.redirectToMyB(MyBErrorCodes.EC00451);
    } else {
      this.redirectService.redirectToLandingPage(errorCode);
    }
  }

  // Unsubscribe to observables when component is destroyed
  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }
}
