import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';

export class FormToFAOwnedHome implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  static readonly OWNED_HOME = 'ownItemsOfValueOwnedHomeCheckbox';

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // remove previous saved data and repopulate with latest data
    delete appData.jsonData[FormToFAOwnedHome.OWNED_HOME];

    if (appData.jsonData.currentHousingSituation === 'I own my home') {
      appData.jsonData[FormToFAOwnedHome.OWNED_HOME] = 'yes';
    }
  }
}
