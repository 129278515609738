<div [class]="'ontario-alert' + ' ontario-alert--error'">
    <div class="ontario-alert__header">
        <div class="ontario-alert__header-icon">
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use [attr.href]="'#ontario-icon-alert-error'"></use>
            </svg>
        </div>


        <div *ngIf="errorHeader" class="ontario-alert__header-title ontario-h4">
            {{errorHeader}}
        </div>
        <div *ngIf="!errorHeader" class="ontario-alert__header-title ontario-h4">
            {{'global-error.session.timeout' | translate}}
        </div>

    </div>

    <div *ngIf="errorBody" class="ontario-alert__body">
        <p [innerHTML]="errorBody"></p>
    </div>
    <div *ngIf="!errorBody" class="ontario-alert__body">
        <p [innerHTML]="'global-error.session.timeout.error.message' | translate:linkParam"></p>
    </div>
</div>