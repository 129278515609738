import {Choice} from '../../common/utils/questions/question-choices';

export function otherAssetTypeChoices() {
  const otherAssetType: Choice[] = [
    {value: 'Educational Savings Plan - RESP', label: 'financial-assets.other.asset.type.option.resp'},
    {value: 'Registered Disability Savings Plan - RDSP', label: 'financial-assets.other.asset.type.option.rdsp'},
    {value: 'Second Property - not the home you live in', label: 'financial-assets.other.asset.type.option.second.property'},
    {value: 'Funeral or burial plan', label: 'financial-assets.other.asset.type.option.funeral.plan'},
    {value: 'Life insurance - Term', label: 'financial-assets.other.asset.type.option.insurance.term'},
    {value: 'Life insurance - Whole life', label: 'financial-assets.other.asset.type.option.insurance.whole'},
    {value: 'Business or farm assets or inventory', label: 'financial-assets.other.asset.type.option.business'},
    {value: 'Money from an inheritance', label: 'financial-assets.other.asset.type.option.inheritance'},
    {value: 'Stocks or shares', label: 'financial-assets.other.asset.type.option.stocks'},
    {value: 'Valuables', label: 'financial-assets.other.asset.type.option.valuables'},
    {value: 'My asset type is not listed here', label: 'financial-assets.other.asset.type.option.not.listed'},
  ];
  return otherAssetType
}

export const otherAssetTypeDropdownOptions = [
  'Educational Savings Plan - RESP', 'Régime d\'épargne-études - REEE',
  'Registered Disability Savings Plan - RDSP', 'Régime enregistré d\'épargne-invalidité - REEI',
  'Second Property - not the home you live in', 'Deuxième propriété - autre que le lieu de résidence',
  'Funeral or burial plan', 'Plan de funérailles ou d\'enterrement',
  'Life insurance - Term', 'Assurance-vie - temporaire',
  'Life insurance - Whole life', 'Assurance vie - vie entière',
  'Business or farm assets or inventory', 'Actifs ou stocks d\'une entreprise ou d\'une ferme',
  'Money from an inheritance', 'Argent provenant d\'un héritage',
  'Stocks or shares', 'Actions ou parts',
  'Valuables', 'Objets de valeur'
]

export function vehicleMakeChoices() {
  const vehicleMakes: Choice[] = [
    {value: 'Ford', label: 'financial-assets.vehicle.make.option.ford'},
    {value: 'Dodge', label: 'financial-assets.vehicle.make.option.dodge'},
    {value: 'Honda', label: 'financial-assets.vehicle.make.option.honda'},
    {value: 'Toyota', label: 'financial-assets.vehicle.make.option.toyota'},
    {value: 'Hyundai', label: 'financial-assets.vehicle.make.option.hyundai'},
    {value: 'Mazda', label: 'financial-assets.vehicle.make.option.mazda'},
    {value: 'Nissan', label: 'financial-assets.vehicle.make.option.nissan'},
    {value: 'Chrysler', label: 'financial-assets.vehicle.make.option.chrysler'},
    {value: 'Kia', label: 'financial-assets.vehicle.make.option.kia'},
    {value: 'Volkswagen', label: 'financial-assets.vehicle.make.option.volkswagen'},
    {value: 'Acura', label: 'financial-assets.vehicle.make.option.acura'},
    {value: 'Alfa', label: 'financial-assets.vehicle.make.option.alfa'},
    {value: 'AM General', label: 'financial-assets.vehicle.make.option.amgeneral'},
    {value: 'AMC', label: 'financial-assets.vehicle.make.option.amc'},
    {value: 'Audi', label: 'financial-assets.vehicle.make.option.audi'},
    {value: 'Bertone', label: 'financial-assets.vehicle.make.option.bertone'},
    {value: 'BMW', label: 'financial-assets.vehicle.make.option.bmw'},
    {value: 'Buick', label: 'financial-assets.vehicle.make.option.buick'},
    {value: 'Cadillac', label: 'financial-assets.vehicle.make.option.cadillac'},
    {value: 'Chevrolet', label: 'financial-assets.vehicle.make.option.chevrolet'},
    {value: 'Daewoo', label: 'financial-assets.vehicle.make.option.daewoo'},
    {value: 'Daihatsu', label: 'financial-assets.vehicle.make.option.daihutsu'},
    {value: 'Datsun', label: 'financial-assets.vehicle.make.option.datsun'},
    {value: 'Eagle', label: 'financial-assets.vehicle.make.option.eagle'},
    {value: 'Fiat', label: 'financial-assets.vehicle.make.option.fiat'},
    {value: 'Geo', label: 'financial-assets.vehicle.make.option.geo'},
    {value: 'GMC', label: 'financial-assets.vehicle.make.option.gmc'},
    {value: 'Harley Davidson', label: 'financial-assets.vehicle.make.option.harleydavidson'},
    {value: 'Indian', label: 'financial-assets.vehicle.make.option.indian'},
    {value: 'Infiniti', label: 'financial-assets.vehicle.make.option.infiniti'},
    {value: 'International', label: 'financial-assets.vehicle.make.option.international'},
    {value: 'Isuzu', label: 'financial-assets.vehicle.make.option.isuzu'},
    {value: 'Jaguar', label: 'financial-assets.vehicle.make.option.jaguar'},
    {value: 'Jeep', label: 'financial-assets.vehicle.make.option.jeep'},
    {value: 'Kawasaki', label: 'financial-assets.vehicle.make.option.kawasaki'},
    {value: 'Land Rover', label: 'financial-assets.vehicle.make.option.landrover'},
    {value: 'Lexus', label: 'financial-assets.vehicle.make.option.lexus'},
    {value: 'Lincoln', label: 'financial-assets.vehicle.make.option.lincoln'},
    {value: 'Mercedes Benz', label: 'financial-assets.vehicle.make.option.mercedesbenz'},
    {value: 'Mercury', label: 'financial-assets.vehicle.make.option.mercury'},
    {value: 'Merkur', label: 'financial-assets.vehicle.make.option.merkur'},
    {value: 'Mitsubishi', label: 'financial-assets.vehicle.make.option.mitsubishi'},
    {value: 'Oldsmobile', label: 'financial-assets.vehicle.make.option.oldsmobile'},
    {value: 'Peugeot', label: 'financial-assets.vehicle.make.option.peugeot'},
    {value: 'Plymouth', label: 'financial-assets.vehicle.make.option.plymouth'},
    {value: 'Pontiac', label: 'financial-assets.vehicle.make.option.pontiac'},
    {value: 'Porsche', label: 'financial-assets.vehicle.make.option.porsche'},
    {value: 'Renault', label: 'financial-assets.vehicle.make.option.renault'},
    {value: 'Saab', label: 'financial-assets.vehicle.make.option.saab'},
    {value: 'Saturn', label: 'financial-assets.vehicle.make.option.saturn'},
    {value: 'Sterling', label: 'financial-assets.vehicle.make.option.sterling'},
    {value: 'Subaru', label: 'financial-assets.vehicle.make.option.subaru'},
    {value: 'Suzuki', label: 'financial-assets.vehicle.make.option.suzuki'},
    {value: 'Volvo', label: 'financial-assets.vehicle.make.option.volvo'},
    {value: 'Yamaha', label: 'financial-assets.vehicle.make.option.yamaha'},
    {value: 'Yugo', label: 'financial-assets.vehicle.make.option.yugo'},
  ];
  return vehicleMakes
}
