import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApplicationStatusResponse} from '../models/application-status-response';
import {ApplicationStatusService} from './application-status.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStatusServiceImpl implements ApplicationStatusService {
  referenceCode: string|undefined;
  statusResponse: ApplicationStatusResponse;

  constructor(private http: HttpClient) {
  }

  getStatus(referenceCode?: string): Observable<ApplicationStatusResponse> {
    return this.http.get<ApplicationStatusResponse>(`/v1/application/status/${referenceCode}`, {withCredentials: true});
  }

  getReferenceCode(): string {
    return this.referenceCode;
  }
  setReferenceCode(referenceCode: string): void {
    this.referenceCode = referenceCode;
  }

  getStatusResponse(): ApplicationStatusResponse {
    return this.statusResponse;
  }
  setStatusResponse(statusResponse: ApplicationStatusResponse): void {
    this.statusResponse = statusResponse;
  }
}
