import { Component, OnInit } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {popup} from '../../dialog-constants';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../services/config.service';
import { MccssDialogTitleComponent } from 'src/app/mccss-dialog-title/mccss-dialog-title.component';
import {AuthService} from '../../services/auth.service';
import {CaseWorkerAuthorizeService} from '../../services/caseWorkerAuthorizeService';
import {UrlInfo} from '../../models/url-map';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  constructor(public dialog: MatDialog,
              private translator: TranslateService,
              public configService: ConfigService,
              private authService: AuthService,
              private caseWorkerAuthorizeService: CaseWorkerAuthorizeService) { }
  linkParam: any;

  ngOnInit(): void {
    if (this.caseWorkerAuthorizeService.isAuthorized()) {
      this.caseWorkerAuthorizeService.setClaims(null);
      }
    this.authService.resetAuthValues();
    this.openPopup(popup.absoluteTimeout)
    this.translator.onLangChange.subscribe((lang) => {
      this.linkParam = { link: this.configService.getUrl(this.translator.currentLang, UrlInfo.socialAssistance)};
    });
  }

  private openPopup(content: any) {
    const dialogRef = this.dialog.open(MccssDialogTitleComponent, {
      panelClass: 'sada-dialog-class',
      autoFocus: false
    });
    dialogRef.componentInstance.dialogHeader = content.header
    dialogRef.componentInstance.dialogBody = content.body
    dialogRef.componentInstance.dialogButtonText = content.button
    dialogRef.componentInstance.buttonAction = () => {
      window.location.href = content.link
    }
    dialogRef.componentInstance.closeButtonAction = () => {
      dialogRef.close()
    }
    dialogRef.afterClosed().subscribe(value =>
      window.location.href = content.link
    )
  }
}
