import {DisableOtherOnCondition, Panel, Question, VisibleOnCondition} from '../questions/question';
import {ApplicationAnswers} from '../../../models/data.model';
import {Choice} from '../questions/question-choices';

export function addDynamicPanels(parentQuestionKey:string, questionKey:string, index:number,
                                 questions:Question<string>[],
                                 panelQuestions:Map<string,Map<number,Question<string>[]>>,
                                 additionalVisibleConditions:VisibleOnCondition[], panelConfig: Panel): void {

  const parentQuestion = questions.find(q => q.key === parentQuestionKey);
  const question = questions.find(q => q.key === questionKey);

  const panels: Question<string>[] = [];

  if (question?.options.length >= 1) {

    for (let i = 0; i < question.options.length; i++) {
      const option = question.options[i];
      const altValue = option.alternateValue;
      let childHeadingIndex= '0';

      if (altValue.includes('Child')){
        const value = altValue.substr(0,altValue.indexOf(':'));
        childHeadingIndex = value.substr(value.length -1);
      }
      const questionKeyPanel = questionKey.concat('Panel').concat(removeSpaces(option.alternateValue));

      const newPanelConfig:Panel = {...panelConfig}
      if (panelConfig.showLineSeparator && i !== 0) {
        newPanelConfig.showLineSeparator = true
      } else {
        newPanelConfig.showLineSeparator = false
      }
      if (panelConfig.showSpace && i !== 0) {
        newPanelConfig.showSpace = true
      } else {
        newPanelConfig.showSpace = false
      }

      panels.push(createDynamicPanel(questionKeyPanel,
        option.label.concat('.panel.heading'), parseInt(childHeadingIndex, 10), altValue,
        questionKey, i, option.labelParam.name,
        panelQuestions.get(option.alternateValue), additionalVisibleConditions, newPanelConfig));

      // add disabled conditions for dynamic panels
      question.disableOthersOnCondition?.push(addDisableOthersOnCondition(questionKeyPanel,'panel',[false],i));
      parentQuestion?.disableOthersOnCondition?.push(addDisableOthersOnCondition(questionKeyPanel,'panel',[false],0))
    }

    questions.splice(index + 1, 0, ...panels);
  }
}

export function addDisableOthersOnCondition(questionKey:string, questionControlType:string,
                                            value:any[], index:number): DisableOtherOnCondition {
  return {
    questionKey,
    questionControlType,
    index,
    value
  }
}

export function removeSpaces(optionAlterValue:string): string {
  const index = optionAlterValue.indexOf(':');
  return optionAlterValue.substr(0,index).replace(/ /g, '');
}

export function createDynamicPanel(questionKey: string,
                                   heading: string,
                                   headingIndex:number,
                                   panelValue:string,
                                   visibleOnKey: string, index: number, name: string,
                                   panelQuestions: Map<number,Question<string>[]>,
                                   additionalVisibleConditions:VisibleOnCondition[],
                                   panelConfig: Panel): Question<string> {

  const question:Question<string> =
    {
      controlType: 'panel',
      key: questionKey,
      value: panelValue,
      panels: [],
      visibleOnConditions:[ {
        questionKey: visibleOnKey,
        questionControlType: 'checkbox',
        value: [true],
        index
      }]
    };

  panelQuestions.forEach((questions, k) => {
    question.panels.push(
      {
        name: questionKey,
        heading1: heading,
        heading1Param: {name,index: headingIndex},
        heading2: panelConfig.heading2,
        questions,
        showAddButton: panelConfig.showAddButton,
        removeButton: panelConfig.removeButton,
        addButton: panelConfig.addButton,
        indented: panelConfig.indented,
        updateValidityOnRemoval: panelConfig.updateValidityOnRemoval,
        showLineSeparator: panelConfig.showLineSeparator,
        showSpace: panelConfig.showSpace
      }
    );
  });

  if (additionalVisibleConditions) {
    question.visibleOnConditions = question.visibleOnConditions.concat(additionalVisibleConditions);
  }

  return question;

}

export function addSpouseOption(appData: ApplicationAnswers, question: Question<string>, selectedOptions: string[]): void {

  const alternateValue = 'Spouse: '.concat(appData.jsonData.spouseFirstName);

  const option: Choice = {
    value: (!!selectedOptions?.includes(alternateValue)),
    label: 'financial-assets.spouse',
    labelParam: {name: appData.jsonData.spouseFirstName},
    alternateValue
  }
  question.options.push(option);

}

export function addChildOptions(appData: ApplicationAnswers, question: Question<string>, selectedOptions: string[]): void {

  let index = 1;

  appData.jsonData.childList.forEach(c => {

    const alternateValue = 'Child '.concat(String(index).concat(': ').concat(c.childFirstName));

    const option: Choice = {
      value: !!selectedOptions?.includes(alternateValue),
      label: 'financial-assets.child',
      labelParam: {name: c.childFirstName, index},
      alternateValue
    }
    question.options.push(option);
    index++;
  });

}

export function isSingleApplicant(appData: ApplicationAnswers): boolean{
  return !(appData.jsonData.spouseFirstName || appData.jsonData.childList);
}

export function hasApplicantData(applicant:string, ownItemOfValueList:any){
  return ownItemOfValueList?.find(e => e.applicant === applicant);
}
