import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {InitializeService} from '../services/initialize.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private initializeService: InitializeService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(
            req.clone({
                headers: this.appendHeaders(req)
            })
        );
    }

    private appendHeaders(req: HttpRequest<any>) {
        const token = this.authService.getAccessToken()
        let headers: HttpHeaders = req.headers;
        if (token) {
          headers = headers.append('Authorization', 'Bearer ' + token);
        }

        const initId = this.initializeService.getInitId();
        if (initId) {
          headers = headers.append('init-id', initId);
        }

        return headers;
    }
}
