import {ApplicationAnswers} from '../../../models/data.model';

export class SponsorshipMappingUtil {
  static extractSponsoredPersons(keyPrefix: string, appData: ApplicationAnswers): {key: string, param: {name: string, index?: number}}[] {
    const sponsoredPersons = [];
    if (appData.jsonData.applicantSponsored === 'yes') {
      sponsoredPersons.push({key: keyPrefix ? (keyPrefix + '.applicant') : 'applicant', param: {name: appData.jsonData.firstName, dob: appData.jsonData.dateOfBirth, arrival: appData.jsonData.arrivalDateToCanada}});
    }
    if (appData.jsonData.spouseSponsored === 'yes') {
      sponsoredPersons.push({key: keyPrefix ? (keyPrefix + '.spouse') : 'spouse', param: {name: appData.jsonData.spouseFirstName, dob: appData.jsonData.spouseDateOfBirth, arrival: appData.jsonData.spouseArrivalDateToCanada}});
    }
    if (appData.jsonData?.childList) {
      const sponsoredChildren = appData.jsonData.childList?.filter(child => ('yes' === child.childSponsored));
      if (sponsoredChildren?.length) {
        sponsoredPersons.push(...sponsoredChildren.map((child, index) => {
          return {
            key: keyPrefix ? (keyPrefix + '.child') : 'child',
            param: {name: child.childFirstName, index: index + 1, dob: child.childDateOfBirth, arrival: child.childArrivalDateToCanada}
          }
        }));
      }
    }
    return sponsoredPersons;
  }

  static getNumberOfMembersInApplication(appData: ApplicationAnswers): number {
    let count = 1
    if (appData.jsonData.spouseFirstName) {
      count++;
    }
    if (appData.jsonData.childList) {
      count += appData.jsonData.childList.length;
    }
    return count;
  }

  static translateApplicantTypeToFrench(applicantRole: string): string {
    if (applicantRole === 'Applicant') {
      return 'Demandeur'
    } else if (applicantRole === 'Spouse') {
      return 'Conjoint'
    } else if (applicantRole.split(' ')[0] === 'Child') {
      return `Enfant ${applicantRole.split(' ')[1]}`
    }
    return applicantRole
  }

  static translateApplicantTypeToEnglish(applicationType: string): string{
    return applicationType
      .replace('Demandeur', 'Applicant')
      .replace('Conjoint', 'Spouse')
      .replace('Enfant', 'Child')
  }
}
