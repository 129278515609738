import {Observable} from 'rxjs';

import {ClientConfig} from '../models/client-config';
import {InitializeResponse} from '../models/initialize-response';

export abstract class InitializeService {
  // abstract getClientConfig(): ClientConfig;
  abstract initialize(): Observable<InitializeResponse>;

  abstract getInitId(): string;
}
