import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface AppState {
  hasHttpError?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private stateSubject = new BehaviorSubject<AppState>({hasHttpError: false});
  public state$ = this.stateSubject.asObservable();

  constructor() {
  }

  public emitState(newState: AppState) {
    this.stateSubject.next({...this.state, ...newState});
  }

  public get state() {
    return this.stateSubject.value;
  }
}
