import {AbstractControl} from '@angular/forms';

import {ApplicationAnswers} from '../../models/data.model';

/**
 * To validate the first and last names of the living-with person is not already in the application.
 * @param formGroup FormControl of the validating field.
 * @param appData The application json data.
 */
export function validateUniqueLivingWithFirstLastNames(formGroup: AbstractControl, appData: ApplicationAnswers): boolean {
  const firstNameField = 'livingWithYouFirstName';
  const lastNameField = 'livingWithYouLastName';
  // @ts-ignore
  if (formGroup?.controls[firstNameField].value && formGroup?.controls[lastNameField].value) {
    // @ts-ignore
    const livingWithFirstName = formGroup.controls[firstNameField].value;
    // @ts-ignore
    const livingWithLastName = formGroup.controls[lastNameField].value;
    const childrenWithSameFirstLastNames = appData?.jsonData?.childList?.filter(child => {
      return (child.childFirstName.toLowerCase() === livingWithFirstName.toLowerCase() &&
        child.childLastName.toLowerCase() === livingWithLastName.toLowerCase());
    })
    return !((appData?.jsonData?.firstName.toLowerCase() === livingWithFirstName.toLowerCase() &&
        appData?.jsonData?.lastName.toLowerCase() === livingWithLastName.toLowerCase()) ||
      (appData?.jsonData?.spouseFirstName?.toLowerCase() === livingWithFirstName.toLowerCase() &&
        appData?.jsonData?.spouseLastName.toLowerCase() === livingWithLastName.toLowerCase()) ||
      (childrenWithSameFirstLastNames?.length > 0));
  }
  return true;
}
