import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export class SadaTranslateLoader implements TranslateLoader {

  constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') {
  }

  public getTranslation(lang: string): Observable<any> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`);
  }
}
