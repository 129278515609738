import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthService} from './services/auth.service';
import {ConfigService} from './services/config.service';

@Injectable()
export class DirectAccessGuard  {
  constructor(private router: Router,
              private configService: ConfigService,
              private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const startUrl = '/intake/home-page';

    // To prevent direct access to book-marked page or the applicant from going back with back-button from
    // ESignature, PSE Create Account Pages.
    if (!this.router.navigated && state.url !== startUrl) {
      if (this.configService.getClientConfig()?.cypress){
        return true;
      }
      if ((next.queryParams?.token) && (this.authService.isAuthorizedToSave())) {
        return true;
      } else {
        console.log('Missing token and unauthenticated, denying access');
        this.router.navigate([startUrl]);
        return false;
      }
    }

    return true;
  }
}
