import { Pipe, PipeTransform} from '@angular/core';
import {SharedService} from '@shared/shared.service';


@Pipe({
  name: 'translationKeyFinder'
})
export class TranslationKeyFinderPipe implements PipeTransform {

  constructor(private sharedService: SharedService) {
  }

  transform(value: string): string {
    return this.sharedService.textMappings[value] || value;
  }

}
