<div *ngIf="panel?.showSpace" class="ontario-margin-top-40-!"></div>

<div [formGroup]="form">
  <div [ngClass]="{'ontario-padding-bottom-8-!': !(panel.text || panel.heading1 || panel.heading2),
                   'panel-container' : (panel.text || panel.heading1 || panel.heading2 || panelQuestion.displayWithInnerCard)}">
    <ng-container formArrayName="panels">
        <div [formGroupName]="panelIndex" *ngFor="let panelForm of panelsFormGroup; let panelIndex = index; trackBy: trackByFn">
          <div [ngClass]="{'indented-row': panel.indented }">
            <h2 class="ontario-h3" *ngIf="panelIndex === 0 && panel.heading1">{{panel.heading1 | translate:panel.heading1Param}}</h2>
            <div [ngClass]="{'inner-card' : panelQuestion.displayWithInnerCard}">
              <div *ngIf="panel.heading2" class="ontario-h4">{{panel.heading2 | translate}} {{' '.concat((panelIndex+ 1).toString())}}</div>
              <div *ngIf="alertMessageKeys[panelIndex]?.key" class="alert mccssWarning">
                <span id="{{id}}{{panelIndex}}-alert" role="alert">{{alertMessageKeys[panelIndex].key | translate}}</span>
              </div>
              <p *ngIf="panel.text" [innerHTML]="panel.text | translate"></p>
              <div *ngFor="let question of questions; let i = index; trackBy: trackByFn"
                   [ngClass]="{'indented-form-row': question.indentedRow}">
                <sd-dynamic-question *ngIf="isVisible(question,panelIndex)" [showError]="showError" [question]="question"
                                     [pageQuestions]="questions"
                                     [panelIndex]="panelIndex" [panelName]="panel.name"
                                     [disabled]="isDisabled(question, panelIndex)"
                                     [form]="panelForm"></sd-dynamic-question>
              </div>
              <button *ngIf="panels.length > 1" [id]="'remove-panel-' + panelIndex" class="ontario-button ontario-button--tertiary ontario-margin-top-24-!" type="button" (click)="removePanel(panelIndex)">{{panel.removeButton | translate}}</button>
            </div>
          </div>
        </div>
    </ng-container>
    <button *ngIf="isAddButtonVisible" id="add-panel" class="ontario-button ontario-button--secondary" type="button" (click)="addPanel()">{{panel.addButton | translate}}</button>
  </div>
</div>
