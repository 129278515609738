import {FormGroup} from '@angular/forms';

// validate form. not all questions need to be answered.
// any one question is sufficient or none is selected.
export class FormValidator {
  static validateForm(form: FormGroup): boolean {
    let formValid = false;

    if (form.valid && form.get('FANoneOfAbove').disabled) {
      formValid = true;
    } else if (form.get('FASoldOrGiveAwayAssets').valid && form.get('FANoneOfAbove').value[0]) {
      formValid = true;
    }

    return formValid;
  }
}

export function selectOneOption(form: FormGroup): boolean {
  return  !(form.get('FACash').value[0] || form.get('FAInvestments').value[0] ||
           form.get('FABankAccounts').value[0] || form.get('FAVehicles').value[0] ||
           form.get('FAOtherAssets').value[0] ||
           form.get('FATrustFundPanel.panels.0.FATrustFundOPGT').value[0]  ||
           form.get('FATrustFundPanel.panels.0.FATrustFundOther').value[0] ||
           form.get('FANoneOfAbove').value[0]);
}
