import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sd-text-list',
  templateUrl: './text-list.component.html',
  styleUrls: ['./text-list.component.scss']
})
export class TextListComponent implements OnInit {
  @Input()
  list: Array<{textKey: string, textParam?: {index?: number, name: string}}>;

  constructor() { }

  ngOnInit(): void {
  }
}
