import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {AbstractControl, ControlContainer, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {functionValidator} from '../../utils/validators/custom-validators';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';
import {Validator} from '../../utils/questions/question';
import {UrlInfo} from '../../../models/url-map';
import {ConfigService} from '../../../services/config.service';

@Component({
  selector: 'sd-date',
  templateUrl: './sd-date.component.html',
  styleUrls: ['./sd-date.component.scss']
})
export class SdDateComponent implements OnChanges, OnInit, OnDestroy, AfterViewChecked{

  @Input()
  public id: string

  @Input()
  public showDay: boolean | undefined;

  @Input()
  public required: boolean | false;

  @Input()
  public disabled = false;

  @Input()
  public needMarginTop: boolean;

  @Input()
  public needMarginBottom: boolean;

  @Input()
  public showError = false;

  @Input()
  public infoMessage: string | undefined;

  @Input()
  public label: string | undefined;

  @Input()
  public labelParam: any | {};

  @Input()
  public controlNameDate: string

  @Input()
  public validators: Validator[] | undefined;

  @Input()
  public validationFns: {validationFunction: (params: any) => boolean, errorKey: string, errorParam?: any, isLinkParamNeedTranslate?:
      boolean}[] | undefined;

  @Input()
  public elementIndex: string;  // To use it for multiple parent panels scenario

  @Input()
  public customHintText: string | undefined;

  @Input()
  public customErrorParam: object | undefined;

  @Input()
  smallRightMargin: boolean;

  @Output()
  blurEvent = new EventEmitter<any[]>();

  parentForm: FormGroup
  form: FormGroup

  public controlNameYear: string;
  public controlNameMonth: string;
  public controlNameDay: string;
  officeLocatorLink: string;
  protected valueChangeSubjects$ = new Subject<void>();

  public hintText: string | 'dateHint';

  constructor(public controlContainer: ControlContainer,
              private translator: TranslateService,
              public formBuilder: FormBuilder,
              protected configService: ConfigService,
              private readonly changeDetectorRef: ChangeDetectorRef) {
    if (this.showDay === undefined || this.showDay === true) {
      this.showDay = true
      this.hintText = 'dateHint'
    }
  }

  ngOnInit(): void {
    this.setupLinks();
    if (!this.showDay) {
      this.hintText = 'dateHintWithoutDay'
    }
    this.controlNameDay = this.id + 'Day'
    this.controlNameYear = this.id + 'Year'
    this.controlNameMonth = this.id + 'Month'

    this.setUpForm();
    this.setupValidators();
    this.form?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      const yy = this.formControlYear.value
      const mm: string = this.formControlMonth.value
      const dd: string = this.formControlDay?.value
      let dateValue = ''
      if (yy !== '' && yy !== undefined) {
        dateValue = yy
      }
      if (mm !== '' && mm !== undefined) {
        if (mm.length === 1) {
          dateValue = dateValue.concat('/').concat('0').concat(mm)
        } else {
          dateValue = dateValue.concat('/').concat(mm)
        }
      }
      if (this.showDay && dd !== '' && dd !== undefined) {
        if (dd.length === 1) {
          dateValue = dateValue.concat('/').concat('0').concat(dd)
        } else {
          dateValue = dateValue.concat('/').concat(dd)
        }
      }
      this.formControlDate.setValue(dateValue)
    });
    this.setupAttribute();
    this.translator.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setupLinks();
    });
  }

  private setupLinks(): void {
    this.officeLocatorLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation);
  }

  private setUpForm() {
    this.form = this.formBuilder.group({});

    this.parentForm = this.controlContainer?.control as FormGroup
    if (this.formControlDate && this.formControlDate.value) {
      const date = this.formControlDate.value.split('/')
      const newFormControlYear = new FormControl(date[0], {updateOn: 'blur'})
      this.form.setControl(this.controlNameYear, newFormControlYear)

      const newFormControlMonth = new FormControl(date[1], {updateOn: 'blur'})
      this.form.setControl(this.controlNameMonth, newFormControlMonth)

      if (this.showDay) {
        const newFormControlDay = new FormControl(date[2], {updateOn: 'blur'})
        this.form.setControl(this.controlNameDay, newFormControlDay)
      }
    } else {
      this.parentForm.addControl(this.controlNameDate, this.formBuilder.control(''))
      const newFormControlYear = new FormControl('', {updateOn: 'blur'})
      this.form.setControl(this.controlNameYear, newFormControlYear)

      const newFormControlMonth = new FormControl('', {updateOn: 'blur'})
      this.form.setControl(this.controlNameMonth, newFormControlMonth)

      if (this.showDay) {
        const newFormControlDay = new FormControl('', {updateOn: 'blur'})
        this.form.setControl(this.controlNameDay, newFormControlDay)
      }
    }
  }

  private setupValidators() {
    const validatorsForYear: ValidatorFn[] = []
    if (this.required) {
      validatorsForYear.push(Validators.required)
    }
    validatorsForYear?.push(Validators.pattern('^[0-9]*$'))
    this.formControlYear?.setValidators(validatorsForYear)

    const validatorsForMonth: ValidatorFn[] = []
    if (this.required) {
      validatorsForMonth?.push(Validators.required)
    }
    validatorsForMonth?.push(Validators.pattern('^([0-9]{1,2})$'))
    this.formControlMonth?.setValidators(validatorsForMonth)

    if (this.showDay === true) {
        const validatorsForDay: ValidatorFn[] = []
        if (this.required) {
          validatorsForDay?.push(Validators.required)
        }
      validatorsForDay?.push(Validators.pattern('^([0-9]{1,2})$'))
      this.formControlDay?.setValidators(validatorsForDay)
    }

    const validatorsForDate: ValidatorFn[] = []
    if (this.required) {
      validatorsForDate.push(Validators.required)
    }
    if (this.validationFns) {
      this.validationFns.forEach(v => {
        validatorsForDate.push(functionValidator(v.validationFunction, v.errorKey, v.errorParam, [], v.isLinkParamNeedTranslate))
      })
    }
    if (this.validators) {
      this.validators.forEach(v =>{
        if (v.type === 'validatorFn' || v.type === 'crossField') {
          validatorsForDate.push(functionValidator(v.validatorFn, v.errorKey, v.errorParam, v.params));
        }
      })
    }

    if (this.showDay === true) {
      validatorsForDate.push(functionValidator(SadaCustomValidator.isValidDate, 'error.invalid.sd.date'))
    } else {
      validatorsForDate.push(functionValidator(SadaCustomValidator.isValidYearMonth, 'error.invalid.sd.date'))
    }
    this.formControlDate?.setValidators(validatorsForDate)
    this.formControlDate?.updateValueAndValidity()
  }

  private setupAttribute(): void {
    if (this.disabled) {
      this.formControlYear.disable();
      this.formControlMonth.disable();
      this.formControlDay.disable();
    }
  }

  get formControlYear(): AbstractControl {
    return this.form?.get(this.controlNameYear);
  }

  get formControlMonth(): AbstractControl {
    return this.form?.get(this.controlNameMonth);
  }

  get formControlDay(): AbstractControl {
    return this.form?.get(this.controlNameDay);
  }

  get formControlDate(): AbstractControl {
    return this.parentForm?.get(this.controlNameDate);
  }

  get shouldShowErrorStyle(): boolean {

    if (this.shouldShowErrorMessageRequired()) {
      return true;
    }

    if (this.shouldShowErrorMessageYearMonthRequired()) {
      return true;
    }

    if (this.shouldShowErrorMessageMonthDayRequired()) {
      return true;
    }

    if (this.shouldShowErrorMessageYearDayRequired()) {
      return true;
    }

    if (this.shouldShowErrorMessageYearRequired()) {
      return true;
    }

    if (this.shouldShowErrorMessageMonthRequired()) {
      return true;
    }

    if (this.shouldShowErrorMessageDayRequired()) {
      return true;
    }

    if (this.shouldShowErrorMessageInvalidPattern()) {
      return true;
    }

    return this.shouldShowCustomErrorMessage();

  }

  get formGroupClass(): string {
    let classes: string | undefined;

    if ( this.needMarginTop ) {
      classes = 'ontario-form-group';
    } else if ( this.needMarginBottom ) {
      classes = 'ontario-form-group-with-margin-bottom'
    }
    else {
      classes = 'ontario-form-group-no-margin'
    }

    return classes;
  }

  public invalidYear(): boolean {
    return this.showError && this.formControlYear?.errors?.minlength;
  }

  public shouldShowErrorMessageRequired(): boolean {
    if (this.shouldShowErrorMessageInvalidPattern()) {
      return false;
    }
    return this.invalidYear() ||
      this.showError &&
      (this.formControlDate?.errors?.required
        || (this.formControlYear?.hasError('required')
          && this.formControlMonth?.hasError('required')
          && (this.showDay && this.formControlDay?.hasError('required'))))
  }

  public shouldShowErrorMessageYearMonthRequired(): boolean {
    return this.showError && ((this.formControlYear?.hasError('required') || this.formControlYear?.value === '')
      && (this.formControlMonth?.hasError('required') || this.formControlMonth?.value === '')
      && this.showDay && (!this.formControlDay?.hasError('required') && this.formControlDay?.value!==''))
  }

  public shouldShowErrorMessageMonthDayRequired(): boolean {
    return this.showError && (((!this.formControlYear?.hasError('required')) && this.formControlYear?.value!=='')
      && !this.invalidYear()
      && (this.formControlMonth?.hasError('required') || this.formControlMonth?.value ==='')
      && (this.showDay && (this.formControlDay?.hasError('required') || this.formControlDay?.value ==='')))
  }

  public shouldShowErrorMessageYearDayRequired(): boolean {
    return this.showError && ((this.formControlYear?.hasError('required') || this.formControlYear?.value ==='')
      && (!this.formControlMonth?.hasError('required') && this.formControlMonth?.value!=='')
      && (this.showDay && (this.formControlDay?.hasError('required')|| this.formControlDay?.value ==='')))
  }

  public shouldShowErrorMessageYearRequired(): boolean {
    return this.showError && ((this.formControlYear?.hasError('required')
        || (this.formControlYear?.value ==='' && this.formControlDay?.value!=='' && this.formControlMonth?.value!==''))
      && !this.formControlMonth?.hasError('required')
      && !(this.showDay && this.formControlDay?.hasError('required')))
  }

  public shouldShowErrorMessageMonthRequired(): boolean {
    return this.showError && (!this.formControlYear?.hasError('required')
      && !this.invalidYear()
      && (this.formControlMonth?.hasError('required')
        || (this.formControlMonth?.value ==='' && this.formControlYear?.value!=='' && this.formControlDay?.value!==''))
      && !(this.showDay && this.formControlDay?.hasError('required')))
  }

  public shouldShowErrorMessageDayRequired(): boolean {
    return this.showError && (!this.formControlYear?.hasError('required')
      && !this.invalidYear()
      && !this.formControlMonth?.hasError('required')
      && (this.showDay && (this.formControlDay?.hasError('required')
        || (this.formControlDay?.value==='' && this.formControlMonth?.value !=='' && this.formControlYear?.value !==''))))
  }

  public shouldShowErrorMessageInvalidPattern(): boolean {
    return this.formControlYear?.hasError('pattern')
      || this.formControlMonth?.hasError('pattern')
      || (this.showDay && this.formControlDay?.hasError('pattern'))
  }

  public shouldShowCustomErrorMessage(): boolean {
    return this.formControlYear?.value && this.formControlMonth?.value && !this.invalidYear()
      && (this.showDay === true && this.formControlDay?.value || this.showDay === false)
      && (this.formControlDate?.errors?.customErrorKey || this.formControlDate?.errors?.crossFieldErrorKey)
  }

  getErrorMessage(key: string, param?: any, isParamNeedTranslate: boolean = false) {
    let link = null;
    if(!!param?.link){
      if(isParamNeedTranslate){
        const translatedLinks = {};
        for (const linkKey in param) {
          if (Object.hasOwnProperty.call(param, linkKey)) {
            const translatedValue = this.translator.instant(param[linkKey]);
            translatedLinks[linkKey] = translatedValue;
          }
        }
        link = translatedLinks;
      }else{
        link = param;
      }
    }
    return this.translator.instant(key, link);
  }

  get errorMessage(){
    return this.formControlDate.errors?.customErrorKey
      ? this.getErrorMessage(this.formControlDate.errors?.customErrorKey, this.formControlDate.errors?.customErrorParam,
        this.formControlDate.errors?.isParamNeedTranslate)
      : this.getErrorMessage(this.formControlDate.errors?.crossFieldErrorKey, this.formControlDate.errors?.crossFieldErrorParam,
        this.formControlDate.errors?.isParamNeedTranslate)
  }

  onBlur() {
    // Remove leading/trailing spaces first
    this.trimField(this.formControlYear);
    this.trimField(this.formControlMonth);
    this.trimField(this.formControlDay);

    const dateValue = this.formControlDate?.value;
    if (dateValue && ((this.showDay && SadaCustomValidator.isValidDate([dateValue]))
    || (!this.showDay && SadaCustomValidator.isValidDate([dateValue], 'YYYY/MM')))) {
      this.blurEvent.emit(dateValue);
    } else {
      this.blurEvent.emit();
    }
  }

  private trimField(formControl): void {
    if (formControl) {
      const trimmedValue = formControl.value.trim();
      formControl.setValue(trimmedValue);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.validationFns) {
      this.setupValidators()
    }
  }

  ngOnDestroy() {
    this.valueChangeSubjects$.next()
    this.valueChangeSubjects$.complete()
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

}
