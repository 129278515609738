<sd-page-container [pageId]="pageId">
  <div id="page-alerts-container">
    <sd-warning-text title="additional-signatures.pageAlertImportant">
      <div>
        <div id="warning-content">
          {{'additional-signatures.important' | translate}}
        </div>
      </div>
    </sd-warning-text>
    <sd-info-text  *ngIf="dependentChildrenCount > 6" header="important.info.header" content="additional-signatures.importantBody"></sd-info-text>
  </div>
  <div id="spinner-container" *ngIf="isLoading">
    <div class="row">
      <div class="col-12">
        <div id="spinner">
        <svg class='ontario-loading-indicator__spinner' viewBox='0 0 66 66'>
          <circle fill='none' stroke-width='6' stroke-linecap='round' cx='33' cy='33' r='30'></circle>
        </svg>
          <span class="sr-only">{{ 'additional-signatures.loadingIndicatorText' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="ontario-padding-bottom-24-!">
    <h2 class="ontario-h2">{{'additional-signatures.subtitle' | translate}}</h2>
  </div>
  <div class="mt-3 mb-4" aria-label="description" id="additional-signatures.instruction1">{{'additional-signatures.instruction1' | translate}}<br><br>
    {{'additional-signatures.instruction2' | translate}}
    <sd-hint-text id="additional-signatures.instruction3.hintText"
                  text="additional-signatures.instruction3.hintText"
                  textContent="additional-signatures.instruction3.hintContent"
                  expandableContent="additional-signatures.instruction3.hintContent">
    </sd-hint-text>
  </div>
  <div class="personContainer">
    <div *ngFor="let person of dependentAdults; let ind = index">
      <div class="ontario-padding-bottom-40-!" id="signerName`${{ind}}`">
        <div class="ontario-h3">
          <div *ngIf="person.type == 'Spouse'">{{'additional-signatures.applicantTypeSpouse' | translate}}{{person.name}}</div>
          <div *ngIf="person.type == 'Adult child'">{{'additional-signatures.applicantTypeAdultChild' | translate}}{{person.name}}</div>
        </div>
        <div>{{'additional-signatures.RequestSentTo' | translate}}<strong id="signerEmail`${{ind}}`">{{person.email}}</strong></div></div>
      <div *ngIf="ind < dependentAdults.length - 1" class="divider mb-5" aria-hidden="true"></div>
    </div>
  </div>

  <div class="ontario-padding-top-42-!"></div>
  <app-mccss-submit-button dataE2E="continueBtn" buttonLabel="additional-signatures.pleaseSubmitNow" [preventDoubleClick]="true" (handleClick)="onSubmit()" [disabled]="isLoading"></app-mccss-submit-button>
</sd-page-container>
