import {Directive, HostListener} from '@angular/core';
import {MatLegacyAutocompleteTrigger as MatAutocompleteTrigger} from '@angular/material/legacy-autocomplete';

@Directive({
  selector: '[autocompletePosition]',
})
export class AutocompletePositionDirective {
  @HostListener('input')  // Triggering when the value of the input field is changed.
  onInput() {
    this.adjustPosition();
  }

  public constructor(private readonly matAutocompleteTrigger: MatAutocompleteTrigger) {
  }

  /**
   * Instruct Material Autocomplete to re-position its predictive-text list.
   */
  private adjustPosition = (): void => {
    if (this.matAutocompleteTrigger == null) {
      return;
    }
    if (this.matAutocompleteTrigger.panelOpen) {
      setTimeout(() => {  // Pause for the change-detection cycle of other elements.
        this.matAutocompleteTrigger.updatePosition();
      }, 500);
    }
  }
}
