import {AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PageBaseComponent} from '../PageBaseComponent';
import {Question} from '../../common/utils/questions/question';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../external.router';
import {HouseHoldIncomeQuestionService} from './service/household-income-question.service';
import {QuestionControlService} from '../../common/utils/questions/question-control.service';
import {AppDataToHHIQuestionsConverter, HHIFormToAppDataConverter} from './converter/household-income-data-mapping';
import {ConfigService} from '../../services/config.service';
import {PageScrollingUtil} from '../../utils/page-scrolling-util';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-household-income',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './household-income.component.html',
  styleUrls: ['./household-income.component.scss']
})
export class HouseholdIncomeComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  showError = false;
  selectAnOptionError = false;
  questions: Question<string>[];

  constructor(private fb: FormBuilder, private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public externalRouter: ExternalRouter,
              private qcs: QuestionControlService,
              private questionService: HouseHoldIncomeQuestionService,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.householdIncome;
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    this.initializeForm();

    this.questions = this.questionService.getQuestions();
    // this.applicationAnswers = this.translatePipe.transform(this.applicationAnswers, this.translator.currentLang ||
    //   this.translator.defaultLang);
    // Create house hold income questions and populate with data if available
    new AppDataToHHIQuestionsConverter().convert(this.applicationAnswers, this.questions);

    // generate form controls based on questions
    this.form  = this.qcs.toFormGroup(this.questions);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  postInitializeForm() {
  }

  preSaveApplication() {
  }

  onSubmit(toContinue: boolean) {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;
    this.selectAnOptionError = false;

    if ((this.form.valid && this.form.get('NoneOfAbove').disabled) || this.form.get('NoneOfAbove').value[0]) {
      // Convert form data values to application answers
      new HHIFormToAppDataConverter().convert(this.form, this.applicationAnswers, this.questions);
      this.buttonContinueClick = true

      this.subscriptions$.push(this.saveToIntake()?.pipe(distinctUntilChanged()).subscribe(() => {
        if (toContinue) {
          this.router.navigate(['/', 'intake', PageInfo.financialAssets])
        } else {
          this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
        }
      }));
    } else {
      this.showError = true;
      this.selectAnOptionError = this.selectOneOption(this.form)

      if (!toContinue) {
        this.scrollToInvalidFormControl(toContinue)
      } else {
        if (this.selectAnOptionError) {
          PageScrollingUtil.scrollToElement(PageScrollingUtil.locateElement('sd-dynamic-question'))
        } else {
          this.scrollToInvalidFormControl(toContinue);
        }
      }
    }
  }

  selectOneOption(form: FormGroup): boolean {
    return this.questionService.questionKeys.filter(qKey => form.get(qKey).value[0]).length === 0;
  }
}
