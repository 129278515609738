import { Injectable } from '@angular/core';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageServiceImpl implements StorageService {

  constructor() { }

  saveAuthToken(authToken: string) {
    localStorage.setItem('authToken', authToken)
  }

  getAuthToken() {
    return localStorage.getItem('authToken')
  }

  removeAuthToken() {
    localStorage.removeItem('authToken')
  }
}
