import {PhoneNumberUtil} from 'google-libphonenumber';
import {AbstractControl, ValidatorFn} from '@angular/forms';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

/**
 * validate phone number using Google phone number library.
 * @param regionCode
 */
export function phoneNumberValidator(regionCode: string): ValidatorFn {

  return (control: AbstractControl): {  [key: string]: any } | null => {
    let validNumber = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        control.value, regionCode
      );
      validNumber = phoneNumberUtil.isValidNumberForRegion(phoneNumber, regionCode);
    } catch (e) { }

    return validNumber ? null : { invalidNumber: control.value };
  };
}
