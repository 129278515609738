import { Component, OnInit } from '@angular/core';
import {PageInfo} from '../../../models/page-map';

@Component({
  selector: 'app-wda-parent',
  templateUrl: './wda-parent.component.html',
  styleUrls: ['./wda-parent.component.scss']
})
export class WdaParentComponent implements OnInit {
  pageId: string;

  constructor() {
    this.pageId = PageInfo.wda;
  }

  ngOnInit(): void {
  }

}
