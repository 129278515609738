import {Injectable} from '@angular/core';
import {IErrorService} from '@microsoft/applicationinsights-angularplugin-js';

import {LoggingService} from './logging.service';

@Injectable()
export class ErrorHandlerService implements IErrorService {
  constructor(private loggingService: LoggingService) {
  }

  handleError(error: Error) {
    if (this.loggingService.enabled) {
      this.loggingService.logException(error); // Manually log exception
    } else {
      throw error;
    }
  }
}
