export interface EarnedIncomeSourceList {
  earnedIncomeSourceList?: IncomeSource[];
  applicantName: string;
  applicantType: string;
}

export interface IncomeSource {
  employerName?: string;
  businessName?: string;
  programName?: string;
  earnedIncomeType: string;
  earnedIncomePay: string;
  continueToReceiveThisIncome: boolean;
  disabilityWorkExpense?: string;
  disabilityWorkExpenseMonthlyAmount?: string;
}

export interface ChildCare {
  childCareExpenseType?: string;
  childCareExpenseAmount?: string;
  careProviderName?: string;
  careRecipientName?: string;
  applicantType: string;
}

export enum IncomeQuestionsFormNames {
  INCOME_TYPE = 'earnedIncomeType',
  EMPLOYER_NAME = 'employerName',
  BUSINESS_NAME = 'businessName',
  PROGRAM_NAME = 'programName',
  PAY = 'earnedIncomePay',
  CONTINUE_RECEIVE_INCOME = 'continueToReceiveThisIncome',
  DATE_OF_FIRST_PAY = 'dateOfFirstPay',
  DISABILITY_WORK_EXPENSE = 'disabilityWorkExpense',
  DISABILITY_WORK_EXPENSE_MONTHLY_AMOUNT = 'disabilityWorkExpenseMonthlyAmount'
}

export enum ChildCareFormNames {
  EXPENSE_TYPE = 'childCareExpenseType',
  CARE_PROVIDER_NAME = 'careProviderName',
  EXPENSE_AMOUNT = 'childCareExpenseAmount',
}
