<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <form [formGroup]="form" sdDisableAutocomplete>
    <div class="form-group required">
      <sd-text-question [showError]="showError" [id]="'spouse-information.spouseFirstName'" [required]=true
                        [validationFns]="firstNameValidationFunction" [capitalizeFirstLetter]=true
                        [minLength]=1 [maxLength]=65
                        [characterWidth]= 20 [truncateMultipleSpaces]="true"
                        [hintText]="'spouse-information.spouseFirstName.hintText'"
                        controlName="spouseFirstName">
      </sd-text-question>

      <sd-text-question [showError]="showError" [id]="'spouse-information.spouseLastName'"
                        [required]="!isNoLastNameSelected()"
                        [validationFns]="lastNameValidationFunction" [capitalizeFirstLetter]=true
                        [minLength]=1 [maxLength]=65
                        [characterWidth]= 20 [truncateMultipleSpaces]="true"
                        [hintText]="'spouse-information.spouseLastName.hintText'"
                        controlName="spouseLastName">
      </sd-text-question>

      <sd-checkbox-question [id]="'spouse-information.spouseNoLastName'" [options]="noLastNameCheckBoxItems"
                            [showLabel]=false
                            (toggle)="onNoLastNameCheckBoxChanges($event)"
                            >
      </sd-checkbox-question>

      <sd-date [showError]="showError"
               id="spouse-information-spouseDateOfBirth"
               label="spouse-information.spouseDateOfBirth"
               controlNameDate="spouseDateOfBirth"
               [validationFns]="dobValidationFunctions"
               [needMarginTop] = true
               [required]=true>
      </sd-date>

      <sd-radio-question [choices]="sexes" [showError]="showError" [id]="'spouse-information.spouseSexAtBirth'"
                         [required]=true
                         [hintText]="'spouse-information.spouseSexAtBirth.hintText'"
                         [context]="'spouse-information.spouseSexAtBirth.context'"
                         controlName="spouseSexAtBirth">
      </sd-radio-question>

      <sd-text-question [showError]="showError" [id]="'spouse-information.spouseEmail'"
                        [required]="isEmailRequired()"
                        [showCustomRequiredErrorMessage]="customRequiredErrorMessage"
                        [validationFns]="emailValidationFunctions"
                        [asyncValidationFns]="emailAsyncValidationFns"
                        [characterWidth]=20
                        (keydown.enter)="preventDefaultAction($event)"
                        [hintText]="authorizeService.isIBauUser() ? 'emailHintWithDesc' : 'emailHint'"
                        controlName="spouseEmail">
      </sd-text-question>

      <sd-checkbox-question *ngIf="authorizeService.isAuthorized()" [id]="'spouse-information.spouseNoUniqueEmail'" [options]="noUniqueEmailCheckBoxItem"
                            [showLabel]=false
                            (toggle)="onNoUniqueEmailCheckBoxChanges($event)">
      </sd-checkbox-question>

      <sd-status-in-canada-questions [id]="'spouse-information.status'"
                                     [applicationAnswers]="applicationAnswers"
                                     [labelParam]="{firstName: spouseFirstName, lastName: spouseLastName}"
                                     [applicantType]="applicantType" [showError]="showError"
                                     [hintText]="'true'" [hintContent]="'true'"
                                     [hintContentParam]="officeLocationLinkParam">
      </sd-status-in-canada-questions>
    </div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
