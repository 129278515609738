import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {
  loading: BehaviorSubject<boolean>;
  altText: boolean;

  constructor() {
    this.loading = new BehaviorSubject<boolean>(false);
    this.altText = false;
  }

  show(altText = false) {
    this.loading.next(true);
    if (altText) {
      this.altText = true
    } else {
      this.altText = false
    }
  }

  hide() {
    this.loading.next(false);
  }
}
