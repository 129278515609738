import {SadaCustomValidator} from '../../../validator/sada-custom-validator';
import {ApplicantType} from '../../../models/applicant-type';
import {StatusNotEligibleForSupport} from '../../utils/questions/question-choices';
import {AbstractControl} from '@angular/forms';
import moment from 'moment';

export class StatusInCanadaValidationUtil {
  static getSINFieldValidationFns(applicationAnswers: any, applicantType: ApplicantType, parentFormGroups: AbstractControl[]) {
    return [
      {
        validationFunction: (value: []) => {
          if (ApplicantType.APPLICANT === applicantType) {
            const spouseSINNo = applicationAnswers.jsonData?.spouseSocialInsuranceNumber ?
              applicationAnswers.jsonData.spouseSocialInsuranceNumber : null;
            const childList = applicationAnswers.jsonData?.childList ? applicationAnswers.jsonData.childList : [];
            return SadaCustomValidator.validateUniqueApplicantSIN([...value, spouseSINNo, childList]);
          } else if (ApplicantType.SPOUSE === applicantType) {
            const applicantSINNo = applicationAnswers.jsonData?.socialInsuranceNumber ?
              applicationAnswers.jsonData.socialInsuranceNumber : null;
            const childList = applicationAnswers.jsonData?.childList ? applicationAnswers.jsonData.childList : [];
            return SadaCustomValidator.validateUniqueSpouseSIN([...value, applicantSINNo, childList]);
          } else if (ApplicantType.CHILD === applicantType) {
            const applicantSINNo = applicationAnswers.jsonData?.socialInsuranceNumber ?
              applicationAnswers.jsonData.socialInsuranceNumber : null;
            const spouseSINNo = applicationAnswers.jsonData?.spouseSocialInsuranceNumber ?
              applicationAnswers.jsonData.spouseSocialInsuranceNumber : null;
            return SadaCustomValidator.validateUniqueChildSIN([...value, applicantSINNo, spouseSINNo, parentFormGroups]);
          } else {
            // TBD
          }
        },
        errorKey: 'status-in-canada.error.idNumberAlreadyUsed'
      },
    ]
  }

  static getCertificateOfIndianStatusNumberFieldValidationFns(applicationAnswers: any, applicantType: ApplicantType,
                                                              parentFormGroups: AbstractControl[]) {
    return [
      {
        validationFunction: (value: []) => {
          if (ApplicantType.APPLICANT === applicantType) {
            const spouseCISN = applicationAnswers.jsonData?.spouseCertificateOfIndianStatusNumber ?
              applicationAnswers.jsonData.spouseCertificateOfIndianStatusNumber : null;
            const childList = applicationAnswers.jsonData?.childList ? applicationAnswers.jsonData.childList : [];
            return SadaCustomValidator.validateUniqueApplicantCertificateOfIndianStatusNumber([...value, spouseCISN, childList]);
          } else if (ApplicantType.SPOUSE === applicantType) {
            const applicantCISN = applicationAnswers.jsonData?.certificateOfIndianStatusNumber ?
              applicationAnswers.jsonData.certificateOfIndianStatusNumber : null;
            const childList = applicationAnswers.jsonData?.childList ? applicationAnswers.jsonData.childList : [];
            return SadaCustomValidator.validateUniqueSpouseCertificateOfIndianStatusNumber([...value, applicantCISN, childList]);
          } else if (ApplicantType.CHILD === applicantType) {
            const applicantCISN = applicationAnswers.jsonData?.certificateOfIndianStatusNumber ?
              applicationAnswers.jsonData.certificateOfIndianStatusNumber : null;
            const spouseCISN = applicationAnswers.jsonData?.spouseCertificateOfIndianStatusNumber ?
              applicationAnswers.jsonData.spouseCertificateOfIndianStatusNumber : null;
            return SadaCustomValidator.validateUniqueChildCertificateOfIndianStatusNumber(
              [...value, applicantCISN, spouseCISN, parentFormGroups]);
          } else {
            // TBD
          }
        },
        errorKey: 'status-in-canada.error.idNumberAlreadyUsed'
      },
    ]
  }

  static getHNFieldValidationFns(applicationAnswers: any, applicantType: ApplicantType, parentFormGroups: AbstractControl[]) {
    return [
      {
        validationFunction: (value: []) => {
          if (ApplicantType.APPLICANT === applicantType) {
            const spouseHNNo = applicationAnswers.jsonData?.spouseHealthCardNumber ?
              applicationAnswers.jsonData.spouseHealthCardNumber : null;
            const childList = applicationAnswers.jsonData?.childList ? applicationAnswers.jsonData.childList : [];
            return SadaCustomValidator.validateUniqueApplicantHN([...value, spouseHNNo, childList]);
          } else if (ApplicantType.SPOUSE === applicantType) {
            const applicantHNNo = applicationAnswers.jsonData?.healthCardNumber ?
              applicationAnswers.jsonData.healthCardNumber : null;
            const childList = applicationAnswers.jsonData?.childList ? applicationAnswers.jsonData.childList : [];
            return SadaCustomValidator.validateUniqueSpouseHN([...value, applicantHNNo, childList]);
          } else if (ApplicantType.CHILD === applicantType) {
            const applicantHNNo = applicationAnswers.jsonData?.healthCardNumber ?
              applicationAnswers.jsonData.healthCardNumber : null;
            const spouseHNNo = applicationAnswers.jsonData?.spouseHealthCardNumber ?
              applicationAnswers.jsonData.spouseHealthCardNumber : null;
            return SadaCustomValidator.validateUniqueChildHN([...value, applicantHNNo, spouseHNNo, parentFormGroups]);
          } else {
            // TBD
          }
        },
        errorKey: 'status-in-canada.error.idNumberAlreadyUsed'
      },
    ]
  }

  static getUCIFieldValidationFns(applicationAnswers: any, applicantType: ApplicantType, parentFormGroups: AbstractControl[]) {
    return [
      {
        validationFunction: SadaCustomValidator.validateImmigrationFileNumber,
        errorKey: 'status-in-canada.error.invalidImmigrationFileNumber'
      },
      {
        validationFunction: SadaCustomValidator.validateUCIPattern,
        errorKey: 'status-in-canada.error.invalidUCIPattern'
      },
      {
        validationFunction: (value: []) => {
          if (ApplicantType.APPLICANT === applicantType) {
            const spouseUCINo = applicationAnswers.jsonData?.spouseImmigrationFileNumber ?
              applicationAnswers.jsonData.spouseImmigrationFileNumber : null;
            const childList = applicationAnswers.jsonData?.childList ? applicationAnswers.jsonData.childList : [];
            return SadaCustomValidator.validateUniqueApplicantUCI([...value, spouseUCINo, childList]);
          } else if (ApplicantType.SPOUSE === applicantType) {
            const applicantUCINo = applicationAnswers.jsonData?.immigrationFileNumber ?
              applicationAnswers.jsonData.immigrationFileNumber : null;
            const childList = applicationAnswers.jsonData?.childList ? applicationAnswers.jsonData.childList : [];
            return SadaCustomValidator.validateUniqueSpouseUCI([...value, applicantUCINo, childList]);
          } else if (ApplicantType.CHILD === applicantType) {
            const applicantUCINo = applicationAnswers.jsonData?.immigrationFileNumber ?
              applicationAnswers.jsonData.immigrationFileNumber : null;
            const spouseUCINo = applicationAnswers.jsonData?.spouseImmigrationFileNumber ?
              applicationAnswers.jsonData.spouseImmigrationFileNumber : null;
            return SadaCustomValidator.validateUniqueChildUCI([...value, applicantUCINo, spouseUCINo, parentFormGroups]);
          } else {
            // TBD
          }
        },
        errorKey: 'status-in-canada.error.idNumberAlreadyUsed'
      }
    ]
  }

  static getArrivalDateValidationFns(dateOfBirth: string) {
    return [
      {
      validationFunction: (value: []) => {
          return SadaCustomValidator.validateDateWithMinAndFuture([...value, dateOfBirth])
      }, errorKey: 'status-in-canada.error.invalidArrivalDate'
    }];
  }

  static getStatusInCanadaValidationFns(applicantType: ApplicantType) {
    if (ApplicantType.APPLICANT === applicantType) {
      return [{
        validationFunction: (value: []) => {
          return !StatusNotEligibleForSupport.includes(value.pop())
        }, errorKey: 'status-in-canada.error.notEligibleForAssistance'
      }]
    } else {
      return [];
    }
  }

  static validateArrivalDateToCanadaForGovernmentRefugee(hasDisability: any, arrivalDateToCanadaControl: any,
                                                         statusInCanadaControl: any,
                                                         resettlementAssistanceProgramLinkParam: any) {
    if (hasDisability === 'yes' && statusInCanadaControl?.value === 'Government assisted refugee') {
      if (moment(arrivalDateToCanadaControl?.value).isBefore('2000/01/01')) {
        arrivalDateToCanadaControl?.setErrors({
          customErrorKey: 'error.invalid.date'
        });
        arrivalDateToCanadaControl?.markAsTouched();
        // arrivalDateToCanadaControl?.updateValueAndValidity();
      } else if (arrivalDateToCanadaControl?.hasError('customErrorKey') &&
        arrivalDateToCanadaControl?.getError('customErrorKey') === 'error.invalid.date') {
        arrivalDateToCanadaControl.setErrors(null)
      }
    } else {
      if (arrivalDateToCanadaControl?.value && statusInCanadaControl?.value === 'Government assisted refugee') {
        if (!SadaCustomValidator.isDateWithinPastMonths(moment(arrivalDateToCanadaControl.value, 'YYYY/MM'), 0, 12)) {
          if (arrivalDateToCanadaControl?.hasError('customErrorKey') &&
            arrivalDateToCanadaControl?.getError('customErrorKey') === 'error.invalid.arrival.status.in.canada') {
            arrivalDateToCanadaControl.setErrors(null)
          }
        } else {
          arrivalDateToCanadaControl.setErrors({
            customErrorKey: 'error.invalid.arrival.status.in.canada', customErrorParam: resettlementAssistanceProgramLinkParam
          });
          arrivalDateToCanadaControl.markAsTouched();
        }
      }
    }
  }

  static validateArrivalDateToCanadaWithDateOfBirth(dateOfBirthValueControl: any, arrivalDateToCanadaControl): boolean {
    if (!arrivalDateToCanadaControl?.value || !dateOfBirthValueControl.value){
      return true;
    }
    let isValid = false;
    if (SadaCustomValidator.validateDateWithMinAndFuture([arrivalDateToCanadaControl.value, dateOfBirthValueControl.value])) {
      if (arrivalDateToCanadaControl.hasError('customErrorKey') &&
        arrivalDateToCanadaControl.getError('customErrorKey') === 'status-in-canada.error.invalidArrivalDate') {
        arrivalDateToCanadaControl.setErrors(null);
      }
      isValid = true;
    } else {
      arrivalDateToCanadaControl.setErrors({customErrorKey: 'status-in-canada.error.invalidArrivalDate'})
      isValid = false;
    }

    return isValid;
  }
}
