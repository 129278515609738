import {HHIDataConversionConfig} from '../model/hhi-data-conversion-config';

export const standardHHIQuestionsMappingConfig: Map<string, HHIDataConversionConfig[]> = new Map([
    ['CPPQPPPaymentPanel', [
      {
        hasPaymentAttributeName: 'hasDisabilityPayment',
        recipientsListAttributeName: 'otherIncomeRecipientsDisability',
        incomeDetailAttributeName: 'anyOtherIncomeListDisability',
        questionKey: 'DisabilityPayment',
        questionPanelKey: 'CPPQPPPaymentPanel',
        incomeStateAttributeName: 'hasDisabilityIncomeState',
        incomeMonthlyAmountAttributeName: 'hasDisabilityIncomeMonthlyAmount'
      },
      {
        hasPaymentAttributeName: 'hasRetirementPayment',
        recipientsListAttributeName: 'otherIncomeRecipientsRetirement',
        incomeDetailAttributeName: 'anyOtherIncomeListRetirement',
        questionKey: 'RetirementPayment',
        questionPanelKey: 'CPPQPPPaymentPanel',
        incomeStateAttributeName: 'hasRetirementPaymentIncomeState',
        incomeMonthlyAmountAttributeName: 'hasRetirementPaymentIncomeMonthlyAmount'
      },
      {
        hasPaymentAttributeName: 'hasSurvivingSpousePayment',
        recipientsListAttributeName: 'otherIncomeRecipientsSurvivingSpousePayment',
        incomeDetailAttributeName: 'anyOtherIncomeListSurvivingSpousePay',
        questionKey: 'SurvivingSpousePensionPayment',
        questionPanelKey: 'CPPQPPPaymentPanel',
        incomeStateAttributeName: 'hasSurvivingSpousePaymentIncomeState',
        incomeMonthlyAmountAttributeName: 'hasSurvivingSpousePaymentIncomeMonthlyAmount'
      }
    ]],
    ['WSIBPaymentPanel', [
      {
        questionKey: 'LossOfIncomePayment',
        questionPanelKey: 'WSIBPaymentPanel',
        hasPaymentAttributeName: 'hasWsibLossOfIncome',
        recipientsListAttributeName: 'otherIncomeRecipientsWsibLossOfIncome',
        incomeDetailAttributeName: 'anyOtherIncomeListWsibLossOfIncome',
        incomeStateAttributeName: 'wsibLossOfIncomeReceiving',
        incomeMonthlyAmountAttributeName: 'wsibLossOfIncomeMonthlyAmount'
      },
      {
        questionKey: 'OtherPayment',
        questionPanelKey: 'WSIBPaymentPanel',
        hasPaymentAttributeName: 'hasWsibOther',
        recipientsListAttributeName: 'otherIncomeRecipientsWsibOther',
        incomeDetailAttributeName: 'anyOtherIncomeListWsibOther',
        incomeStateAttributeName: 'wsibOtherReceiving',
        incomeMonthlyAmountAttributeName: 'wsibOtherMonthlyAmount'
      }
    ]],
    ['InsurancePaymentPanel', [
      {
        questionKey: 'InsurancePayment',
        questionPanelKey: 'InsurancePaymentPanel',
        hasPaymentAttributeName: 'hasInsurancePayment',
        recipientsListAttributeName: 'otherIncomeRecipientsInsurancePay',
        incomeDetailAttributeName: 'anyOtherIncomeListInsurancePay',
        incomeStateAttributeName: 'hasInsurancePaymentIncomeState',
        incomeMonthlyAmountAttributeName: 'hasInsurancePaymentIncomeMonthlyAmount'
      }
    ]],
    ['GovernmentPaymentPanel', [
      {
        questionKey: 'OldAgeSecurity',
        hasPaymentAttributeName: 'hasOldAgeSecurity',
        recipientsListAttributeName: 'otherIncomeRecipientsOldAgeSecurity',
        incomeDetailAttributeName: 'anyOtherIncomeListOldAgeSecurity',
        incomeStateAttributeName: 'hasOldAgeSecurityIncomeState',
        incomeMonthlyAmountAttributeName: 'hasOldAgeSecurityIncomeMonthlyAmount',
        questionPanelKey: 'GovernmentPaymentPanel'
      },
      {
        questionKey: 'GuaranteedIncomeSupplement',
        questionPanelKey: 'GovernmentPaymentPanel',
        hasPaymentAttributeName: 'hasGuaranteedIncomeSupplement',
        recipientsListAttributeName: 'otherIncomeRecipientsGuaranteedIncomeSupplement',
        incomeDetailAttributeName: 'anyOtherIncomeListGuaranteedIncomeSupplement',
        incomeStateAttributeName: 'hasGuaranteedIncomeSupplementIncomeState',
        incomeMonthlyAmountAttributeName: 'hasGuaranteedIncomeSupplementIncomeMonthlyAmount'
      },
      {
        hasPaymentAttributeName: 'hasSpousalAllowance',
        recipientsListAttributeName: 'otherIncomeRecipientsSpousalAllowance',
        incomeDetailAttributeName: 'anyOtherIncomeListSpousalAllowance',
        questionKey: 'SpousalAllowance',
        questionPanelKey: 'GovernmentPaymentPanel',
        incomeStateAttributeName: 'hasSpousalAllowanceIncomeState',
        incomeMonthlyAmountAttributeName: 'hasSpousalAllowanceIncomeMonthlyAmount'
      },
      {
        hasPaymentAttributeName: 'hasSurvivorAllowanceProgram',
        recipientsListAttributeName: 'otherIncomeRecipientsSurvivorAllowanceProgram',
        incomeDetailAttributeName: 'anyOtherIncomeListSurvivorAllowanceProgram',
        questionKey: 'SurvivorAllowanceProgram',
        questionPanelKey: 'GovernmentPaymentPanel',
        incomeStateAttributeName: 'hasSurvivorAllowanceProgramIncomeState',
        incomeMonthlyAmountAttributeName: 'hasSurvivorAllowanceProgramIncomeMonthlyAmount'
      }
    ]],
    ['GainsPaymentPanel', [
      {
        questionKey: 'GainsPayment',
        questionPanelKey: 'GainsPaymentPanel',
        hasPaymentAttributeName: 'hasGainsPayment',
        recipientsListAttributeName: 'otherIncomeRecipientsGainsPayment',
        incomeDetailAttributeName: 'anyOtherIncomeListGainsPayment',
        incomeStateAttributeName: 'hasGainsPaymentIncomeState',
        incomeMonthlyAmountAttributeName: 'hasGainsPaymentIncomeMonthlyAmount'
      }
    ]],
    ['EIPaymentPanel', [
      {
        questionKey: 'EIPayment',
        questionPanelKey: 'EIPaymentPanel',
        hasPaymentAttributeName: 'hasEIPayment',
        recipientsListAttributeName: 'otherIncomeRecipientsEIPayment',
        incomeDetailAttributeName: 'anyOtherIncomeListEIPayment',
        incomeStateAttributeName: 'hasEIPaymentIncomeState',
        incomeMonthlyAmountAttributeName: 'hasEIPaymentIncomeMonthlyAmount'
      }
    ]],
    ['CRBPaymentPanel', [
      {
        questionKey: 'CRBPayment',
        questionPanelKey: 'CRBPaymentPanel',
        hasPaymentAttributeName: 'hasCanadaRecoveryBenefit',
        recipientsListAttributeName: 'otherIncomeRecipientsCanadaRecoveryBenefit',
        incomeDetailAttributeName: 'anyOtherIncomeListCanadaRecoveryBenefit',
        incomeStateAttributeName: 'hasCanadaRecoveryBenefitReceiving',
        incomeMonthlyAmountAttributeName: 'hasCanadaRecoveryBenefitMonthlyAmount'
      },
      {
        questionKey: 'CRSBPayment',
        questionPanelKey: 'CRBPaymentPanel',
        hasPaymentAttributeName: 'hasCanadaRecoverySicknessBenefit',
        recipientsListAttributeName: 'otherIncomeRecipientsCanadaRecoverySicknessBenefit',
        incomeDetailAttributeName: 'anyOtherIncomeListCanadaRecoverySicknessBenefit',
        incomeStateAttributeName: 'hasCanadaRecoverySicknessBenefitReceiving',
        incomeMonthlyAmountAttributeName: 'hasCanadaRecoverySicknessBenefitMonthlyAmount'
      },
      {
        questionKey: 'CRCBPayment',
        questionPanelKey: 'CRBPaymentPanel',
        hasPaymentAttributeName: 'hasCanadaRecoveryCaregivingBenefit',
        recipientsListAttributeName: 'otherIncomeRecipientsCanadaRecoveryCaregivingBenefit',
        incomeDetailAttributeName: 'anyOtherIncomeListCanadaRecoveryCaregivingBenefit',
        incomeStateAttributeName: 'hasCanadaRecoveryCaregivingBenefitReceiving',
        incomeMonthlyAmountAttributeName: 'hasCanadaRecoveryCaregivingBenefitMonthlyAmount'
      }
    ]],
    ['StudentLoanPaymentPanel', [
      {
        questionKey: 'OSAPPayment',
        questionPanelKey: 'StudentLoanPaymentPanel',
        hasPaymentAttributeName: 'hasStudentLoansGrantsOSAP',
        recipientsListAttributeName: 'otherIncomeRecipientsStudentLoansGrantsOSAP',
        incomeDetailAttributeName: 'anyOtherIncomeListStudentLoansGrantsOSAP',
        incomeStateAttributeName: 'studentLoansGrantsOSAPReceiving',
        incomeMonthlyAmountAttributeName: 'studentLoansGrantsOSAPReceivingMonthlyAmount'
      },
      {
        questionKey: 'OtherStudentLoanPayment',
        questionPanelKey: 'StudentLoanPaymentPanel',
        hasPaymentAttributeName: 'hasStudentLoansGrantsOther',
        recipientsListAttributeName: 'otherIncomeRecipientsStudentLoansGrantsOther',
        incomeDetailAttributeName: 'anyOtherIncomeListStudentLoansGrantsOther',
        incomeStateAttributeName: 'studentLoansGrantsOtherReceiving',
        incomeMonthlyAmountAttributeName: 'studentLoansGrantsOtherMonthlyAmount'
      }
    ]],
  ]);

export const otherIncomeQuestionsMappingConfig: HHIDataConversionConfig = {
  questionKey: 'OtherIncome',
  questionPanelKey: 'OtherIncomePanel',
  hasPaymentAttributeName: 'hasOtherPayments',
  recipientsListAttributeName: 'otherIncomeRecipientsOtherPayments',
  incomeDetailAttributeName: 'anyOtherIncomeList',
};
