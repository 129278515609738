export class Person {
  personId: string
  names: string[]
  eidProgress: EidProgress = undefined
}

export enum EidProgress {
  completed = 'COMPLETED',
  skipped = 'SKIPPED',
  unavailable = 'UNAVAILABLE'
}
