import {Injectable} from '@angular/core';
import {SessionStorageService} from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageServiceImpl implements SessionStorageService {

  private static readonly STARTED_TIME_KEY = 'started_ts';

  constructor() {}

  getStartedTime() {
    return sessionStorage.getItem(SessionStorageServiceImpl.STARTED_TIME_KEY)
  }

  saveStartedTime() {
    sessionStorage.setItem(SessionStorageServiceImpl.STARTED_TIME_KEY, new Date().getTime().toString())
  }
}
