import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {HHIDataConversionConfig} from '../model/hhi-data-conversion-config';
import {HouseholdIncomeQuestionHelper} from '../service/household-income-question.helper';
import {BaseAppDataToHHIQuestions} from './base-app-data-to-hhi-questions';
import {addDynamicPanels} from '../../../common/utils/dynamic-panel/dynamic-panel.util';

export class AppDataToHHIOtherIncomeQuestions extends BaseAppDataToHHIQuestions {

  convert(appData: ApplicationAnswers, question: Question<string>, dataConversionConfig: HHIDataConversionConfig): void {

    this.populatePaymentCheckBoxValue(appData, question, dataConversionConfig)
    this.addSingleOrFamilyOptions(appData, question, dataConversionConfig)

    // Add dynamic panels for applicant, spouse and children
    const index = question.panels[0].questions.findIndex(
      q => q.key === dataConversionConfig.questionKey.concat('WhoGetsThePayment'));
    addDynamicPanels(dataConversionConfig.questionKey,
      dataConversionConfig.questionKey.concat('WhoGetsThePayment'), index, question.panels[0].questions,
      this.populateIncomeDetailQuestions(appData, question.panels[0].questions, dataConversionConfig),
      [
        {
          questionKey: dataConversionConfig.questionKey,
          questionControlType: 'checkbox',
          value: [true],
          index: 0
        }
      ], {
        showAddButton: true,
        addButton: 'household-income.add-other-income',
        removeButton: 'household-income.remove-other-income',
        indented: true,
        updateValidityOnRemoval: true,
        showLineSeparator: true
      });
  }

  populateIncomeDetailQuestions(appData: ApplicationAnswers, questions: Question<string>[],
                                dataConversionConfig: HHIDataConversionConfig): Map<string, Map<number, Question<string>[]>> {

    const map: Map<string, Map<number, Question<string>[]>> = new Map();
    const question = questions.find(q => q.key === dataConversionConfig.questionKey.concat('WhoGetsThePayment'))

    question.options.forEach(o => {
      const applicantValue = appData.jsonData[dataConversionConfig.incomeDetailAttributeName]?.find(
        a => a.applicant === o.alternateValue);
      const panelMap: Map<number, Question<string>[]> = new Map();

      for (let i = 0; i < applicantValue?.otherIncomeOtherPaymentList.length; i++) {
        let incomeDetailQuestions;
        if (o.alternateValue.startsWith('Applicant')) {
          incomeDetailQuestions = [...HouseholdIncomeQuestionHelper.getIncomeDetailQuestions([o.labelParam?.name],'household-income.applicant',true)];
        } else if (o.alternateValue.startsWith('Spouse')) {
          incomeDetailQuestions = [...HouseholdIncomeQuestionHelper.getIncomeDetailQuestions([o.labelParam?.name],'household-income.spouse',true)];
        } else if (o.alternateValue.startsWith('Child')) {
          incomeDetailQuestions = [...HouseholdIncomeQuestionHelper.getIncomeDetailQuestions([o.labelParam.index, o.labelParam?.name],'household-income.child',true)];
        }
        if (applicantValue) {
          incomeDetailQuestions[0].value = applicantValue.otherIncomeOtherPaymentList[i].otherIncomeType;
          incomeDetailQuestions[1].value = applicantValue.otherIncomeOtherPaymentList[i].otherIncomeType === 'My income type is not listed here'
            ? applicantValue.otherIncomeOtherPaymentList[i].otherIncomeDescription : '';
          incomeDetailQuestions[2].value = applicantValue.otherIncomeOtherPaymentList[i].otherIncomeSourceReceiving;
          if (applicantValue.otherIncomeOtherPaymentList[i].otherIncomeSourceMonthlyAmount) {
            incomeDetailQuestions[3].value = applicantValue.otherIncomeOtherPaymentList[i].otherIncomeSourceMonthlyAmount;
          }

          if (applicantValue.otherIncomeOtherPaymentList[i].otherIncomeSourceAnnualAmount) {
            incomeDetailQuestions[4].value = applicantValue.otherIncomeOtherPaymentList[i].otherIncomeSourceAnnualAmount;
          }

          if (applicantValue.otherIncomeOtherPaymentList[i].otherIncomeValueOfPension) {
            incomeDetailQuestions[5].value = applicantValue.otherIncomeOtherPaymentList[i].otherIncomeValueOfPension;
          }
        }
        panelMap.set(i, incomeDetailQuestions);
      }

      if (panelMap.size === 0) {
        let incomeDetailQuestions;
        if (o.alternateValue.startsWith('Applicant')) {
          incomeDetailQuestions = [...HouseholdIncomeQuestionHelper.getIncomeDetailQuestions([o.labelParam?.name],'household-income.applicant',true)];
        } else if (o.alternateValue.startsWith('Spouse')) {
          incomeDetailQuestions = [...HouseholdIncomeQuestionHelper.getIncomeDetailQuestions([o.labelParam?.name],'household-income.spouse',true)];
        } else if (o.alternateValue.startsWith('Child')) {
          incomeDetailQuestions = [...HouseholdIncomeQuestionHelper.getIncomeDetailQuestions([o.labelParam.index, o.labelParam?.name],'household-income.child',true)];
        }
        panelMap.set(0, incomeDetailQuestions);
      }
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }
}
