import {Converter} from '../../../common/utils/converters/converter';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';

export class AppDataToHHINoneQuestion implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    if (appData.jsonData.noOtherIncomeType === 'yes') {
      question.options[0].value = true;
    }
  }
}
