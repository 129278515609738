import {ApplicantType} from '../models/applicant-type';

export class NameUpdateUtil {
  static updateFirstNameInSavedData(updatedFirstName: string, jsonData: any, localeId: string, applicantType: ApplicantType,
        elementIndex: number) {
    let oldFirstName: string
    if (ApplicantType.APPLICANT === applicantType) {
      oldFirstName = jsonData.firstName;
    } else if(ApplicantType.SPOUSE === applicantType) {
      oldFirstName = jsonData.spouseFirstName;
    } else if(ApplicantType.CHILD === applicantType) {
      oldFirstName = jsonData.childList[elementIndex].childFirstName;
    }

    if (oldFirstName !== updatedFirstName) {
      const oldApplicant = this.jsonDataApplicant(applicantType, oldFirstName, elementIndex);
      const newApplicant = this.jsonDataApplicant(applicantType, updatedFirstName, elementIndex);
      AttributesWithName.forEach(attr => {
        const values: string[] = jsonData[attr];
        if (values) {
          jsonData[attr].forEach((value, index) => {
            if (value === oldApplicant) {
              values.splice(index, 1);
              values.push(newApplicant);
            }
          });
          jsonData[attr] = values;
        }
      })

      AttributesWithNameInObjs.forEach(attr => {
        const objs: [] = jsonData[attr];
        if (objs) {
          objs.forEach((obj: any) => {
            if (obj.applicant && obj.applicant === oldApplicant) {
              obj.applicant = newApplicant;
              obj.applicantName = updatedFirstName;
              if (obj.applicantDisplayType) {
                obj.applicantDisplayType = this.jsonDataApplicantDisplayType(localeId, applicantType, updatedFirstName, elementIndex);
              }
            }
          })
        }
      })
    }
  }

  /**
   * Returns the applicant for the JSON Data.
   */
  static jsonDataApplicant(applicantType: ApplicantType, firstName: string, elementIndex: number) {  // Not language dependent
    if (ApplicantType.APPLICANT === applicantType) {
      return `Applicant: ${firstName}`;
    } else if(ApplicantType.SPOUSE === applicantType) {
      return `Spouse: ${firstName}`;
    } else if(ApplicantType.CHILD === applicantType) {
      return `Child ${elementIndex + 1}: ${firstName}`;
    }
  }

  /**
   * Returns the applicantDisplayType for the JSON Data.
   */
  static jsonDataApplicantDisplayType(localeId: string, applicantType: ApplicantType, firstName: string, elementIndex: number) {
    if (ApplicantType.APPLICANT === applicantType) {
      return localeId === 'fr' ? `Demandeur` : `Applicant`;
    } else if(ApplicantType.SPOUSE === applicantType) {
      return localeId === 'fr' ? `Conjoint` : `Spouse`;
    } else if(ApplicantType.CHILD === applicantType) {
      return localeId === 'fr' ? `Enfant: ${elementIndex + 1}` : `Child ${elementIndex + 1}`;
    }
  }
}

export const AttributesWithNameInObjs = [
  'anyEarnedIncomeList',
  'anyEarnedIncomeListPartTime',
  'anyEarnedIncomeListSelfEmpl',
  'anyEarnedIncomeListTraining',
  'anyOtherIncomeListDisability',
  'anyOtherIncomeListRetirement',
  'anyOtherIncomeListSurvivingSpousePay',
  'anyOtherIncomeListWsibLossOfIncome',
  'anyOtherIncomeListWsibOther',
  'anyOtherIncomeListInsurancePay',
  'anyOtherIncomeListOldAgeSecurity',
  'anyOtherIncomeListGainsPayment',
  'anyOtherIncomeListEIPayment',
  'anyOtherIncomeListCanadaRecoveryBenefit',
  'anyOtherIncomeListCanadaRecoverySicknessBenefit',
  'anyOtherIncomeListCanadaRecoveryCaregivingBenefit',
  'anyOtherIncomeListStudentLoansGrantsOSAP',
  'anyOtherIncomeListStudentLoansGrantsOther',
  'anyOtherIncomeList',
  'ownItemsOfValueDetailsListCash',
  'ownItemsOfValueDetailsListBankAccounts',
  'ownItemsOfValueDetailsListInvestment',
  'ownItemsOfValueDetailsListVehicle',
  'ownItemsOfValueDetailsListTrustFundOpgt',
  'ownItemsOfValueDetailsListTrustFundOther',
  'ownItemsOfValueDetailsListOtherAssets'
]

export const AttributesWithName = [
  'earnedIncomeRecipients',
  'earnedIncomeRecipientsPartTime',
  'earnedIncomeRecipientsSelfEmployed',
  'earnedIncomeRecipientsTraining',
  'otherIncomeRecipientsDisability',
  'otherIncomeRecipientsRetirement',
  'otherIncomeRecipientsSurvivingSpousePayment',
  'otherIncomeRecipientsWsibLossOfIncome',
  'otherIncomeRecipientsWsibOther',
  'otherIncomeRecipientsInsurancePay',
  'otherIncomeRecipientsOldAgeSecurity',
  'otherIncomeRecipientsGainsPayment',
  'otherIncomeRecipientsEIPayment',
  'otherIncomeRecipientsCanadaRecoveryBenefit',
  'otherIncomeRecipientsCanadaRecoverySicknessBenefit',
  'otherIncomeRecipientsCanadaRecoveryCaregivingBenefit',
  'otherIncomeRecipientsStudentLoansGrantsOSAP',
  'otherIncomeRecipientsStudentLoansGrantsOther',
  'otherIncomeRecipientsOtherPayments',
  'ownItemsOfValueApplicantCheckboxCash',
  'ownItemsOfValueApplicantCheckboxBankAccounts',
  'ownItemsOfValueApplicantCheckboxInvestment',
  'ownItemsOfValueApplicantCheckboxVehicle',
  'ownItemsOfValueApplicantCheckboxTrustFundOpgt',
  'ownItemsOfValueApplicantCheckboxTrustFundOther',
  'ownItemsOfValueApplicantCheckboxOtherAssets'
]
