import {Converter} from 'src/app/common/utils/converters/converter';
import {Question} from 'src/app/common/utils/questions/question';
import {ApplicationAnswers} from 'src/app/models/data.model';
import {Injectable} from '@angular/core';

@Injectable()
export class AppDataToAdditionalInfoQuestions implements Converter<ApplicationAnswers, Question<string>[]> {

  convert(appData: ApplicationAnswers, questions: Question<string>[]): void {
    // update questions based on app data
    questions.forEach(question => {
      if (question.controlType === 'panel') {
        this.setPanelFieldValue(question.key, appData, question)
      } else {
        this.setFieldValue(question.key, appData, question)
      }
    });
  }

  setPanelFieldValue(panel: string, appData: ApplicationAnswers, question: Question<string>) {
    question.panels[0]?.questions.forEach(q => {
      this.setFieldValue(q.key, appData, q)
    })
  }

  setFieldValue(qKey: string, appData: ApplicationAnswers, question: Question<string>): void {
    if (question.controlType === 'checkbox' && appData.jsonData[qKey] && appData.jsonData[qKey][0]) {
      question.options[0].value = appData.jsonData[qKey];
    } else if (appData.jsonData[qKey]) {
      question.value = appData.jsonData[qKey];
    }
  }
}
