import {AbstractControl, ControlContainer, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Component, Input} from '@angular/core';

@Component({
  selector: '',
  template: '',
  styles: []
})
export abstract class QuestionBaseComponent {
  public formControlName: string;

  @Input()
  public disabled = false;

  // Hint-text related attributes
  @Input()
  hintText: string | undefined;
  @Input()
  hintExpandableContent: string | undefined;
  @Input()
  hintExpandableContentParam: any;

  form: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(public controlContainer: ControlContainer,
              public formBuilder: FormBuilder) {
  }

  setUpForm(controlName: string, validateOnlyOnBlur?: boolean) {
    this.formControlName = controlName;
    this.form = this.controlContainer?.control as FormGroup
    this.form.addControl(controlName, this.formBuilder.control(''));
    if (validateOnlyOnBlur) {
      const newFormControl = new FormControl(this.form.get(controlName).value, {updateOn: 'blur'})
      if ('DISABLED' === this.form.get(controlName).status) {
        newFormControl.disable()
      }
      this.form.setControl(controlName, newFormControl)
    }
    if (this.disabled) {
      this.formControl.disable();
    }
  }

  // extract field name from ID
  getFieldName(id: string): string {
    return id?.substr(id.indexOf('.') + 1);
  }

  get formControl(): AbstractControl {
    return this.form.get(this.formControlName);
  }
}
