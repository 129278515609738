import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {FormToAppDataBase} from '../../../common/utils/converters/form-to-app-data-base';

export class FormToFaTrustFund extends FormToAppDataBase implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  static readonly OPGT_CHECK_BOX = 'ownItemsOfValueOpgtCheckbox';
  static readonly OTHER_CHECK_BOX = 'ownItemsOfValueOtherTrustCheckbox';
  static readonly APPLICANT_OPGT_CHECKBOX = 'ownItemsOfValueApplicantCheckboxTrustFundOpgt';
  static readonly APPLICANT_OTHER_CHECKBOX = 'ownItemsOfValueApplicantCheckboxTrustFundOther';
  static readonly OPGT_DETAILS_LIST = 'ownItemsOfValueDetailsListTrustFundOpgt';
  static readonly OTHER_DETAILS_LIST = 'ownItemsOfValueDetailsListTrustFundOther';

  static readonly OPGT_PANEL_CHILD = 'FATrustFundWhoOwnsThisAssetPanelChild';
  static readonly OTHER_PANEL_CHILD = 'FATrustFundOtherWhoOwnsThisAssetPanelChild';
  static readonly OPGT_WHO_OWN_THIS_ASSET = 'FATrustFundWhoOwnsThisAsset';
  static readonly OTHER_WHO_OWN_THIS_ASSET = 'FATrustFundOtherWhoOwnsThisAsset';
  static readonly TRUST_FUND_FORM_GROUP = 'FATrustFundPanel.panels.0';

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // remove previous saved data and repopulate with latest form data
    delete appData.jsonData[FormToFaTrustFund.OPGT_CHECK_BOX];
    delete appData.jsonData[FormToFaTrustFund.APPLICANT_OPGT_CHECKBOX];
    delete appData.jsonData[FormToFaTrustFund.OPGT_DETAILS_LIST];

    delete appData.jsonData[FormToFaTrustFund.OTHER_CHECK_BOX];
    delete appData.jsonData[FormToFaTrustFund.APPLICANT_OTHER_CHECKBOX];
    delete appData.jsonData[FormToFaTrustFund.OTHER_DETAILS_LIST];

    if ( form.get('FATrustFundPanel')?.value.panels[0].FATrustFundOPGT[0]) {
      appData.jsonData[FormToFaTrustFund.OPGT_CHECK_BOX] = ['yes'];

      const panelQuestion = questions.find(q => q.key === 'FATrustFundPanel');

      // populate applicant check box list
      this.populateCheckBoxList(form,appData,panelQuestion.panels[0].questions);

      // populate details list
      this.populateDetailsList(form, appData, panelQuestion.panels[0].questions);
    }

    if ( form.get('FATrustFundPanel')?.value.panels[0].FATrustFundOther[0]) {
      appData.jsonData[FormToFaTrustFund.OTHER_CHECK_BOX] = ['yes'];

      const panelQuestion = questions.find(q => q.key === 'FATrustFundPanel');
      // populate applicant check box list
      this.populateOtherCheckBoxList(form,appData,panelQuestion.panels[0].questions);

      // populate details list
      this.populateOtherDetailsList(form, appData, panelQuestion.panels[0].questions);
    }
  }

  populateCheckBoxList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const OPGTList = [];
    const question = questions.find(q => q.key === FormToFaTrustFund.OPGT_WHO_OWN_THIS_ASSET);

    if (appData.jsonData.spouseFirstName || appData.jsonData.childList) {

      const applicantOption = question.options.find(o => o.label === 'financial-assets.applicant');

      if (this.isApplicantSelected(FormToFaTrustFund.OPGT_WHO_OWN_THIS_ASSET,
        form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions) &&
        form.get('FATrustFundPanel.panels.0.FATrustFundWhoOwnsThisAssetPanelApplicant')?.value.panels[0].trustFundOpgtTotalValue) {
        OPGTList.push(applicantOption.alternateValue);
      }
    }

    if (this.isSpouseSelected(FormToFaTrustFund.OPGT_WHO_OWN_THIS_ASSET,
      form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions) &&
      form.get('FATrustFundPanel.panels.0.FATrustFundWhoOwnsThisAssetPanelSpouse')?.value.panels[0].trustFundOpgtTotalValue){
      const spouseOption = question.options.find(o => o.label === 'financial-assets.spouse');
      OPGTList.push(spouseOption.alternateValue);
    }

    if (appData.jsonData.childList){
      for (let i = 1; i <= appData.jsonData.childList.length; i++) {
        const childPanel = 'FATrustFundPanel.panels.0.'.concat(FormToFaTrustFund.OPGT_PANEL_CHILD.concat(String(i)));
        if (form.get(childPanel)?.value.panels[0].trustFundOpgtTotalValue) {
          const child = questions.find(q => q.key === FormToFaTrustFund.OPGT_PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFaTrustFund.OPGT_WHO_OWN_THIS_ASSET,
            form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions)) {
            OPGTList.push(child.value);
          }
        }
      }
    }

    if (OPGTList.length > 0) {
      appData.jsonData[FormToFaTrustFund.APPLICANT_OPGT_CHECKBOX] = OPGTList;
    }
  }

  populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const opgtDetailsList = [];

    if (this.isApplicantSelected(FormToFaTrustFund.OPGT_WHO_OWN_THIS_ASSET,
      form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions) &&
      form.get('FATrustFundPanel.panels.0.FATrustFundWhoOwnsThisAssetPanelApplicant').value.panels[0].trustFundOpgtTotalValue) {
      opgtDetailsList.push({
        applicant: 'Applicant: '.concat(appData.jsonData.firstName),
        applicantType: 'Applicant',
        applicantDisplayType: 'Applicant',
        applicantName: ' '.concat(appData.jsonData.firstName),
        opgtTotalValue: String(form.get('FATrustFundPanel.panels.0.FATrustFundWhoOwnsThisAssetPanelApplicant').
          value.panels[0].trustFundOpgtTotalValue),
        opgtAccessibleValue: String(form.get('FATrustFundPanel.panels.0.FATrustFundWhoOwnsThisAssetPanelApplicant').
          value.panels[0].trustFundOpgtAccessibleValue)
      })
    }

    if (this.isSpouseSelected(FormToFaTrustFund.OPGT_WHO_OWN_THIS_ASSET,
      form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions) &&
      form.get('FATrustFundPanel.panels.0.FATrustFundWhoOwnsThisAssetPanelSpouse')?.value.panels[0].trustFundOpgtTotalValue) {
      opgtDetailsList.push({
        applicant: 'Spouse: '.concat(appData.jsonData.spouseFirstName),
        applicantType: 'Spouse',
        applicantDisplayType: 'Spouse',
        applicantName: ' '.concat(appData.jsonData.spouseFirstName),
        opgtTotalValue: String(form.get('FATrustFundPanel.panels.0.FATrustFundWhoOwnsThisAssetPanelSpouse').
          value.panels[0].trustFundOpgtTotalValue),
        opgtAccessibleValue: String(form.get('FATrustFundPanel.panels.0.FATrustFundWhoOwnsThisAssetPanelSpouse').
          value.panels[0].trustFundOpgtAccessibleValue)
      })
    }

    // populate children data
    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        const childPanelKey = 'FATrustFundPanel.panels.0.'.concat(FormToFaTrustFund.OPGT_PANEL_CHILD.concat(String(i)));
        const childPanel = form.get(childPanelKey);
        if (childPanel?.value.panels[0].trustFundOpgtTotalValue) {
          const child = questions.find(q => q.key === FormToFaTrustFund.OPGT_PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFaTrustFund.OPGT_WHO_OWN_THIS_ASSET,
            form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions)) {
            opgtDetailsList.push({
              applicant: child.value,
              applicantType: 'Child '.concat(String(i)),
              applicantDisplayType: 'Child '.concat(String(i)),
              applicantName: child.value.substr(child.value.indexOf(':') + 1),
              opgtTotalValue: String(childPanel.value.panels[0].trustFundOpgtTotalValue),
              opgtAccessibleValue: String(childPanel.value.panels[0].trustFundOpgtAccessibleValue),
            })
          }
        }
      }
    }

    if (opgtDetailsList.length > 0){
      appData.jsonData[FormToFaTrustFund.OPGT_DETAILS_LIST] = opgtDetailsList;
    }
  }

  populateOtherCheckBoxList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const otherList = [];
    const question = questions.find(q => q.key === 'FATrustFundOtherWhoOwnsThisAsset');

    if(appData.jsonData.spouseFirstName || appData.jsonData.childList){

      const applicantOption = question.options.find(o => o.label === 'financial-assets.applicant');

      if (this.isApplicantSelected(FormToFaTrustFund.OTHER_WHO_OWN_THIS_ASSET,
        form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions) &&
        form.get('FATrustFundPanel.panels.0.FATrustFundOtherWhoOwnsThisAssetPanelApplicant')?.value.panels[0].trustFundOtherTotalValue) {
        otherList.push(applicantOption.alternateValue);
      }
    }

    if (this.isSpouseSelected(FormToFaTrustFund.OTHER_WHO_OWN_THIS_ASSET,
      form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions) &&
      form.get('FATrustFundPanel.panels.0.FATrustFundOtherWhoOwnsThisAssetPanelSpouse')?.value.panels[0].trustFundOtherTotalValue){
      const spouseOption = question.options.find(o => o.label === 'financial-assets.spouse');
      otherList.push(spouseOption.alternateValue);
    }

    if (appData.jsonData.childList){
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        const childPanel = 'FATrustFundPanel.panels.0.'.concat(FormToFaTrustFund.OTHER_PANEL_CHILD.concat(String(i)));
        if (form.get(childPanel)?.value.panels[0].trustFundOtherTotalValue) {
          const child = questions.find(q => q.key === FormToFaTrustFund.OTHER_PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFaTrustFund.OTHER_WHO_OWN_THIS_ASSET,
            form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions)) {
            otherList.push(child.value);
          }
        }
      }
    }

    if (otherList.length > 0) {
      appData.jsonData[FormToFaTrustFund.APPLICANT_OTHER_CHECKBOX] = otherList;
    }

  }

  populateOtherDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const otherDetailsList = [];

    if (this.isApplicantSelected(FormToFaTrustFund.OTHER_WHO_OWN_THIS_ASSET,
      form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions) &&
      form.get('FATrustFundPanel.panels.0.FATrustFundOtherWhoOwnsThisAssetPanelApplicant').value.panels[0].trustFundOtherTotalValue) {
      otherDetailsList.push({
        applicant: 'Applicant: '.concat(appData.jsonData.firstName),
        applicantType: 'Applicant',
        applicantDisplayType: 'Applicant',
        applicantName: ' '.concat(appData.jsonData.firstName),
        privateTrustTotalValue: String(form.get('FATrustFundPanel.panels.0.FATrustFundOtherWhoOwnsThisAssetPanelApplicant').
          value.panels[0].trustFundOtherTotalValue),
        privateTrustAccessibleValue:String(form.get('FATrustFundPanel.panels.0.FATrustFundOtherWhoOwnsThisAssetPanelApplicant').
          value.panels[0].trustFundPrivateAccessibleValue)
      })
    }

    if (this.isSpouseSelected(FormToFaTrustFund.OTHER_WHO_OWN_THIS_ASSET,
      form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions) &&
      form.get('FATrustFundPanel.panels.0.FATrustFundOtherWhoOwnsThisAssetPanelSpouse')?.value.panels[0].trustFundOtherTotalValue) {
      otherDetailsList.push({
        applicant: 'Spouse: '.concat(appData.jsonData.spouseFirstName),
        applicantType: 'Spouse',
        applicantDisplayType: 'Spouse',
        applicantName: ' '.concat(appData.jsonData.spouseFirstName),
        privateTrustTotalValue: String(form.get('FATrustFundPanel.panels.0.FATrustFundOtherWhoOwnsThisAssetPanelSpouse').
          value.panels[0].trustFundOtherTotalValue),
        privateTrustAccessibleValue:String(form.get('FATrustFundPanel.panels.0.FATrustFundOtherWhoOwnsThisAssetPanelSpouse').
          value.panels[0].trustFundPrivateAccessibleValue)
      })
    }

    // populate children data
    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        const childPanel = 'FATrustFundPanel.panels.0.'.concat(FormToFaTrustFund.OTHER_PANEL_CHILD.concat(String(i)));
        if (form.get(childPanel)?.value.panels[0].trustFundOtherTotalValue) {
          const child = questions.find(q => q.key === FormToFaTrustFund.OTHER_PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFaTrustFund.OTHER_WHO_OWN_THIS_ASSET,
            form.get(FormToFaTrustFund.TRUST_FUND_FORM_GROUP) as FormGroup, questions)) {
            otherDetailsList.push({
              applicant: child.value,
              applicantType: 'Child '.concat(String(i)),
              applicantDisplayType: 'Child '.concat(String(i)),
              applicantName: child.value.substr(child.value.indexOf(':') + 1),
              // tslint:disable-next-line:max-line-length
              privateTrustTotalValue: String(form.get(childPanel).value.panels[0].trustFundOtherTotalValue),
              privateTrustAccessibleValue:String(form.get(childPanel).value.panels[0].trustFundPrivateAccessibleValue)
            })
          }
        }
      }
    }

    if (otherDetailsList.length > 0){
      appData.jsonData[FormToFaTrustFund.OTHER_DETAILS_LIST] = otherDetailsList;
    }
  }
}
