<footer class='ontario-footer ontario-footer--default'>
  <div class="ontario-row">
    <div class='footer-padding footer-row'>
      <div class='ontario-columns ontario-small-12'>
        <ul class='ontario-footer__links-container ontario-footer__links-container--inline'>
          <li>
            <a id='accessibility-link' class='ontario-footer__link' href="{{'footer.accessibility' | translate}}">{{'footer.accessibilityText' | translate}}</a>
          </li>
          <li>
            <a id='terms-of-use-link' class='ontario-footer__link' href="{{'footer.termsOfUse' | translate}}">{{'footer.termsOfUseText' | translate}}</a>
          </li>
          <li>
            <a id='privacy-link' class='ontario-footer__link' href="{{'footer.privacy' | translate}}">{{'footer.privacyText' | translate}}</a>
          </li>
          <li>
            <a id='contact-us-link' class='ontario-footer__link' href="{{'footer.contactUs' | translate}}">{{'footer.contactUsText' | translate}}</a>
          </li>
        </ul>
        <div class='ontario-footer__copyright'>
          <a id='copyright-link' class='ontario-footer__link' href="{{'footer.copyright' | translate}}">
            {{'footer.copyrightText' | translate}},&nbsp;2012&ndash;{{currentDate | date:'yy'}}
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
