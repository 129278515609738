import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {HHIDataConversionConfig} from '../model/hhi-data-conversion-config';
import {BaseHHIFormToData} from './base-hhi-form-to-data';

/**
 * Converter to convert form control values of questions with trying or receiving payment options to application data
 * to send to back end
 */
export class HHIStandardFormValuesToAppData extends BaseHHIFormToData {

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[], formToDataConfig: HHIDataConversionConfig): void {
    super.convert(form, appData, questions, formToDataConfig)
  }

  populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[],
                              formToDataConfig: HHIDataConversionConfig): void {
    const qPanelKey = formToDataConfig.questionPanelKey
    const qKey = formToDataConfig.questionKey
    const incomeDetailList = [];
    const WHO_GETS_THE_PAYMENT_QKEY = qKey.concat('WhoGetsThePayment');
    const PAYMENT_FORM_GROUP = qPanelKey.concat('.panels.0');
    const APPLICANT_PANEL = qPanelKey.concat('.panels.0.').concat(qKey).concat('WhoGetsThePaymentPanelApplicant');
    const SPOUSE_PANEL = qPanelKey.concat('.panels.0.').concat(qKey).concat('WhoGetsThePaymentPanelSpouse');
    const PAYMENT_PANEL_CHILD = 'WhoGetsThePaymentPanelChild';

    if (this.isApplicantSelected(WHO_GETS_THE_PAYMENT_QKEY, form.get(PAYMENT_FORM_GROUP) as FormGroup, questions) &&
      (this.isIncomeInfoAvailable(APPLICANT_PANEL, form))) {
      incomeDetailList.push(
        this.getIncomeInfo('Applicant', appData.jsonData.firstName, APPLICANT_PANEL, formToDataConfig, form))
    }

    if (this.isSpouseSelected(WHO_GETS_THE_PAYMENT_QKEY, form.get(PAYMENT_FORM_GROUP) as FormGroup, questions) &&
      this.isIncomeInfoAvailable(SPOUSE_PANEL, form)) {
      incomeDetailList.push(this.getIncomeInfo('Spouse', appData.jsonData.spouseFirstName, SPOUSE_PANEL, formToDataConfig, form))
    }

    // populate children data
    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++) {
        const CHILD_PANEL_KEY = qPanelKey.concat('.panels.0.').concat(qKey.concat(PAYMENT_PANEL_CHILD).concat(String(i)));

        if (this.isIncomeInfoAvailable(CHILD_PANEL_KEY, form)) {
          const child = questions.find(q => q.key === qKey.concat(PAYMENT_PANEL_CHILD).concat(String(i)));
          const chileFirstName = child.value.substr(child.value.indexOf(':') + 2);
          if (this.isChildSelected(i, WHO_GETS_THE_PAYMENT_QKEY, form.get(PAYMENT_FORM_GROUP) as FormGroup, questions)) {
            incomeDetailList.push(this.getIncomeInfo('Child'.concat(' '.concat(String(i))),
              chileFirstName, CHILD_PANEL_KEY, formToDataConfig, form))
          }
        }
      }
    }

    if (incomeDetailList.length > 0) {
      appData.jsonData[formToDataConfig.incomeDetailAttributeName] = incomeDetailList;
    }
  }

  private isIncomeInfoAvailable(panelName: string, form: FormGroup): boolean {
    return form.get(panelName)?.value.panels[0].tryingOrReceivingThisIncome || form.get(panelName)?.value.panels[0].monthlyAmount
  }

  private getIncomeInfo(applicantTypeStr: string, firstName: string, panelName: string,
                        formToDataConfig: HHIDataConversionConfig, form: FormGroup) {
    const obj = {
      applicant: applicantTypeStr.concat(': ').concat(firstName),
      applicantType: applicantTypeStr,
      applicantDisplayType: applicantTypeStr,
      applicantName: ' '.concat(firstName),
    }

    if(form.get(panelName)?.value.panels[0].tryingOrReceivingThisIncome) {
      obj[formToDataConfig.incomeStateAttributeName] = form.get(
        panelName)?.value.panels[0].tryingOrReceivingThisIncome
    }

    if(form.get(panelName)?.value.panels[0].monthlyAmount) {
      obj[formToDataConfig.incomeMonthlyAmountAttributeName] = String(
        form.get(panelName)?.value.panels[0].monthlyAmount)
    }

    return obj;
  }
}
