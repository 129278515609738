<div id='{{id}}{{occurrence}}-accordion' class='ontario-accordion'>
  <h3 class='ontario-accordion-heading'>
    <button id="{{id}}{{occurrence}}{{ACCORDION_BUTTON_ID_SUFFIX}}"
            class="ontario-accordion__button"
            tabindex="0"
            (keyup.enter)="onHeadingClicked()"
            attr.aria-controls="{{id}}{{occurrence}}-accordion-expander-content"
            attr.aria-expanded="{{expanded}}" data-toggle="ontario-collapse">
      <span class="ontario-accordion__button-icon--close">
        <svg class="ontario-icon"
             aria-hidden="true"
             focusable="false" sol:category="primary"
             viewBox="0 0 24 24"
             preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-chevron-up"></use>
        </svg>
      </span>
      <span class="ontario-accordion__button-icon--open">
        <svg class="ontario-icon"
             aria-hidden="true"
             focusable="false"
             sol:category="primary"
             viewBox="0 0 24 24"
             preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-chevron-down"></use>
        </svg>
      </span>
      {{heading | translate}}
    </button>
  </h3>
  <section id="{{id}}{{occurrence}}-accordion-expander-content"
           class="ontario-accordion__content"
           [ngClass]="{'ontario-expander__content--opened' : expanded}"
           attr.aria-labelledby="{{id}}{{occurrence}}{{ACCORDION_BUTTON_ID_SUFFIX}}"
           attr.aria-hidden="{{!expanded}}"
           data-toggle="ontario-expander-content">
    <ng-content></ng-content>
  </section>
</div>
