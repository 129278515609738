import {AfterContentChecked, Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[ellipsis]'
})
/**
 * This directive will allow the element to show 'ellipsis' (without having the specific styling on the element) if the length of content
 * exceeds the max-width of the parent element, and shows the content in tooltip when the element is being hovered.
 */
export class EllipsisDirective implements AfterContentChecked {
  private readonly domElement: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement;  // To get DOM element and store it in global variable.
    // Setting compulsory required styles to the DOM element.
    const newStyles = {
      'text-overflow': 'ellipsis',
      'overflow': 'hidden',
      'white-space': 'nowrap',
    };
    Object.keys(newStyles).forEach(newStyle => {
      this.renderer.setStyle(
        this.domElement, `${newStyle}`, newStyles[newStyle]
      );
    });
  }

  ngAfterContentChecked(): void {
    // To allow the values of offsetWidth & scrollWidth to set properly when the element is rendered first time.
    this.renderer.setProperty(this.domElement, 'scrollTop', 1);
    this.isTitleAttribute();
  }

  @HostListener('window:resize', ['$event.target'])
  isTitleAttribute() {
    // To add or remove title attribute on the element when it is changing width.
    (this.domElement.offsetWidth < this.domElement.scrollWidth) ?
      this.renderer.setAttribute(this.domElement, 'title', this.domElement.textContent) :
      this.renderer.removeAttribute(this.domElement, 'title');
  }
}
