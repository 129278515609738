import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {IntakeService} from '../../services/intake.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EsignUtil} from '../../esign-util';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../BaseComponent';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../external.router';
import {ConfigService} from '../../services/config.service';
import {ApplicationAnswers} from '../../models/data.model';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageInfo} from '../../models/page-map';

@Component({
  selector: 'app-additional-signatures',
  templateUrl: './additional-signatures.component.html',
  styleUrls: ['./additional-signatures.component.scss']
})
export class AdditionalSignaturesComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false
  people = []
  dependentAdults = [];
  dependentChildrenCount = 0;

  constructor(intakeService: IntakeService,
              public route: ActivatedRoute,
              private router: Router,
              private esignUtil: EsignUtil,
              public ngZone: NgZone, public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              public translator: TranslateService,
              public http: HttpClient,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.additionalSignatures;
  }

  ngOnInit(): void {
    this.isLoading = true
    this.subscriptions$.push(this.route.queryParams.subscribe(params => {
      this.subscriptions$.push(this.intakeService.getApplication(this.pageId).subscribe((data: ApplicationAnswers) => {
        this.isLoading = false

        // in the event session has ended, go straight to confirmation,
        // can happen if user refreshes page or navigates away and comes back via back button
        if (data == null) {
          this.onSubmit();
        }

        const spouseTitle = 'Spouse';
        const childTitle = 'Adult child';
        this.people = this.esignUtil.parseSigners(data, spouseTitle, childTitle)
        this.dependentAdults = this.processDependents(spouseTitle);
        this.dependentChildrenCount = this.people.filter(p => p.type === childTitle).length;
      }, error => this.isLoading = false));
    }));
  }

  ngOnDestroy(): void {
    super.onDestroy()
  }

  onSubmit() {
    this.router.navigate(['/', PageInfo.confirmation])
  }

  processDependents(title: string) {
    if(!this.people || !this.people.length){
      return []
    }
    let slice = 6;

    if (this.people[0].type === title) {
      slice = 7;
    }

    return this.people.slice(0, slice);
  }
}
