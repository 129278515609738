import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {EmailService} from './email.service';

@Injectable({
  providedIn: 'root'
})
export class EmailImplService implements EmailService {
  constructor(private http: HttpClient) {
  }

  validateEmailDomain(email: string, resultCallback: any) {
    return this.http.post(
      `/v1/email/validate`,
      {email}, {withCredentials: true}
    ).subscribe(
      () => resultCallback(true),
      () => resultCallback(false)
    )
  }

  validateEmailAddress(email: string): Observable<boolean> {
    return this.http.post(
      `/v1/email/validate`,
      {email}, {withCredentials: true}
    ).pipe(map(response => {
        return true
      }),
      catchError((err) => {
        return of(false)
      })
    );
  }

  sendConfirmationEmail(): Observable<boolean> {
    return this.http.post(
      `/v1/email/confirmation`, {}, {withCredentials: true}
    ).pipe(map(response => {
        return true
      }),
      catchError((err) => {
        return of(false)
      })
    );
  }

}
