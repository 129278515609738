import {AfterViewChecked, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PageBaseComponent} from '../PageBaseComponent';
import {Question} from '../../common/utils/questions/question';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../external.router';
import {
  createChildCareFG,
  createIncomeFG,
  EarnedIncomeQuestionsService,
  getChildCareExpenseFGName,
  getIncomesFormArrayNameByApplicantType
} from './service/earned-income-questions.service';
import {ConfigService} from '../../services/config.service';
import {Choice, IncomeTypes} from '../../common/utils/questions/question-choices';
import {APPLICANT, CHILD_PREFIX, getChildrenYoungerThan18, isChildrenYoungerThan18, NONE, SPOUSE} from './earned-income.util'
import {distinctUntilChanged, pairwise, takeUntil} from 'rxjs/operators';
import {getCurrentMonthAsString, getCurrentYear} from '../../utils/date-util';
import {ApplicationAnswers} from '../../models/data.model';
import {requireCheckboxesToBeChecked} from '../../common/utils/validators/custom-validators';
import {CheckboxItem} from '../../common/ui/multi-option-checkbox-question/multi-option-checkbox-question.component';
import {ChildCare, EarnedIncomeSourceList} from './earned-income.model';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';

@Component({
  selector: 'sd-earned-income',
  templateUrl: './earned-income.component.html',
  styleUrls: ['./earned-income.component.scss']
})
export class EarnedIncomeComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  showError = false;
  selectAnOptionError = false;
  questions: Question<string>[];
  applicantOptions: CheckboxItem[] = [];
  childCareChildrenOptions: CheckboxItem[] = [];
  incomeTypes: Choice[] = IncomeTypes;
  currentMonthYearLabelParam = {
    month: getCurrentMonthAsString(),
    year: getCurrentYear()
  };
  isEarnedIncomeMonthSame: boolean;
  getFormArrayNameByApplicantType = getIncomesFormArrayNameByApplicantType;
  getChildCareExpenseFGName = getChildCareExpenseFGName;

  constructor(private fb: FormBuilder, private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              private service: EarnedIncomeQuestionsService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.earnedIncome
  }

  ngOnInit(): void {
    this.initialize();

    this.translator.onLangChange.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(langChangeEvent => {
      this.currentMonthYearLabelParam = {month: getCurrentMonthAsString(langChangeEvent.lang), year: getCurrentYear()};
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  preSaveApplication() {}

  postInitializeForm() {}

  private initialize() {
    this.route.data.subscribe((data: { appData: ApplicationAnswers }) => {
      if (data && data.appData?.jsonData) {
        this.applicationAnswers = data.appData;
        this.setupForm();
        this.applicantOptions = this.getApplicantsCheckBoxOptions(this.applicationAnswers);
        if (!this.isSingleApplicant && getChildrenYoungerThan18(this.applicationAnswers)?.length) {
          this.childCareChildrenOptions = this.getChildCareChildrenOptions(this.applicationAnswers);
        }
        this.service.appDataToForm(this.applicationAnswers, this.form, this.fb);

        this.isEarnedIncomeMonthSame = !!this.applicationAnswers.jsonData.earnedIncome
                            && this.applicationAnswers.jsonData.earnedIncome[0].earnedIncomeMonth !== getCurrentMonthAsString()
                            ? true : false;

        if (this.isSingleApplicant) {
          this.handleApplicantReceiveEarnedIncomeValueChanges();
        } else {
          this.handleAnyOneReceiveEarnedIncomeCBValueChanges();
          if (getChildrenYoungerThan18(this.applicationAnswers).length) {
            this.handleChildCareExpenseValueChanges();
          }
        }
      }
    });
  }

  private handleApplicantReceiveEarnedIncomeValueChanges() {
    this.form?.controls.applicantReceiveEarnedIncome?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$))
      .subscribe(value => {
        if (value === 'yes') {
          this.toggleIncomePanel([true]);
        } else {
          this.toggleIncomePanel([false]);
        }
      });
  }

  private handleAnyOneReceiveEarnedIncomeCBValueChanges() {
    this.form?.controls.anyOneReceiveEarnedIncomeCB?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$))
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        const noneIndex = this.applicantOptions.findIndex(c => NONE === c.value);
        if (prev[noneIndex] !== next[noneIndex] && next[noneIndex]) {
          // If none of the above check box selected, reset all the check boxes except none of the above
          const cbFormArray = this.form.get('anyOneReceiveEarnedIncomeCB') as FormArray
          const resetValues = next.map((v, i) => i === noneIndex);
          cbFormArray.reset(resetValues, {emitEvent: false});
          this.toggleIncomePanel(resetValues, true);
        } else if (!!next.filter((v, i) => v && i !== noneIndex).length) {
          // If any check box except none of the above selected, reset none of the above
          const cbFormArray = this.form.get('anyOneReceiveEarnedIncomeCB') as FormArray
          cbFormArray.at(noneIndex)?.setValue(false, {emitEvent: false});
          next[noneIndex] = false;
          this.toggleIncomePanel(next);
        } else {
          this.toggleIncomePanel(next);
        }
      });
  }

  private handleChildCareExpenseValueChanges() {
    this.form.controls.anyChildCareExpense?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$))
      .subscribe(next => {
        this.toggleChildCare(next);
      });

    this.form.controls.anyChildCareExpenseCB?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$))
      .subscribe(next => {
        this.selectChildForChildCare(next);
      });
  }

  onSubmit(toContinue: boolean) {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;
    this.selectAnOptionError = false;

    if (this.isNoneOfTheAboveSelected || this.form.valid) {
      this.showError = false;
      this.applicationAnswers = this.service.formToAppData(this.isSingleApplicant, this.form, this.applicationAnswers,
                                                                  this.applicantOptions, this.childCareChildrenOptions);
      this.subscriptions$.push(this.saveToIntake()?.pipe(distinctUntilChanged()).subscribe(() => {
        if (toContinue) {
          this.router.navigate(['/', 'intake', PageInfo.householdIncome])
        } else {
          this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
        }
      }));
    } else {
      this.showError = true;
      this.scrollToInvalidFormControl(toContinue);
    }
  }

  private toggleIncomePanel(selectionArray: boolean[], isNoneOfTheAboveSelected = false) {

    selectionArray.forEach((v, index) => {
      const choice = this.applicantOptions[index];
      choice.checked = v;
      if (NONE !== choice.value && !isNoneOfTheAboveSelected) {
        if (choice.checked) {
          this.addIncome(choice.value, true)
        } else {
          // Disable the panels for options that are not selected
          this.disableIncomesFormArray(choice.value);
        }
      }
    });
  }

  private selectChildForChildCare(value: boolean[]) {
    value.forEach((v, index) => {
      const choice = this.childCareChildrenOptions[index];
      choice.checked = v;
      const fgName = getChildCareExpenseFGName(choice.value)
      const formGroup = this.childCareExpenses.get(fgName)
      if (choice.checked) {
        formGroup.enable();
      } else {
        formGroup.disable();
      }
    });
  }

  private toggleChildCare(value: string) {
    if ('yes' === value) {
      this.childCareExpenseList.enable()
    } else {
      this.childCareExpenseList.disable();
      this.childCareExpenses.disable();
    }
  }

  addIncome(applicantType: string, addOnlyIfEmpty = false) {
    const formArrayName: string = getIncomesFormArrayNameByApplicantType(applicantType);
    const incomesFormArray = this.incomes.get(formArrayName) as FormArray;
    if (addOnlyIfEmpty) {
      if (incomesFormArray.length === 0) {
        incomesFormArray.push(createIncomeFG(this.fb));
      } else if (incomesFormArray.disabled) {
        incomesFormArray.enable();
      }
    } else {
      incomesFormArray.push(createIncomeFG(this.fb));
    }

    // Remove focus from add income button
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  removeIncome(applicantType: string, index: number) {
    const incomesFormArray = this.incomes.get(
      getIncomesFormArrayNameByApplicantType(applicantType)) as FormArray;
    incomesFormArray.removeAt(index);
  }

  private disableIncomesFormArray(applicantType: string) {
    const formArrayName: string = getIncomesFormArrayNameByApplicantType(applicantType);
    const incomesFormArray = this.incomes.get(formArrayName) as FormArray;
    incomesFormArray?.controls.forEach(incomeFG => {
      const fg = incomeFG as FormGroup;
      Object.keys(fg.controls).forEach(key => {
        fg.get(key).disable();
      })
    })
  }

  private getApplicantsCheckBoxOptions(applicationAnswers: ApplicationAnswers): CheckboxItem[] {
    const checkboxItems: CheckboxItem[] = []
    const jsonData = applicationAnswers.jsonData;
    if (jsonData) {
      checkboxItems.push({
        label: 'appData.multiple.applicant.label.applicant',
        labelParam: {name: jsonData.firstName},
        value: APPLICANT,
        checked: jsonData.anyOneReceiveEarnedIncome?.findIndex(v => v === APPLICANT) >= 0 ||
          jsonData.applicantReceiveEarnedIncome === 'yes'
      })

      if (jsonData.spouseFirstName) {
        checkboxItems.push({
          label: 'appData.multiple.applicant.label.spouse',
          labelParam: {name: jsonData.spouseFirstName},
          value: SPOUSE,
          checked: jsonData.anyOneReceiveEarnedIncome?.findIndex(v => v === SPOUSE) >= 0
        })
      }

      jsonData.childList?.forEach((child, childIndex) => {
        const childType = CHILD_PREFIX + ' ' + (childIndex + 1);
        checkboxItems.push({
          label: 'appData.multiple.applicant.label.child',
          labelParam: {index: (childIndex + 1), name: child.childFirstName},
          value: childType,
          checked: this.isEarnedIncomeCheckedForChild(childType)
        });
      })

      if (!this.isSingleApplicant) {
        checkboxItems.push({
          label: 'earned-income.none.of.above',
          value: NONE,
          checked: jsonData.anyOneReceiveEarnedIncome?.findIndex(v => v === NONE) >= 0
        })
      }
    }
    return checkboxItems
  }

  private getChildCareChildrenOptions(applicationAnswers: ApplicationAnswers): CheckboxItem[] {
    const checkboxItems: CheckboxItem[] = [];

    if (applicationAnswers) {
      applicationAnswers.jsonData?.childList?.forEach((child, childIndex) => {
        if (isChildrenYoungerThan18(child)) {
          const childType = CHILD_PREFIX + ' ' + (childIndex + 1);
          checkboxItems.push({
            label: 'appData.applicant.label.child',
            labelParam: {index: (childIndex + 1), name: child.childFirstName},
            value: childType,
            checked: this.isChildCareChildrenOptionChecked(applicationAnswers, childIndex)
          });
        }
      })
    }
    return checkboxItems
  }

  private isEarnedIncomeCheckedForChild(applicantType: string) {
    const earnedIncome: EarnedIncomeSourceList[] = this.applicationAnswers.jsonData.earnedIncome;
    return earnedIncome?.some(income =>
      income.applicantType === applicantType &&
      this.service.getApplicantNameByType(applicantType, this.applicationAnswers) === income.applicantName
    )
  }

  private isChildCareChildrenOptionChecked(applicationAnswers: ApplicationAnswers, childIndex: number): boolean {
    const child = CHILD_PREFIX + ' ' + (childIndex + 1);
    const childName = this.service.getChildName(this.applicationAnswers, child)
    if (applicationAnswers.jsonData.anyChildCareExpenseList?.length) {
      return applicationAnswers.jsonData.anyChildCareExpenseList[0].childCare?.find(
        (v: ChildCare) => v.applicantType === child && v.careRecipientName === childName)
    } else {
      return false;
    }
  }

  getIncomesFormArray(applicantType: string): FormArray {
    return this.incomes.get(getIncomesFormArrayNameByApplicantType(applicantType)) as FormArray;
  }

  private setupForm(): void {
    this.form = this.fb.group({
      incomes: this.fb.group({
        primaryApplicantIncomes: this.fb.array([])
      })
    });
    if (this.isSingleApplicant) {
      this.form.addControl('applicantReceiveEarnedIncome', this.fb.control(''));
    } else {
      this.form.addControl('anyOneReceiveEarnedIncomeCB', this.fb.array([], requireCheckboxesToBeChecked()));

      if (this.applicationAnswers.jsonData.spouseFirstName) {
        this.incomes.addControl('spouseIncomes', this.fb.array([]));
      }

      this.applicationAnswers.jsonData.childList?.forEach((child, childIndex) => {
        this.incomes.addControl(getIncomesFormArrayNameByApplicantType(CHILD_PREFIX + (childIndex + 1)), this.fb.array([]))
      })

      if (getChildrenYoungerThan18(this.applicationAnswers)?.length) {
        this.form.addControl('anyChildCareExpense', this.fb.control(''));
        this.form.addControl('anyChildCareExpenseCB', this.fb.array([], requireCheckboxesToBeChecked()));
        this.form.addControl('childCareExpenses', this.fb.group({}));

        this.applicationAnswers.jsonData.childList?.forEach((child, childIndex) => {
          if (isChildrenYoungerThan18(child)) {
            this.childCareExpenses.addControl(
              getChildCareExpenseFGName(CHILD_PREFIX + (childIndex + 1)), createChildCareFG(this.fb))
          }
        });
      }
    }
  }

  get isNoneOfTheAboveSelected() {
    return this.checkedChoices.some(c => NONE === c.value);
  }

  get checkedChoices(): CheckboxItem[] {
    return this.applicantOptions?.filter(choice => choice.checked);
  }

  get checkedChoicesExcludingNone(): CheckboxItem[] {
    return this.applicantOptions?.filter(choice => NONE !== choice.value && choice.checked);
  }

  get checkedChildrenForChildCare(): CheckboxItem[] {
    return this.childCareChildrenOptions?.filter(choice => choice.checked);
  }

  get isAnyOneReceiveEarnedIncomeCBSelected() {
    return this.form.get('anyOneReceiveEarnedIncomeCB').hasError('requireCheckboxesToBeChecked')
  }

  get isAnyChildCareExpenseCBSelected() {
    return this.form.get('anyChildCareExpenseCB').hasError('requireCheckboxesToBeChecked')
  }

  get incomes(): FormGroup {
    return this.form.get('incomes') as FormGroup;
  }

  get childCareExpenseList() {
    return this.form.get('anyChildCareExpenseCB') as FormArray;
  }

  get childCareExpenses() {
    return this.form.get('childCareExpenses') as FormGroup;
  }

  get anyChildCareExpense() {
    return this.form.get('anyChildCareExpense');
  }

  get isPayingForChildCare() {
    return this.anyChildCareExpense.value === 'yes';
  }
}
