import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PageBaseComponent} from '../PageBaseComponent';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../external.router';
import {ConfigService} from '../../services/config.service';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageInfo} from '../../models/page-map';
import {FormBuilder} from '@angular/forms';
import {ProgramTypeService} from '../../services/program-type.service';
import {ProgramType} from '../../models/program-type';
import {ProgramRecommendation} from '../../models/program-recommendation.model';
import {ProgramTypeUpdateRequest} from '../../models/program-type-update-request';
import { CaseWorkerAuthorizeService } from 'src/app/services/caseWorkerAuthorizeService';

@Component({
  selector: 'sd-program-selection',
  templateUrl: './program-recommendation.component.html',
  styleUrls: ['./program-recommendation.component.scss']
})
export class ProgramRecommendationComponent extends PageBaseComponent implements OnInit, OnDestroy {
  recommendedProgramType: ProgramType;
  otherAvailableProgramType1: ProgramType;
  otherAvailableProgramType2: ProgramType;
  savedProgramType: ProgramType;
  selectAtLeastOneButtonError: boolean;
  programTypeRadioControlName = 'programType';

  constructor(private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              private authorizeService: CaseWorkerAuthorizeService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              public formBuilder: FormBuilder,
              private programTypeService: ProgramTypeService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.programRecommendation;
    this.form = this.formBuilder.group({
      [this.programTypeRadioControlName]: ''
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.programTypeService.getProgramType(true).subscribe(response => {
      // Application created before ODSP release could have OW as program type
      if (response.programType === ProgramType.OW) {
        this.savedProgramType = ProgramType.ONW;
      } else {
        this.savedProgramType = response.programType;
      }

      const recommendations: ProgramRecommendation = response.programRecommendations;

      this.recommendedProgramType = recommendations.recommendedProgramType;
      this.otherAvailableProgramType1 = recommendations.otherAvailableProgramTypes[0]
        ? recommendations.otherAvailableProgramTypes[0] : undefined;
      this.otherAvailableProgramType2 = recommendations.otherAvailableProgramTypes[1]
        ? recommendations.otherAvailableProgramTypes[1] : undefined;

      this.preSelectSavedProgramType();
    });
  }

  getCardId(programType: ProgramType) {
    return programType.concat('_card');
  }

  get selectedValue() {
    return this.form.get(this.programTypeRadioControlName)?.value;
  }

  preSelectSavedProgramType() {
    if (this.savedProgramType && this.isSavedProgramTypeInRecommendation(this.savedProgramType)) {
      this.form.get(this.programTypeRadioControlName).setValue(this.savedProgramType)
    } else {
      this.form.get(this.programTypeRadioControlName).setValue(this.recommendedProgramType)
    }
  }

  private isSavedProgramTypeInRecommendation(savedProgramType: ProgramType) {
    return savedProgramType === this.recommendedProgramType || this.otherAvailableProgramType1 || this.otherAvailableProgramType2;
  }

  onSubmit(toContinue: boolean): void {
    const selectedProgramType: ProgramType = this.form.controls.programType?.value;

    if (selectedProgramType) {
      this.saveAndSubmit(toContinue, selectedProgramType)
    } else {
      this.selectAtLeastOneButtonError = true;
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  }

  saveAndSubmit(toContinue: boolean, selectedProgramType: ProgramType) {
    if (toContinue) {
      this.openDialog(this.getSelectionModalContent(selectedProgramType), false, () => {
        const request = new ProgramTypeUpdateRequest();
        request.programType = selectedProgramType;
        request.recommendedProgramType = this.recommendedProgramType;
        this.programTypeService.updateProgramType(request).subscribe(() => {
          this.navigateToNextPage();
        })
      }, () => {
      }, false);
    } else {  // 'Save & Exit' is requested for authenticated applicant.
      const request = new ProgramTypeUpdateRequest();
      request.programType = selectedProgramType;
      request.recommendedProgramType = this.recommendedProgramType;
      this.programTypeService.updateProgramType(request).subscribe(() => {
        this.saveAndExit();
      })
    }
  }

  private navigateToNextPage() {
    this.router.navigate(['/', PageInfo.wda]);
  }

  private saveAndExit() {
    if (this.authService.isAuthorizedToSave()) {
      this.intakeService.saveProgress(PageInfo.programRecommendation)
        .subscribe(() => {
          this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
        })
    } else {
      this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
    }
  }

  postInitializeForm() {
  }

  preSaveApplication() {
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get recommendationContent() {
    return `program-recommendation.${this.recommendedProgramType.toLowerCase()}.recommendation`;
  }

  getSelectionModalContent(selectedProgramType: ProgramType) {
    let bodyContentKey: string;
    let dialogBodyParam: any;

    if (this.recommendedProgramType === selectedProgramType) {
      bodyContentKey = `program-recommendation.dialog.confirmation.selected-recommended.body`;
      dialogBodyParam = {
        selectedProgramType: this.translator.instant(`program-recommendation.${this.recommendedProgramType}`)
      }
    } else {
      bodyContentKey = `program-recommendation.dialog.confirmation.selected-other.body`;
      dialogBodyParam = {
        recommendedProgramType: this.translator.instant(
          `program-recommendation.${this.recommendedProgramType}`),
        selectedProgramType: this.translator.instant(`program-recommendation.${selectedProgramType}`)
      }
    }
    return {
      title: 'program-recommendation.dialog.confirmation.title',
      body: bodyContentKey,
      dialogBodyParam,
      button: 'program-recommendation.dialog.confirmation.button',
      rightButton: 'program-recommendation.dialog.confirmation.rightButton',
      alertInfoKey: 'program-recommendation.dialog.confirmation.alertInfo'
    }
  }

  get showCalloutMessage(): boolean {
    return !this.authorizeService.isAuthorized() && !this.intake.getIsApplyingForSomeoneElse();
  }
}
