import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {FormToAppDataBase} from '../../../common/utils/converters/form-to-app-data-base';

export class FormToFAOtherAssets extends FormToAppDataBase implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  static readonly CHECK_BOX = 'ownItemsOfValueOtherAssetsCheckbox';
  static readonly DETAILS_LIST = 'ownItemsOfValueDetailsListOtherAssets';
  static readonly APPLICANT_CHECKBOX = 'ownItemsOfValueApplicantCheckboxOtherAssets';
  static readonly PANEL_CHILD = 'FAOtherAssetsWhoOwnsThisAssetPanelChild';
  static readonly WHO_OWN_THIS_ASSET = 'FAOtherAssetsWhoOwnsThisAsset';

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // remove previous saved data and repopulate with latest form data
    delete appData.jsonData[FormToFAOtherAssets.CHECK_BOX];
    delete appData.jsonData[FormToFAOtherAssets.DETAILS_LIST];
    delete appData.jsonData[FormToFAOtherAssets.APPLICANT_CHECKBOX];

    if (form.get('FAOtherAssets')?.value[0]) {
      appData.jsonData[FormToFAOtherAssets.CHECK_BOX] = ['yes'];

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, questions);

      // populate details list
      this.populateDetailsList(form, appData, questions);
    }
  }

  populateCheckBoxList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const list = [];
    const question = questions.find(q => q.key === FormToFAOtherAssets.WHO_OWN_THIS_ASSET);

    if(appData.jsonData.spouseFirstName || appData.jsonData.childList){

      const applicantOption = question.options.find(o => o.label === 'financial-assets.applicant');

      if (this.isApplicantSelected(FormToFAOtherAssets.WHO_OWN_THIS_ASSET,form,questions) &&
        form.get('FAOtherAssetsWhoOwnsThisAssetPanelApplicant')?.value.panels[0].otherAssetValue) {
        list.push(applicantOption.alternateValue);
      }
    }

    if (this.isSpouseSelected(FormToFAOtherAssets.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FAOtherAssetsWhoOwnsThisAssetPanelSpouse')?.value.panels[0].otherAssetValue){
      const spouseOption = question.options.find(o => o.label === 'financial-assets.spouse');
      list.push(spouseOption.alternateValue);
    }

    if (appData.jsonData.childList){
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (form.get(FormToFAOtherAssets.PANEL_CHILD.concat(String(i)))?.value.panels[0].otherAssetValue) {
          const child = questions.find(q => q.key === FormToFAOtherAssets.PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFAOtherAssets.WHO_OWN_THIS_ASSET,form,questions)) {
            list.push(child.value);
          }
        }
      }
    }

    if (list.length > 0) {
      appData.jsonData[FormToFAOtherAssets.APPLICANT_CHECKBOX] = list;
    }
  }

  populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const detailsList: {
      applicant: string,
      applicantType: string,
      applicantDisplayType: string,
      applicantName: string,
      otherAssetList: any[]
    }[] = [];

    if (this.isApplicantSelected(FormToFAOtherAssets.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FAOtherAssetsWhoOwnsThisAssetPanelApplicant').value.panels[0].otherAssetValue) {
      const applicant:any = {
        applicant: 'Applicant: '.concat(appData.jsonData.firstName),
        applicantType: 'Applicant',
        applicantDisplayType: 'Applicant',
        applicantName: ' '.concat(appData.jsonData.firstName),
        otherAssetList: []
      };

      form.get('FAOtherAssetsWhoOwnsThisAssetPanelApplicant').value.panels.forEach(p => {
        applicant.otherAssetList.push({
          value: p.otherAssetValue,
          moneyInheritanceValue:p.moneyInheritanceValue,
          description: p.otherAssetDesc ? p.otherAssetDesc : p.otherAssetType,
          assetType: p.otherAssetType
        });
      });

      detailsList.push(applicant);
    }

    if (this.isSpouseSelected(FormToFAOtherAssets.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FAOtherAssetsWhoOwnsThisAssetPanelSpouse')?.value.panels[0].otherAssetValue) {
      const spouse:any = {
        applicant: 'Spouse: '.concat(appData.jsonData.spouseFirstName),
        applicantType: 'Spouse',
        applicantDisplayType: 'Spouse',
        applicantName: ' '.concat(appData.jsonData.spouseFirstName),
        otherAssetList: []
      };

      form.get('FAOtherAssetsWhoOwnsThisAssetPanelSpouse').value.panels.forEach(p => {
        spouse.otherAssetList.push({
          value: p.otherAssetValue,
          moneyInheritanceValue:p.moneyInheritanceValue,
          description: p.otherAssetDesc ? p.otherAssetDesc : p.otherAssetType,
          assetType: p.otherAssetType
        });
      });

      detailsList.push(spouse);
    }

    // populate children data
    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (this.isChildSelected(i,FormToFAOtherAssets.WHO_OWN_THIS_ASSET,form,questions) &&
          form.get(FormToFAOtherAssets.PANEL_CHILD.concat(String(i)))?.value.panels[0].otherAssetValue) {
          const child = questions.find(q => q.key === FormToFAOtherAssets.PANEL_CHILD.concat(String(i)));
          const childAccount =
            {
              applicant: child.value,
              applicantType: 'Child '.concat(String(i)),
              applicantDisplayType: 'Child '.concat(String(i)),
              applicantName: child.value.substr(child.value.indexOf(':') + 1),
              otherAssetList: []
            }

          form.get(FormToFAOtherAssets.PANEL_CHILD.concat(String(i)))?.value.panels.forEach(p => {
            childAccount.otherAssetList.push(
              {
                value: p.otherAssetValue,
                moneyInheritanceValue:p.moneyInheritanceValue,
                description: p.otherAssetDesc ? p.otherAssetDesc : p.otherAssetType,
                assetType: p.otherAssetType
              }
            );
          });

          detailsList.push(childAccount);
        }
      }
    }

    if (detailsList.length > 0){
      appData.jsonData[FormToFAOtherAssets.DETAILS_LIST] = detailsList;
    }
  }
}
