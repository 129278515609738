import {Observable} from 'rxjs';
import {QuestionList} from '../models/eid/question-list.model';
import {AnswerList} from '../models/eid/answer-list.model';
import {EidProgress, Person} from '../models/eid/person.model';

export abstract class EidService {
  abstract getEligiblePersons(): Observable<Person[]>

  abstract setEligiblePersons(persons: any)

  abstract isApplicationEligible()

  abstract readEIdApplicantQuestions(personId: string): Observable<QuestionList>

  abstract cancelEIdForApplicant(personId: string): Observable<void>

  abstract answerEIdApplicantQuestions(personId: string, answerList: AnswerList): Observable<void>

  abstract updateEidProgress(personId: string, eidProgress: EidProgress)

  abstract skipEidProcess(applicationId: string): Observable<void>

  abstract completeEidProcess(): Observable<void>
}
