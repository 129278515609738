import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {concatMap, first, switchMap, tap} from 'rxjs/operators';
import {AppSessionService} from '../../services/app-session.service';
import {IntakeService} from '../../services/intake.service';
import {AuthService} from '../../services/auth.service';
import {EsignUtil} from '../../esign-util';
import {ExternalRouter} from '../../external.router';
import {ApplicationAnswers} from '../../models/data.model';
import {EligibilityCheckUtil} from '../../utils/eligibility-check-util';
import {EmailService} from '../../services/email.service';
import {CaseWorkerAuthorizeService} from '../../services/caseWorkerAuthorizeService';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../BaseComponent';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ConfigService} from '../../services/config.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageInfo} from '../../models/page-map';
import {LanguageInfo, UrlInfo} from '../../models/url-map';
import {ProgramType} from '../../models/program-type';
import {ProgramTypeService} from '../../services/program-type.service';

export const sendEmailByRefCodeAndThenLogout = (emailService: EmailService, authService: AuthService) => {
  return switchMap(({referenceCode}) => emailService.sendConfirmationEmail().pipe(concatMap(() => authService.logout())));
}

@Component({
  selector: 'sd-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent extends BaseComponent implements OnInit  {
  public applicationProgramType: ProgramType;
  referenceCode: string;
  family = [];
  caringForChild: boolean;
  tcaLink: string;
  acsdEligibility: boolean;
  acsdLink: string;
  feedbackUrl: string;
  isErrorPage: boolean;
  clearCacheLinkParam: any;
  odspAdjudicationExceptionsLinkParam: any;
  myBenefitsLink: string;

  protected readonly ProgramType = ProgramType;

  constructor(public route: ActivatedRoute,
              private window: Window,
              private appSessionService: AppSessionService,
              protected authService: AuthService,
              public intakeService: IntakeService,
              private esignUtil: EsignUtil,
              public externalRouter: ExternalRouter,
              private router: Router,
              private emailService: EmailService,
              private authorizeService: CaseWorkerAuthorizeService,
              public translator: TranslateService,
              public dialog: MatDialog,
              public ngZone: NgZone, public idle: Idle,
              public configService: ConfigService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              private programTypeService: ProgramTypeService,
  ) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.confirmation;
    this.setupLinks();
    this.setProgramType();
    this.isErrorPage = this.router.url === '/error-page-with-ref-number';
  }

  getUrlForLang(lang: string|undefined, enUrl: string, frUrl: string): string{
    return lang === 'fr' ? frUrl : enUrl;
  }

  ngOnInit() {
    this.translator.onLangChange.subscribe((lang) => {
      this.setupLinks();
    });

    // # Reset username before the 2 sec delay for error confirmation page and confirmation page(PSE user story #55085)
    if (this.authService.isAuthorizedToSave()) {
      this.authService.setUserName('');
    }
    if (this.isErrorPage) {
      this.appSessionService.getAppReferenceNumber().pipe(
        tap(res => {
          this.referenceCode = res.referenceNumber;
          this.intakeService.getApplication(this.pageId).subscribe((data) => {
            this.caringForChild = this.isCaringForChild(data)
            this.acsdEligibility = EligibilityCheckUtil.acsdEligilityChecking(data);
          })
        }),
        switchMap(res => this.emailService.sendConfirmationEmail()),
        concatMap(() => this.authService.logout()),
        first()
      ).subscribe();
    } else {
      this.appSessionService.getAppReferenceNumber().pipe(
        tap(res => {
          this.referenceCode = res.referenceNumber;
          this.intakeService.getApplication(this.pageId).subscribe((data) => {
            this.family = this.esignUtil.parseSigners(data, this.translator.instant('consent.applicantType.spouse'),
              this.translator.instant('consent.applicantType.adultChild'))
            this.caringForChild = this.isCaringForChild(data)
            this.acsdEligibility = EligibilityCheckUtil.acsdEligilityChecking(data);
          })
        }),
        switchMap(res => this.emailService.sendConfirmationEmail()),
        concatMap(() => this.authService.logout()),
        first()
      ).subscribe();
    }

    window.scrollTo(0, 0)
  }

  onSubmit() {
    if (this.authorizeService.isIBauUser()) {
      return this.externalRouter.navigate(this.configService.getClientConfig()?.redirectUrl);
    }
    return this.externalRouter.navigate(this.configService.getUrl(this.translator.currentLang || this.translator.defaultLang,
      UrlInfo.socialAssistance));
  }

  private setupLinks(): void {
    this.tcaLink = this.configService.getUrl(LanguageInfo.english, UrlInfo.tca);
    this.acsdLink = this.configService.getUrl(LanguageInfo.english, UrlInfo.acsd);
    this.feedbackUrl = this.configService.getUrl(this.translator.currentLang || this.translator.defaultLang, UrlInfo.feedback);
    this.clearCacheLinkParam = {
      link1: this.configService.getUrl(this.translator.currentLang, UrlInfo.chromeClearCache),
      link2: this.configService.getUrl(this.translator.currentLang, UrlInfo.safariClearCache),
      link3: this.configService.getUrl(this.translator.currentLang, UrlInfo.edgeClearCache),
      link4: this.configService.getUrl(this.translator.currentLang, UrlInfo.fireboxClearCache)
    };
    this.myBenefitsLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.myBenefitsLogin);
    this.odspAdjudicationExceptionsLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.odspAdjudicationExceptionsLink)
    };
  }

  private setProgramType(): void{
    this.programTypeService.getProgramType(false).subscribe(response => {
      // Application created before ODSP release could have OW as program type
      if (response.programType === ProgramType.OW) {
        this.applicationProgramType = ProgramType.ONW;
      } else {
        this.applicationProgramType = response.programType;
      }
    });
  }

  isCaringForChild(data: ApplicationAnswers): boolean {
    return !data?.jsonData ? false : (data.jsonData.caringForChild && data.jsonData.caringForChild === 'yes')
      || (data.jsonData.additionalCaringForChild && data.jsonData.additionalCaringForChild === 'yes');
  }

  get isIBauUser() {
    return this.authorizeService.isIBauUser();
  }

  get appStatusCheckerUrl(): string {
    const ascUrl = 'applicationstatuschecker';
    const lang = this.translator.currentLang;
    return `/${lang}/${ascUrl}`;
  }

  handleMyBenefitBtn(){
    this.externalRouter.navigate(this.myBenefitsLink, true);
  }
}
