import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';

import {popup} from '../../../dialog-constants';
import {ApplicationAnswers} from '../../../models/data.model';
import {EidProgress, Person} from '../../../models/eid/person.model';
import {EidService} from '../../../services/eid.service';
import {CaseWorkerAuthorizeService} from '../../../services/caseWorkerAuthorizeService';
import {ESignatureService} from '../../../services/esignature.service';
import {IntakeService} from '../../../services/intake.service';
import {ButtonUtil} from '../../../utils/button-util';
import {Subject} from 'rxjs';
import {MccssDialogTitleComponent} from '../../../mccss-dialog-title/mccss-dialog-title.component';
import {BaseComponent} from '../../BaseComponent';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {ExternalRouter} from '../../../external.router';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {PageService} from '../../../services/page.service';
import {LoadingSpinnerService} from '../../../services/loading-spinner.service';
import {PageInfo} from '../../../models/page-map';

@Component({
  selector: 'app-confirm-identity',
  templateUrl: './eid-confirm-identity.component.html',
  styleUrls: ['./eid-confirm-identity.component.scss']
})
export class EidConfirmIdentityComponent extends BaseComponent implements OnInit, OnDestroy {
  protected readonly _destroyed$ = new Subject<void>();

  persons: Person[] = [];
  loaded = false;
  dialogVisible = false;
  eidStatus: string;
  data: ApplicationAnswers = {jsonData: {}};
  isAuthorizedUser = false;
  isConfirmIdentitySecondaryBtn = true;

  constructor(private eidService: EidService,
              public dialog: MatDialog,
              private router: Router,
              public route: ActivatedRoute,
              private eSignatureService: ESignatureService,
              public intakeService: IntakeService,
              private authorizeService: CaseWorkerAuthorizeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public externalRouter: ExternalRouter,
              public configService: ConfigService,
              public authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.eidPage;
  }

  ngOnInit(): void {
    this.isAuthorizedUser = this.authorizeService.isAuthorized()

    this.eidService.getEligiblePersons().subscribe(persons => {
      this.persons = persons
      this.loaded = this.persons != null
    }, error => this.loaded = true)

    this.intakeService.getApplication(this.pageId).subscribe(data => {
      this.data = data
    })

    this.eidStatus = '';

  }

  ngOnDestroy() {
    super.onDestroy()
    this._destroyed$.next(undefined);
    this._destroyed$.complete();
  }

  onStartClicked(person: Person) {
    this.router.navigate(['..', 'questions', person.personId],
      {
        relativeTo: this.route,
        replaceUrl: true
      })
  }

  onSubmit() {
    this.eidService.completeEidProcess().subscribe()
    this.eSignatureService.isApplicationESignEligible().subscribe((result) => {
      if (result) {
        this.navigateToESignature()
      } else {
        const buttonId = this.getContinueButtonId()
        ButtonUtil.toggleContinueButton(false, buttonId);
        this.intakeService.submitApplication().subscribe((submissionResponse) => {
          this.router.navigate(['/', PageInfo.confirmation],
            {
              queryParams: {
                esignredirect: false
              }
            })
        }, () => {
          ButtonUtil.toggleContinueButton(true, buttonId);
        })
      }
    })
  }

  hasNoUniqueEmails(): boolean{
    let childrenHasNoEmails = false;
    childrenHasNoEmails = this.data.jsonData.childList?.some(e => e.childNoUniqueEmail)

    return this.data.jsonData.noUniqueEmail || this.data.jsonData.spouseNoUniqueEmail || childrenHasNoEmails
  }

  getName(person: Person, index) {
    if(index === 0){
      this.isConfirmIdentitySecondaryBtn = true;
    }
    return person.names.join(' ')
  }

  private navigateToESignature() {
    this.router.navigate(['/', 'esignature'])
  }

  isComplete(): boolean {
    return this.loaded && this.persons?.find(person => person.eidProgress === undefined) == null
  }

  private skipEidForPerson(person: Person) {
    this.eidService.cancelEIdForApplicant(person.personId).subscribe(() => {
      this.eidService.updateEidProgress(person.personId, EidProgress.skipped)
    })
  }

  imageForPerson(person: Person) {
    switch (person.eidProgress) {
      case EidProgress.unavailable:
        return '#custom-icon-alert-warning'
      case EidProgress.completed:
        return '#ontario-icon-alert-success'
      case EidProgress.skipped:
        return '#ontario-icon-alert-error'
    }
  }

  progressTextForPerson(person: Person): boolean {
    switch (person.eidProgress) {
      case EidProgress.unavailable:
        this.eidStatus = 'eid-confirm-identity.status.unavailable'
        return true;
      case EidProgress.completed:
        this.eidStatus = 'eid-confirm-identity.status.complete'
        return true;
      case EidProgress.skipped:
        this.eidStatus = 'eid-confirm-identity.status.not.complete'
        return true;
    }
  }

  private confirmEidNow(person: Person) {
    this.router.navigate(['..', 'questions', person.personId],
      {
        relativeTo: this.route,
        replaceUrl: true
      })
  }

  private getContinueButtonId() {
    const hasNoUniqueEmailsIndicator = this.hasNoUniqueEmails()
    if (!this.isAuthorizedUser || !hasNoUniqueEmailsIndicator) {
      return '#submit-button'
    } else if (this.isAuthorizedUser && hasNoUniqueEmailsIndicator) {
      return '#submit-now-button'
    } else {
      return '#submit-button'
    }
  }

  public isSecondaryButton(){
    if(this.isConfirmIdentitySecondaryBtn){
      this.isConfirmIdentitySecondaryBtn = false;
      return false;
    }

    return true;
  }
}
