import {Injectable} from '@angular/core';
import {ProgramTypeService} from './program-type.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ProgramType} from '../models/program-type';
import {Observable} from 'rxjs';
import {ProgramTypeUpdateRequest} from '../models/program-type-update-request';
import {ProgramTypeResponse} from '../models/program-type-response';

@Injectable({
    providedIn: 'root'
})
export class ProgramTypeServiceImpl implements ProgramTypeService {

    constructor(private http: HttpClient) {
    }

    updateProgramType(request: ProgramTypeUpdateRequest) {
        return this.http.put<void>(`/v1/update-program-type`, request, {withCredentials: true});
    }

    getProgramType(isRecommendationsNeed: boolean=true): Observable<ProgramTypeResponse> {
      // tslint:disable-next-line:object-literal-shorthand
      return this.http.get<ProgramTypeResponse>(`/v1/program-type`, {params: { isRecommendationsNeed: isRecommendationsNeed }, withCredentials: true });
    }
}
