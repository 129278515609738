import {TranslateService} from '@ngx-translate/core';


export class TranslationUtil {

  static translateFrenchApplicantType (applicantRole: string, localeId: string) {
    if (localeId === 'fr') {
      if (applicantRole === 'Applicant'){
        return 'Demandeur'
      }else if (applicantRole === 'Spouse') {
        return 'Conjoint'
      }else if (applicantRole.split(' ')[0] === 'Child') {
        return `Enfant ${applicantRole.split(' ')[1]}`
      }
    }
    return applicantRole
  }

  static translateText (inputText: string, translator: TranslateService) {
    let translatedText = inputText
    if(inputText) {
      const s = translator.instant(inputText)
      if (s) {
        translatedText = s
      }
    }
    return translatedText
  }

  static isFrenchLang(lang: string) {
    return lang === 'fr' || lang === 'fr-CA';
  }
}
