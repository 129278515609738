import {FormToAppDataBase} from '../../../common/utils/converters/form-to-app-data-base';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {HHIDataConversionConfig} from '../model/hhi-data-conversion-config';

export abstract class BaseHHIFormToData extends FormToAppDataBase {
  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[], formToDataConfig: HHIDataConversionConfig): void {
    // remove previous saved data and repopulate with latest form data
    delete appData.jsonData[formToDataConfig.hasPaymentAttributeName];
    delete appData.jsonData[formToDataConfig.recipientsListAttributeName];
    delete appData.jsonData[formToDataConfig.incomeDetailAttributeName];

    if (form.get(formToDataConfig.questionPanelKey)?.value.panels[0][formToDataConfig.questionKey][0]) {
      appData.jsonData[formToDataConfig.hasPaymentAttributeName] = ['yes'];

      const panelQuestion = questions.find(q => q.key === formToDataConfig.questionPanelKey);

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, panelQuestion.panels[0].questions, formToDataConfig.questionPanelKey,
        formToDataConfig.questionKey, formToDataConfig.recipientsListAttributeName);

      // populate details list
      this.populateDetailsList(form, appData, panelQuestion.panels[0].questions, formToDataConfig);
    }
  }

  private populateCheckBoxList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[],
                               qPanelKey: string, qKey: string, recipientListName: string): void {
    const WHO_GETS_THE_PAYMENT_QKEY = qKey.concat('WhoGetsThePayment');
    const PAYMENT_PANEL_CHILD = 'WhoGetsThePaymentPanelChild';
    const PAYMENT_FORM_GROUP = qPanelKey.concat('.panels.0');
    const APPLICANT_PANEL = qPanelKey.concat('.panels.0.').concat(qKey).concat('WhoGetsThePaymentPanelApplicant')
    const SPOUSE_PANEL = qPanelKey.concat('.panels.0.').concat(qKey).concat('WhoGetsThePaymentPanelSpouse')
    const recipientList = [];
    const question = questions.find(q => q.key === WHO_GETS_THE_PAYMENT_QKEY);

    if (appData.jsonData.spouseFirstName || appData.jsonData.childList) {

      const applicantOption = question.options.find(o => o.label === 'financial-assets.applicant');

      if (this.isApplicantSelected(WHO_GETS_THE_PAYMENT_QKEY,
        form.get(PAYMENT_FORM_GROUP) as FormGroup, questions)) {
        if (form.get(APPLICANT_PANEL)?.value.panels[0].tryingOrReceivingThisIncome) {
          recipientList.push(applicantOption.alternateValue);
        } else if (form.get(APPLICANT_PANEL)?.value.panels[0].monthlyAmount) {
          recipientList.push(applicantOption.alternateValue);
        }
      }
    }

    if (this.isSpouseSelected(WHO_GETS_THE_PAYMENT_QKEY, form.get(PAYMENT_FORM_GROUP) as FormGroup, questions) &&
      (form.get(SPOUSE_PANEL)?.value.panels[0].tryingOrReceivingThisIncome || form.get(SPOUSE_PANEL)?.value.panels[0].monthlyAmount)) {
      const spouseOption = question.options.find(o => o.label === 'financial-assets.spouse');
      recipientList.push(spouseOption.alternateValue);
    }

    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++) {
        const CHILD_PANEL = PAYMENT_FORM_GROUP.concat('.').concat(qKey).concat(PAYMENT_PANEL_CHILD.concat(String(i)));

        if (form.get(CHILD_PANEL)?.value.panels[0].tryingOrReceivingThisIncome || form.get(CHILD_PANEL)?.value.panels[0].monthlyAmount) {
          const child = questions.find(q => q.key === qKey.concat(PAYMENT_PANEL_CHILD).concat(String(i)));

          if (this.isChildSelected(i, WHO_GETS_THE_PAYMENT_QKEY, form.get(PAYMENT_FORM_GROUP) as FormGroup, questions)) {
            recipientList.push(child.value);
          }
        }
      }
    }

    if (recipientList.length > 0) {
      appData.jsonData[recipientListName] = recipientList;
    }
  }

  abstract populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[],
                               formToDataConfig: HHIDataConversionConfig): void
}
