import {AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Question} from '../../common/utils/questions/question';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {QuestionControlService} from '../../common/utils/questions/question-control.service';
import {PageBaseComponent} from '../PageBaseComponent';
import {FinancialAssetsQuestionService} from './financial-assets-question.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AppDataToQuestionsConverter, FormDataToAppDataConverter} from './financial-assets-mapper';
import {FormValidator, selectOneOption} from './financial-assets-form-validator';
import {ExternalRouter} from '../../external.router';
import {SharedUtil} from '@shared/shared.util';
import {ConfigService} from '../../services/config.service';
import {PageScrollingUtil} from '../../utils/page-scrolling-util';
import {AuthService} from '../../services/auth.service';
import {PageInfo} from '../../models/page-map';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-financial-assets',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './financial-assets.component.html',
  styleUrls: ['./financial-assets.component.scss']
})
export class FinancialAssetsComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  showError = false;
  selectAnOptionError = false;
  questions: Question<string>[];

  constructor(private fb: FormBuilder, private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              private questionService: FinancialAssetsQuestionService,
              private qcs: QuestionControlService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public externalRouter: ExternalRouter,
              private appDataToQuestionsConverter: AppDataToQuestionsConverter,
              private formDataToAppDataConverter: FormDataToAppDataConverter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.financialAssets;
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    this.initializeForm();

    // convert applicationAnswers to questions
    this.questions = [...this.questionService.getFinancialAssetsQuestions()];
    this.appDataToQuestionsConverter.convert(this.applicationAnswers, this.questions);

    // generate form controls based on questions
    this.form  = this.qcs.toFormGroup(this.questions);

  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  postInitializeForm() {}

  preSaveApplication() {}

  onSubmit(toContinue: boolean) {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;
    this.selectAnOptionError = false;

    if (FormValidator.validateForm(this.form)) {
      // convert form data to applicationAnswers JSON
      this.formDataToAppDataConverter.convert(this.form, this.applicationAnswers, this.questions);
      this.buttonContinueClick = true
      const shouldExcludeDbd = SharedUtil.checkIfDbdShouldBeExcluded(this.applicationAnswers)
      if (shouldExcludeDbd) {
        // remove dbd bank information if they exist
        this.deleteDbdData()
      }

      this.subscriptions$.push(this.saveToIntake()?.pipe(distinctUntilChanged()).subscribe(() => {
        if (toContinue) {
          this.navigateTo(shouldExcludeDbd);
        } else {
          this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
        }
      }));
    } else {
      this.showError = true;
      this.selectAnOptionError = selectOneOption(this.form);

      if (!toContinue) { // save and exit scenario
        this.scrollToInvalidFormControl(toContinue)
      } else {
        if (this.selectAnOptionError){
          PageScrollingUtil.scrollToElement(PageScrollingUtil.locateElement('sd-dynamic-question'))
        } else {
          this.scrollToInvalidFormControl(toContinue);
        }
      }
    }
  }

  private navigateTo(shouldExcludeDbd: boolean) {
      if (shouldExcludeDbd) {
        this.intake.setShouldExcludeDbd(true);
        this.router.navigate(['/', 'intake', PageInfo.review]);
      } else {
        this.intake.setShouldExcludeDbd(false);
        this.router.navigate(['/', 'intake', PageInfo.bankDetails])
      }
  }

  get financialAssetsQuestions(): Question<string>[] {
    return this.questions;
  }

  deleteDbdData(){
    // remove dbd bank information if they exist
    delete this.applicationAnswers.jsonData.provideBankDetails
    delete this.applicationAnswers.jsonData.bankName
    delete this.applicationAnswers.jsonData.accountValue
    delete this.applicationAnswers.jsonData.accountNumber
    delete this.applicationAnswers.jsonData.accountType
    delete this.applicationAnswers.jsonData.institutionNumber
    delete this.applicationAnswers.jsonData.branchNumber
  }

}
