import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EidService} from './eid.service';
import {EidProgress, Person} from '../models/eid/person.model';
import {QuestionList} from '../models/eid/question-list.model';
import {AnswerList} from '../models/eid/answer-list.model';

@Injectable({
  providedIn: 'root'
})
export class EidServiceImpl implements EidService {
  private eligiblePersonsSource = new BehaviorSubject<Person[]>(undefined);
  eligiblePersons = this.eligiblePersonsSource.asObservable();

  constructor(private http: HttpClient) {
  }

  getEligiblePersons(): Observable<Person[]> {
    return this.eligiblePersons;
  }

  setEligiblePersons(persons: any) {
    this.eligiblePersonsSource.next(persons)
  }

  isApplicationEligible() {
    this.eligiblePersonsSource.next(null)
    return this.http.get<Person[]>(`/v1/eid/application/eligible`, {withCredentials: true});
  }

  readEIdApplicantQuestions(personId: string): Observable<QuestionList> {
    return this.http.get<QuestionList>(`/v1/eid/applicant/${personId}/questions`, {withCredentials: true});
  }

  cancelEIdForApplicant(personId:string): Observable<void> {
    return this.http.patch<void>(`/v1/eid/applicant/${personId}`,{withCredentials: true});
  }

  answerEIdApplicantQuestions(personId: string, answerList: AnswerList): Observable<void> {
    return this.http.post<void>(`/v1/eid/applicant/${personId}/answers`, answerList, {withCredentials: true});
  }

  updateEidProgress(personId: string, eidProgress: EidProgress) {
    this.eligiblePersonsSource.getValue().find(person => {
      return personId === person.personId
    }).eidProgress = eidProgress
  }

  skipEidProcess(applicationId: string): Observable<void>{
    return this.http.put<void>(`/v1/eid/application/${applicationId}/skip`, {}, {withCredentials: true});
  }

  completeEidProcess(): Observable<void>{
    return this.http.patch<void>(`/v1/eid/application/complete`, {}, {withCredentials: true});
  }
}
