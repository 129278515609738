<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <div id="page-alerts-container">
    <sd-info-text header="sponsorship.page.info.header"
                  contentId="sponsorshipPageInfo"
                  content="sponsorship.page.info.content">
    </sd-info-text>
  </div>

  <sd-alert-err *ngIf="selectAnOptionError"
                [errorId]="'selectAnOptionError'"
                [errorMessage]="'error.select.an.option' | translate">
  </sd-alert-err>

  <form [formGroup]="form" sdDisableAutocomplete>
    <div class="form-group required">
      <sd-dynamic-container [id]="'sponsorship.select.all'"
                            [showError]="showError"
                            [questions]="sponsorshipQuestions">
      </sd-dynamic-container>
    </div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
