import {Component, Input, OnInit} from '@angular/core';
import {ProgramType} from '../../../models/program-type';
import {ControlContainer, FormBuilder, FormGroup} from '@angular/forms';
import {UrlInfo} from '../../../models/url-map';
import {ConfigService} from '../../../services/config.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'sd-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.scss']
})
export class ProgramCardComponent implements OnInit {

  @Input()
  displayImage: boolean;

  @Input()
  programType: string;

  @Input()
  isRecommended: boolean;

  // To mark the control selected initially on load
  @Input()
  markSelected: boolean;

  @Input()
  controlName: string

  form: FormGroup;

  protected readonly ProgramType = ProgramType;
  applyForOdspLinkParam: any;
  ontarioWorksLinkParam: any;
  odspAdjudicationExceptionsLinkParam: any;

  inputId: string;
  labelId: string;
  titleId: string;
  titleContent: string;
  imagePath: string;
  imageAlt: string;

  constructor(public controlContainer: ControlContainer, public formBuilder: FormBuilder,
              public translator: TranslateService, private configService: ConfigService) {
    this.setupLinks();
  }

  ngOnInit(): void {
    this.form = this.controlContainer?.control as FormGroup
    this.initialize();
    this.translator.onLangChange.subscribe((lang) => {
      this.setupLinks();
    });
    if (this.markSelected) {
      this.form.get(this.controlName).setValue(this.programType);
    }
  }

  private setupLinks(): void {
    this.applyForOdspLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.applyForOdsp)
    };
    this.odspAdjudicationExceptionsLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.odspAdjudicationExceptionsLink)
    };
    this.ontarioWorksLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.ontarioWorks)
    };

  }

  initialize() {
    this.inputId = this.programType.toLowerCase().concat('-radio-button-option');
    this.labelId = this.programType.toLowerCase().concat('-radio-button-option-label');
    this.titleId = this.programType.toLowerCase().concat('-title');
    this.titleContent = `program-recommendation.${this.programType.toLowerCase()}.title`;
    switch (this.programType) {
      case ProgramType.ONW: {
        this.imagePath = 'assets/images/OW.jpg';
        this.imageAlt = 'program-recommendation.onw.image.alt';
        break;
      }
      case ProgramType.ODS: {
        this.imagePath = 'assets/images/ODSP.jpg';
        this.imageAlt = 'program-recommendation.ods.image.alt';
        break;
      }
      case ProgramType.ONWODS: {
        this.imagePath = 'assets/images/OW_and_ODSP.jpg';
        this.imageAlt = 'program-recommendation.onwods.image.alt';
        break;
      }
      default: {
        this.imagePath = 'assets/images/OW.jpg';
        this.imageAlt = 'program-recommendation.onw.image.alt';
        break;
      }
    }
  }

  get programTypeContext(): string {
    return this.translator.instant(`program-recommendation.${this.programType.toLowerCase()}.title`);
  }
}
