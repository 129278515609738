import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {HHIDataConversionConfig} from '../model/hhi-data-conversion-config';
import {
  addChildOptions,
  addSpouseOption,
  hasApplicantData,
  isSingleApplicant
} from '../../../common/utils/dynamic-panel/dynamic-panel.util';

export class BaseAppDataToHHIQuestions {

  populatePaymentCheckBoxValue(appData: ApplicationAnswers, question: Question<string>,
                               dataConversionConfig: HHIDataConversionConfig) {
    if (appData.jsonData[dataConversionConfig.hasPaymentAttributeName] &&
      appData.jsonData[dataConversionConfig.hasPaymentAttributeName][0] === 'yes') {
      const hasPaymentCheckBox = question.panels[0].questions.find(q => q.key === dataConversionConfig.questionKey);
      hasPaymentCheckBox.options[0].value = true;
    }
  }

  addSingleOrFamilyOptions(appData: ApplicationAnswers, question: Question<string>,
                           dataConversionConfig: HHIDataConversionConfig): void {
    const whoGetsThePaymentQKey = dataConversionConfig.questionKey.concat('WhoGetsThePayment')
    const whoGetsThisPayment = question.panels[0].questions.find(q => q.key === whoGetsThePaymentQKey);
    whoGetsThisPayment.options[0].labelParam = {name: appData.jsonData.firstName};
    whoGetsThisPayment.options[0].alternateValue = 'Applicant: '.concat(appData.jsonData.firstName);
    whoGetsThisPayment.options[0].value = !!hasApplicantData(whoGetsThisPayment.options[0].alternateValue,
      appData.jsonData[dataConversionConfig.incomeDetailAttributeName]);

    if (isSingleApplicant(appData)) {
      whoGetsThisPayment.options[0].value = true;
      whoGetsThisPayment.visible = false;
      delete whoGetsThisPayment.visibleOnConditions;
    }

    if (appData.jsonData.spouseFirstName) {
      addSpouseOption(appData, whoGetsThisPayment, appData?.jsonData[dataConversionConfig.recipientsListAttributeName]);
    }

    if (appData.jsonData.childList) {
      addChildOptions(appData, whoGetsThisPayment, appData?.jsonData[dataConversionConfig.recipientsListAttributeName]);
    }
  }
}
