import {Observable} from 'rxjs';

/**
 * This class encapsulates page-level service requests.
 */
export abstract class PageService {
  abstract logout(redirectToUrl: string, envName?: string): Observable<string>;

  abstract findRouteUrl(pageName: string): string[];
}
