<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [disableScrollEvent]="true"
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">

  <p class="ontario-label">{{'financial-assets.heading' | translate}}</p>

  <p class="ontario-label">{{'financial-assets.select.all' | translate}}</p>

  <sd-alert-err *ngIf="selectAnOptionError" [errorId]="'selectAnOptionError'" [errorMessage]="'error.select.an.option' | translate"></sd-alert-err>

  <form [formGroup]="form" sdDisableAutocomplete>

    <div class="form-group required">

      <sd-dynamic-container [id]="'financial-assets.select.all'"
                            [showError]="showError"
                            [questions]="financialAssetsQuestions">

      </sd-dynamic-container>

    </div>

    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
