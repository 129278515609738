<sd-page-container [pageId]="pageId" [showPsRedirectError]="showPsRedirectError">
  <div id="page-alerts-container">
    <div *ngIf="existingSessionWarning">
      <sd-warning-text [title]="'start-one.multi-tab.warning.title'">
        <div id="warning-content">
          {{'start-one.multi-tab.warning.content' | translate}}
        </div>
      </sd-warning-text>
    </div>
    <sd-alert-error-box *ngIf="errorCode"
                        [errorHeader]="errorHeader | translate"
                        [errorBody]="'error.' + errorCode | translate: psSignInUrl">
    </sd-alert-error-box>
    <div *ngFor="let banner of banners" [ngClass]="{'ontario-margin-top-32-!': !errorCode && !existingSessionWarning}" [class]="getBannerClass(banner.type)">
      <div class="ontario-alert__header">
        <div class="ontario-alert__header-icon">
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use [attr.href]="getBannerIcon(banner.type)"></use>
          </svg>
        </div>
        <h2 class="ontario-alert__header-title ontario-h4">
          <span>{{ translator.currentLang === 'en' ? banner.headerEn : banner.headerFr }}</span>
        </h2>
      </div>
      <div class="ontario-alert__body sm-custom-body">
        <p id="alert-banner-content" [innerHTML]="translator.currentLang === 'en' ? banner.messageEn : banner.messageFr"></p>
      </div>
    </div>
  </div>
  <div class="ontario-margin-left-16-! ontario-margin-right-16-!">
    <div class="banner-apply-btn-container ontario-margin-bottom-32-!">
      <div class="apply-btn-container">
        <div>
          <h1 class="ontario-h1 ontario-margin-bottom-32-!">{{'start-one.apply.heading' | translate}}</h1>
          <p class="ontario-margin-bottom-32-!" [innerHTML]="'start-one.apply.body' | translate"></p>
          <app-mccss-submit-button dataE2E="continueBtn" buttonLabel="{{'start-one.start.application.button' | translate}}" [preventDoubleClick]="true" (handleClick)="onSubmit()"></app-mccss-submit-button>
        </div>
      </div>
      <div class="banner-img-container">
        <img class="banner-img" src="assets/images/banner.png" alt="{{'start-one.banner.alt' | translate}}" />
      </div>
    </div>
    <div class="flex-box-container ontario-margin-bottom-32-!">
      <a class="flex-box-hyperlink-container flex-box-text" href="{{myBenefitsLink}}" tabindex="-1">
        <div class="flex-box-item ontario-padding-bottom-24-!">
          <div class="flex-box-content ontario-padding-top-24-! ontario-padding-right-custom ontario-padding-left-custom">
            <img class="icon-img ontario-margin-bottom-12-!" src="assets/images/continue-application-icon.png" alt="" />
            <h2 class="ontario-h4">{{'start-one.continue.application.heading' | translate}}</h2>
            <span [innerHTML]="'start-one.continue.application.content' | translate:{link: myBenefitsLink}"></span>
          </div>
        </div>
      </a>
      <a class="flex-box-text flex-box-hyperlink-container" href="{{applicationStatusLink}}" tabindex="-1">
        <div class="flex-box-item ontario-padding-bottom-24-!">
          <div class="flex-box-content ontario-padding-top-24-! ontario-padding-right-custom ontario-padding-left-custom">
            <img class="icon-img ontario-margin-bottom-12-!" src="assets/images/application-status-icon.png" alt="" />
            <h2 class="ontario-h4">{{'start-one.application.status.heading' | translate}}</h2>
            <p [innerHTML]="'start-one.application.status.content' | translate: {link: applicationStatusLink}"></p>
          </div>
        </div>
      </a>
      <a class="flex-box-text flex-box-hyperlink-container " href="tel:18889991142" target='_blank' tabindex="-1">
        <div class="flex-box-item ontario-padding-bottom-24-!">
          <div class="flex-box-content ontario-padding-top-24-! ontario-padding-right-custom ontario-padding-left-custom">
            <img class="icon-img ontario-margin-bottom-12-!" src="assets/images/need-help-icon.png" alt="" />
            <h2 class="ontario-h4">{{'start-one.need.help.heading' | translate}}</h2>
            <p [innerHTML]="'start-one.need.help.content' | translate"></p>
          </div>
        </div>
      </a>
    </div>
    <div class="ontario-row">
      <div class="ontario-columns ontario-medium-6 ontario-small-12 ontario-padding-right-32-!">
        <h2 class="ontario-h3 ontario-margin-bottom-24-!">{{'start-one.bua' | translate}}</h2>
        <div class="ontario-margin-bottom-24-!">
          <span [innerHTML]="'start-one.bua.list1' | translate"></span>
        </div>
        <div class="ontario-margin-bottom-24-!">
          <span [innerHTML]="'start-one.bua.list2' | translate"></span>
        </div>
        <div class="ontario-margin-bottom-24-!">
          <span [innerHTML]="'start-one.bua.list3' | translate"></span>
        </div>
        <div class="ontario-margin-bottom-24-!">
          <ul>
            <li>{{'start-one.bua.list4.item1' | translate}}</li>
            <li>{{'start-one.bua.list4.item2' | translate}}</li>
            <li>{{'start-one.bua.list4.item3' | translate}}</li>
            <li>{{'start-one.bua.list4.item4' | translate}}</li>
          </ul>
        </div>
      </div>
      <div class="ontario-columns ontario-medium-6 ontario-small-12 ontario-margin-bottom-32-!">
        <img class="device-img" src="{{'start-one.cell.phones.image' | translate}}" alt="{{'start-one.cell.phones.image.alt' | translate}}" />
      </div>
    </div>
    <div class="resource-container ontario-margin-bottom-32-!">
      <div class="center-container">
        <h2 class="ontario-h3 ontario-margin-bottom-32-!">{{'start-one.explore.resources' | translate}}</h2>
      </div>
      <div class="flex-box-container ontario-margin-bottom-32-!">
        <a class="flex-box-hyperlink-container flex-box-text" href="officeLocatorLink" tabindex="-1">
          <div class="flex-box-item ontario-padding-bottom-24-!">
            <div class="flex-box-content ontario-padding-top-24-! ontario-padding-right-custom ontario-padding-left-custom">
              <img class="icon-img ontario-margin-bottom-12-!" src="assets/images/ontario-works-office-icon.png" alt="" />
              <h2 class="ontario-h4" [innerHTML]="'start-one.find.local.office.heading' | translate"></h2>
              <span [innerHTML]="'start-one.find.local.office.content' | translate: {link: officeLocatorLink}"></span>
            </div>
          </div>
        </a>
        <a class="flex-box-hyperlink-container flex-box-text" href="{{ontarioWorksSectionLink}}" tabindex="-1">
          <div class="flex-box-item ontario-padding-bottom-24-!">
            <div class="flex-box-content ontario-padding-top-24-! ontario-padding-right-custom ontario-padding-left-custom">
              <img class="icon-img ontario-margin-bottom-12-!" src="assets/images/learn-more-ontario-works-icon.png" alt="" />
              <h2 class="ontario-h4" [innerHTML]="'start-one.learn.more.heading' | translate"></h2>
              <span [innerHTML]="'start-one.learn.more.content' | translate: {link: ontarioWorksSectionLink}"></span>
            </div>
          </div>
        </a>
        <a class="flex-box-hyperlink-container flex-box-text" href="{{benefitsFinderLink}}" tabindex="-1">
          <div class="flex-box-item ontario-padding-bottom-24-!">
            <div class="flex-box-content ontario-padding-top-24-! ontario-padding-right-32-! ontario-padding-left-32-!">
              <img class="icon-img ontario-margin-bottom-12-!" src="assets/images/find-benefits-and-programs-icon.png" alt="" />
              <h2 class="ontario-h4">{{'start-one.find.benefits.heading' | translate}}</h2>
              <span [innerHTML]="'start-one.find.benefits.content' | translate: {link: benefitsFinderLink}"></span>
            </div>
          </div>
        </a>
      </div>
      <div class="flex-box-container ontario-margin-bottom-32-!">
        <a class="flex-box-hyperlink-container flex-box-text" href="{{contactUsSendEmailLink}}" tabindex="-1">
          <div class="flex-box-item ontario-padding-bottom-24-!">
            <div class="flex-box-content ontario-padding-top-24-! ontario-padding-right-custom ontario-padding-left-custom">
              <img class="icon-img ontario-margin-bottom-12-!" src="assets/images/contact-us-icon.png" alt="" />
              <h2 class="ontario-h4">{{'start-one.contact.us.heading' | translate}}</h2>
              <span [innerHTML]="'start-one.contact.us.content' | translate: {link: contactUsSendEmailLink}"></span>
            </div>
          </div>
        </a>
        <a class="flex-box-hyperlink-container flex-box-text" href="{{privacySecurityLearnMoreLink}}" tabindex="-1">
          <div class="flex-box-item ontario-padding-bottom-24-!">
            <div class="flex-box-content ontario-padding-top-24-! ontario-padding-right-custom ontario-padding-left-custom">
              <img class="icon-img ontario-margin-bottom-12-!" src="assets/images/privacy-security-icon.png" alt="" />
              <h2 class="ontario-h4">{{'start-one.privacy.security.heading' | translate}}</h2>
              <span [innerHTML]="'start-one.privacy.security.content' | translate: {link: privacySecurityLearnMoreLink}"></span>
            </div>
          </div>
        </a>
        <a class="flex-box-hyperlink-container flex-box-text" href="{{odspLink}}" tabindex="-1">
          <div class="flex-box-item ontario-padding-bottom-24-!">
            <div class="flex-box-content ontario-padding-top-24-! ontario-padding-right-32-! ontario-padding-left-32-!">
              <img class="icon-img ontario-margin-bottom-12-!" src="assets/images/odsp-icon.png" alt="" />
              <h2 class="ontario-h4">{{'start-one.odsp.heading' | translate}}</h2>
              <span [innerHTML]="'start-one.odsp.content' | translate: {link: odspLink}"></span>
            </div>
          </div>
        </a>
      </div>
      <div class="ontario-padding-bottom-32-!" [innerHTML]="'start-one.let.us.know' | translate: {link: suspectFraudLink}"></div>
    </div>
  </div>
</sd-page-container>
