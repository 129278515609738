<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <div id="page-alerts-container">
    <sd-info-text header="confirm-address.homeAddressTitle"
                  contentId="pageAlertContentId"
                  content="address-information.homeless.text"
                  [contentParam]="officeLocatorLinkParam">
    </sd-info-text>
  </div>
  <form [formGroup]="form" sdDisableAutocomplete>
    <div class="form-group required">
      <sd-radio-question [showError]="showError"
                         [id]="'address-information.remoteNorthernCommunityRoadAccess'"
                         [required]="true"
                         [context]="'address-information.remoteNorthernCommunityRoadAccess.context'"
                         controlName="remoteNorthernCommunityRoadAccess">
      </sd-radio-question>
      <sd-radio-question [choices]="deliveryTypeChoices"
                         [showError]="showError" [id]="'address-information.deliveryType'" [required]="true"
                         controlName="deliveryType">
      </sd-radio-question>
      <sd-alert-err *ngIf="homeAddressStreetNoMismatch"
                    [errorId]="'address-information.home-addr-street-no-mismatch'"
                    [errorMessage]="'address-information.home-addr-street-no-mismatch' | translate"
                    [isInnerHtml]="'true'">
      </sd-alert-err>
      <sd-text-question *ngIf="homeAddressDeliveryType === 'Rural route'"
                        [showError]="showError"
                        [id]="'address-information.ruralRoute'"
                        [validationFns]="ruralRouteValidationFunction"
                        [characterWidth]= 20
                        [minLength]= 1 [maxLength]= 60 [capitalizeFirstLetter]= true
                        [required]="homeAddressDeliveryType === 'Rural route'"
                        controlName="ruralRoute">
      </sd-text-question>
      <sd-text-question [showError]="showError" [id]="'address-information.apartmentNumber'"
                        [validationFns]="apartmentNumberValidationFunction"
                        [characterWidth]= 20
                        [minLength]= 1 [maxLength]= 50 [capitalizeFirstLetter]= true
                        controlName="apartmentNumber">
      </sd-text-question>
      <sd-text-question [showError]="showError" [id]="'address-information.streetNumber'"
                        [validationFns]="streetNumberValidationFunction"
                        [characterWidth]= 20 [capitalizeFirstLetter]= true
                        [minLength]= 1 [maxLength]= 60
                        [required]="homeAddressDeliveryType !== 'Rural route'"
                        controlName="streetNumber">
      </sd-text-question>
      <sd-dropdown-question [choices]="streetNumberSuffixChoices" [showError]="showError" [id]="'address-information.streetNumberSuffix'"
                            [required]="false"
                            controlName="streetNumberSuffix">
      </sd-dropdown-question>
      <sd-text-question [showError]="showError" [id]="'address-information.streetName'"
                        [validationFns]="streetNameValidationFunction"
                        [characterWidth]= 20 [truncateMultipleSpaces]="true"
                        [minLength]= 1 [maxLength]= 60 [capitalizeFirstLetter]= true
                        [required]="homeAddressDeliveryType !== 'Rural route'"
                        controlName="streetName">
      </sd-text-question>
      <sd-dropdown-question [choices]="streetTypeSuffixChoices" [showError]="showError"
                            [id]="'address-information.streetType'"
                            [required]="homeAddressDeliveryType !== 'Rural route'"
                            controlName="streetType">
      </sd-dropdown-question>
      <sd-dropdown-question [choices]="directionChoices" [showError]="showError" [id]="'address-information.direction'"
                            [required]="false"
                            controlName="direction">
      </sd-dropdown-question>
      <sd-text-question [showError]="showError" [id]="'address-information.cityOrTown'"
                        [validationFns]="cityOrTownValidationFunction" [capitalizeFirstLetter]= true
                        [minLength]= 1 [maxLength]= 50 [required]="true"
                        [characterWidth]= 20 [truncateMultipleSpaces]="true"
                        controlName="cityOrTown">
      </sd-text-question>
      <sd-text-question [showError]="showError" [id]="'address-information.province'"
                        [required]="true"
                        [characterWidth]= 20
                        controlName="province">
      </sd-text-question>
      <sd-text-question [showError]="showError" [showCustomError]="invalidPostalCodeError"
                        [showCustomErrorParam]="onlineSocialAssistanceLinkParam" [id]="'address-information.postalCode'"
                        [validationRegex]="postalCodeRegex" [validationRegexError]="'error.invalid.postalCode'"
                        [capitalize]=true [required]=true
                        [characterWidth]= 10
                        hintText="address-information.postalCode.hintText"
                        hintExpandableContent="address-information.postalCode.expandableHintText"
                        [hintExpandableContentParam]="postalCodeFinderLinkParam"
                        controlName="postalCode">
      </sd-text-question>

      <div>&nbsp;</div>

      <sd-checkbox-question [id]="'address-information.addressSame'" [options]="addressSameCheckBoxItems"
                            [showLabel]=false
                            (toggle)="onAddressSameCheckBoxChanges($event)">
      </sd-checkbox-question>

      <div [ngClass]="mailingAddressStyle">
        <sd-alert-err *ngIf="mailingAddressStreetNoMismatch && !isAddressSame"
                      [errorId]="'address-information.mailing-addr-street-no-mismatch'"
                      [errorMessage]="'address-information.home-addr-street-no-mismatch' | translate"
                      [isInnerHtml]="'true'">
        </sd-alert-err>
        <sd-dropdown-question *ngIf="!isAddressSame"
                              [choices]="mailingAddressDeliveryTypeChoices" [showError]="showError"
                              [id]="'address-information.mDeliveryType'"
                              [required]="!isAddressSame"
                              controlName="mDeliveryType">
        </sd-dropdown-question>
        <sd-text-question *ngIf="mailingAddressDeliveryType === 'Rural route' && !isAddressSame"
                          [showError]="showError"
                          [id]="'address-information.mRuralRoute'"
                          [validationFns]="ruralRouteValidationFunction"
                          [minLength]= 1 [maxLength]= 60 [capitalizeFirstLetter]= true
                          [characterWidth]=20
                          [required]="mailingAddressDeliveryType === 'Rural route' && !isAddressSame"
                          controlName="mRuralRoute">
        </sd-text-question>
        <sd-text-question *ngIf="mailingAddressDeliveryType === 'PO Box' && !isAddressSame"
                          [showError]="showError"
                          [id]="'address-information.mPoBox'"
                          [validationFns]="mPoBoxValidationFunction"
                          [minLength]= 1 [maxLength]= 60 [capitalizeFirstLetter]= true
                          [required]="mailingAddressDeliveryType === 'PO Box' && !isAddressSame"
                          controlName="mPoBox">
        </sd-text-question>
        <sd-text-question *ngIf="mailingAddressDeliveryType === 'PO Box' && !isAddressSame"
                          [showError]="showError"
                          [id]="'address-information.mStation'"
                          [validationFns]="mStationValidationFunction"
                          [minLength]= 1 [maxLength]= 60 [capitalizeFirstLetter]= true
                          [required]="mailingAddressDeliveryType === 'PO Box' && !isAddressSame"
                          controlName="mStation">
        </sd-text-question>
        <sd-text-question *ngIf="mailingAddressDeliveryType === 'General delivery' && !isAddressSame"
                          [showError]="showError"
                          [id]="'address-information.mGeneralDelivery'"
                          [validationFns]="mGeneralDeliveryValidationFunction"
                          [capitalizeFirstLetter]= true
                          [required]="mailingAddressDeliveryType === 'General delivery' && !isAddressSame"
                          controlName="mGeneralDelivery">
        </sd-text-question>
        <sd-text-question *ngIf="mailingAddressDeliveryType !== 'General delivery' && !isAddressSame"
                          [showError]="showError" [id]="'address-information.mApartmentNumber'"
                          [validationFns]="apartmentNumberValidationFunction"
                          [characterWidth]=20
                          [minLength]= 1 [maxLength]= 50 [capitalizeFirstLetter]= true
                          controlName="mApartmentNumber">
        </sd-text-question>
        <sd-text-question *ngIf="mailingAddressDeliveryType !== 'General delivery' && !isAddressSame"
                          [showError]="showError" [id]="'address-information.mStreetNumber'"
                          [validationFns]="streetNumberValidationFunction"
                          [characterWidth]=20
                          [minLength]= 1 [maxLength]= 60 [capitalizeFirstLetter]= true
                          [required]="mailingAddressDeliveryType === 'Standard street address' && !isAddressSame"
                          controlName="mStreetNumber">
        </sd-text-question>
        <sd-dropdown-question *ngIf="mailingAddressDeliveryType !== 'General delivery' && !isAddressSame"
                              [choices]="streetNumberSuffixChoices" [showError]="showError"
                              [id]="'address-information.mStreetNumberSuffix'"
                              [required]="false"
                              controlName="mStreetNumberSuffix">
        </sd-dropdown-question>
        <sd-text-question *ngIf="mailingAddressDeliveryType !== 'General delivery' && !isAddressSame"
                          [showError]="showError" [id]="'address-information.mStreetName'"
                          [validationFns]="streetNameValidationFunction"
                          [characterWidth]=20
                          [minLength]= 1 [maxLength]= 60 [capitalizeFirstLetter]= true
                          [required]="mailingAddressDeliveryType === 'Standard street address' && !isAddressSame"
                          controlName="mStreetName">
        </sd-text-question>
        <sd-dropdown-question *ngIf="mailingAddressDeliveryType !== 'General delivery' && !isAddressSame"
                              [choices]="streetTypeSuffixChoices" [showError]="showError"
                              [id]="'address-information.mStreetType'"
                              [required]="mailingAddressDeliveryType === 'Standard street address' && !isAddressSame"
                              controlName="mStreetType">
        </sd-dropdown-question>
        <sd-dropdown-question *ngIf="mailingAddressDeliveryType !== 'General delivery' && !isAddressSame"
                              [choices]="directionChoices" [showError]="showError"
                              [id]="'address-information.mDirection'"
                              controlName="mDirection">
        </sd-dropdown-question>
        <sd-text-question *ngIf="!isAddressSame"
                          [showError]="showError" [id]="'address-information.mCityOrTown'"
                          [validationFns]="cityOrTownValidationFunction"
                          [characterWidth]=20
                          [minLength]= 1 [maxLength]= 50 [capitalizeFirstLetter]= true
                          [required]="!isAddressSame"
                          controlName="mCityOrTown">
        </sd-text-question>
        <sd-dropdown-question *ngIf="!isAddressSame"
                              [choices]="mailingAddressProvinceChoice" [showError]="showError"
                              [id]="'address-information.mProvince'"
                              [required]="!isAddressSame"
                              controlName="mProvince">
        </sd-dropdown-question>
        <sd-text-question *ngIf="!isAddressSame"
                          [showError]="showError"
                          [id]="'address-information.mPostalCode'"
                          [validationRegex]="postalCodeRegex" [validationRegexError]="'error.invalid.postalCode'"
                          [capitalize]=true [required]="!isAddressSame"
                          [characterWidth]=10
                          hintText="address-information.postalCode.hintText"
                          hintExpandableContent="address-information.postalCode.expandableHintText"
                          [hintExpandableContentParam]="postalCodeFinderLinkParam"
                          controlName="mPostalCode">
        </sd-text-question>
      </div>
    </div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
