import {AfterViewChecked, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Idle} from '@ng-idle/core';
import {TranslateService} from '@ngx-translate/core';

import {AppDataToSponsorshipQuestion} from './converter/app-data-to-questions';
import {SponsorshipQuestionsToAppData} from './converter/questions-to-app-data';
import {SponsorshipQuestionService} from './sponsorship-question.service';
import {PageBaseComponent} from '../PageBaseComponent';
import {Question} from '../../common/utils/questions/question';
import {QuestionControlService} from '../../common/utils/questions/question-control.service';
import {ExternalRouter} from '../../external.router';
import {IntakeService} from '../../services/intake.service';
import {ConfigService} from '../../services/config.service';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'sd-sponsorship',
  templateUrl: './sponsorship.component.html',
  styleUrls: ['./sponsorship.component.scss']
})
export class SponsorshipComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  toContinue: boolean;
  showError = false;
  selectAnOptionError = false;
  questions: Question<string>[];

  constructor(private fb: FormBuilder, private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              private questionService: SponsorshipQuestionService,
              private qcs: QuestionControlService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.sponsorship;
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    this.initializeForm();

    // Convert applicationAnswers to questions.
    this.questions = [...this.questionService.getSponsorshipQuestions(this.translator, this.applicationAnswers)];
    // Setup the questions and populate applicationAnswers to them if exists.
    new AppDataToSponsorshipQuestion().convert(this.applicationAnswers, this.questions, this.translator);

    // Generate form controls based on questions.
    this.form  = this.qcs.toFormGroup(this.questions);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  postInitializeForm() {}

  preSaveApplication() {}

  onSubmit(toContinue: boolean) {
    this.toContinue = toContinue;
    this.showRequiredInfoBanner = false;
    if (this.validateForm(this.form)) {
      // Convert form data to applicationAnswers JSON.
      new SponsorshipQuestionsToAppData().convert(this.form, this.applicationAnswers, this.questions, this.translator);
      this.buttonContinueClick = true

      this.subscriptions$.push(this.saveToIntake()?.pipe(distinctUntilChanged()).subscribe(() => {
        if (toContinue) {
          this.router.navigate(['/', 'intake', PageInfo.addressInfo])
        } else {
          this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
        }
      }));
    } else {
      this.showError = true;
      this.scrollToInvalidFormControl(toContinue)
    }
  }

  get sponsorshipQuestions(): Question<string>[] {
    return this.questions;
  }

  private validateForm(form: FormGroup): boolean {
    return form.valid;
  }
}
