import {Component, Input, OnInit} from '@angular/core';

import {ConfigService} from '../../../services/config.service';
import {CreateAccountReminderParameter} from './create-account-reminder-parameter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'sd-create-account-reminder-dialog',
  templateUrl: './create-account-reminder-dialog.component.html',
  styleUrls: ['./create-account-reminder-dialog.component.scss']
})
export class CreateAccountReminderDialogComponent implements OnInit {
  @Input()
  parameter: CreateAccountReminderParameter;

  @Input()
  handleCreateAccount: () => void;
  @Input()
  handleContinueWithoutAccount: () => void;
  @Input()
  handleReturnToApplication: () => void;
  @Input()
  handleMockLogin: () => void;
  @Input()
  handleClose: () => void;

  isLocal: boolean;
  termsAndConditionsItems;
  form: FormGroup | undefined;
  showCustomError: string;

  constructor(protected configService: ConfigService,
              public formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      termsAndConditions: ['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.isLocal = this.configService.getClientConfig()?.local;
    this.termsAndConditionsItems = this.parameter?.termAndConditionItems;
  }

  onCreateAccountClicked(): void {
    // verify myb terms are selected, if not show error
    if (this.form.valid) {
      this.handleCreateAccount();
    } else {
      this.showCustomError = 'create-account.error.termAndCondition.required'
    }
  }

  onTermsAndConditionsBoxChanges(questionKey: string, event: any): void {
    if (event && event.length) {
      this.form.controls.termsAndConditions.setValue(event[0])
      this.showCustomError = undefined
    } else {
      this.showCustomError = undefined
      this.form.get('termsAndConditions').setValue('')
    }
  }
}
