<div>
  <sd-accordion [id]="'onw-program-heading'"
                [heading]="'app-status.ONW.multi-programs.onw-heading'"
                [toExpand]="true">
    <div #content>
      <sd-asc-status-progress-bar [id]="'financial-eligibility'"
                                  [progressSteps]="onwProgramProgressSteps">
      </sd-asc-status-progress-bar>
    </div>
  </sd-accordion>
  <sd-accordion [id]="'ods-heading'"
                [heading]="'app-status.ODS.multi-programs.ods-heading'"
                [toExpand]="true">
    <div #content>
      <sd-asc-status-progress-bar [id]="'application-decision'"
                                  [progressSteps]="odsProgramProgressSteps">
      </sd-asc-status-progress-bar>
    </div>
  </sd-accordion>
</div>
