import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PageBaseComponent} from '../../PageBaseComponent';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../../services/intake.service';
import {ApplicationStatusService} from '../../../services/application-status.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../../external.router';
import {GoogleReCaptchaV2Service} from '../../../services/google-recaptcha.service';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {PageService} from '../../../services/page.service';
import {LoadingSpinnerService} from '../../../services/loading-spinner.service';
import {PageInfo} from '../../../models/page-map';
import {ApplicationStatusResponse} from '../../../models/application-status-response';
import {UrlInfo} from '../../../models/url-map';

@Component({
  selector: 'sd-asc-pending',
  templateUrl: './asc-pending.component.html',
  styleUrls: ['./asc-pending.component.scss']
})
export class AscPendingComponent extends PageBaseComponent implements OnInit, OnDestroy {
  private applicationProgramStatus: ApplicationStatusResponse;
  private socialAssistanceUrl: string;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public appStatusService: ApplicationStatusService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              private cdr: ChangeDetectorRef,
              public recaptchaV2Service: GoogleReCaptchaV2Service,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.ascPending;
  }

  ngOnInit(): void {
    this.applicationProgramStatus = this.appStatusService.getStatusResponse();
    this.socialAssistanceUrl = this.configService.getUrl(this.translator.currentLang, UrlInfo.socialAssistance);

    if (this.applicationProgramStatus == null) {
      this.router.navigate(['/', 'applicationstatuschecker']);
    }
  }

  onSubmit(toContinue?: boolean) {

  }

  ngOnDestroy() {
    super.onDestroy();
  }

  postInitializeForm() {
  }

  preSaveApplication() {
  }

  onGoHomeClicked() {
    // return this.router.navigate(['/', 'intake', PageInfo.home]);
    return this.externalRouter.navigate(this.socialAssistanceUrl, false);
  }
}
