<sd-page-container [pageId]="pageId">
  <div id="page-alerts-container">
    <sd-message-box *ngIf="failedSubmit" msgType="error" [title]="'error.service.down.title' | translate" [message]="'dialog-constant.failedValidationErrorText' | translate"></sd-message-box>
  </div>
  <form [formGroup]="form">
    <h2 id="consent-personalSubtitle" class="ontario-h3">
      <span>{{'consent.personalSubtitle' | translate}}</span>
    </h2>
    <h3 id="consent-personalSection1Title" class="ontario-h4">
      <span>{{'consent.personalSection1Title' | translate}}</span>
    </h3>
    <ul>
      <li id="consent-personalSection1Bullet1" >{{'consent.personalSection1Bullet1' | translate}}</li>
      <li id="consent-personalSection1Bullet2">{{'consent.personalSection1Bullet2' | translate}}</li>
      <li id="consent-personalSection1Bullet3">{{'consent.personalSection1Bullet3' | translate}}
        <ul>
          <li id="consent-personalSection1Bullet3SubItem1">{{'consent.personalSection1Bullet3SubItem1' | translate}}</li>
          <li id="consent-personalSection1Bullet3SubItem2">{{'consent.personalSection1Bullet3SubItem2' | translate}}</li>
        </ul>
      </li>
      <li id="consent-personalSection1Bullet4">{{'consent.personalSection1Bullet4' | translate}}</li>
      <li id="consent-personalSection1Bullet5">{{'consent.personalSection1Bullet5' | translate}}</li>
    </ul>
    <h3 id="consent-personalSection2Title" class="ontario-h4">
      <span>{{'consent.personalSection2Title' | translate}}</span>
    </h3>
    <ul>
      <li id="consent-personalSection2Bullet1">{{'consent.personalSection2Bullet1' | translate}}</li>
      <li id="consent-personalSection2Bullet2">{{'consent.personalSection2Bullet2' | translate}}</li>
      <li id="consent-personalSection2Bullet3">{{'consent.personalSection2Bullet3' | translate}}</li>
      <li id="consent-personalSection2Bullet4">{{'consent.personalSection2Bullet4' | translate}}</li>
      <li id="consent-personalSection2Bullet5">{{'consent.personalSection2Bullet5' | translate}}
        <ul>
          <li id="consent-personalSection2Bullet5SubItem1">{{'consent.personalSection2Bullet5SubItem1' | translate}}</li>
          <li id="consent-personalSection2Bullet5SubItem2">{{'consent.personalSection2Bullet5SubItem2' | translate}}</li>
        </ul>
      </li>
    </ul>

    <div class="ontario-margin-bottom-16-!">
      <sd-hint-text [id]="'consent.general.hint'" [text]="'consent.general.hintText'" [expandableContent]="'consent.general.hintText.content'">
      </sd-hint-text>
    </div>

    <h3 id="consent-personalSection3Title" class="ontario-h4"><span>{{'consent.personalSection3Title' | translate}}</span>
    </h3>
    <ul>
      <li id="consent-personalSection3Bullet1">{{'consent.personalSection3Bullet1' | translate}}</li>
      <li id="consent-personalSection3Bullet2">{{'consent.personalSection3Bullet2' | translate}}</li>
      <li id="consent-personalSection3Bullet3">{{'consent.personalSection3Bullet3' | translate}}</li>
      <li id="consent-personalSection3Bullet4">{{'consent.personalSection3Bullet4' | translate}}</li>
    </ul>

    <div class="ontario-margin-bottom-16-!">
      <sd-hint-text [id]="'consent.cra.hint'" [text]="'consent.viewCRA.hintText'" [expandableContent]="'consent.viewCRA.hintText.content'">
      </sd-hint-text>
    </div>

   <h3 id="consent-viewDeclarationTitle" class="ontario-h4"><span>{{'consent.viewDeclarationTitle' | translate}}</span></h3>
   <span id="consent-viewDeclarationSection">{{'consent.viewDeclarationSection' | translate}}</span>
    <ol type="1">
      <li id="consent-viewDeclarationSection1">{{'consent.viewDeclarationSection1' | translate}}</li>
      <li id="consent-viewDeclarationSection2"><span>{{'consent.viewDeclarationSection2' | translate}}</span>
        <ul>
          <li id="consent-viewDeclarationSection2Bullet1">{{'consent.viewDeclarationSection2Bullet1' | translate}}</li>
          <li id="consent-viewDeclarationSection2Bullet2">{{'consent.viewDeclarationSection2Bullet2' | translate}}</li>
        </ul>
      </li>
      <li id="consent-viewDeclarationSection3"><span>{{'consent.viewDeclarationSection3' | translate}}</span>
        <ul>
          <li id="consent-viewDeclarationSection3Bullet1">{{'consent.viewDeclarationSection3Bullet1' | translate}}</li>
          <li id="consent-viewDeclarationSection3Bullet2">{{'consent.viewDeclarationSection3Bullet2' | translate}}</li>
          <li id="consent-viewDeclarationSection3Bullet3">{{'consent.viewDeclarationSection3Bullet3' | translate}}</li>
        </ul>
      </li>
      <li id="consent-viewDeclarationSection4"><span>{{'consent.viewDeclarationSection4' | translate}}</span>
        <ul>
          <li id="consent-viewDeclarationSection4Bullet1">{{'consent.viewDeclarationSection4Bullet1' | translate}}</li>
          <li id="consent-viewDeclarationSection4Bullet2">{{'consent.viewDeclarationSection4Bullet2' | translate}}</li>
        </ul>
      </li>
      <li id="consent-viewDeclarationSection5">{{'consent.viewDeclarationSection5' | translate}}</li>
      <li id="consent-viewDeclarationSection6">{{'consent.viewDeclarationSection6' | translate}}</li>
      <li id="consent-viewDeclarationSection7"><span>{{'consent.viewDeclarationSection7' | translate}}</span>
        <ul>
          <li id="consent-viewDeclarationSection7Bullet1">{{'consent.viewDeclarationSection7Bullet1' | translate}}</li>
          <li id="consent-viewDeclarationSection7Bullet2">{{'consent.viewDeclarationSection7Bullet2' | translate}}</li>
        </ul>
        <h4></h4>
      </li>
    </ol>
    <h3 id="consent-forOWOnly" class="ontario-h4"><span>{{'consent.forOWOnly' | translate}}</span></h3>
    <ol start="8">
      <li id="consent-viewDeclarationSection8">{{'consent.viewDeclarationSection8' | translate}}</li>
      <li id="consent-viewDeclarationSection9">{{'consent.viewDeclarationSection9' | translate}}</li>
    </ol>

    <div class="ontario-alert ontario-alert--informational consent-info">
      <div class="ontario-alert__header">
        <h2 id="consent-info-header" class="ontario-alert__header-title ontario-h4" [innerHTML]="'important.info.header' | translate">
        </h2>
      </div>
      <div>
        <p id="consent-info-content" [innerHTML]="'consent.personalSection1' | translate"></p>
      </div>
    </div>

    <sd-checkbox-question [id]="'consent.sharingPersonalInfo'" [options]="dataSharingItems"
                          [showLabel]="true"
                          [applyStylingToLabel]="false"
                          (change)="onDataSharingCheckboxChange()"
                          [required]="false">
    </sd-checkbox-question>

    <div class="ontario-form-group">
      <span>{{'consent.declarationAcceptedTermsText' | translate}}</span>
      <span class="ontario-label__flag">{{'Required' | translate}}</span>
    </div>
    <sd-multi-option-checkbox-question [id]="'consent.declaration'" [options]="applicantConsents"
                                       [showLabel]=false
                                       (toggle)="toggleCheckbox($event)"
                                       [displayDivider]="false"
                                       [labelParam]="{}">
    </sd-multi-option-checkbox-question>

    <div class="ontario-padding-top-48-!">
      <app-mccss-submit-button dataE2E="continueBtn" buttonLabel="{{titleForApplicant}}" [preventDoubleClick]="true" (handleClick)="onSubmit()" [disabled]="haveNotAcceptedTerms()"></app-mccss-submit-button>
    </div>
  </form>
</sd-page-container>
