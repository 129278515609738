import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PageInfo} from '../../models/page-map';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private router: Router) {
    this.router = router;
  }

  ngOnInit(): void  {
  }

  onGoHomeClicked() {
    return this.router.navigate(['/', 'intake', PageInfo.home]);
  }
}
