import {ApplicationAnswers} from '../../../models/data.model';

export interface Page {
  name: string;
  questions: Question[];
  title: string;
  isVisibleCondition?: (applicationAnswers: ApplicationAnswers) => boolean;
  isVisible?: boolean; // Populated based on isVisibleCondition using application data
  isErrorIconVisibleCondition?: (applicationAnswers: ApplicationAnswers, applicationValidationErrors) => boolean;
  isErrorIconVisible?: boolean; // Populated based on isErrorIconVisibleCondition using application data and/or applicationValidationErrors
}

export interface Question {
  /**
   * type attr
   * Possible values: General input types like radiogroup, dropdown, checkbox, text
   * Special types like paneldynamic, checkboxlist, applicantlist
   * paneldynamic - To display values from dynamic panel components
   * checkboxlist - To display values from list of checkboxes (eg: housing cost details checkboxes)
   * applicantlist - To handle applicant list checkbox (eg: Applicant: John, Spouse: Jane)
   */
  type: string;
  name: string;
  label?: string; // Translation key
  labelParams?: LabelParam[]; // {key = 'Param name', value ='Attr name from application json'}
  translateLabelParamValue?: boolean; // Set this to true in order to translate the param value as well
  label2?: string;
  isRequired?: boolean;
  // When used in dynamic panel context, panel level data from application json is passed into panelJsonData
  isVisibleCondition?: (applicationAnswers: ApplicationAnswers, panelJsonData?: {[propName: string]: string} ) => boolean;
  choices?: Map<string, string>;
  panels?: Panel[];
  radioValueAsLabel?: boolean;

  populatedLabelParams?: {}; // Populated based on labelParams using application data
  value?: string; // Populated with value from application data
  skipValueTranslate?: boolean; // Set this to true if skip translation of value
  isDollarValue?: boolean;
  isVisible?: boolean; // Populated by evaluating isVisibleCondition using application data
}

export class Panel {
  label?: string; // Translation key
  labelParams?: LabelParam[]; // {key = 'Param name', value ='Attr name from application json'}
  questions: Question[];

  populatedLabelParams?: {}; // Populated based on labelParams using application data
}

export interface LabelParam {
  param: {}; // {key = 'Param name', value ='Attr name from application json'}
  translateParamValue?: boolean; // Set this to true in order to translate the param value as well
  skipAttrValueFromJsonData?:boolean; // Set this to true in order to skip the attribute fetch from application json
}
