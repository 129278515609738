import {TranslateService} from '@ngx-translate/core';

import {FormMapping, FormDataMapping} from './form-data-mapping-config';
import {SponsorshipMappingUtil} from './sponsorship-mapping.util';
import {FormConverter} from '../../../common/utils/converters/converter';
import {Question} from '../../../common/utils/questions/question';
import {ApplicationAnswers} from '../../../models/data.model';

export class AppDataToSponsorshipQuestion implements FormConverter<ApplicationAnswers, Question<string>[], TranslateService> {
  translator: TranslateService;

  convert(appData: ApplicationAnswers, questions: Question<string>[], translator: TranslateService): void {
    this.translator = translator;

    this.setupSponsoredPersonQuestion(appData, questions);
    this.setupAllSameSponsorQuestion(questions);
    if (!this.isFirstTime(appData)) {  // When there's data populated in previous run.
      this.populateCommonSponsorQuestions(appData, questions);
      this.populateSponsorDataQuestions(appData, questions);
    }
  }

  private setupSponsoredPersonQuestion(appData: ApplicationAnswers, questions: Question<string>[]) {
    const sponsoredPersons: {key: string, param: {name: string, index?: number}}[] =
      SponsorshipMappingUtil.extractSponsoredPersons('appData.applicant.label', appData);
    const sponsoredPersonQuestion: Question<string> = questions.find(q => q.key === FormMapping.SELECT_SPONSORED_PERSON_ELEMENT);
    if (SponsorshipMappingUtil.getNumberOfMembersInApplication(appData) === 1) {
      sponsoredPersonQuestion.visible = false;
      return;
    }

    sponsoredPersonQuestion.options = [];
    const options = sponsoredPersons.map((person) => {
      const text = this.translator.instant(person.key, person.param);
      return {
        label: person.key,
        labelParam: person.param,
        value: true,
        alternateValue: text,
      }
    });
    sponsoredPersonQuestion.options.push(...options);
  }

  private setupAllSameSponsorQuestion(questions: Question<string>[]): void {
    const sponsoredPersonQuestion: Question<string> = questions.find(q => q.key === FormMapping.SELECT_SPONSORED_PERSON_ELEMENT);
    const allSameSponsorQuestion: Question<string> = questions.find(q => q.key === FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR);
    allSameSponsorQuestion.visible = sponsoredPersonQuestion?.options?.length > 1;
  }

  private populateCommonSponsorQuestions(appData: ApplicationAnswers, questions: Question<string>[]): void {
    this.populateQuestions(new Map(Object.entries(FormDataMapping.Common)), appData.jsonData, questions);
  }

  private populateSponsorDataQuestions(appData: ApplicationAnswers, questions: Question<string>[]): void {
    const sponsoredPersons: { key: string, param: { name: string, index?: number } }[] =
      SponsorshipMappingUtil.extractSponsoredPersons('appData.applicant.label', appData);
    if (sponsoredPersons.length === 1) {  // Single sponsored member
      // The panel where the questions that need to be populated.
      const panel: Question<string> = questions.find(q => q.key === FormMapping.SPONSOR_DETAIL_PANEL_ELEMENT);
      const panelQuestions = panel.panels[0].questions;
      if (sponsoredPersons[0].key.endsWith('.applicant')) {  // Applicant
        this.populateQuestions(new Map(Object.entries(FormDataMapping.Sponsor)), appData.jsonData, panelQuestions);
      } else {  // Other family member
        appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_LIST]?.forEach((data) => {
          this.populateQuestions(new Map(Object.entries(FormDataMapping.Sponsor)), data, panelQuestions);
        })
      }
    } else {  // Multiple sponsored members
      const sameSponsorQuestion: Question<string> =
        questions.find(q => q.key === FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR);
      if (appData.jsonData[FormDataMapping.Common.COMMON_SPONSOR_FIRST_NAME]) {  // Same sponsor
        sameSponsorQuestion.value = 'yes';
      } else {  // Different sponsors
        if (appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_CHECKBOX]?.length > 1) {  // had multiple persons previously
          sameSponsorQuestion.value = 'no';
        }
      }
      this.populateSponsorData(appData, questions);
    }
  }

  private populateSponsorData( appData: ApplicationAnswers,
      questions: Question<string>[]): void {
    // Populate both same and different sets of sponsor panel.
    this.populateSponsorQuestions(true, appData, questions);
    this.populateSponsorQuestions( false, appData, questions);
  }

  private populateSponsorQuestions(sameSponsor: boolean, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const checkList = appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_CHECKBOX] || [];
    checkList.forEach( (application, index) => {
      const sponsorData = appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_LIST]?.find(
        (sponsor) => sponsor.applicant === application);
      if (sponsorData) {
        const panel: Question<string> = questions.find(q => q.key === (sameSponsor ?
          FormMapping.SAME_SPONSOR_DETAIL_PANEL_ELEMENT + index : FormMapping.DIFFERENT_SPONSOR_DETAIL_PANEL_ELEMENT + index));
        const panelQuestions: Question<string>[] = panel.panels[0].questions;
        this.populateQuestions(new Map(Object.entries(FormDataMapping.Sponsor)), sponsorData, panelQuestions);
      }
    })
  }

  private populateQuestions(mappings: Map<string, string>, data: any, questions: Question<string>[]) {
    for (const [k, v] of mappings.entries()) {
      const question: Question<string> = questions.find(q => q.key === v);
      if (question && data[v]) {
        if (Array.isArray(data[v])) {
          if (data[v].length && data[v][0] === 'yes') {
            question.options[0].value = true;
          }
        } else if (data[v]) {
          question.value = data[v];
        }
      }
    }
  }

  private isFirstTime(appData: ApplicationAnswers) {
    return !(appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_CHECKBOX] ||
      appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_LIST] ||
      appData.jsonData[FormDataMapping.Sponsor.SPONSOR_FIRST_NAME]);
  }
}
