import {Observable} from 'rxjs';
import {ApplicationResponse} from '../models/application-response.model';
import {ApplicationAnswers} from '../models/data.model';
import {SubmissionResponse} from '../models/submission-response.model'
import {ResumeApplicationResponse} from '../models/resume-application-response';
import {LastAccessedInfoResponse} from '../models/last-accessed-info-response';

export abstract class IntakeService {
  abstract submitApplication(): Observable<SubmissionResponse>

  abstract submitQuestionnaire(dataSharingForMLITSD: string): Observable<ApplicationResponse>

  abstract saveApplication(answers: ApplicationAnswers, page: string): Observable<void>

  abstract saveToResume(answers: ApplicationAnswers, page: string): Observable<void>

  abstract saveProgress(page: string): Observable<void>

  abstract getApplication(page: string): Observable<ApplicationAnswers>

  abstract getLastAccessedInfo(): Observable<LastAccessedInfoResponse>

  abstract resumeApplication(): Observable<ResumeApplicationResponse>

  abstract getSavedApplication(): Observable<ApplicationAnswers>

  abstract logout(): Observable<void>

  abstract getIsApplyingForSomeoneElse():boolean

  abstract getIsRelatedToLocalOfficeAssistance():boolean

  abstract setIsRelatedToLocalOfficeAssistance(localOfficeAssistanceVal:boolean):void

  abstract setIsApplyingForSomeoneElse(SomeoneElse:boolean):void

  abstract getAppSpouseRequired():boolean

  abstract getAppChildRequired():boolean

  abstract setAppSpouseRequired(required:boolean):void

  abstract setAppChildRequired(required:boolean):void

  abstract getSponsorshipRequired():boolean

  abstract setSponsorshipRequired(required:boolean):void

  abstract getFinIndependencePageRequired():boolean

  abstract setFinIndependencePageRequired(required:boolean):void

  abstract getShouldExcludeDbd():boolean

  abstract setShouldExcludeDbd(required:boolean):void
}
