import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({ selector: '[numbersOnly]' })
export class NumbersOnlyDirective {
  @Input() allowDecimals = false;
  @Input() allowSign = false;
  @Input() decimalSeparator = '.';
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  readonly minusSign: string = '-';
  readonly HTML_SELECTION_START = 'selectionStart';
  readonly HTML_VALUE = 'value';
  readonly NATIVE_ELEMENT_VALUE = 'value';

  previousValue = '';
  // Regular expressions
  integerUnsigned = '^[0-9]*$';
  integerSigned = '^-?[0-9]+$';
  decimalUnsigned = '^[0-9]+(.[0-9]+)?$';
  decimalSigned = '^-?[0-9]+(.[0-9]+)?$';

  /**
   * Class constructor
   */
  constructor(private hostElement: ElementRef) {}

  /**
   * Event handler for host's change event
   */
  @HostListener('change', ['$event']) onChange() {
    this.validateValue(this.hostElement.nativeElement.value);
  }

  /**
   * Event handler for host's paste event
   */
  @HostListener('paste', ['$event']) onPaste(e: any) {
    // get and validate data from clipboard
    const value = e.clipboardData.getData('text/plain');
    this.validateValue(value);
    e.preventDefault();
  }

  /**
   * Event handler for host's keydown event
   */
  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    const cursorPosition: number = (e.target as HTMLInputElement)[this.HTML_SELECTION_START] ?? 0;
    const originalValue: string = (e.target as HTMLInputElement)[this.HTML_VALUE];
    const key: string = this.getName(e);
    const controlOrCommand = (e.ctrlKey === true || e.metaKey === true);
    const shiftOrCommand = (e.shiftKey === true || e.metaKey === true);
    const signExists = originalValue.includes('-');
    const separatorExists = originalValue.includes(this.decimalSeparator);

    // allowed keys apart from numeric characters
    const allowedKeys = [
      'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'End', 'Escape', 'Home', 'Shift', 'Tab'
    ];

    // when decimals are allowed, add
    // decimal separator to allowed codes when
    // its position is not close to the the sign (-. and .-)
    const separatorIsCloseToSign = (signExists && cursorPosition <= 1);
    if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      if (this.decimalSeparator === '.') {
        allowedKeys.push('.');
      } else {
        allowedKeys.push(',');
      }
    }

    // when minus sign is allowed, add its
    // key to allowed key only when the
    // cursor is in the first position, and
    // first character is different from
    // decimal separator
    const firstCharacterIsSeparator = (originalValue.charAt(0) !== this.decimalSeparator);
    if (this.allowSign && !signExists &&
      firstCharacterIsSeparator && cursorPosition === 0) {
      allowedKeys.push('-');
    }

    // allow some non-numeric characters
    if (allowedKeys.indexOf(key) !== -1 ||
      // Allow: Shift+Home and Command+Home
      (key === 'Home' && shiftOrCommand) ||
      // Allow: Ctrl+A and Command+A
      (key === 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key === 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key === 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key === 'x' && controlOrCommand)) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;

    // allow number characters only
    const isNumber = (new RegExp(this.integerUnsigned)).test(key);
    if (isNumber) {
      return;
    } else {
      e.preventDefault();
    }
  }

  /**
   * Test whether value is a valid number or not
   */
  validateValue(value: string): void {
    // choose the appropiate regular expression
    let regex: string = this.integerUnsigned;
    if (!this.allowDecimals && !this.allowSign) {
      regex = this.integerUnsigned;
    }
    if (!this.allowDecimals && this.allowSign) {
      regex = this.integerSigned;
    }
    if (this.allowDecimals && !this.allowSign) {
      regex = this.decimalUnsigned;
    }
    if (this.allowDecimals && this.allowSign) {
      regex = this.decimalSigned;
    }

    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    let firstCharacter = value.charAt(0);
    if (firstCharacter === this.decimalSeparator) {
      value = 0 + value;
    }

    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    const lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter === this.decimalSeparator) {
      value = value + 0;
    }

    // remove unnececary leading zeros
    let signedValue = false;
    if (firstCharacter === this.minusSign) {  // test for 0's without the sing interuption (avoid -004)
      signedValue = true;
      value = value.substring(1);
      firstCharacter = value.charAt(0);
    }
    let secondChar = value.charAt(1);
    while (firstCharacter === '0' && (secondChar !== '' && secondChar !== this.decimalSeparator)) {
      value = value.substring(1);
      firstCharacter = value.charAt(0);
      secondChar = value.charAt(1);
    }
    if (signedValue === true) {  // return the minus value if required
      value = this.minusSign + value;
    }

    // test for excess zeros following the decimal point
    const valueParts = value.split(this.decimalSeparator);
    const naturalPart = valueParts?.[0];
    let decimalPart = valueParts?.[1];

    // remove unnececary zeros after decimal part
    if (decimalPart != null && /^0+$/.test(decimalPart)) {
      decimalPart = '0';
      value = naturalPart + '.' + decimalPart;
    }

    // test for -0.0 or -0
    if (value === '-0') {
      value = '0';
    }
    if (value === '-0.0')
    {
      value='0.0';
    }
    // if (/^-?0(\.0*)*$/.test(value)) {
    //   value = '0';
    // }

    // test number with regular expression, when
    // number is invalid, replace it with a empty string
    const valid: boolean = (new RegExp(regex)).test(value);
    this.hostElement.nativeElement[this.NATIVE_ELEMENT_VALUE] = valid ? value : '';
    this.ngModelChange.emit(valid ? value : '');
  }

  /**
   * Get key's name
   */
  getName(e: any): string {
    if (e.key) {
      return e.key;
    } else {
      // for old browsers
      if (e.keyCode && String.fromCharCode) {
        switch (e.keyCode) {
          case 8: return 'Backspace';
          case 9: return 'Tab';
          case 27: return 'Escape';
          case 37: return 'ArrowLeft';
          case 39: return 'ArrowRight';
          case 188: return ',';
          case 190: return '.';
          case 109: return '-'; // minus in numbpad
          case 173: return '-'; // minus in alphabet keyboard in firefox
          case 189: return '-'; // minus in alphabet keyboard in chrome
          default: return String.fromCharCode(e.keyCode);
        }
      }
      return String.fromCharCode(e.keyCode);
    }
  }
}
