<sd-info-text header="demographic-information.landing.page.alert.title" content="demographic-information.landing.page.alert.content" contentId="pageAlertContentId"></sd-info-text>
<sd-message-box *ngIf="failedSubmit" msgType="error" [title]="'error.service.down.title' | translate" [message]="'error.service.down' | translate"></sd-message-box>
<h1 data-e2e="pageTitle" id="{{pageId}}.page.title" class="ontario-h1" aria-level="1" tabindex="0">{{pageId + ".page.title" | translate}}</h1>
<div id="applicantLabel" class="ontario-h2">{{titleLabel | translate: {
  'applicantType': applicantTypeStr,
  'index': parseChildIndex,
  'name': applicantName
} }}</div>
<form [formGroup]="form" sdDisableAutocomplete>
  <div class="form-group required">
    <sd-dropdown-question [choices]="genderIdentityChoices" [showError]="showError"
                          [id]="'demographic-information.genderIdentity'"
                          [description]="'demographic-information.genderIdentity.desc'"
                          [required]="true"
                          controlName="genderIdentity">
    </sd-dropdown-question>
    <sd-dropdown-question [choices]="indigenousIdentityChoices" [showError]="showError"
                          [id]="'demographic-information.indigenousIdentity'"
                          [description]="'demographic-information.indigenousIdentity.desc'"
                          [required]="true"
                          controlName="indigenousIdentity">
    </sd-dropdown-question>
    <sd-dropdown-question *ngIf="indigenousIdentity === 'YES'" [choices]="indigenousIdentityGroupChoices"
                          [showError]="showError" [id]="'demographic-information.indigenousIdentityGroup'"
                          [description]="'demographic-information.indigenousIdentityGroup.desc'"
                          [required]="true"
                          controlName="indigenousIdentityGroup">
    </sd-dropdown-question>
    <sd-dropdown-question [choices]="racialIdentityChoices" [showError]="showError"
                          [id]="'demographic-information.racialIdentity'"
                          [description]="'demographic-information.racialIdentity.desc'"
                          [required]="true"
                          controlName="racialIdentity">
    </sd-dropdown-question>
    <div *ngIf="racialIdentityEgText" class="description-text">
      <span id="descriptionText">{{racialIdentityEgText | translate}}</span>
    </div>
    <sd-dropdown-question [choices]="visibleMinorityChoices" [showError]="showError"
                          [id]="'demographic-information.visibleMinority'"
                          [description]="'demographic-information.visibleMinority.desc'"
                          [required]="true"
                          controlName="visibleMinority">
    </sd-dropdown-question>
    <sd-dropdown-question [choices]="languageChoices" [showError]="showError"
                          [id]="'demographic-information.language'"
                          [description]="'demographic-information.language.desc'"
                          [required]="true"
                          controlName="languageOfChoice">
    </sd-dropdown-question>
  </div>
  <app-mccss-submit-button dataE2E="continueBtn" buttonLabel="btn.continue" [preventDoubleClick]="true" (handleClick)="onSubmit()"></app-mccss-submit-button>
</form>
