import {ApplicationAnswers} from '../../../models/data.model';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';

export class AdditionalInfoMappingUtil {

  static getFemaleApplicantsAboveAgeThreshold(appData: ApplicationAnswers, ageThreshold: number): string[] {
    const applicants: string[] = [];
    if (appData.jsonData.sexAtBirth === 'female' &&
      SadaCustomValidator.validateOverAge(appData.jsonData.dateOfBirth, ageThreshold)) {
      applicants.push('Applicant: '.concat(appData.jsonData.firstName));
    }

    if (appData.jsonData.spouseSexAtBirth === 'female' &&
      SadaCustomValidator.validateOverAge(appData.jsonData.spouseDateOfBirth, ageThreshold)) {
      applicants.push('Spouse: '.concat(appData.jsonData.spouseFirstName));
    }

    appData.jsonData.childList?.forEach((child, index) => {
      if (child.childSexAtBirth === 'female' && SadaCustomValidator.validateOverAge(child.childDateOfBirth, ageThreshold)) {
        applicants.push('Child '.concat(String(index + 1).concat(': ').concat(child.childFirstName)));
      }
    });

    return applicants;
  }

  static getApplicantsAboveAgeThreshold(appData: ApplicationAnswers, ageThreshold: number): string[] {
    const applicants: string[] = [];
    if (SadaCustomValidator.validateOverAge(appData.jsonData.dateOfBirth, ageThreshold)) {
      applicants.push('Applicant: '.concat(appData.jsonData.firstName));
    }

    if (SadaCustomValidator.validateOverAge(appData.jsonData.spouseDateOfBirth, ageThreshold)) {
      applicants.push('Spouse: '.concat(appData.jsonData.spouseFirstName));
    }

    appData.jsonData.childList?.forEach((child, index) => {
      if (SadaCustomValidator.validateOverAge(child.childDateOfBirth, ageThreshold)) {
        applicants.push('Child '.concat(String(index + 1).concat(': ').concat(child.childFirstName)));
      }
    });

    return applicants;
  }
  static getApplicantsBelow20AgeThreshold(appData: ApplicationAnswers, ageThreshold: number): string[] {
    const applicants: string[] = [];
    if (SadaCustomValidator.validateAgeBelow20(appData.jsonData.dateOfBirth)) {
      applicants.push('Applicant: '.concat(appData.jsonData.firstName));
    }

    if (SadaCustomValidator.validateAgeBelow20(appData.jsonData.spouseDateOfBirth)) {
      applicants.push('Spouse: '.concat(appData.jsonData.spouseFirstName));
    }

    appData.jsonData.childList?.forEach((child, index) => {
      if (SadaCustomValidator.validateAgeBelow20(child.childDateOfBirth)) {
        applicants.push('Child '.concat(String(index + 1).concat(': ').concat(child.childFirstName)));
      }
    });

    return applicants;
  }
}
