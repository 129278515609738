import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sd-warning-text',
  templateUrl: './warning-text.component.html',
  styleUrls: ['./warning-text.component.scss']
})
export class WarningTextComponent implements OnInit {
  @Input()
  id = 'warning';

  @Input()
  title: string

  constructor() { }

  ngOnInit(): void {
  }

}
