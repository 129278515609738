import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';

import {DisableOtherOnCondition, Question, Validator} from '../../utils/questions/question';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil} from 'rxjs/operators';
import {StringUtil} from '../../../utils/string-util';
import {TranslationKeyFinderPipe} from '@shared/pipe/translation-key-finder.pipe';
import {ConfigService} from '../../../services/config.service';

@Component({
  selector: 'sd-dynamic-question',
  templateUrl: './dynamic-question.component.html',
  styleUrls: ['./dynamic-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicQuestionComponent implements OnInit,  AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {
  displayPanelIndex = 1;
  checkboxSelected = false;
  autoCompleteOptions = [];
  filteredAutoCompleteOptions: Observable<string[]>;
  private readonly _destroyed$ = new Subject<void>();

  @Input() question!: Question<string>;

  // Overall page questions. This is used in disableOtherControls to enable/disable other fields.
  @Input() pageQuestions: Question<string>[];

  @Input() form!: FormGroup;

  @Input()
  panelIndex?: number;

  @Input()
  panelName?: string;

  @Input()
  disabled?: boolean;

  @Input()
  showError!: boolean;

  @Input()
  public showCustomError: string | undefined;

  @Input()
  characterWidth: number | undefined;

  @Input()
  validators?: Validator[]

  isDollarValue = true;

  private textAreaElement: HTMLInputElement | undefined;

  private hintTextElement: HTMLElement | undefined;

  public charsRemaining: string | undefined;
  public dynamicQuestionErrorParam:string | undefined

  constructor(private readonly changeDetectorRef: ChangeDetectorRef,
              public translator: TranslateService,
              public configService: ConfigService,
              private translationKeyFinderPipe: TranslationKeyFinderPipe) {
    this.translator.onLangChange.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      if(this.question.controlType === 'textbox-auto-complete'){
        this.autoCompleteOptions = [...this.autoCompleteOptions.map(
          opt => this.translator.instant(this.translationKeyFinderPipe.transform(opt)))]

        if (this.formControl.value) {
          const translatedValueOption = this.question.options.find(choice=>choice.value===this.formControl.value);
          const translatedValue = translatedValueOption ? this.translator.instant(translatedValueOption.label) : this.formControl.value;
          this.formControl.setValue(translatedValue)
        } else {
          this.formControl.updateValueAndValidity({onlySelf: false, emitEvent: true})
        }
      }
      // update char hint text on lang change
      this.updateCharsRemaining(true);
    })
  }

  ngOnInit(): void {
    if (this.panelIndex) {
      this.displayPanelIndex = this.panelIndex + 1;
    }

    this.formControl.valueChanges.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      // disable/enable other controls
      if (this.question.disableOthersOnCondition) {
        this.disableOtherControls()
      }

      if (this.question.controlType === 'checkbox') {
        this.setCheckBoxSelection();
      }
    })

    if (this.question.validators?.filter((v) => v.type === 'crossField')) {
      this.formControl.valueChanges.pipe(
        takeUntil(this._destroyed$),
        debounceTime(500),
        distinctUntilChanged()).subscribe(() => {
        const controls = this.form.parent?.controls as FormGroup[];
        // Force Angular to execute the validator of specific child components of parent FormGroup.
        controls?.forEach((formGroup: FormGroup) => {
          const fc = formGroup.get(this.question.key);
          if (fc) {
            fc.updateValueAndValidity({emitEvent: false, onlySelf: false});
          }
        })
      })
    }

    this.filteredAutoCompleteOptions = this.formControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterAutoCompleteOptions(value))
      );

    if (this.question.controlType === 'textbox-auto-complete') {
      if (this.formControl.value) {
        const translatedValueOption = this.question.options.find(choice=>choice.value===this.formControl.value);
        const translatedValue = translatedValueOption ? this.translator.instant(translatedValueOption.label) : this.formControl.value;
        this.formControl.setValue(translatedValue)
      }
      this.translateAutoCompleteKeys();
    }

    if (this.question.controlType === 'checkbox') {
      this.question.options.forEach((q, i) => {
        if (q.disabled) {
          this.items.controls[i]?.disable()
        }
      })
    }

    // sets the default
    this.updateCharsRemaining(true);
  }

  ngAfterViewInit(): void {
    if (this.question.displayCharsRemaining && this.question.controlType === 'textarea') {
      const textAreaID = this.id.concat('_textarea');
      const hintTextID = this.id.concat('-question-hintText0-hint-text');
      this.textAreaElement = (document.getElementById(textAreaID) as HTMLInputElement);
      this.hintTextElement = document.getElementById(hintTextID);

      // Deal with existing data
      this.updateCharsRemaining(true);
    }

    if(this.question.contextParams){
      const fieldsetElement = document.getElementById(this.id).closest('fieldset');

      if(fieldsetElement){
        this.question.contextParams.forEach(key=>{
          this.getFormControl(key)?.valueChanges.subscribe(change=>{
            fieldsetElement.setAttribute('aria-label',this.getAriaLabel());
          })
        })
      }

    }
  }

  ngAfterViewChecked(): void {
    // Need extra change cycle for cross field validators only
    if (this.question.validators?.some((validator) => {
      return validator.type === 'crossField'})) {
      this.changeDetectorRef.detectChanges();
    }

    if(!!this.question.onChangeFn) {
      this.formControl.valueChanges.pipe(takeUntil(this._destroyed$)).subscribe(value => {
        this.question.onChangeFn.onChangeFunction(this.form, value)
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void{
    // disable control when disable flag true
    if (this.disabled) {
        if (this.question.controlType === 'checkbox') {
          this.items.controls.forEach(control => {
            control.disable();
            if (this.question.resetWhenDisabled) {
              this.resetValue(this.question.controlType, control);
            }
          });
        } else {
          this.formControl.disable();
          if (this.question.resetWhenDisabled) {  // Cleaned up the data entered prior.
            this.resetValue(this.question.controlType, this.formControl);
          }
        }
    } else { // enable control
        if (this.question.controlType === 'checkbox') {
          this.items.controls.forEach(control => {
            control?.enable();
          });
        } else {
          this.formControl?.enable();
        }
    }

    // determine if checkbox selected for background changes
    if (this.question.controlType === 'checkbox') {
      this.setCheckBoxSelection();
    }

    // disable other controls
    if (this.question.disableOthersOnCondition) {
      this.disableOtherControls()
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe to events when component is destroyed.
    this._destroyed$.next(undefined);
    this._destroyed$.complete();
  }

  // disable other controls based on condition of this question.
  disableOtherControls():void {
    this.question.disableOthersOnCondition.forEach(q => {
      const cv = this.getControlValue(q.index)
      if ((Array.isArray(q.value) && q.value.includes(cv) || Array.isArray(q.valueNotEqual) && !q.valueNotEqual.includes(cv))
              || this.evaluateOrCondition(q)) {
        if (q.questionControlType === 'checkbox') {
          this.getItems(q.questionKey)?.controls.forEach(control => {
            control.disable();
          });
        } else if (this.getFormControl(q.questionKey)?.status !== 'DISABLED') {
          this.getFormControl(q.questionKey)?.disable();
          const questionToDisable = this.pageQuestions.find((pageQuestion) => pageQuestion.key === q.questionKey);
          if (questionToDisable.resetWhenDisabled) {  // Cleared up the data.
            this.resetValue(q.questionControlType, this.getFormControl(q.questionKey));
          }
        }
      } else { // enable control
          if (q.questionControlType === 'checkbox') {
            this.pageQuestions.find(question => question.key === q.questionKey).options.forEach((option, index) =>  {
              if (!option.disabled) { // Dont disable if the option was set to be disabled always
                this.getItems(q.questionKey)?.controls[index]?.enable()
              }
            })
          } else if (this.getFormControl(q.questionKey)?.status === 'DISABLED') {
            if (this.form.status !== 'DISABLED') {  // Only if the containing FormGroup is enabled.
              this.getFormControl(q.questionKey)?.enable()
            }
          }
        }
    })
  }

  resolveExpandableContentParam(contentParam: any): any {
    let param: any = null;
    if (contentParam) {
      param = {};
      Object.keys(contentParam).forEach((urlKey) => {
        param[urlKey] = this.configService.getUrl(this.translator.currentLang, contentParam[urlKey]);
      })
    }
    return param;
  }
  get resolveDynamicQuestionErrorParam(): any {
    let param: any = null;
    if (this.dynamicQuestionErrorParam) {
      param = {};
      Object.keys(this.dynamicQuestionErrorParam).forEach((urlKey) => {
        param[urlKey] = this.configService.getUrl(this.translator.currentLang, this.dynamicQuestionErrorParam[urlKey]);
      })
    }
    return param;
  }

  private resetValue(type: string, control: AbstractControl) {
    if (type === 'checkbox') {  // TODO Add reset logic for other type of controls.
      control.setValue(false);
    } else if (type === 'textbox') {
      control?.setValue('')
    }
  }

  evaluateOrCondition(condition: DisableOtherOnCondition): boolean {
    if (condition.orCondition) {
      const formValue = this.getFormControl(condition.orCondition.questionKey)?.value
      return ((Array.isArray(condition.orCondition.value) && condition.orCondition.value.includes(formValue)) ||
        (Array.isArray(condition.orCondition.valueNotEqual) && !condition.orCondition.valueNotEqual.includes(formValue)));
    } else {
      return false;
    }
  }

  setCheckBoxSelection(): void {
    this.items.controls.forEach(control => {
      this.checkboxSelected = control.value;
    });
  }

  get id(): string {
    if (this.panelName){
      return this.panelName.concat('_').concat(this.question.key.concat(String(this.panelIndex? this.panelIndex : 0)));
    } else {
      return this.question.key;
    }
  }

  get formControl(): AbstractControl{
    return this.form.controls[this.question.key];
  }

  getFormControl(key:string): AbstractControl{
    return this.form.get(key);
  }

  get labelParams(): any {
    const paramObj = {index: this.displayPanelIndex};
    this.question.labelParams?.forEach((param, i) => {
      paramObj['param' + i] = param;
    })
    return paramObj;
  }

  get subLabelParams(): any {
    const paramObj = {index: this.displayPanelIndex};
    this.question.subLabelList?.forEach((param, i) => {
      paramObj['param0'] = paramObj['param0'] ? paramObj['param0'].concat(', ').concat(param.textParam.name) : param.textParam.name;
    })
    return paramObj;
  }

  get errorKey(): string | undefined {
    let errorKey: string | undefined;

    // convert list of validators to map
    const validatorMap = new Map(this.question.validators?.map(obj => [obj.type, obj]));
    const formControl = this.form.controls[this.question.key];

    if ((formControl.status === 'VALID') || (formControl.status === 'DISABLED')) {
      return '';
    } else if (formControl.status === 'INVALID') {
      if (formControl?.hasError('required')) {
        errorKey = validatorMap.get('required')?.errorKey;
      } else  if (formControl?.hasError('min')) {
        errorKey = validatorMap.get('min')?.errorKey;
      } else  if (formControl?.hasError('max')) {
        errorKey = validatorMap.get('max')?.errorKey;
      } else  if (formControl?.hasError('pattern')) {
        errorKey = validatorMap.get('pattern')?.errorKey;
      } else  if (formControl?.hasError('minlength')) {
        errorKey = validatorMap.get('minLength')?.errorKey;
      } else if (formControl?.hasError('maxlength')) {
        errorKey = validatorMap.get('maxLength')?.errorKey;
      } else if (formControl?.hasError('maxNumericLength')) {
        errorKey = validatorMap.get('maxNumericLength')?.errorKey;
      } else if (formControl?.hasError('vehicleYearBefore1900Check')) {
        errorKey = validatorMap.get('vehicleYearBefore1900Check')?.errorKey;
      } else  if (formControl?.hasError('customErrorKey')) {
        errorKey = formControl.getError('customErrorKey');
        if(formControl?.hasError('customErrorParam'))
        {
          this.dynamicQuestionErrorParam = formControl.getError('customErrorParam')
        }
      } else  if (formControl?.hasError('crossField')) {
        errorKey = validatorMap.get('crossField')?.errorKey;
        this.dynamicQuestionErrorParam = validatorMap.get('crossField')?.errorKeyParam
      } else  if (formControl?.hasError('asyncValidatorFn')) {
        errorKey = validatorMap.get('asyncValidatorFn')?.errorKey;
      }
      return errorKey;
    } else {  // status = 'PENDING' - wait for the async-validator to return the result.
      formControl.statusChanges.pipe(filter(status => status !== 'PENDING'), distinctUntilChanged()).subscribe(() => {
        if (formControl?.hasError('required')) {
          errorKey = validatorMap.get('required')?.errorKey;
        } else if (formControl?.hasError('min')) {
          errorKey = validatorMap.get('min')?.errorKey;
        } else if (formControl?.hasError('max')) {
          errorKey = validatorMap.get('max')?.errorKey;
        } else if (formControl?.hasError('pattern')) {
          errorKey = validatorMap.get('pattern')?.errorKey;
        } else if (formControl?.hasError('minlength')) {
          errorKey = validatorMap.get('minLength')?.errorKey;
        } else if (formControl?.hasError('maxlength')) {
          errorKey = validatorMap.get('maxLength')?.errorKey;
        } else if (formControl?.hasError('maxNumericLength')) {
          errorKey = validatorMap.get('maxNumericLength')?.errorKey;
        } else if (formControl?.hasError('vehicleYearBefore1900Check')) {
          errorKey = validatorMap.get('vehicleYearBefore1900Check')?.errorKey;
        } else if (formControl?.hasError('customErrorKey')) {
          errorKey = formControl.getError('customErrorKey');
          if(formControl?.hasError('customErrorParam')) {
            this.dynamicQuestionErrorParam = formControl.getError('customErrorParam')
          }
        } else if (formControl?.hasError('crossField')) {
          errorKey = validatorMap.get('crossField')?.errorKey;
          this.dynamicQuestionErrorParam = validatorMap.get('crossField')?.errorKeyParam
        } else if (formControl?.hasError('asyncValidatorFn')) {
          this.showError = true;
          errorKey = validatorMap.get('asyncValidatorFn')?.errorKey;
        }
        if (errorKey) {
          this.showError = true;
        } else {
          this.showError = false;
        }
        this.changeDetectorRef.detectChanges();
        return errorKey;
      });
    }
  }

  get panelForm(): FormGroup {
    return this.form?.get(this.question.key) as FormGroup;
  }

  get items(): FormArray {
    return this.form.get(this.question.key) as FormArray;
  }

  getItems(key:string): FormArray{
    return this.form.get(key) as FormArray;
  }

  getControlValue(index:number): any {
    if (this.question.controlType === 'checkbox') {
      const formArray = this.form.get(this.question.key) as FormArray;
      const control = formArray?.controls[index? index: 0];
      return control?.value;
    } else {
      const control = this.form.get(this.question.key);
      return (control)?.value;
    }
  }

  private trimField(): any {
      const trimmedValue = this.formControl.value.trim();
      this.formControl.setValue(trimmedValue);
      return this.formControl.value;
  }


  get backgroundClasses(): string {
    let classes: string | undefined;

    if ( this.question.backGroundClass && this.question.controlType === 'checkbox') {
      classes = this.checkboxSelected ? this.question.backGroundClassSelected : this.question.backGroundClass;
    }

    if (this.question.panelBackground) {
      if (classes !== undefined) {
        classes.concat('ontario-padding-top-8-! ontario-padding-bottom-8-!');
      }
      else {
        classes = 'ontario-padding-top-8-! ontario-padding-bottom-8-!';
      }
    }

    return classes;
  }

  translateAutoCompleteKeys(): void {
    const filteredAutoCompleteChoices = this.question.options.filter(option=>
      option.lang === this.translator.currentLang || option.lang === undefined)
    const keys = filteredAutoCompleteChoices.map(item=>item.label);
    this.translator.get([...keys]).pipe(takeUntil(this._destroyed$)).subscribe(m => {
      keys.forEach(k => {
        this.autoCompleteOptions.push(m[k]);
      });
    })
  }

  onTextBoxBlur(): void {
    if (!!this.formControl.value) {
      let fieldValue = this.trimField();
      if (this.question.capitalizeFirstLetter) {
        fieldValue = StringUtil.capitalizeFirstLetter(fieldValue)
      }
      if(this.question.truncateMultipleSpaces){
        fieldValue = fieldValue.replace(/\s\s+/g, ' ')
      }
      this.formControl.setValue(fieldValue);
    }
  }

  private filterAutoCompleteOptions(value: string): string[] {
    return this.autoCompleteOptions.filter(option => value === null ? true : option.toLowerCase().includes(value.toLocaleLowerCase()));
  }

  get shouldShowErrorStyle(): boolean {
    return ((this.showError || this.formControl?.touched) && this.errorKey && !this.formControl?.valid && this.question.controlType !== 'panel')
      || (this.showCustomError !== undefined && this.showCustomError !== '')
  }

  get shouldAlertPredictiveTextError(): boolean {
           // to check value changed to prevent flashing error during selection
    return (this.formControl.dirty && (this.showError || this.formControl?.touched))
          // to alert error after initial loading and field is empty during form submission
        || (!this.formControl.dirty && this.showError && !this.formControl?.touched)
         // to alert error after initial loading, then field is touched but empty during form submission
        || (!this.formControl.dirty && this.showError && this.formControl?.touched)
  }

  get shouldShowPredictiveTextErrorStyle(): boolean {
    return ( this.formControl.dirty && (this.showError || this.formControl?.touched) && this.errorKey
        && !this.formControl?.valid && this.question.controlType !== 'panel')
      || (this.showCustomError !== undefined && this.showCustomError !== '')
  }

  get formGroupClass(): string {
    let classes: string | undefined;

    if ( this.question.needMarginTop ) {
      classes = 'ontario-form-group';
    } else if ( this.question.needMarginBottom ) {
      classes = 'ontario-form-group-with-margin-bottom'
    }
    else {
      classes = 'ontario-form-group-no-margin'
    }

    return classes;
  }

  updateCharsRemaining(isExecutedOnComponentLoad:boolean=false): void {
    if (this.question && this.question.controlType === 'textarea' && this.question.displayCharsRemaining) {
      if (this.textAreaElement) {
        const len = this.textAreaElement.value.length;
        const diff = this.question.displayCharsRemaining - len;
        this.charsRemaining = diff > 0 ? diff.toString() : '0';

        // update DOM for real-time changes
        this.hintTextElement.innerText = this.translator
          .instant('chars-remaining', {charsRemaining: this.charsRemaining});

        // simulate blur for error message handling
        if(!isExecutedOnComponentLoad || (isExecutedOnComponentLoad && (diff<0))){
          this.textAreaElement.blur();
          this.textAreaElement.focus();
        }
      }
      else {
        // set default
        this.charsRemaining = this.question.displayCharsRemaining.toString();
      }
    }
  }

  public getInputClass(characterWidth: number): string{
    switch(characterWidth){
      case 20:
        return 'ontario-input--20-char-width'
      case 10:
        return 'ontario-input--10-char-width'
      case 7:
        return 'ontario-input--7-char-width'
      case 5:
        return 'ontario-input--5-char-width'
      case 4:
        return 'ontario-input--4-char-width'
      case 3:
        return 'ontario-input--3-char-width'
      case 2:
        return 'ontario-input--2-char-width'
      default:
        return ''
    }
  }

  get contextParams(): any {
    const paramObj = {index: this.displayPanelIndex};
    this.question.contextParams?.forEach((param, i) => {
      paramObj['param' + i] = this.form.value[param] ?? '';
    })
    return paramObj;
  }

  getAriaLabel(): string {
    let ariaLabel: string = null;

    if (this.question.context) {
      if (this.question.contextParams) {
        ariaLabel = this.translator.instant(this.question.context,this.contextParams);
      } else if (this.question.subLabelList ) {
        ariaLabel =  this.translator.instant(this.question.context, this.subLabelParams);
      } else if (this.question.labelParams) {
        ariaLabel = this.translator.instant(this.question.context, this.labelParams);
      } else {
        ariaLabel = this.translator.instant(this.question.context);
      }
    } else if (this.question.label && (this.question.controlType === 'radio')) {
      ariaLabel = this.translator.instant(this.question.label, this.labelParams);
    } else {
      return null;
    }

    if (this.question.required) {
      ariaLabel = ariaLabel.concat(' ', this.translator.instant('Required'));
    } else {
      ariaLabel = ariaLabel.concat(' ', this.translator.instant('Optional'));
    }
    return ariaLabel;
  }

  getForAttr(): string | null{
    if(this.question.controlType === 'textbox' || this.question.controlType === 'textbox-auto-complete'){
      return this.id.concat('_input');
    } else if(this.question.controlType === 'dropdown'){
      return this.id.concat('_select')
    }
    return null;
  }
}
