import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-mccss-footer',
  templateUrl: './mccss-footer.component.html',
  styleUrls: ['./mccss-footer.component.scss']
})
export class MccssFooterComponent {
  currentDate = new Date();
}
