import {AfterViewChecked, ChangeDetectorRef, Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Idle} from '@ng-idle/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {distinctUntilChanged, filter, startWith, take} from 'rxjs/operators';
import {NoLastNamePopup} from '../personal-information/personal-information.component';
import {PageBaseComponent} from '../PageBaseComponent';
import {Sexes} from '../../common/utils/questions/question-choices';
import {ExternalRouter} from '../../external.router';
import {ApplicantType} from '../../models/applicant-type';
import {ApplicationAnswers} from '../../models/data.model';
import {IntakeService} from '../../services/intake.service';
import {EmailService} from '../../services/email.service';
import {NameUpdateUtil} from '../../utils/name-update-util';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {CaseWorkerAuthorizeService} from '../../services/caseWorkerAuthorizeService';
import {ConfigService} from '../../services/config.service';
import {FieldFormatUtil} from '../../utils/field-format-util';
import {StringUtil} from '../../utils/string-util';
import {AdditionalInfoMappingUtil} from '../additional-information/converters/additional-info-mapping.util';
import {CheckboxItem} from '../../common/ui/checkbox-question/checkbox-question.component';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {FieldsCheckUtil} from '../../utils/fields-check-util';
import {UrlInfo} from '../../models/url-map';
import {ValidationFnsUtil} from '../../utils/validation-fns-util';

@Component({
  selector: 'sd-children-information',
  templateUrl: './children-information.component.html',
  styleUrls: ['./children-information.component.scss']
})
export class ChildrenInformationComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  form: FormGroup;
  showError = false;
  officeLocationLinkParam: any;

  readonly applicantType = ApplicantType.CHILD;
  readonly sexes = Sexes;
  readonly customRequiredErrorMessage = 'error.empty.email.address';
  readonly CHECKBOX_CONTROLS = ['childNoLastName', 'childNoUniqueEmail', 'childNoSin'];

  firstNameValidationFunctions;
  lastNameValidationFunctions;
  dobValidationFunctions;
  emailValidationFunctions;
  emailAsyncValidationFunctions;
  anyPrevFemaleOfAgeOnApplication;

  constructor(public formBuilder: FormBuilder, private router: Router, public route: ActivatedRoute,
              public intake: IntakeService, private emailService: EmailService, public translator: TranslateService,
              @Inject(LOCALE_ID) protected localeId: string,
              public ngZone: NgZone, public idle: Idle, public dialog: MatDialog, private readonly changeDetectorRef: ChangeDetectorRef,
              public externalRouter: ExternalRouter, public authorizeService: CaseWorkerAuthorizeService,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.childrenInfo;
  }

  ngOnInit() {
    this.isAuthorizedUser = this.authorizeService.isAuthorized();
    this.setupForm();
    this.initializeForm();
    this.setupLinks();

    this.anyPrevFemaleOfAgeOnApplication = AdditionalInfoMappingUtil.getFemaleApplicantsAboveAgeThreshold(
      this.applicationAnswers, 12).length > 0

    this.setupValidators();

    this.translator.onLangChange.subscribe((lang) => {
      this.setupLinks();
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  postInitializeForm() {}
  preSaveApplication() {}

  ngOnDestroy() {
    super.onDestroy();
  }

  children(): FormArray {
    return this.form.get('children') as FormArray;
  }

  private setupForm(): void {
    this.form = this.formBuilder.group({
      children: this.formBuilder.array([])
    });
  }

  private setupLinks(): void {
    this.officeLocationLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)
    };
  }

  /**
   * Initialize form with application data.
   */
  initializeForm(): void {
    this.subscriptions$.push(this.route.data.subscribe((data: {appData: ApplicationAnswers}) => {
      this.applicationAnswers.jsonData = {...data.appData?.jsonData};
      if (data && data.appData?.jsonData?.childList) {
        this.applicationAnswers.jsonData.childList.forEach((controlGroup, index) => {
          const childGroup = this.createChild();
          this.children().push(childGroup);
          Object.keys(childGroup.controls).forEach((propertyName) => {
            if (this.CHECKBOX_CONTROLS.includes(propertyName)) {
              if (controlGroup[propertyName] === 'yes') {
                childGroup.get(propertyName).setValue(true);
                if (['childNoLastName'].includes(propertyName)) {
                  this.onNoLastNameCheckBoxChanges(index, 'yes', false);
                } else if (['childNoUniqueEmail'].includes(propertyName)) {
                  this.onNoUniqueEmailCheckBoxChanges(index, 'yes');
                }
              } else {
                childGroup.get(propertyName).setValue(false);
              }
            } else {
              childGroup.get(propertyName).setValue(controlGroup[propertyName]);
              if (['childDateOfBirth'].includes(propertyName)) {
                this.onChildDateOfBirthBlur(controlGroup[propertyName], index);
              }
            }
          })
        })
      } else {
        this.addChild();
      }
    }));
  }

  private setupValidators() {
    this.firstNameValidationFunctions = [
      {
        validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
        errorKey: 'error.invalid.firstNameWithOnlyCharacters'
      },
      {
        validationFunction: SadaCustomValidator.validateName,
        errorKey: 'error.invalid.firstName'
      },
      {
        validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
        errorKey: 'error.invalid.fr.character'
      }
    ];
    this.lastNameValidationFunctions = [
      {
        validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
        errorKey: 'error.invalid.lastNameWithOnlyCharacters'
      },
      {
        validationFunction: SadaCustomValidator.validateName,
        errorKey: 'error.invalid.lastName'
      },
      {
        validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
        errorKey: 'error.invalid.fr.character'
      }
    ];
    this.emailValidationFunctions = [
      ...ValidationFnsUtil.emailValidationFns(
        this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse, this.isAuthorizedUser),
      {
        validationFunction: (value: []) => {
          return SadaCustomValidator.validateUniqueChildEmail([...value, this.applicationAnswers.jsonData?.email ?
            this.applicationAnswers.jsonData.email : null,
            this.applicationAnswers.jsonData?.spouseEmail ? this.applicationAnswers.jsonData.spouseEmail : null,
            this.children().controls]);
        },
        errorKey: 'error.notUnique.email.address'
      },
    ];
    this.emailAsyncValidationFunctions = [
      {
        validationFunction: (value) => this.emailService.validateEmailAddress(value),
        errorKey: 'error.invalid.email.domain'
      }
    ];
    this.dobValidationFunctions = [
      {
        validationFunction: (value: []) => {
          return SadaCustomValidator.validateDateWithMinAndFuture([...value, '1900/01/01']);
        },
        errorKey: 'error.invalid.year'
      }
    ];
  }

  /**
   * Store the newly created child-info panel formGroup to the FormArray.
   */
  addChild() {
    this.children().push(this.createChild());
    if (!this.applicationAnswers.jsonData.childList) {
      this.applicationAnswers.jsonData.childList = [];
    }
    this.applicationAnswers.jsonData.childList.push({});  // Maintain the no. of children consistent with the page
    document.getElementById('children-information.addChild').blur();
  }

  /**
   * Create the FormGroup for new the child-info panel.
   */
  private createChild(): FormGroup {
    return this.formBuilder.group({
      childFirstName: [],
      childLastName: [],
      childNoLastName: [],
      childDateOfBirth: [],
      childSexAtBirth: [],
      childEmail: [],
      childNoUniqueEmail: [],
      childStatusInCanada: [''],
      childArrivalDateToCanada: [],
      childSocialInsuranceNumber: [],
      childNoSin: [],
      childCertificateOfIndianStatusNumber: [],
      childSponsored: [],
      childHealthCardNumber: [],
      childImmigrationFileNumber: [],
    });
  }

  removeChild(childIndex: number) {
    this.children().removeAt(childIndex);
    this.applicationAnswers.jsonData.childList.splice(childIndex, 1);  // Maintain the no. of children consistent with the page
  }
  isEmailRequired():boolean
  {
    return this.isAuthorizedUser || this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse === 'APPLICATION_SELF'
      || this.intakeService.getIsRelatedToLocalOfficeAssistance()
  }

  onSubmit(toContinue: boolean): void {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;
    let notSubmitForm = false; // prevent submitting the form when the user changes the field value while it's handling ASYNC validators


    if (this.form.valid) {
      this.saveAndContinueOrExit(toContinue);
    } else if (this.form.invalid || FieldsCheckUtil.findInvalidFieldInArray(this.form.controls.children)) {
      this.showError = true
      this.scrollToInvalidFormControl(toContinue);
    } else {
      // Below handling is required to handle ASYNC validators. Submission should be delayed until all async validators are executed.
      // Until async validators are done, status would be 'PENDING'
      this.subscriptions$.push(
        this.form.statusChanges.pipe(
          distinctUntilChanged(),
          startWith(this.form.status),
          filter(status => status !== 'PENDING'),
          take(1)).subscribe(status => {
          this.form.updateValueAndValidity();

          // Until async validators are done status would be 'PENDING'
          if (!notSubmitForm && this.form.valid && status === 'VALID') {
            this.saveAndContinueOrExit(toContinue)
          } else if(!notSubmitForm && status !== 'PENDING') {
            notSubmitForm = true;
            this.showError = true
            this.scrollToInvalidFormControl(toContinue);
          }
      }))
    }
  }

  private saveAndContinueOrExit(toContinue: boolean) {
    // To update the rest of the JSON data when the child first-name has been changed.
    this.children().controls.forEach((control, childIndex) => {
      if (control.get('childFirstName')?.value && this.applicationAnswers.jsonData?.childList &&
        this.applicationAnswers.jsonData.childList[childIndex]?.childFirstName) {
        NameUpdateUtil.updateFirstNameInSavedData(control.get('childFirstName').value,
          this.applicationAnswers.jsonData, this.localeId, ApplicantType.CHILD, childIndex);
      }
    });
    this.updateAppDataIfNotSponsored()

    // Delete pregnancy related appdata if net change in sex from male to female within household as result of child data
    if (!this.anyPrevFemaleOfAgeOnApplication) {
      this.children().controls.forEach((control, childIndex) => {
        if (this.applicationAnswers.jsonData.hasOwnProperty('pregnantOrBreastFeedingInFamily') &&
          control.get('childSexAtBirth').value === 'female') {
          delete this.applicationAnswers.jsonData.pregnantOrBreastFeedingInFamily
        }
      });
    }

    // Call Intake service to save application
    const childList: any[] = this.createChildList();
    this.applicationAnswers.jsonData = { ...this.applicationAnswers.jsonData, childList: [...childList]};

    this.subscriptions$.push(this.saveToIntake()?.pipe(distinctUntilChanged()).subscribe(() => {
      if (toContinue) {
        this.router.navigate(['/', 'intake', PageInfo.additionalInfo])
      } else {
        this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
      }
    }));
  }

  private updateAppDataIfNotSponsored() {
    this.children().controls.forEach((control, childIndex) => {
      if(!control.get('childSponsored')){
        delete this.applicationAnswers.jsonData.childList[childIndex].childSponsored
      }
      if(!control.get('childArrivalDateToCanada')){
        delete this.applicationAnswers.jsonData.childList[childIndex].childArrivalDateToCanada
      }
    })

    const sponsoredChildrenNames = this.children().controls
      .filter((control, childIndex) => control.get('childSponsored') && control.get('childSponsored').value === 'yes')
      .map(control => control.get('childFirstName').value as string);
    const {anyoneSponsoredList, anyoneSponsoredCheckbox} = this.applicationAnswers.jsonData
    const [childrenSponsorList, childrenSponsorCheckboxList] = this.getNewSponsoredInfoForChildren(
      sponsoredChildrenNames, anyoneSponsoredList, anyoneSponsoredCheckbox)
    const applicationSpouseSponsorList = anyoneSponsoredList?.filter((app: {applicantType: string }) => !app.applicantType.startsWith('Child')) || [];
    const applicationSpouseSponsorCheckboxList = anyoneSponsoredCheckbox?.filter((title: string) =>
      !title.startsWith('Child') && !title.startsWith('Enfant') ) || []
    const newSponsorCheckboxList = [...applicationSpouseSponsorCheckboxList, ...childrenSponsorCheckboxList]
    let newSponsorList = [...applicationSpouseSponsorList, ...childrenSponsorList]

    function getSponsorInfoForSpouse(lastName: string|undefined, firstName: string) {
      return newSponsorList.map((app: {applicantType: string, sponsorFirstName: string, sponsorLastName: string }) => {
        if(app.applicantType === 'Spouse'){
          if(!lastName){
            return ({...app, sponsorFirstName: firstName})
          }else{
            return ({...app,
              sponsorFirstName: firstName,
              sponsorLastName: lastName
            })

          }
        }
        return {...app}
      })
    }
    if(newSponsorCheckboxList.length < 2 && this.applicationAnswers.jsonData.commonSponsorFirstName){
      newSponsorList = this.updateSponsorInfoForApplicant(newSponsorList)
      newSponsorList = getSponsorInfoForSpouse(this.applicationAnswers.jsonData.commonSponsorLastName,
        this.applicationAnswers.jsonData.commonSponsorFirstName)

      delete this.applicationAnswers.jsonData.commonSponsorFirstName
      delete this.applicationAnswers.jsonData.commonSponsorLastName
      delete this.applicationAnswers.jsonData.allHaveSameSponsor
    }
    if(newSponsorList.length){
      this.applicationAnswers.jsonData = {
        ...this.applicationAnswers.jsonData,
        anyoneSponsoredList: [...newSponsorList],
        anyoneSponsoredCheckbox: [...newSponsorCheckboxList]
      };
    }else {
      delete this.applicationAnswers.jsonData.anyoneSponsoredList
      delete this.applicationAnswers.jsonData.anyoneSponsoredCheckbox
    }

  }

  private updateSponsorInfoForApplicant(sponsoredList: { applicantType: string, sponsorSupportAmount: string, sponsorLivesWith: string }[]){
    const applicantSponsor = sponsoredList.find((app: {applicantType: string }) => app.applicantType === 'Applicant');
    if(applicantSponsor){
      const {sponsorSupportAmount, sponsorLivesWith} = applicantSponsor;
      if(this.applicationAnswers.jsonData.commonSponsorLastName){
        this.applicationAnswers.jsonData.sponsorLastName = this.applicationAnswers.jsonData.commonSponsorLastName
      }
      this.applicationAnswers.jsonData.sponsorFirstName = this.applicationAnswers.jsonData.commonSponsorFirstName
      this.applicationAnswers.jsonData.sponsorLivesWith = sponsorLivesWith
      this.applicationAnswers.jsonData.sponsorSupportAmount = sponsorSupportAmount;
      return [];
    }
    return [...sponsoredList];
  }

  getNewSponsoredInfoForChildren(childrenNames: string[], anyoneSponsoredList: { applicant: string, applicantDisplayTypeFr: string,
    applicantType: string, applicantDisplayType: string} [], anyoneSponsoredCheckbox: string []) {

    const changeValueAtIndex = (str, index, value) => str.split(' ').map((word, i) => i === index ? value : word).join(' ')

    const newSponsoredCheckbox = childrenNames
      .map(name => anyoneSponsoredCheckbox?.find(title => title.endsWith(name)) || '')
      .filter(title => title.startsWith('Child') || title.startsWith('Enfant'))
      .map((title, index) => changeValueAtIndex(title, 1, `${index+1}:`))

    const updatedAnyoneSponsoredList = anyoneSponsoredList ? [ ...anyoneSponsoredList ] : [];
    const newSponsoredList = childrenNames
      .map(name => {
        const foundIndex = updatedAnyoneSponsoredList.findIndex(s => s.applicant.endsWith(name));
        if(foundIndex < 0) {
          return { applicant: '', applicantDisplayTypeFr: '', applicantType: '', applicantDisplayType: ''}
        }
        return updatedAnyoneSponsoredList.splice(foundIndex, 1)[0];
      })
      .filter(sponsor => sponsor.applicantType.startsWith('Child'))
      .map((sponsor, index) => ({
        ...sponsor,
        applicant: changeValueAtIndex(sponsor.applicant, 1, `${index+1}:`),
        applicantType: changeValueAtIndex(sponsor.applicantType, 1, `${index+1}`),
        applicantDisplayType: changeValueAtIndex(sponsor.applicantDisplayType, 1, `${index+1}`),
        applicantDisplayTypeFr: changeValueAtIndex(sponsor.applicantDisplayTypeFr, 1, `${index+1}`)
      }))
    return [newSponsoredList, newSponsoredCheckbox];
  }

  private createChildList(): any[] {
    // Form controls should follow naming convention that match JSON data elements expected on backend API.
    return this.children().controls.map((control, childIndex) => {
      let child: any;
      if (this.applicationAnswers.jsonData.childList[childIndex]) {
        child = {...this.applicationAnswers.jsonData.childList[childIndex]};  // Save the original data as part of the child info initially
      } else {
        child = {};
      }
      // @ts-ignore
      Object.keys(control.controls).forEach((propertyName) => {
        if (this.CHECKBOX_CONTROLS.includes(propertyName)) {
          if (control.get(propertyName).value) {
            child[propertyName] = 'yes';
          } else {  // This attribute was turned on previously
            // Need to remove the attr from the json data so that it won't be saved with previous value
            delete child[propertyName];
          }
        } else if (control.get(propertyName).value) {
          if (FieldFormatUtil.needsToFormatValue(propertyName)) {
            child[propertyName] = StringUtil.removeSinHealthCardNumberSeparators(control.get(propertyName).value);
          } else {
            child[propertyName] = control.get(propertyName).value;
          }
        } else if (child[propertyName]) {  // This attribute had a value previously
          // Need to remove the attr from the json data so that it won't be saved with previous value
          delete child[propertyName];
        }
      })
      return child;
    })
  }

  onNoLastNameCheckBoxChanges(childIndex: number, event: any, displayPopup: boolean): void {
    if (event && event.length) {
      this.children().controls[childIndex].get('childLastName').setValue(null);
      this.children().controls[childIndex].get('childLastName').disable();
      if (displayPopup) {
        this.openDialog(NoLastNamePopup.noLastName);
      }
      this.children().controls[childIndex].get('childNoLastName').setValue(true);
    } else {
      this.children().controls[childIndex].get('childLastName').enable();
      this.children().controls[childIndex].get('childNoLastName').setValue(null);
    }
  }

  onChildDateOfBirthBlur(event, childIndex): void {
    if (event) {
      if (SadaCustomValidator.validateOverAge(event, 18)) {
        if (this.children().controls[childIndex].get('childEmail').disabled) {
          this.children().controls[childIndex].get('childEmail').enable();
        }
      } else if (this.children().controls[childIndex].get('childEmail').enabled) {
        this.children().controls[childIndex].get('childEmail').disable();
        this.children().controls[childIndex].get('childEmail').setValue(null);
      }
    } else if (this.children().controls[childIndex].get('childEmail').disabled) {
      this.children().controls[childIndex].get('childEmail').enable();
    }
  }

  onNoUniqueEmailCheckBoxChanges(childIndex: number, event: any): void {
    if (event && event.length) {
      this.children().controls[childIndex].get('childEmail').disable();
      this.children().controls[childIndex].get('childNoUniqueEmail').setValue(true);
      this.children().controls[childIndex].get('childEmail').setValue(null);
    } else {
      this.children().controls[childIndex].get('childEmail').enable();
      this.children().controls[childIndex].get('childNoUniqueEmail').setValue(null);
    }
  }

  showEmailAddress(childIndex: number): boolean {
    return !this.children()?.controls[childIndex]?.get('childDateOfBirth')?.value ||
      SadaCustomValidator.validateOverAge(this.children()?.controls[childIndex]?.get('childDateOfBirth')?.value, 18);
  }

  isNoLastNameSelected(childIndex: number) {
    return this.children().controls[childIndex].get('childNoLastName')?.value?.length;
  }

  getFirstName(childIndex: number) {
    if (this.children().controls[childIndex].get('childFirstName')?.value) {
      return this.children().controls[childIndex].get('childFirstName')?.value;
    } else {
      return '';
    }
  }

  getNoLastNameCheckBoxItems(index: number): {value: string, label: string; checked: boolean}[] {
    return this.getCheckBoxItems('childNoLastName', this.children().controls[index].get('childNoLastName')?.value, index);
  }
  getNoUniqueEmailCheckBoxItems(index: number) {
    return this.getCheckBoxItems('childNoUniqueEmail', this.children().controls[index].get('childNoUniqueEmail')?.value, index);
  }

  getCheckBoxItems(elementName: string, value: string, childIndex: number): CheckboxItem[] {
    if (value) {
      return [{
        value: 'yes',
        label: 'children-information.' + elementName,
        checked: true,
        labelParam: {index: childIndex + 1, name: this.getFirstName(childIndex)}
      }]
    } else {
      return [{
        value: 'yes',
        label: 'children-information.' + elementName,
        checked: false,
        labelParam: {index: childIndex + 1, name: this.getFirstName(childIndex)}
      }]
    }
  }

  findInvalidControl(panelName: any): any {
    const formGroups: FormArray = this.form.get(panelName) as FormArray;
    let inValidControlName: string;
    for (let i = 0; i < formGroups.length; i++){
      const fg = formGroups.at(i) as FormGroup
      if (!fg.valid) {
        inValidControlName = this.findInvalidControlNameInFormGroup(fg)
        return `${inValidControlName}`
      }
    }
  }
}
