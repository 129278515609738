import {ApplicationAnswers} from '../../models/data.model';

export class SharedUtil {

  static checkIfDbdShouldBeExcluded(applicationAnswers: ApplicationAnswers): boolean {

    if (applicationAnswers.jsonData.ownItemsOfValueDetailsListBankAccounts) {
      return applicationAnswers.jsonData.ownItemsOfValueDetailsListBankAccounts?.filter(
        bankAccount => {
        return bankAccount.applicantType.indexOf('Applicant') >= 0;
      }).length > 0
    }
    return false
  }
}

export const swapKeyValue = (obj, key: string[]) => Object.keys(key.reduce((object, path) => object[path], {...obj}) || [])
  .map(k => ({[key.reduce((object, path) => object[path], {...obj})[k]]: `${key.join('.')}.${k}`}))

export const flatToObject = (objs: any[][]) => objs.reduce((p,c)=>([...p, ...c])).reduce((p,c)=>({...p, ...c}), {})
