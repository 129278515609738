<sd-page-container [pageId]="pageId" [showResumeBanner]="showResumeBanner" [showPsRedirectError]="showPsRedirectError"
                   [showBackButton]=true>
  <div id="page-alerts-container">
    <sd-alert-error-box *ngIf="selectAtLeastOneButtonError"
                        errorHeader="{{'global-error.error' | translate}}"
                        errorBody="{{'program-recommendation.select.one.program-type' | translate}}">
    </sd-alert-error-box>
  </div>
  <div>
    <form *ngIf="recommendedProgramType" [formGroup]="form">
      <div>
        <div class="ontario-card__container">
          <div [id]="getCardId(recommendedProgramType)" class="ontario-card ontario-card--cards-per-row-1"
               [ngClass]="{'ontario-card__active': selectedValue === recommendedProgramType}">
            <div class="ontario-bg-dark-purple recommendation-header">
              <p class="ontario-application-subheader__heading">
                {{recommendationContent | translate}}
              </p>
            </div>
            <div class="ontario-card__description">
              <sd-program-card [displayImage]=true [programType]="recommendedProgramType"
                               [isRecommended]=true
                               [controlName]="programTypeRadioControlName"
              ></sd-program-card>
            </div>
          </div>
        </div>
        <ng-container *ngIf="otherAvailableProgramType1">
          <div>
            <p class="ontario-padding-top-8-! ontario-padding-bottom-8-!">{{'program-recommendation.other.available.programs' | translate}}</p>
          </div>
          <div class="ontario-card__container ontario-card--cards-per-row-2">
            <div [id]="getCardId(otherAvailableProgramType1)" class="ontario-card"
                 [ngClass]="{'ontario-card__active': selectedValue === otherAvailableProgramType1}">
              <div class="ontario-card__description">
                <sd-program-card [displayImage]=false [isRecommended]=false
                                 [programType]="otherAvailableProgramType1"
                                 [controlName]="programTypeRadioControlName"
                ></sd-program-card>
              </div>
            </div>
            <div [id]="getCardId(otherAvailableProgramType2)" class="ontario-card"
                 [ngClass]="{'ontario-card__active': selectedValue === otherAvailableProgramType2}">
              <div class="ontario-card__description">
                <sd-program-card [displayImage]=false [isRecommended]=false
                                 [programType]="otherAvailableProgramType2"
                                 [controlName]="programTypeRadioControlName"
                ></sd-program-card>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <sd-info-text *ngIf="showCalloutMessage" [isInfoTextOnly]="true" [contentId]="'program-recommendation-callout-message'" [content]="'program-recommendation.callout.message'"></sd-info-text>
  <app-mccss-save-continue-button [pageId]="pageId"
                                  dataE2E="continueBtn"
                                  (handlePrimaryButtonClick)="onSubmit(true)"
                                  (handleSecondaryButtonClick)="onSubmit(false)">
  </app-mccss-save-continue-button>
</sd-page-container>
