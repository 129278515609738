import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';

export class FormToFaSoldOrGiveAwayAssets implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    appData.jsonData.soldOrGivenItemsOfValue = form.get('FASoldOrGiveAwayAssets').value;
  }
}
