<sd-page-container [pageId]="pageId">
  <div id="page-alerts-container">
    <div [class]="'ontario-alert' + ' ontario-alert--error'">
      <div class="ontario-alert__header">
        <div class="ontario-alert__header-icon">
          <svg
            class="ontario-icon"
            alt=""
            aria-hidden="true"
            focusable="false"
            sol:category="primary"
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet">
            <use [attr.href]="'#ontario-icon-alert-error'"></use>
          </svg>
        </div>
        <div class="ontario-alert__header-title ontario-h4">
          {{'global-error.error' | translate}}
        </div>
      </div>
      <div class="ontario-alert__body">
        <p [innerHTML]="'global-error.'+errorBannerMsgKey | translate"></p>
      </div>
    </div>
  </div>
  <div *ngIf="subHeaderKey" class="ontario-lead-statement">{{'global-error.page.subheader.'+subHeaderKey | translate}}</div>

  <div class="mt-5" *ngIf="startNewButtonVisible">
    <app-mccss-submit-button buttonLabel="{{'global-error.session.timeout.button.label' | translate}}" (handleClick)="onSubmit()"></app-mccss-submit-button>
  </div>
</sd-page-container>
