import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ExternalRouter} from '../../external.router';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {CaseWorkerAuthorizeService} from '../../services/caseWorkerAuthorizeService';
import {filter, takeUntil} from 'rxjs/operators';
import {AuthenticationResult, EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {ConfigService} from '../../services/config.service';
import {PageInfo} from '../../models/page-map';

/**
 * login/authenticate internal OPS users.
 * public users are anonymous and not required to login.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>()

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private externalRouter: ExternalRouter,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authorizeService: CaseWorkerAuthorizeService,
    public configService: ConfigService) {
  }

  ngOnInit(): void {
    // only enabled in lower env to run test automation scripts
    if(this.configService.getClientConfig()?.enableClaimTokenStub) {
      this.authorizeClaimTokenStub();
    } else {
      this.externalRouter.loginRedirect();
      this.authorizeUser();
    }
  }

  authorizeClaimTokenStub():void {
    this.route.queryParams.subscribe(params => {
      if (params.token) { // if token provided
        const decodedToken = atob(params.token) // decode base64 string
        this.authorizeService.setClaims(JSON.parse(decodedToken))

        if (this.authorizeService.isAuthorized()) {
          this.router.navigate(['/', 'intake', PageInfo.home])
        } else {
          this.router.navigate(['/', 'unauthorized']);
        }
      } else { // when token not provided then use Azure AD to authenticate
        this.externalRouter.loginRedirect();
        this.authorizeUser();
      }
    });
  }

  // authenticate user against Azure AD and check if they've authorized roles to access SADA app.
  authorizeUser(){
    /**
     * subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // console.log('login success', result);
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // console.log('login inProgress$');
        this.checkAndSetActiveAccount();

        const idTokenClaims = this.msalService.instance.getActiveAccount()?.idTokenClaims;
        // console.log('toke claims:',JSON.stringify(idTokenClaims));
        this.authorizeService.setClaims(idTokenClaims);

        if (this.authorizeService.isAuthorized()) {
          this.router.navigate(['/', 'intake', PageInfo.home])
        } else if (!idTokenClaims) {
          this.router.navigate(['/', 'caseworkerlogin']);
        } else {
          this.router.navigate(['/', 'unauthorized']);
        }
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in app component
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
