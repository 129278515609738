import {SadaCustomValidator} from '../../validator/sada-custom-validator';

export const PoBoxValidationFunction = [
  {
    validationFunction: SadaCustomValidator.validatePoBox,
    errorKey: 'error.invalid.mPoBox.characters'
  }];

export const StationValidationFunction = [
  {
    validationFunction: SadaCustomValidator.validateStation,
    errorKey: 'error.invalid.mStation.characters'
  }];

export const ApartmentNumberValidationFunction = [
  {
    validationFunction: SadaCustomValidator.validateDashSlashLetterAndNumber,
    errorKey: 'error.invalid.apartmentNumber.characters'
  }];

export const StreetNumberValidationFunction = [
  {
    validationFunction: SadaCustomValidator.validateDescription,
    errorKey: 'error.invalid.streetNameOrNumber.characters.detailed'
  },
]

export const StreetNameValidationFunction = [
  {
    validationFunction: SadaCustomValidator.validateStreetName,
    errorKey: 'error.invalid.streetNameOrNumber.characters.detailed'
  },
]

export const CityOrTownValidationFunction = [
  {
    validationFunction: SadaCustomValidator.validateCityOrTownName,
    errorKey: 'error.invalid.cityOrTown.characters'
  }
]

export const RuralRouteValidationFunction = [
  {
    validationFunction: SadaCustomValidator.validateStringHasOnlyAlphaNumericCharsAndDashes,
    errorKey: 'error.invalid.ruralRoute.characters'
  }
]

export const MGeneralDeliveryValidationFunction = [
  {
    validationFunction: SadaCustomValidator.validateGeneralDelivery,
    errorKey: 'error.invalid.mGeneralDelivery.characters'
  }
]
