import {AbstractControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {BankingService} from '../../../services/banking.service';

/**
 * validate bank institution and branch number using backend service.
 */
export function validateInstitutionBranchNumber(control: AbstractControl,
                                                controlName: string,
                                                bankingService: BankingService): Observable<boolean> {

  const formGroup = control.parent;
  const institutionNumberCtrl = formGroup.get('institutionNumber');
  const branchNumberCtrl = formGroup.get('branchNumber');

  if (institutionNumberCtrl.value && branchNumberCtrl.value) {

    if (controlName === 'institutionNumber' && !bankingService.isManualTriggerValidation()) {

      bankingService.setManualTriggerValidation(true);
      branchNumberCtrl.updateValueAndValidity(); // also trigger validation of branch when institution is updated

    } else if (controlName === 'branchNumber' && !bankingService.isManualTriggerValidation()) {

      bankingService.setManualTriggerValidation(true);
      institutionNumberCtrl.updateValueAndValidity(); // also trigger validation of institution when branch is updated

    } else {

      bankingService.setManualTriggerValidation(false);

    }

    return bankingService.validateInstitutionBranchNumber(institutionNumberCtrl.value, branchNumberCtrl.value);

  } else {

    return of(true);

  }

}
