import {Component, OnInit} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-eid-parent',
  templateUrl: './eid-parent.component.html',
  styleUrls: ['./eid-parent.component.scss']
})
export class EidParentComponent implements OnInit {
  ngOnInit(): void {
  }
}
