import {
  AppDataToFABankAccounts,
  AppDataToFABankAccountsDynamicPanelsConverter,
  AppDataToFABankAccountsWhoOwnsThisAsset,
  AppDataToFACash,
  AppDataToFACashDynamicPanelsConverter,
  AppDataToFACashWhoOwnsThisAsset,
  AppDataToFAInvestments,
  AppDataToFAInvestmentsDynamicPanelsConverter,
  AppDataToFAInvestmentsWhoOwnsThisAsset,
  AppDataToFANoneOfAbove,
  AppDataToFAOtherAssets,
  AppDataToFAOtherAssetsDynamicPanelsConverter,
  AppDataToFAOtherAssetsWhoOwnsThisAsset,
  AppDataToFAOwnedHome,
  AppDataToFASoldOrGiveAwayAssets,
  AppDataToFATrustFund,
  AppDataToFAVehicles,
  AppDataToFAVehiclesDynamicPanelsConverter,
  AppDataToFAVehiclesWhoOwnsThisAsset
} from './converters/app-data-to-questions';
import {Question} from '../../common/utils/questions/question';
import {ApplicationAnswers} from '../../models/data.model';
import {Converter, FormConverter} from '../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {FormToFACash} from './converters/form-to-fa-cash';
import {FormToFAInvestments} from './converters/form-to-fa-investments';
import {FormToFaTrustFund} from './converters/form-to-fa-trust-fund';
import {FormToFaSoldOrGiveAwayAssets} from './converters/form-to-fa-sold-or-give';
import {FormToFANoneOfAbove} from './converters/form-to-fa-none';
import {FormToFABankAccounts} from './converters/form-to-fa-bank-accounts';
import {FormToFAVehicles} from './converters/form-to-fa-vehicles';
import {FormToFAOtherAssets} from './converters/form-to-fa-other-assets';
import {FormToFAOwnedHome} from './converters/form-to-fa-owned-home';
import {Injectable} from '@angular/core';

export const appDataToQuestionConverterMap: Map<string, Converter<ApplicationAnswers, Question<string>>> =  new Map([
    ['FACash', new AppDataToFACash()],
    ['FACashWhoOwnsThisAsset', new AppDataToFACashWhoOwnsThisAsset()],
    ['FABankAccounts', new AppDataToFABankAccounts()],
    ['FABankAccountsWhoOwnsThisAsset', new AppDataToFABankAccountsWhoOwnsThisAsset()],
    ['FAInvestments', new AppDataToFAInvestments()],
    ['FAInvestmentsWhoOwnsThisAsset', new AppDataToFAInvestmentsWhoOwnsThisAsset()],
    ['FAVehicles', new AppDataToFAVehicles()],
    ['FAVehiclesWhoOwnsThisAsset', new AppDataToFAVehiclesWhoOwnsThisAsset()],
    ['FATrustFundPanel', new AppDataToFATrustFund()],
    ['FAOwnedHome', new AppDataToFAOwnedHome()],
    ['FAOtherAssets', new AppDataToFAOtherAssets()],
    ['FAOtherAssetsWhoOwnsThisAsset', new AppDataToFAOtherAssetsWhoOwnsThisAsset()],
    ['FANoneOfAbove', new AppDataToFANoneOfAbove()],
    ['FASoldOrGiveAwayAssets', new AppDataToFASoldOrGiveAwayAssets()],

  ]);

export const formDataToAppDataConverterMap: Map<string, FormConverter<FormGroup, ApplicationAnswers, Question<string>[]>> =  new Map([
  ['FACash', new FormToFACash()],
  ['FABankAccounts', new FormToFABankAccounts()],
  ['FAInvestments', new FormToFAInvestments()],
  ['FAVehicles', new FormToFAVehicles()],
  ['FATrustFundPanel', new FormToFaTrustFund()],
  ['FAOwnedHome', new FormToFAOwnedHome()],
  ['FAOtherAssets', new FormToFAOtherAssets()],
  ['FASoldOrGiveAwayAssets', new FormToFaSoldOrGiveAwayAssets()],
  ['FANoneOfAbove', new FormToFANoneOfAbove()],

]);

@Injectable()
export class AppDataToQuestionsConverter implements Converter<ApplicationAnswers, Question<string>[]> {

  constructor(private appDataToFACashDynamicPanels: AppDataToFACashDynamicPanelsConverter,
              private appDataToFABankAccountsDynamicPanels: AppDataToFABankAccountsDynamicPanelsConverter,
              private appDataToFAInvestmentsDynamicPanels: AppDataToFAInvestmentsDynamicPanelsConverter,
              private appDataToFAVehiclesDynamicPanels: AppDataToFAVehiclesDynamicPanelsConverter,
              private appDataToFAOtherAssetsDynamicPanels: AppDataToFAOtherAssetsDynamicPanelsConverter) {
  }

  convert(appData: ApplicationAnswers, questions: Question<string>[]): void {

    // update questions based on app data
    questions.forEach(question => {
      appDataToQuestionConverterMap.get(question.key)?.convert(appData, question);
    });

    // FACash add dynamic panels for applicant, spouse and children.
    this.appDataToFACashDynamicPanels.convert(appData,questions);

    // FABankAccounts add dynamic panels for applicant, spouse and children
    this.appDataToFABankAccountsDynamicPanels.convert(appData, questions);

    // FAInvestments add dynamic panels for applicant, spouse and children
    this.appDataToFAInvestmentsDynamicPanels.convert(appData,questions);

    // FAVehicles add dynamic panels for applicant, spouse and children
    this.appDataToFAVehiclesDynamicPanels.convert(appData,questions);

    // FAOtherAssets add dynamic panels for applicant, spouse and children
    this.appDataToFAOtherAssetsDynamicPanels.convert(appData,questions);

  }

}

@Injectable()
export class FormDataToAppDataConverter implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // iterate thru top level controls on form and call mapped converter
    Object.keys(form.controls).forEach(key => {
      formDataToAppDataConverterMap.get(key)?.convert(form, appData, questions);
    });
  }
};
