<sd-page-container [pageId]="pageId">
  <div id="page-alerts-container">
    <sd-info-text header="eid-page.page.alert.title" content="eid-page.page.alert.content" contentId="pageAlertContentId"></sd-info-text>
  </div>

  <div id="spinner-container" *ngIf="!this.loaded">
    <div class="row">
      <div id="spinner-col" class="col-12">
        <div id="spinner">
          <svg class='ontario-loading-indicator__spinner' viewBox='0 0 66 66'>
            <circle fill='none' stroke-width='6' stroke-linecap='round' cx='33' cy='33' r='30'></circle>
          </svg>
          <span class="sr-only">{{'eid-page.loading.indicator.text' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="page-description mt-5">{{'eid-page.loading.text' | translate}}</div>
  </div>

  <div *ngFor="let person of persons; let index = index; let lastItem = last">
    <div class="row my-3">
      <div class="ontario-h2 col-6">{{getName(person, index)}}</div>
    </div>
    <div *ngIf="person.eidProgress == null" class="row my-5">
      <div class="col row pad-left">
        <app-mccss-submit-button buttonId="confirm-button" dataE2E="confirmIdBtn" buttonLabel="{{'eid-page.button.confirm.identity.online'}}" [preventDoubleClick]="true" (handleClick)="onStartClicked(person)" [secondaryButton]="isSecondaryButton()"></app-mccss-submit-button>
      </div>
    </div>
    <div *ngIf="progressTextForPerson(person)" class="row my-5">
      <div class="row col eid-progress">
        <svg class="ontario-icon" alt="test" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <use class="eid-progress-icon" attr.href="{{imageForPerson(person)}}"></use>
        </svg>
        <span class="eid-progress-label"><strong>{{eidStatus | translate}}</strong></span>
      </div>
    </div>
    <div *ngIf="!lastItem" class="divider" aria-hidden="true"></div>
  </div>
  <app-mccss-submit-button *ngIf="isComplete() && (!isAuthorizedUser || !hasNoUniqueEmails())" buttonId="submit-button" dataE2E="EIdConfirmBtn" buttonLabel="{{'eid-confirm-identity.button.continue'}}" [preventDoubleClick]="true" (handleClick)="onSubmit()"></app-mccss-submit-button>
  <app-mccss-submit-button *ngIf="isComplete() && isAuthorizedUser && hasNoUniqueEmails()" buttonId="submit-now-button" dataE2E="EIdConfirmBtn" buttonLabel="{{'eid-confirm-identity.button.submit'}}" [preventDoubleClick]="true" (handleClick)="onSubmit()"></app-mccss-submit-button>
</sd-page-container>
