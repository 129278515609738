import {Injectable} from '@angular/core';
import {AddressLookupService} from './address-lookup.service';
import {Observable} from 'rxjs';
import {PcLookupResponse} from '../models/pc-lookup-response';
import {PcLookupRequest} from '../models/pc-lookup-request';
import {map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddressLookupServiceImpl implements AddressLookupService {
  private addressChoices: BehaviorSubject<PcLookupResponse> = new BehaviorSubject<PcLookupResponse>(null)

  constructor(private http: HttpClient) {
  }

  getAddressChoices(pcLookupRequest: PcLookupRequest): Observable<PcLookupResponse> {
    return this.http.post<PcLookupResponse>(`/v1/address-choices`, pcLookupRequest,
      {withCredentials: true});
  }

  getAddressChoicesAndPopulateCache(pcLookupRequest: PcLookupRequest, isHomeAddressUpdated,
                                    isMailingAddressUpdated): Observable<PcLookupResponse> {
    return this.getAddressChoices(pcLookupRequest).pipe(map(data => {
      return this.populateAddressChoiceCache(data, isHomeAddressUpdated, isMailingAddressUpdated)
    }))
  }

  private populateAddressChoiceCache(pcLookUpResponse: PcLookupResponse, isHomeAddressUpdated,
                                     isMailingAddressUpdated): PcLookupResponse {
    const data: PcLookupResponse = {
      homeAddressChoices: isHomeAddressUpdated ? pcLookUpResponse.homeAddressChoices :
        this.addressChoices.getValue() ? this.addressChoices.getValue().homeAddressChoices : [],
      mailingAddressChoices: isMailingAddressUpdated ? pcLookUpResponse.mailingAddressChoices :
        this.addressChoices.getValue() ? this.addressChoices.getValue().mailingAddressChoices : []
    }
    this.addressChoices.next(data)
    return data
  }

  getCachedAddressChoices(): Observable<PcLookupResponse> {
    return this.addressChoices.asObservable();
  }

  clearCachedAddressChoices(): void {
    this.addressChoices.next(null)
  }

  clearCachedMailingAddressChoices(): void {
    const data = this.addressChoices.getValue()
    if (data) {
      data.mailingAddressChoices = []
    }
  }

  isCachedAddressChoicesAvailable() {
    const data = this.addressChoices.getValue()
    return (data && (this.isArrayNotEmpty(data.homeAddressChoices)
      || this.isArrayNotEmpty(data.mailingAddressChoices)))
  }

  private isArrayNotEmpty(value) {
    return (Array.isArray(value) && value.length)
  }
}
