import {AbstractControl, ValidatorFn} from '@angular/forms';

/**
 * validate valid value.
 * @param validValue
 */
export function invalidValueValidator(inValidValues: string[]): ValidatorFn {

  return (control: AbstractControl): {  [key: string]: any } | null => {

    const value = control.value;

    const valid = !inValidValues.includes(value);

    return valid ? null : { invalidValue: value };
  };
}
