<div *ngIf="loadingSpinnerService.loading | async">
  <div class='ontario-loading-indicator__overlay' aria-hidden='false' role='alert' aria-live='assertive'>
    <div id="spinner" class='ontario-loading-indicator'>
      <svg class='ontario-loading-indicator__spinner' viewBox='0 0 66 66'>
        <circle fill='none' stroke-width='6' stroke-linecap='round' cx='33' cy='33' r='30'></circle>
      </svg>
      <p *ngIf="!loadingSpinnerService.altText">{{'loading.spinner.text' | translate}}</p>
      <p *ngIf="loadingSpinnerService.altText">{{'loading.spinner.alttext' | translate}}</p>
    </div>
  </div>
</div>
