import {FormConverter} from '../../../common/utils/converters/converter';
import {FormGroup} from '@angular/forms';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {FormToAppDataBase} from '../../../common/utils/converters/form-to-app-data-base';

export class FormToFABankAccounts extends FormToAppDataBase implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  static readonly CHECK_BOX = 'ownItemsOfValueBankAccountsCheckbox';
  static readonly DETAILS_LIST = 'ownItemsOfValueDetailsListBankAccounts';
  static readonly APPLICANT_CHECKBOX = 'ownItemsOfValueApplicantCheckboxBankAccounts';
  static readonly PANEL_CHILD = 'FABankAccountsWhoOwnsThisAssetPanelChild';
  static readonly WHO_OWN_THIS_ASSET = 'FABankAccountsWhoOwnsThisAsset';

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    // remove previous saved data and repopulate with latest form data
    delete appData.jsonData[FormToFABankAccounts.CHECK_BOX];
    delete appData.jsonData[FormToFABankAccounts.DETAILS_LIST];
    delete appData.jsonData[FormToFABankAccounts.APPLICANT_CHECKBOX];

    if (form.get('FABankAccounts')?.value[0]) {
      appData.jsonData[FormToFABankAccounts.CHECK_BOX] = ['yes'];

      // populate applicant check box list
      this.populateCheckBoxList(form, appData, questions);

      // populate details list
      this.populateDetailsList(form, appData, questions);
    }
  }

  populateCheckBoxList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const list = [];
    const question = questions.find(q => q.key === 'FABankAccountsWhoOwnsThisAsset');

    if(appData.jsonData.spouseFirstName || appData.jsonData.childList){

      const applicantOption = question.options.find(o => o.label === 'financial-assets.applicant');

      if (this.isApplicantSelected(FormToFABankAccounts.WHO_OWN_THIS_ASSET,form,questions) &&
        form.get('FABankAccountsWhoOwnsThisAssetPanelApplicant')?.value.panels[0].bankAccountValue) {
        list.push(applicantOption.alternateValue);
      }
    }

    if (this.isSpouseSelected(FormToFABankAccounts.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FABankAccountsWhoOwnsThisAssetPanelSpouse')?.value.panels[0].bankAccountValue){
      const spouseOption = question.options.find(o => o.label === 'financial-assets.spouse');
      list.push(spouseOption.alternateValue);
    }

    if (appData.jsonData.childList){
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (form.get(FormToFABankAccounts.PANEL_CHILD.concat(String(i)))?.value.panels[0].bankAccountValue) {
          const child = questions.find(q => q.key === FormToFABankAccounts.PANEL_CHILD.concat(String(i)));

          if (this.isChildSelected(i,FormToFABankAccounts.WHO_OWN_THIS_ASSET,form,questions)) {
            list.push(child.value);
          }
        }
      }
    }

    if (list.length > 0) {
      appData.jsonData[FormToFABankAccounts.APPLICANT_CHECKBOX] = list;
    }
  }

  populateDetailsList(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const detailsList = [];

    if (this.isApplicantSelected(FormToFABankAccounts.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FABankAccountsWhoOwnsThisAssetPanelApplicant').value.panels[0].bankAccountValue) {
      const applicant:any = {
        applicant: 'Applicant: '.concat(appData.jsonData.firstName),
        applicantType: 'Applicant',
        applicantDisplayType: 'Applicant',
        applicantName: ' '.concat(appData.jsonData.firstName),
        bankAccountList: []
      };

      form.get('FABankAccountsWhoOwnsThisAssetPanelApplicant').value.panels.forEach(p => {
        applicant.bankAccountList.push({
          bankName: p.bankName,
          accountType: p.accountType,
          value: p.bankAccountValue,
          useAccountForDBD: p.useAccountForDBD && p.useAccountForDBD[0] ? ['yes'] : ['no'],
          branchNumber: p.branchNumber,
          institutionNumber: p.institutionNumber,
          accountNumber: p.accountNumber
        });
      });

      const found = applicant.bankAccountList.find(x => x.useAccountForDBD[0] === 'yes');

      if (found){
        this.removeDataEnteredOnDBDPage(appData);
      }

      detailsList.push(applicant);
    }

    if (this.isSpouseSelected(FormToFABankAccounts.WHO_OWN_THIS_ASSET,form,questions) &&
      form.get('FABankAccountsWhoOwnsThisAssetPanelSpouse')?.value.panels[0].bankAccountValue) {
      const spouse:any = {
        applicant: 'Spouse: '.concat(appData.jsonData.spouseFirstName),
        applicantType: 'Spouse',
        applicantDisplayType: 'Spouse',
        applicantName: ' '.concat(appData.jsonData.spouseFirstName),
        bankAccountList: []
      };

      form.get('FABankAccountsWhoOwnsThisAssetPanelSpouse').value.panels.forEach(p => {
        spouse.bankAccountList.push({
          bankName: p.bankName,
          accountType: p.accountType,
          value: p.bankAccountValue
        });
      });

      detailsList.push(spouse);
    }

    // populate children data
    if (appData.jsonData.childList) {
      for (let i = 1; i <= appData.jsonData.childList.length; i++){
        if (this.isChildSelected(i,FormToFABankAccounts.WHO_OWN_THIS_ASSET,form,questions) &&
          form.get(FormToFABankAccounts.PANEL_CHILD.concat(String(i)))?.value.panels[0].bankAccountValue) {
          const child = questions.find(q => q.key === FormToFABankAccounts.PANEL_CHILD.concat(String(i)));
          const childAccount =
          {
            applicant: child.value,
            applicantType: 'Child '.concat(String(i)),
            applicantDisplayType: 'Child '.concat(String(i)),
            applicantName: child.value.substr(child.value.indexOf(':') + 1),
            bankAccountList: []
          }

          form.get(FormToFABankAccounts.PANEL_CHILD.concat(String(i)))?.value.panels.forEach(p => {
            childAccount.bankAccountList.push(
              {
                bankName: p.bankName,
                accountType: p.accountType,
                value: p.bankAccountValue
              }
             );
          });

          detailsList.push(childAccount);
        }
      }
    }

    if (detailsList.length > 0){
      appData.jsonData[FormToFABankAccounts.DETAILS_LIST] = detailsList;
    }
  }

  /**
   * DBD entered on Financial Assets will override info entered on DBD page.
   */
  removeDataEnteredOnDBDPage(appData: ApplicationAnswers):void {
    delete appData.jsonData.bankName;
    delete appData.jsonData.accountType;
    delete appData.jsonData.branchNumber;
    delete appData.jsonData.institutionNumber;
    delete appData.jsonData.accountNumber;
    delete appData.jsonData.accountValue;
  }
}
