import {Observable} from 'rxjs';

import {ApplicationStatusResponse} from '../models/application-status-response';

export abstract class ApplicationStatusService {
  abstract getStatus(referenceCode?: string, incompleteApplication?: boolean): Observable<ApplicationStatusResponse>;
  abstract getReferenceCode(): string;
  abstract setReferenceCode(referenceCode:string): void;
  abstract getStatusResponse(): ApplicationStatusResponse;
  abstract setStatusResponse(programStatuses: ApplicationStatusResponse): void;
}

