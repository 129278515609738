// These classes are created for the client configurations returned from the backend.

// Language is the top level key for the configurations.
export class LanguageInfo {
  public static readonly english = 'en';
  public static readonly french = 'fr';
}

// This class holds the keys to the URL being used in SADA pages.
// Note: Make sure the keys are synced with app-url.yml in the config repo.
export class UrlInfo {
  public static readonly acsd = 'acsdLink';
  public static readonly applicationStatus = 'applicationStatusLink';
  public static readonly applyForOdsp = 'applyForOdspLink';
  public static readonly odspAdjudicationExceptionsLink ='odspAdjudicationExceptionsLink';
  public static readonly benefitsFinder = 'benefitsFinderLink';
  public static readonly certificateOfIndianStatus = 'certIndianStatusLink'
  public static readonly chromeClearCache = 'chromeClearCacheLink';
  public static readonly contactUs = 'contSendEmailLink';
  public static readonly edgeClearCache = 'edgeClearCacheLink';
  public static readonly emergencyAssistance = 'emergencyAssistanceLink';
  public static readonly feedback = 'feedbackUrl';
  public static readonly fireboxClearCache = 'firefoxClearCacheLink';
  public static readonly immigrationStatusDocument = 'immigrationStatusDocLink';
  public static readonly myBenefits = 'myBenefitsLink';
  public static readonly myBenefitsLogin = 'myBenefitsLoginLink';
  public static readonly myBenefitsTerms = 'myBenefitsTermsLink';
  public static readonly odspMedicalCheckExemptions = 'odspMedicalCheckExemptionsLink';
  public static readonly disabilityAdjudicationUnit='disabilityAdjudicationUnitLink'
  public static readonly officeLocation = 'officeLocatorLink';
  public static readonly ontarioWorks = 'ontarioWorksLink';
  public static readonly onlineSocialAssistance = 'onlineSocialAssistanceLink';
  public static readonly ontarioWorksSection = 'ontarioWorksSecLink';
  public static readonly postalCodeFinder = 'postalCodeFinderLink';
  public static readonly publicGuardianTrusteeOffice = 'publicGuardianTrusteeOfficeLink';
  public static readonly resettlementAssistanceProgram = 'resettlementAssistanceProgramLink';
  public static readonly safariClearCache = 'safariClearCacheLink';
  public static readonly securityLearn = 'securityLearnLink';
  public static readonly socialAssistance = 'socialAssistanceUrl';
  public static readonly suspectFraud = 'suspectFraudLink'
  public static readonly tca = 'tcaLink';
}
