export class WdaComponentUtil {

  static isPrimaryApplicant(type: string) {
    return type.toLowerCase() === applicantType.primary.toLowerCase();
  }

  static isSpouseApplicant(type: string) {
    return type.toLowerCase() === applicantType.spouse.toLowerCase();
  }

  static isChildApplicant(type: string) {
    return type.toLowerCase() === applicantType.child.toLowerCase();
  }

  static buildNameString(firstName: string, lastName: string) {
    return lastName ? firstName + ' ' + lastName : firstName;
  }

  static getChildTypeStr(childListIndex: number) {
    return applicantType.child + ' ' + (childListIndex + 1);
  }
}

export const applicantType = {
  primary: 'Applicant',
  spouse: 'Spouse',
  child: 'Child',
} as const
