export enum ProgramType {
  ONW = 'ONW', // Ontario Works
  ODS = 'ODS', // Ontario Disability Support Program (ODSP)
  ONWODS = 'ONWODS',
  ACSD = 'ACSD', // Assistance for Children with Severe Disabilities (ACSD)
  /**
   * @deprecated Kept to support older apps. Will be removed eventually.
   */
  OW = 'OW'
}
