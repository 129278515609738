import {Injectable} from '@angular/core';
import {Question} from '../../../common/utils/questions/question';
import {HouseholdIncomeQuestionHelper} from './household-income-question.helper';

@Injectable()
export class HouseHoldIncomeQuestionService {

  public questionKeys = ['CPPQPPPaymentPanel.panels.0.DisabilityPayment',
    'CPPQPPPaymentPanel.panels.0.RetirementPayment',
    'CPPQPPPaymentPanel.panels.0.SurvivingSpousePensionPayment',
    'WSIBPaymentPanel.panels.0.LossOfIncomePayment',
    'WSIBPaymentPanel.panels.0.OtherPayment',
    'InsurancePaymentPanel.panels.0.InsurancePayment',
    'GovernmentPaymentPanel.panels.0.OldAgeSecurity',
    'GovernmentPaymentPanel.panels.0.GuaranteedIncomeSupplement',
    'GovernmentPaymentPanel.panels.0.SpousalAllowance',
    'GovernmentPaymentPanel.panels.0.SurvivorAllowanceProgram',
    'GainsPaymentPanel.panels.0.GainsPayment',
    'EIPaymentPanel.panels.0.EIPayment',
    'StudentLoanPaymentPanel.panels.0.OSAPPayment',
    'StudentLoanPaymentPanel.panels.0.OtherStudentLoanPayment',
    'OtherIncomePanel.panels.0.OtherIncome'
  ]

  getDivider(parentKey: string): Question<string> {
    return {
      controlType: 'divider',
      key: 'whatever',
      visibleOnConditions: [{
        questionKey: parentKey,
        questionControlType: 'checkbox',
        index: 0,
        value: [true]
      }]
    }
  }

  getQuestions(): Question<string>[] {
    return [
      {
        controlType: 'panel',
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        key: 'CPPQPPPaymentPanel',
        needMarginTop: true,
        panels: [{
          name: 'CPPQPPPaymentPanel',
          text: 'household-income.cpp.heading',
          questions: [...HouseholdIncomeQuestionHelper.getPaymentQuestions('DisabilityPayment', 'household-income.disabilityPayment'),
            this.getDivider('DisabilityPayment'),
            ...HouseholdIncomeQuestionHelper.getPaymentQuestions('RetirementPayment', 'household-income.retirementPayment'),
            this.getDivider('RetirementPayment'),
            ...HouseholdIncomeQuestionHelper.getPaymentQuestions('SurvivingSpousePensionPayment', 'household-income.survivingSpousePensionPayment')
          ],
          showAddButton: false
        }]
      },
      {
        controlType: 'panel',
        key: 'WSIBPaymentPanel',
        needMarginTop: true,
        panels: [{
          name: 'WSIBPaymentPanel',
          text: 'household-income.wsib.heading',
          questions: [...HouseholdIncomeQuestionHelper.getPaymentQuestions('LossOfIncomePayment', 'household-income.lossOfIncomePayment'),
            this.getDivider('LossOfIncomePayment'),
            ...HouseholdIncomeQuestionHelper.getPaymentQuestions('OtherPayment', 'household-income.otherPayment'),
          ],
          showAddButton: false
        }]
      },
      {
        controlType: 'panel',
        key: 'InsurancePaymentPanel',
        needMarginTop: true,
        checkboxPanelBorders: true,
        panels: [{
          name: 'InsurancePaymentPanel',
          questions: HouseholdIncomeQuestionHelper.getPaymentQuestions('InsurancePayment', 'household-income.insurancePayment'),
          showAddButton: false
        }]
      },
      {
        controlType: 'panel',
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        key: 'GovernmentPaymentPanel',
        needMarginTop: true,
        panels: [{
          name: 'GovernmentPaymentPanel',
          text: 'household-income.cpp.governmentPayment.heading',
          questions: [...HouseholdIncomeQuestionHelper.getPaymentQuestions('OldAgeSecurity', 'household-income.oldAgeSecurity'),
            this.getDivider('OldAgeSecurity'),
            // tslint:disable-next-line:max-line-length
            ...HouseholdIncomeQuestionHelper.getPaymentQuestions('GuaranteedIncomeSupplement', 'household-income.guaranteedIncomeSupplement'),
            this.getDivider('GuaranteedIncomeSupplement'),
            ...HouseholdIncomeQuestionHelper.getPaymentQuestions('SpousalAllowance', 'household-income.spousalAllowance'),
            this.getDivider('SpousalAllowance'),
            ...HouseholdIncomeQuestionHelper.getPaymentQuestions('SurvivorAllowanceProgram', 'household-income.survivorAllowanceProgram')
          ],
          showAddButton: false
        }]
      },
      {
        controlType: 'panel',
        key: 'GainsPaymentPanel',
        needMarginTop: true,
        checkboxPanelBorders: true,
        panels: [{
          name: 'GainsPaymentPanel',
          questions: HouseholdIncomeQuestionHelper.getPaymentQuestions('GainsPayment', 'household-income.gainsPayment'),
          showAddButton: false
        }]
      },
      {
        controlType: 'panel',
        key: 'EIPaymentPanel',
        needMarginTop: true,
        checkboxPanelBorders: true,
        panels: [{
          name: 'EIPaymentPanel',
          questions: HouseholdIncomeQuestionHelper.getPaymentQuestions('EIPayment', 'household-income.eiPayment'),
          showAddButton: false
        }]
      },
      {
        controlType: 'panel',
        key: 'StudentLoanPaymentPanel',
        needMarginTop: true,
        panels: [{
          name: 'StudentLoanPaymentPanel',
          text: 'household-income.student-loan.heading',
          questions: [...HouseholdIncomeQuestionHelper.getPaymentQuestions('OSAPPayment', 'household-income.student-loan.osapPayment'),
            this.getDivider('OSAPPayment'),
            ...HouseholdIncomeQuestionHelper.getPaymentQuestions('OtherStudentLoanPayment', 'household-income.otherStudentLoanPayment')
          ],
          showAddButton: false
        }]
      },
      {
        controlType: 'panel',
        key: 'OtherIncomePanel',
        needMarginTop: true,
        checkboxPanelBorders: true,
        panels: [{
          name: 'OtherIncomePanel',
          questions: HouseholdIncomeQuestionHelper.getOtherIncomeQuestions('OtherIncome'),
          showAddButton: false
        }]
      },
      {
        controlType: 'checkbox',
        key: 'NoneOfAbove',
        needMarginTop: true,
        checkboxBorders: true,
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        resetWhenDisabled: true,
        options: [{
          value: false,
          label: 'household-income.none.of.above'
        }],
        disabledOnAnyCondition: this.getDisabledConditionForNoneOfAboveQuestion()
      }
    ];
  }

  private getDisabledConditionForNoneOfAboveQuestion() {
    const conditions = []
    this.questionKeys.forEach(qKey => {
      conditions.push(
        {
          questionKey: qKey,
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
        }
      )
    })

    return conditions;
  }
}
