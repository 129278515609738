import {AbstractControl} from '@angular/forms';

import {healthCardNumberFormatter, phoneNumberFormatter, socialInsuranceNumberFormatter} from '../../../utils/formatters/formatter';
import {StringUtil} from '../../../../utils/string-util';

export enum DataType {
  CISN = 'certificateOfIndianStatusNumber',
  DATE = 'date',
  EMAIL = 'email',
  HN = 'healthCardNumber',
  PN = 'phoneNumber',
  SIN = 'socialInsuranceNumber'
}

export class TextQuestionComponentUtil {
  static shouldAllowsNumbersOnly(dataType: string): boolean {
    switch (dataType) {
      case DataType.CISN:
      case DataType.HN:
      case DataType.PN:
      case DataType.SIN:
        return true;
        break;
    }
    return false;
  }

  static shouldFormatControlValue(dataType: string): boolean {
    switch (dataType) {
      case DataType.HN:
      case DataType.PN:
      case DataType.SIN:
        return true;
        break;
    }
    return false;
  }

  static formatControlValue(dataType: string, control: AbstractControl): void {
    switch (dataType) {
      case DataType.HN:
        TextQuestionComponentUtil.formatHealthCardNumber(control);
        break;
      case DataType.PN:
        TextQuestionComponentUtil.formatPhoneNumber(control);
        break;
      case DataType.SIN:
        TextQuestionComponentUtil.formatSocialInsuranceNumber(control);
        break;
    }
  }

  static unformatControlValue(dataType: string, control: AbstractControl): void {
    switch (dataType) {
      case DataType.CISN:
        TextQuestionComponentUtil.unformatCertificateOfIndianStatusNumber(control);
        break;
      case DataType.HN:
        TextQuestionComponentUtil.unformatHealthCardNumber(control);
        break;
      case DataType.PN:
        TextQuestionComponentUtil.unformatPhoneNumber(control);
        break;
      case DataType.SIN:
        TextQuestionComponentUtil.unformatSocialInsuranceNumber(control);
        break;
    }
  }

  static unformatCertificateOfIndianStatusNumber(control: AbstractControl): void {
    if (control.value) {
      control.setValue(StringUtil.removeSinHealthCardNumberSeparators(control.value));
    }
  }

  static formatHealthCardNumber(control: AbstractControl): void {
    if (control.value) {
      control.setValue(healthCardNumberFormatter(control.value));
    }
  }
  static unformatHealthCardNumber(control: AbstractControl): void {
    if (control.value) {
      control.setValue(StringUtil.removeSinHealthCardNumberSeparators(control.value));
    }
  }

  static formatPhoneNumber(control: AbstractControl): void {
    if (control.value) {
      control.setValue(phoneNumberFormatter(control.value));
    }
  }
  static unformatPhoneNumber(control: AbstractControl): void {
    if (control.value) {
      control.setValue(StringUtil.removeSeparators(control.value));
    }
  }

  static formatSocialInsuranceNumber(control: AbstractControl): void {
    if (control.value) {
      control.setValue(socialInsuranceNumberFormatter(control.value));
    }
  }
  static unformatSocialInsuranceNumber(control: AbstractControl): void {
    if (control.value) {
      control.setValue(StringUtil.removeSinHealthCardNumberSeparators(control.value));
    }
  }
}
