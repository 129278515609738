import {FormGroup} from '@angular/forms';
import {Question} from '../questions/question';

export abstract class FormToAppDataBase {
  isApplicantSelected(questionKey:string, form: FormGroup, questions: Question<string>[]):boolean {
    const question = questions.find(q => q.key === questionKey);
    const optionIndex = question.options.findIndex(o => o.label === 'financial-assets.applicant');
    const rawValue = form.getRawValue()[questionKey];
    return rawValue ? rawValue[optionIndex] : false;
  }

  isSpouseSelected(questionKey:string, form: FormGroup, questions: Question<string>[]):boolean {
    const question = questions.find(q => q.key === questionKey);
    const optionIndex = question.options.findIndex(o => o.label === 'financial-assets.spouse');
    const rawValue = form.getRawValue()[questionKey];
    return rawValue ? rawValue[optionIndex] : false;
  }

  isChildSelected(childIndex:number, questionKey:string, form: FormGroup, questions: Question<string>[]):boolean {
    const question = questions.find(q => q.key === questionKey);
    const childPanel = questions.find(q => q.key === questionKey.concat('PanelChild').concat(String(childIndex)));
    const optionIndex = question.options.findIndex(o => o.alternateValue === childPanel.value);
    const rawValue = form.getRawValue()[questionKey];
    return rawValue ? rawValue[optionIndex] : false;
  }

  isChildSelectedByAltValue(alternateValue:string, questionKey:string, form: FormGroup, questions: Question<string>[]):boolean {
    const question = questions.find(q => q.key === questionKey);
    const optionIndex = question.options.findIndex(o => o.alternateValue === alternateValue);
    const rawValue = form.getRawValue()[questionKey];
    return rawValue ? rawValue[optionIndex] : false;
  }
}
