import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';

import {popup} from '../../../dialog-constants';
import {ApplicationAnswers} from '../../../models/data.model';
import {EidProgress, Person} from '../../../models/eid/person.model';
import {EidService} from '../../../services/eid.service';
import {IntakeService} from '../../../services/intake.service';
import {ButtonUtil} from '../../../utils/button-util';
import {MccssDialogTitleComponent} from '../../../mccss-dialog-title/mccss-dialog-title.component';
import {BaseComponent} from '../../BaseComponent';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {ExternalRouter} from '../../../external.router';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {PageService} from '../../../services/page.service';
import {LoadingSpinnerService} from '../../../services/loading-spinner.service';
import {PageInfo} from '../../../models/page-map';

@Component({
  selector: 'app-eid-landing',
  templateUrl: './eid-landing.component.html',
  styleUrls: ['./eid-landing.component.scss']
})
export class EidLandingComponent extends BaseComponent implements OnInit, OnDestroy {
  protected readonly _destroyed$ = new Subject<void>();

  dialogVisible = false
  data: ApplicationAnswers = {jsonData: {}};
  person: Person

  constructor(private router: Router,
              public route: ActivatedRoute,
              public dialog: MatDialog,
              private eidService: EidService,
              public intakeService: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public externalRouter: ExternalRouter,
              public configService: ConfigService,
              public authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService
  ) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.eidPage;
  }

  ngOnInit(): void {
    this.intakeService.getApplication(this.pageId).subscribe(data => {
      this.data = data
    })
    this.eidService.getEligiblePersons().subscribe(persons => {
      this.person = persons?.find(person => person.personId != null)
    })
  }

  ngOnDestroy() {
    super.onDestroy()
    // this.dialog.closeAll()
    this._destroyed$.next(undefined);
    this._destroyed$.complete();
  }

  onConfirmIdentityClicked() {
    this.confirmEidNow()
  }

  // TBD
  onSubmit(toContinue?: boolean) {
  }

  private waitForAppointment() {
    this.eidService.cancelEIdForApplicant(this.person.personId).subscribe(() => {
      this.eidService.updateEidProgress(this.person.personId, EidProgress.skipped)
      this.router.navigate(['/', 'eid', 'confirm'])
    })
  }

  private confirmEidNow() {
    this.router.navigate(['..', 'questions', this.person.personId],
      {
        relativeTo: this.route,
        replaceUrl: true
      })
  }

  private toggleActionButtons(enabled: boolean) {
    ButtonUtil.toggleContinueButton(enabled, '#confirm-button');
    ButtonUtil.toggleContinueButton(enabled, '#skip-button');
  }
}
