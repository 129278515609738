import {Converter} from '../../../common/utils/converters/converter';
import {ApplicationAnswers} from '../../../models/data.model';
import {Question} from '../../../common/utils/questions/question';
import {
  FinancialAssetsQuestionService,
  getAmountQuestion,
  getInvestmentValueQuestion,
  getOtherAssetsQuestions,
  getTrustFundOpgtQuestions,
  getTrustFundOtherQuestion,
  getVehicleQuestions
} from '../financial-assets-question.service';
import {
  addChildOptions,
  addDynamicPanels,
  addSpouseOption,
  hasApplicantData,
  isSingleApplicant
} from '../../../common/utils/dynamic-panel/dynamic-panel.util';
import {validateGreaterThanProvidedValue} from '../../../common/utils/validators/custom-validators';
import {Injectable} from '@angular/core';
import {otherAssetTypeChoices} from "../financial-assets.util";

export class AppDataToFACash implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    if (appData.jsonData.ownItemsOfValueCashCheckbox){
      question.options[0].value = appData.jsonData.ownItemsOfValueCashCheckbox[0] === 'yes' ? true: false;
    }
  }

}

export class AppDataToFACashWhoOwnsThisAsset implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {

    question.options[0].labelParam = {name:  appData.jsonData.firstName};
    question.options[0].alternateValue = 'Applicant: '.concat(appData.jsonData.firstName);
    question.options[0].value = hasApplicantData(question.options[0].alternateValue,appData.jsonData.ownItemsOfValueDetailsListCash) ? true: false;

    // FACashWhoOwnsThisAsset checkbox is not visible for single applicant without children
    if(isSingleApplicant(appData)){
      question.options[0].value = true;
      question.visible = false;
      delete question.visibleOnConditions;
    }

    if (appData.jsonData.spouseFirstName) {
       addSpouseOption(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxCash);
    }

    if (appData.jsonData.childList) {
      addChildOptions(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxCash);
    }

  }

}

// add FACash dynamic panels for applicant, spouse and children
@Injectable()
export class AppDataToFACashDynamicPanelsConverter implements Converter<ApplicationAnswers, Question<string>[]> {

  convert(appData: ApplicationAnswers, questions: Question<string>[]): void {

    const index = questions.findIndex(q => q.key === 'FACashWhoOwnsThisAsset');

    addDynamicPanels('FACash','FACashWhoOwnsThisAsset', index, questions, this.populateAmountQuestions(appData,questions),
      [{
        questionKey: 'FACash',
        questionControlType: 'checkbox',
        value: [true],
        index: 0
      }],{showAddButton: false, showLineSeparator: true});

  }

  populateAmountQuestions(appData: ApplicationAnswers, questions: Question<string>[]): Map<string, Map<number,Question<string>[]>> {

    const map: Map<string, Map<number,Question<string>[]>>  = new Map();
    const question = questions.find(q => q.key === 'FACashWhoOwnsThisAsset');

    question.options.forEach(o => {
       const amountQuestion = [...getAmountQuestion()];
       const applicantValue = appData.jsonData.ownItemsOfValueDetailsListCash?.find(a => a.applicant === o.alternateValue);
       if ( applicantValue ){
         amountQuestion[0].value = applicantValue.ownItemsOfValueCashAmount;
       }
       const panelMap: Map<number, Question<string>[]> = new Map();
       panelMap.set(0,amountQuestion);
       map.set(o.alternateValue,panelMap);
    });
    return map;
  }

}

export class AppDataToFABankAccounts implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    if (appData.jsonData.ownItemsOfValueBankAccountsCheckbox){
      question.options[0].value = appData.jsonData.ownItemsOfValueBankAccountsCheckbox[0] === 'yes' ? true: false;
    }
  }

}

export class AppDataToFABankAccountsWhoOwnsThisAsset implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {

    question.options[0].labelParam = {name:  appData.jsonData.firstName};
    question.options[0].alternateValue= 'Applicant: '.concat(appData.jsonData.firstName);
    question.options[0].value = hasApplicantData(question.options[0].alternateValue,appData.jsonData.ownItemsOfValueDetailsListBankAccounts) ? true: false;

    // FABankAccountsWhoOwnsThisAsset checkbox is not for single applicant without children
    if(isSingleApplicant(appData)){
      question.options[0].value = true;
      question.visible = false;
      delete question.visibleOnConditions;
    }

    if (appData.jsonData.spouseFirstName) {
      addSpouseOption(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxBankAccounts);
    }

    if (appData.jsonData.childList) {
      addChildOptions(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxBankAccounts);
    }
  }

}


export class AppDataToFAVehicles implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    if (appData.jsonData.ownItemsOfValueVehiclesCheckbox){
      question.options[0].value = appData.jsonData.ownItemsOfValueVehiclesCheckbox[0] === 'yes' ? true: false;
    }
  }

}

export class AppDataToFAVehiclesWhoOwnsThisAsset implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {

    question.options[0].labelParam = {name:  appData.jsonData.firstName};
    question.options[0].alternateValue= 'Applicant: '.concat(appData.jsonData.firstName);
    question.options[0].value = hasApplicantData(question.options[0].alternateValue,appData.jsonData.ownItemsOfValueDetailsListVehicle)? true: false;

    // FAVehiclesWhoOwnsThisAsset checkbox is not for single applicant without children
    if(isSingleApplicant(appData)){
      question.options[0].value = true;
      question.visible = false;
      delete question.visibleOnConditions;
    }

    if (appData.jsonData.spouseFirstName) {
      addSpouseOption(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxVehicle);
    }

    if (appData.jsonData.childList) {
      addChildOptions(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxVehicle);
    }
  }

}


export class AppDataToFAOtherAssets implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    if (appData.jsonData.ownItemsOfValueOtherAssetsCheckbox){
      question.options[0].value = appData.jsonData.ownItemsOfValueOtherAssetsCheckbox[0] === 'yes' ? true: false;
    }
  }

}

export class AppDataToFAOtherAssetsWhoOwnsThisAsset implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {

    question.options[0].labelParam = {name:  appData.jsonData.firstName};
    question.options[0].alternateValue= 'Applicant: '.concat(appData.jsonData.firstName);
    question.options[0].value = hasApplicantData(question.options[0].alternateValue,appData.jsonData.ownItemsOfValueDetailsListOtherAssets) ? true: false;

    // FAOtherAssetsWhoOwnsThisAsset checkbox is not for single applicant without children
    if(isSingleApplicant(appData)){
      question.options[0].value = true;
      question.visible = false;
      delete question.visibleOnConditions;
    }

    if (appData.jsonData.spouseFirstName) {
      addSpouseOption(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxOtherAssets);
    }

    if (appData.jsonData.childList) {
      addChildOptions(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxOtherAssets);
    }
  }

}

// add FAOtherAssets dynamic panels for applicant, spouse and children
@Injectable()
export class AppDataToFAOtherAssetsDynamicPanelsConverter implements Converter<ApplicationAnswers, Question<string>[]> {

  convert(appData: ApplicationAnswers, questions: Question<string>[]): void {

    const index = questions.findIndex(q => q.key === 'FAOtherAssetsWhoOwnsThisAsset');

    addDynamicPanels('FAOtherAssets','FAOtherAssetsWhoOwnsThisAsset', index, questions,
      this.populateOtherAssetsQuestions(appData,questions),
      [{
        questionKey: 'FAOtherAssets',
        questionControlType: 'checkbox',
        value: [true],
        index: 0
      }],{showAddButton: true, addButton: 'financial-assets.add.other.asset',
        removeButton: 'financial-assets.remove.other.asset', updateValidityOnRemoval: true, showLineSeparator: true});

  }

  populateOtherAssetsQuestions(appData: ApplicationAnswers, questions: Question<string>[]): Map<string, Map<number,Question<string>[]>> {
    const map: Map<string, Map<number,Question<string>[]>>  = new Map();
    const question = questions.find(q => q.key === 'FAOtherAssetsWhoOwnsThisAsset');

    question.options.forEach(o => {
      const applicant = appData.jsonData.ownItemsOfValueDetailsListOtherAssets?.find(a => a.applicant === o.alternateValue);
      const panelMap: Map<number, Question<string>[]> = new Map();

      for(let i=0; i< applicant?.otherAssetList.length; i++) {
        const otherAssetsQuestion = [...getOtherAssetsQuestions()];

        if ( applicant ){
          otherAssetsQuestion[0].value = applicant.otherAssetList[i].assetType;
          otherAssetsQuestion[1].value = applicant.otherAssetList[i].assetType === 'My asset type is not listed here'
            ? applicant.otherAssetList[i].description : '';
          otherAssetsQuestion[2].value = applicant.otherAssetList[i].value;
          otherAssetsQuestion[3].value = applicant.otherAssetList[i].moneyInheritanceValue;
        }

        panelMap.set(i,otherAssetsQuestion);
      };

      if (panelMap.size === 0) {
        panelMap.set(0, [...getOtherAssetsQuestions()]);
      }
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }

}


// add FABankAccounts dynamic panels for applicant, spouse and children
@Injectable()
export class AppDataToFABankAccountsDynamicPanelsConverter implements Converter<ApplicationAnswers, Question<string>[]> {

  constructor(private questionService: FinancialAssetsQuestionService) {
  }

  convert(appData: ApplicationAnswers, questions: Question<string>[]): void {

    const index = questions.findIndex(q => q.key === 'FABankAccountsWhoOwnsThisAsset');

    addDynamicPanels('FABankAccounts','FABankAccountsWhoOwnsThisAsset', index, questions, this.populateAccountQuestions(appData,questions),
      [{
        questionKey: 'FABankAccounts',
        questionControlType: 'checkbox',
        value: [true],
        index: 0
      }],{showAddButton: true, addButton: 'financial-assets.add.bank.account', removeButton: 'financial-assets.remove.bank.account', showLineSeparator: true});

  }

  populateAccountQuestions(appData: ApplicationAnswers, questions: Question<string>[]): Map<string, Map<number,Question<string>[]>> {

    const map: Map<string, Map<number,Question<string>[]>>  = new Map();
    const question = questions.find(q => q.key === 'FABankAccountsWhoOwnsThisAsset');

    question.options.forEach(o => {
      const applicantAccount = appData.jsonData.ownItemsOfValueDetailsListBankAccounts?.find(a => a.applicant === o.alternateValue);
      const panelMap: Map<number, Question<string>[]> = new Map();

      for (let i=0; i< applicantAccount?.bankAccountList.length; i++) {
        let accountQuestions;
        if (o.alternateValue.startsWith('Applicant')) {
          accountQuestions = [...this.questionService.getBankAccountsQuestions([o.labelParam?.name],'bank-details.accountType.applicant.context')];
        } else if (o.alternateValue.startsWith('Spouse')) {
          accountQuestions = [...this.questionService.getBankAccountsQuestions([o.labelParam?.name],'bank-details.accountType.spouse.context')];
        } else if (o.alternateValue.startsWith('Child')) {
          accountQuestions = [...this.questionService.getBankAccountsQuestions([o.labelParam.index, o.labelParam?.name],'bank-details.accountType.child.context')];
        }
        if ( applicantAccount ){

          accountQuestions.find(q => q.key === 'bankName').value = applicantAccount.bankAccountList[i].bankName;
          accountQuestions.find(q => q.key === 'bankAccountValue').value = applicantAccount.bankAccountList[i].value;
          accountQuestions.find(q => q.key === 'applicantType').value = this.getApplicantType(o.alternateValue);
          accountQuestions.find(q => q.key === 'useAccountForDBD').options[0].value = this.getUseAccountForDBD(applicantAccount.bankAccountList[i].useAccountForDBD);
          accountQuestions.find(q => q.key === 'accountType').value = applicantAccount.bankAccountList[i].accountType;
          accountQuestions.find(q => q.key === 'branchNumber').value = applicantAccount.bankAccountList[i].branchNumber;
          accountQuestions.find(q => q.key === 'institutionNumber').value = applicantAccount.bankAccountList[i].institutionNumber;
          accountQuestions.find(q => q.key === 'accountNumber').value = applicantAccount.bankAccountList[i].accountNumber;

        }

        panelMap.set(i,accountQuestions);

      };

      if (panelMap.size === 0) {
        let accountQuestions;
        if (o.alternateValue.startsWith('Applicant')) {
          accountQuestions = [...this.questionService.getBankAccountsQuestions([o.labelParam?.name],'bank-details.accountType.applicant.context')];
        } else if (o.alternateValue.startsWith('Spouse')) {
          accountQuestions = [...this.questionService.getBankAccountsQuestions([o.labelParam?.name],'bank-details.accountType.spouse.context')];
        } else if (o.alternateValue.startsWith('Child')) {
          accountQuestions = [...this.questionService.getBankAccountsQuestions([o.labelParam.index, o.labelParam?.name],'bank-details.accountType.child.context')];
        }
        accountQuestions.find(q => q.key === 'applicantType').value = this.getApplicantType(o.alternateValue);
        panelMap.set(0, accountQuestions);
      }

      map.set(o.alternateValue, panelMap);

    });

    return map;
  }

  getUseAccountForDBD(useAccountForDBD: string[]):boolean {
    return useAccountForDBD && useAccountForDBD[0] === 'yes' ? true : false;
  }

  getApplicantType(alternateValue:string): string {

    let applicantType;

    if (alternateValue.startsWith('Applicant')) {
      applicantType = 'primary';
    } else if (alternateValue.startsWith('Spouse')) {
      applicantType = 'spouse';
    } else if (alternateValue.startsWith('Child')) {
      applicantType = 'child';
    }

    return applicantType;
  }

}

// add FAVehicles dynamic panels for applicant, spouse and children
@Injectable()
export class AppDataToFAVehiclesDynamicPanelsConverter implements Converter<ApplicationAnswers, Question<string>[]> {

  convert(appData: ApplicationAnswers, questions: Question<string>[]): void {

    const index = questions.findIndex(q => q.key === 'FAVehiclesWhoOwnsThisAsset');

    addDynamicPanels('FAVehicles','FAVehiclesWhoOwnsThisAsset', index, questions, this.populateVehicleQuestions(appData,questions),
      [{
        questionKey: 'FAVehicles',
        questionControlType: 'checkbox',
        value: [true],
        index: 0
      }],{showAddButton: true, addButton: 'financial-assets.add.vehicle', removeButton: 'financial-assets.remove.vehicle', showLineSeparator: true});

  }

  populateVehicleQuestions(appData: ApplicationAnswers, questions: Question<string>[]): Map<string, Map<number,Question<string>[]>> {

    const map: Map<string, Map<number,Question<string>[]>>  = new Map();
    const question = questions.find(q => q.key === 'FAVehiclesWhoOwnsThisAsset');

    question.options.forEach(o => {
      const applicant = appData.jsonData.ownItemsOfValueDetailsListVehicle?.find(a => a.applicant === o.alternateValue);
      const panelMap: Map<number, Question<string>[]> = new Map();

      for(let i=0; i< applicant?.vehicleList.length; i++) {
        let vehicleQuestion;
        if (o.alternateValue.startsWith('Applicant')) {
          vehicleQuestion = [...getVehicleQuestions([o.labelParam?.name],'financial-assets.vehicle.for.work.applicant.context')];
        } else if (o.alternateValue.startsWith('Spouse')) {
          vehicleQuestion = [...getVehicleQuestions([o.labelParam?.name],'financial-assets.vehicle.for.work.spouse.context')];
        } else if (o.alternateValue.startsWith('Child')) {
          vehicleQuestion = [...getVehicleQuestions([o.labelParam.index, o.labelParam?.name],'financial-assets.vehicle.for.work.child.context')];
        }

        if ( applicant ){
          vehicleQuestion[0].value = applicant.vehicleList[i].year;
          vehicleQuestion[1].value = applicant.vehicleList[i].make;
          vehicleQuestion[2].value = applicant.vehicleList[i].model;
          vehicleQuestion[3].value = applicant.vehicleList[i].value;
          vehicleQuestion[4].value = applicant.vehicleList[i].usedForWork;
        }
        panelMap.set(i,vehicleQuestion);
      };

      if (panelMap.size === 0) {
        let vehicleQuestion;
        if (o.alternateValue.startsWith('Applicant')) {
          vehicleQuestion = [...getVehicleQuestions([o.labelParam?.name],'financial-assets.vehicle.for.work.applicant.context')];
        } else if (o.alternateValue.startsWith('Spouse')) {
          vehicleQuestion = [...getVehicleQuestions([o.labelParam?.name],'financial-assets.vehicle.for.work.spouse.context')];
        } else if (o.alternateValue.startsWith('Child')) {
          vehicleQuestion = [...getVehicleQuestions([o.labelParam.index, o.labelParam?.name],'financial-assets.vehicle.for.work.child.context')];
        }
        panelMap.set(0, vehicleQuestion);
      }
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }

}

export class AppDataToFAInvestments implements Converter<ApplicationAnswers, Question<string>> {

  static isRRSPRRIFPayment(incomeDesc: string): boolean {
    return incomeDesc === 'RRSP or RRIF payments'
  }

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    const isRRSPRRIFSelected = !!appData.jsonData.anyOtherIncomeList?.find(list =>
      !!list.otherIncomeOtherPaymentList.find(
        otherPayment => AppDataToFAInvestments.isRRSPRRIFPayment(otherPayment.otherIncomeDescription)
      )
    );

    question.options[0].value = (appData.jsonData.ownItemsOfValueInvestmentsCheckbox &&
      appData.jsonData.ownItemsOfValueInvestmentsCheckbox[0] === 'yes') || isRRSPRRIFSelected;
    if (isRRSPRRIFSelected) {
      question.disabled = true;
    }
  }
}

// add FAInvestments dynamic panels for applicant, spouse and children
@Injectable()
export class AppDataToFAInvestmentsDynamicPanelsConverter implements Converter<ApplicationAnswers, Question<string>[]> {

  convert(appData: ApplicationAnswers, questions: Question<string>[]): void {

    const index = questions.findIndex(q => q.key === 'FAInvestmentsWhoOwnsThisAsset');

    addDynamicPanels('FAInvestments','FAInvestmentsWhoOwnsThisAsset', index, questions, this.populateAmountQuestions(appData, questions),
      [{
        questionKey: 'FAInvestments',
        questionControlType: 'checkbox',
        value: [true],
        index: 0
      }],{showAddButton: false, showLineSeparator: true});

  }

  populateAmountQuestions(appData: ApplicationAnswers, questions: Question<string>[]): Map<string,Map<number, Question<string>[]>> {

    const map: Map<string, Map<number,Question<string>[]>>  = new Map();
    const question = questions.find(q => q.key === 'FAInvestmentsWhoOwnsThisAsset');

    question.options.forEach(o => {
      const amountQuestion = [...getInvestmentValueQuestion()];
      const applicantValue = appData.jsonData.ownItemsOfValueDetailsListInvestment?.find(a => a.applicant === o.alternateValue);
      if ( applicantValue ){
        amountQuestion[0].value = applicantValue.investmentsTotalValue;
      }
      const rrspMonthlyAmount = appData.jsonData.anyOtherIncomeList?.find(otherIncome =>
        otherIncome.applicant === o.alternateValue)?.otherIncomeOtherPaymentList?.find(
        otherIncomePayment => AppDataToFAInvestments.isRRSPRRIFPayment(otherIncomePayment.otherIncomeDescription)
      )?.otherIncomeSourceMonthlyAmount

      if (rrspMonthlyAmount) {
        amountQuestion[0].validators = [...amountQuestion[0].validators,
          {
            type: 'crossField',
            validatorFn: validateGreaterThanProvidedValue('investmentTotalValue', rrspMonthlyAmount),
            errorKey: 'financial-assets.error.investmentLessThanMonthlyAmount'
          }
        ]
      }

      const panelMap: Map<number, Question<string>[]> = new Map();
      panelMap.set(0,amountQuestion);
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }

}


export class AppDataToFAInvestmentsWhoOwnsThisAsset implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    question.options[0].labelParam = {name:  appData.jsonData.firstName};
    question.options[0].alternateValue= 'Applicant: '.concat(appData.jsonData.firstName);
    question.options[0].value = (!!hasApplicantData(question.options[0].alternateValue, appData.jsonData.ownItemsOfValueDetailsListInvestment)
      || this.isRRSPRRIFSelected(appData, 'Applicant: '.concat(appData.jsonData.firstName)));

    // FAInvestmentsWhoOwnsThisAsset checkbox is not for single applicant without children
    if(isSingleApplicant(appData)){
      question.options[0].value = true;
      question.visible = false;
      delete question.visibleOnConditions;
    } else {
      question.options[0].disabled = this.isRRSPRRIFSelected(appData, 'Applicant: '.concat(appData.jsonData.firstName));
    }

    if (appData.jsonData.spouseFirstName) {
      addSpouseOption(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxInvestment);
      const spouseApplicantLabel = 'Spouse: '.concat(appData.jsonData.spouseFirstName);
      if (this.isRRSPRRIFSelected(appData, spouseApplicantLabel)) {
        const spouseOption = question.options.find(e => e.alternateValue === spouseApplicantLabel);
        spouseOption.value = true;
        spouseOption.disabled = true;
      }
    }

    if (appData.jsonData.childList) {
      addChildOptions(appData, question, appData.jsonData.ownItemsOfValueApplicantCheckboxInvestment);

      appData.jsonData.childList.forEach((c, i) => {
        const childApplicantLabel = 'Child '.concat(String(i + 1).concat(': ').concat(c.childFirstName));
        if (this.isRRSPRRIFSelected(appData, childApplicantLabel)) {
          const childOption = question.options.find(e => e.alternateValue === childApplicantLabel);
          childOption.value = true;
          childOption.disabled = true;
        }
      });
    }
  }

  private isRRSPRRIFSelected(appData: ApplicationAnswers, applicantLabel: string) {
    return !!appData.jsonData.anyOtherIncomeList?.find(
      otherIncome => otherIncome.applicant === applicantLabel && !!otherIncome.otherIncomeOtherPaymentList.find(
        otherIncomePayment => AppDataToFAInvestments.isRRSPRRIFPayment(otherIncomePayment.otherIncomeDescription))
    );
  }
}


export class AppDataToFATrustFund implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {

    if (appData.jsonData.ownItemsOfValueOpgtCheckbox && appData.jsonData.ownItemsOfValueOpgtCheckbox[0] === 'yes'){
      const faTrustFundOPGT = question.panels[0].questions.find(q => q.key === 'FATrustFundOPGT');
      faTrustFundOPGT.options[0].value = true;
    }

    const whoOwnsThisAsset = question.panels[0].questions.find(q => q.key === 'FATrustFundWhoOwnsThisAsset');
    whoOwnsThisAsset.options[0].labelParam = { name: appData.jsonData.firstName};
    whoOwnsThisAsset.options[0].alternateValue= 'Applicant: '.concat(appData.jsonData.firstName);
    whoOwnsThisAsset.options[0].value = hasApplicantData(whoOwnsThisAsset.options[0].alternateValue,appData.jsonData.ownItemsOfValueDetailsListTrustFundOpgt)? true: false;

    // FATrustFundWhoOwnsThisAsset checkbox is not for single applicant without children
    if(isSingleApplicant(appData)){
      whoOwnsThisAsset.options[0].value = true;
      whoOwnsThisAsset.visible = false;
      delete whoOwnsThisAsset.visibleOnConditions;
    }

    if (appData.jsonData.spouseFirstName) {
      addSpouseOption(appData, whoOwnsThisAsset, appData?.jsonData.ownItemsOfValueApplicantCheckboxTrustFundOpgt);
    }

    if (appData.jsonData.childList) {
      addChildOptions(appData, whoOwnsThisAsset, appData?.jsonData.ownItemsOfValueApplicantCheckboxTrustFundOpgt);
    }

    if (appData.jsonData.ownItemsOfValueOtherTrustCheckbox && appData.jsonData.ownItemsOfValueOtherTrustCheckbox[0] === 'yes'){
      const faTrustFundOther = question.panels[0].questions.find(q => q.key === 'FATrustFundOther');
      faTrustFundOther.options[0].value = true;
    }

    const otherWhoOwnsThisAsset = question.panels[0].questions.find(q => q.key === 'FATrustFundOtherWhoOwnsThisAsset');
    otherWhoOwnsThisAsset.options[0].labelParam = { name: appData.jsonData.firstName};
    otherWhoOwnsThisAsset.options[0].alternateValue= 'Applicant: '.concat(appData.jsonData.firstName);
    otherWhoOwnsThisAsset.options[0].value = hasApplicantData(otherWhoOwnsThisAsset.options[0].alternateValue,appData.jsonData.ownItemsOfValueDetailsListTrustFundOther) ? true: false;

    // FATrustFundOtherWhoOwnsThisAsset checkbox is not for single applicant without children
    if(isSingleApplicant(appData)){
      otherWhoOwnsThisAsset.options[0].value = true;
      otherWhoOwnsThisAsset.visible = false;
      delete otherWhoOwnsThisAsset.visibleOnConditions;
    }

    if (appData.jsonData.spouseFirstName) {
      addSpouseOption(appData, otherWhoOwnsThisAsset, appData?.jsonData.ownItemsOfValueApplicantCheckboxTrustFundOther);
    }

    if (appData.jsonData.childList) {
      addChildOptions(appData, otherWhoOwnsThisAsset, appData?.jsonData.ownItemsOfValueApplicantCheckboxTrustFundOther);
    }

    // Trust fund OPGT add dynamic panels for applicant, spouse and children
    let index =  question.panels[0].questions.findIndex(q => q.key === 'FATrustFundWhoOwnsThisAsset');
    addDynamicPanels('FATrustFundOPGT','FATrustFundWhoOwnsThisAsset', index, question.panels[0].questions,
      this.populateOpgtAmountQuestions(appData, question.panels[0].questions),
      [{
        questionKey: 'FATrustFundOPGT',
        questionControlType: 'checkbox',
        value: [true],
        index: 0
      }],{showAddButton: false, showLineSeparator: true})

    // Trust fund Other add dynamic panels for applicant, spouse and children
    index =  question.panels[0].questions.findIndex(q => q.key === 'FATrustFundOtherWhoOwnsThisAsset');
    addDynamicPanels('FATrustFundOther','FATrustFundOtherWhoOwnsThisAsset', index, question.panels[0].questions,
      this.populateOtherAmountQuestions(appData,question.panels[0].questions),
      [{
        questionKey: 'FATrustFundOther',
        questionControlType: 'checkbox',
        value: [true],
        index: 0
      }],{showAddButton: false, showLineSeparator: true})

  }

  populateOpgtAmountQuestions(appData: ApplicationAnswers, questions: Question<string>[]): Map<string, Map<number,Question<string>[]>> {

    const map: Map<string, Map<number,Question<string>[]>>  = new Map();
    const question = questions.find(q => q.key === 'FATrustFundWhoOwnsThisAsset');

    question.options.forEach(o => {
      const amountQuestions = [...getTrustFundOpgtQuestions()];
      const applicantValue = appData.jsonData.ownItemsOfValueDetailsListTrustFundOpgt?.find(a => a.applicant === o.alternateValue);
      if ( applicantValue ){
        amountQuestions[0].value = applicantValue.opgtTotalValue;
        amountQuestions[1].value = applicantValue.opgtAccessibleValue;
      }
      const panelMap: Map<number, Question<string>[]> = new Map();
      panelMap.set(0,amountQuestions);
      map.set(o.alternateValue,panelMap);
    });
    return map;
  }

  populateOtherAmountQuestions(appData: ApplicationAnswers, questions: Question<string>[]): Map<string,Map<number,Question<string>[]>> {

    const map: Map<string, Map<number,Question<string>[]>>  = new Map();
    const question = questions.find(q => q.key === 'FATrustFundOtherWhoOwnsThisAsset');

    question.options.forEach(o => {
      const amountQuestion = [...getTrustFundOtherQuestion()];
      const applicantValue = appData.jsonData.ownItemsOfValueDetailsListTrustFundOther?.find(a => a.applicant === o.alternateValue);
      if ( applicantValue ){
        amountQuestion[0].value = applicantValue.privateTrustTotalValue;
        amountQuestion[1].value = applicantValue.privateTrustAccessibleValue;
      }
      const panelMap: Map<number, Question<string>[]> = new Map();
      panelMap.set(0,amountQuestion);
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }

}


export class AppDataToFAOwnedHome implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    if (appData.jsonData.currentHousingSituation === 'I own my home'){
      question.visible = true;
    } else {
      question.options.forEach(v => {v.value = false});
    }
  }

}

export class AppDataToFANoneOfAbove implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    if (appData.jsonData.noOtherFinancialAssets === 'yes') {
      question.options[0].value = true;
    }
  }

}

export class AppDataToFASoldOrGiveAwayAssets implements Converter<ApplicationAnswers, Question<string>> {

  convert(appData: ApplicationAnswers, question: Question<string>): void {
    if (appData.jsonData.soldOrGivenItemsOfValue) {
      question.value = appData.jsonData.soldOrGivenItemsOfValue;
    }
  }

}
