import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ESignatureService} from '../../services/esignature.service';
import {ExternalRouter} from '../../external.router'
import {IntakeService} from '../../services/intake.service';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {popup} from '../../dialog-constants';
import {CaseWorkerAuthorizeService} from '../../services/caseWorkerAuthorizeService';
import {ESignatureResponse} from '../../models/esignature/esignature-response.model';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {BaseComponent} from '../BaseComponent';
import {AppStateService} from '../../services/app-state.service';
import {ConfigService} from '../../services/config.service';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageInfo} from '../../models/page-map';

@Component({
  selector: 'sd-esignature-landing',
  templateUrl: './esignature-landing.component.html',
  styleUrls: ['./esignature-landing.component.scss']
})
export class EsignatureLandingComponent extends BaseComponent implements OnInit, OnDestroy {
  bilApplicationId: string
  isLoading = false
  submittedByCaseWorker = false;

  data: any

  constructor(
    public route: ActivatedRoute,
    private esignatureService: ESignatureService,
    public externalRouter: ExternalRouter,
    public intakeService: IntakeService,
    public dialog: MatDialog,
    private router: Router,
    private authorizeService: CaseWorkerAuthorizeService,
    public translator: TranslateService,
    public ngZone: NgZone, public idle: Idle,
    private appStateService: AppStateService,
    protected configService: ConfigService,
    protected authService: AuthService,
    protected pageService: PageService,
    protected loadingSpinnerService: LoadingSpinnerService) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.signatureLanding;
  }

  ngOnInit(): void {
    this.intakeService.getApplication(this.pageId).subscribe(data => {
      this.data = data
    })
    this.submittedByCaseWorker = this.authorizeService.isAuthorized();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onSubmit() {
    const redirectDelay = this.configService.getClientConfig().esignRedirectDelay;
    this.isLoading = true;
    this.esignatureService.startESignature().subscribe((esignatureResponse) => {
      // submitted by case worker, emails will be sent to all applicants to sign
      if(this.isSubmittedToCaseWoker_Or_IsLocalOfficeAssistance()) {
        this.onSendButtonClick(esignatureResponse)
      } else { // for public users, primary applicant needs to sign
        // Set delay as workaround for Direct API ESign panel issue
        setTimeout(() => {
          this.externalRouter.navigateWithSession(esignatureResponse.signingUrl);
        }, redirectDelay);
      }
    }, () => {
      this.handleESignServiceNotAvailable();
    });
  }

  onSendButtonClick(esignResponse: ESignatureResponse): void {
    this.isLoading = false
    const dialogTitle = popup.signatureRequestEmailed.title;
    const dialogBodyLabel = this.hasFamily() ? popup.signatureRequestEmailed.familyApplicants
      : popup.signatureRequestEmailed.singleApplicant;
    const dialogButton = popup.signatureRequestEmailed.button;
    this.openDialog({title: dialogTitle, body: dialogBodyLabel, button: dialogButton}, true, () => {
      this.isLoading = true;
      this.router.navigate(['/', PageInfo.confirmation],
        {
          queryParams: {
            esignredirect: false
          }
        });
    });
  }

  handleESignServiceNotAvailable(): void {
    this.isLoading = false;
    this.openDialog(popup.submitWithoutESign, true,
      () => {
        this.isLoading = true;
        // submit application without ESignature
        this.intakeService.submitApplication().subscribe((submissionResponse) => {
          this.router.navigate(['/', PageInfo.confirmation],
            {
              queryParams: {
                esignredirect: false
              }
            });
        }, (error => {
          this.appStateService.emitState({hasHttpError: true});
          this.isLoading = false;
        }))
      });
  }

  hasFamily(): boolean {
    return !this.data ||
      (this.data.jsonData.maritalStatus === 'Married' || this.data.jsonData.maritalStatus === 'Common Law') ||
      this.hasAdultChild()
  }

  hasAdultChild(): boolean {
    return this.data.jsonData.childList && this.data.jsonData.childList.length > 0 &&
      this.data.jsonData.childList.filter(child => SadaCustomValidator.validateOverAge(child.childDateOfBirth, 18)).length > 0

  }
  isSubmittedToCaseWoker_Or_IsLocalOfficeAssistance():boolean
  {
    return this.submittedByCaseWorker || this.intakeService.getIsRelatedToLocalOfficeAssistance();
  }
}
