import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PageBaseComponent} from '../PageBaseComponent';
import {IntakeService} from '../../services/intake.service';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';
import {ExternalRouter} from '../../external.router';
import {ConfigService} from '../../services/config.service';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageInfo} from '../../models/page-map';
import {SadaErrorCodes} from '../../utils/sada-error-codes';

@Component({
  selector: 'sd-global-error',
  templateUrl: './global-error.component.html',
  styleUrls: ['./global-error.component.scss']
})
export class GlobalErrorComponent extends PageBaseComponent implements OnInit {

  subHeaderKey = null;
  errorBannerMsgKey = 'genErrorText';
  startNewButtonVisible = true;

  constructor(private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.globalError;
  }

  ngOnInit(): void {
    this.subscriptions$.push(this.route.queryParams?.subscribe(params => {
      const errorCode = params?.errorCode;

      if (errorCode === SadaErrorCodes.SADAERR104) {
        this.subHeaderKey = 'pse.redirect.error'; // PSE redirect error
        this.errorBannerMsgKey = 'pseRedirectErrorText';
      }
      else if (errorCode === SadaErrorCodes.EC0005 || errorCode === SadaErrorCodes.AUTHEC04) {
        this.subHeaderKey = 'failed.submission'; // App failed to submit to BIL
      }
      else if (errorCode === SadaErrorCodes.AUTHEC01 || errorCode === SadaErrorCodes.AUTHEC05) {
        this.startNewButtonVisible = false;
        this.subHeaderKey = 'error.encountered';
        this.errorBannerMsgKey = 'tryAgainLater';
      }
      else if (errorCode === SadaErrorCodes.AUTHEC03) {
        this.startNewButtonVisible = false;
        this.subHeaderKey = 'error.encountered';
        this.errorBannerMsgKey = SadaErrorCodes.AUTHEC03;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }

  onSubmit() {
    this.router.navigate(['/'])
      .then(()=> {
        window.location.reload();
      });
  }

  postInitializeForm() {
  }

  preSaveApplication() {
  }
}
