import {Question} from '../../../common/utils/questions/question';
import {
  amountMaxLength,
  positiveCurrencyPattern,
  alphaNonNumericSplCharPattern,
  validateGreaterThan,
  validateDescriptionExists
} from '../../../common/utils/validators/custom-validators';
import {otherIncomeQuestionsMappingConfig, standardHHIQuestionsMappingConfig} from './form-data-mapping-config';
import {HHIDataConversionConfig} from '../model/hhi-data-conversion-config';
import {householdOtherIncomeTypeChoices, householdOtherIncomeTypeDropdownOptions} from '../household-income.util';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';

export class HouseholdIncomeQuestionHelper {
  public static getPaymentQuestions(qKey: string, qLabel: string): Question<string>[] {
    return [
      {
        controlType: 'checkbox',
        key: qKey,
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        options: [{
          value: false,
          label: qLabel
        }],
        disableOthersOnCondition: [
          {
            questionKey: qKey.concat('WhoGetsThePayment'),
            questionControlType: 'checkbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: qKey.concat('WhoGetsThePayment'),
        required: true,
        label: 'household-income.who-gets-this-payment',
        options: [
          {
            value: false,
            label: 'financial-assets.applicant'
          }
        ],
        indentedRow: true,
        visibleOnConditions: [{
          questionKey: qKey,
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
        }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.select.one.person'
          }
        ],
        disableOthersOnCondition: []
      }
    ];
  }

  public static getOtherIncomeQuestions(qKey: string): Question<string>[] {
    return [
      {
        controlType: 'checkbox',
        key: qKey,
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        options: [{
          value: false,
          label: 'household-income.other-income'
        }],
        disableOthersOnCondition: [
          {
            questionKey: qKey.concat('WhoGetsThePayment'),
            questionControlType: 'checkbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: qKey.concat('WhoGetsThePayment'),
        required: true,
        label: 'household-income.who-gets-this-payment',
        options: [
          {
            value: false,
            label: 'financial-assets.applicant'
          }
        ],
        indentedRow: true,
        visibleOnConditions: [{
          questionKey: qKey,
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
        }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.select.one.person'
          }
        ],
        disableOthersOnCondition: []
      }
    ];
  }

  public static getIncomeDetailQuestions(labelParams:string[],context:string,includeIncomeDescription?: boolean): Question<string>[] {
    const questions: Question<string>[] = [];

    if (includeIncomeDescription) {
      questions.push(...HouseholdIncomeQuestionHelper.getIncomeDescriptionQuestion())
    }

    questions.push(
      {
        controlType: 'radio',
        required: true,
        context,
        labelParams,
        key: 'tryingOrReceivingThisIncome',
        options: [{
          value: 'Trying to get this income',
          label: 'household-income.choices.radio.tryingToGetThisIncome'
        },
          {
            value: 'Receiving this income',
            label: 'household-income.choices.radio.receivingThisIncome'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'monthlyAmount',
        isDollarValue: true,
        required: true,
        needMarginTop: true,
        characterWidth: 10,
        label: includeIncomeDescription ? 'household-income.other.income.monthlyAmount' : 'household-income.monthlyAmount.value',
        visibleOnConditions: [
          {
            questionKey: 'tryingOrReceivingThisIncome',
            questionControlType: 'radio',
            index: 0,
            value: ['Receiving this income']
          },
          {
            questionKey: 'otherIncomeType',
            questionControlType: 'dropdown',
            index: 0,
            valueNotEqual: ['HST return']
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'min',
            minimumValue: 0,
            errorKey: 'error.invalid.min.amount'
          },
          {
            type: 'maxNumericLength',
            maxLengthValue: amountMaxLength,
            errorKey: 'error.invalid.max.amount'
          },
          {
            type: 'pattern',
            regex: positiveCurrencyPattern,
            errorKey: 'error.invalid.currency.amount'
          },
        ]
      },
      {
        controlType: 'textbox',
        key: 'annualAmount',
        isDollarValue: true,
        needMarginTop: true,
        label: 'household-income.other.income.annualAmount',
        characterWidth: 10,
        visibleOnConditions: [
          {
            questionKey: 'tryingOrReceivingThisIncome',
            questionControlType: 'radio',
            index: 0,
            value: ['Receiving this income']
          },
          {
            questionKey: 'otherIncomeType',
            questionControlType: 'dropdown',
            index: 0,
            value: ['HST return']
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'min',
            minimumValue: 0,
            errorKey: 'error.invalid.min.amount'
          },
          {
            type: 'maxNumericLength',
            maxLengthValue: amountMaxLength,
            errorKey: 'error.invalid.max.amount'
          },
          {
            type: 'pattern',
            regex: positiveCurrencyPattern,
            errorKey: 'error.invalid.currency.amount'
          }
        ]
      }
    )

    if (includeIncomeDescription) {
      questions.push(HouseholdIncomeQuestionHelper.getTotalPensionValueQuestion())
    }

    return questions;
  }

  public static getIncomeDescriptionQuestion(): Question<string>[] {
    return [{
      controlType: 'dropdown',
      key: 'otherIncomeType',
      required: true,
      needMarginBottom: true,
      label: 'household-income.other.income.type',
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'crossField',
          validatorFn: validateDescriptionExists('otherIncomeType', 'My income type is not listed here'),
          errorKey: 'household-income.error.duplicateIncomeType'
        }
      ],
      options: [...householdOtherIncomeTypeChoices()],
      disableOthersOnCondition: [
        {
          questionKey: 'totalValueOfPension',
          questionControlType: 'textbox',
          valueNotEqual: ['Pension - private'],
        }
      ]
    },{
      controlType: 'textbox',
      key: 'otherIncomeDesc',
      required: true,
      needMarginBottom: true,
      label: 'household-income.other.income.desc',
      capitalizeFirstLetter: true,
      characterWidth: 20,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'maxLength',
          maxLengthValue: 131,
          errorKey: 'error.invalid.description.max.length'
        },
        {
          type: 'pattern',
          regex: alphaNonNumericSplCharPattern,
          errorKey: 'error.invalid.description.asset'
        },
        {
          type: 'validatorFn',
          params: [householdOtherIncomeTypeDropdownOptions],
          validatorFn: SadaCustomValidator.validateSameValueAsDropdown,
          errorKey: 'household-income.error.sameAsDropdown'
        },
        {
          type: 'crossField',
          validatorFn: validateDescriptionExists('otherIncomeDesc'),
          errorKey: 'household-income.error.duplicateIncomeType'
        }
      ],
      visibleOnConditions: [
        {
          questionKey: 'otherIncomeType',
          questionControlType: 'dropdown',
          value: ['My income type is not listed here']
        }
      ],
    }]
  }

  private static getTotalPensionValueQuestion(): Question<string> {
    return {
      controlType: 'textbox',
      key: 'totalValueOfPension',
      isDollarValue: true,
      needMarginTop: true,
      label: 'household-income.totalValueOfPension.value',
      characterWidth: 10,
      visibleOnConditions: [
        {
          questionKey: 'tryingOrReceivingThisIncome',
          questionControlType: 'radio',
          index: 0,
          value: ['Receiving this income', 'Trying to get this income']
        },
        {
          questionKey: 'otherIncomeType',
          questionControlType: 'dropdown',
          index: 0,
          value: ['Pension - private']
        }
      ],
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 0,
          errorKey: 'error.invalid.min.amount'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
        {
          type: 'crossField',
          validatorFn: validateGreaterThan('monthlyAmount','totalValueOfPension'),
          errorKey: 'household-income.error.totalPensionLessThanMonthlyAmount'
        }
      ]
    }
  }

  /**
   * Return attribute name details to which form data should be converted to inorder to be sent to backend. This method
   * returns the details for the standard questions which has trying or receiving income option.
   */
  public static getStandardHHIQuestionsMappingConfig():Map<string, HHIDataConversionConfig[]> {
    return standardHHIQuestionsMappingConfig;
  }

  /**
   * Return attribute name details to which form data should be converted to inorder to be sent to backend. This method
   * returns the details for the other income question.
   */
  public static getOtherIncomeQuestionsMappingConfig(): HHIDataConversionConfig {
    return otherIncomeQuestionsMappingConfig;
  }
}
