import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InitializeService} from './initialize.service';
import {InitializeResponse} from '../models/initialize-response';
import {ConfigService} from './config.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InitializeServiceImpl implements InitializeService {
  initId: string| undefined;

  constructor(private http: HttpClient, private configService: ConfigService, private authService: AuthService) {
  }

  initialize(): Observable<InitializeResponse> {
    return this.http.post<InitializeResponse>(`/v1/initialize`, {}, {withCredentials: true})
      .pipe(
        map(response => {
          this.configService.setClientConfig(response.clientConfig);
          this.authService.setAuthorizedToSave(response.authorizedToSave);
          this.authService.setActiveSessionExists(response.activeSessionExists);
          this.authService.setUserName(response.userName)
          this.authService.setAccessToken(response.accessToken);
          this.initId = response.initId;
          return response;
        })
      );
  }

  getInitId(): string {
      return this.initId;
  }
}
