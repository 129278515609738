import {
  Component, Input, Output,
  OnInit, EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-mccss-submit-button',
  templateUrl: './mccss-submit-button.component.html',
  styleUrls: ['./mccss-submit-button.component.scss']
})
export class MccssSubmitButtonComponent implements OnInit {
  @Input()
  buttonId = 'continue-button';
  @Input()
  preventDoubleClick: boolean;
  @Input()
  dataE2E: string;
  @Input()
  buttonLabel: string;
  @Input()
  hidden: boolean;
  @Input()
  disabled: boolean;
  @Input()
  secondaryButton: boolean;
  @Output()
  handleThrottledClick = new EventEmitter<string>();
  @Output()
  handleClick = new EventEmitter<string>();
  @Input()
  removeMargin: boolean;
  @Input()
  removeMarginRight: boolean;

  constructor() {}

  ngOnInit(): void {
  }

  onClick() {
    this.handleClick.emit();
  }
}
