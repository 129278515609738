import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {ApplicationStatusBaseComponent} from '../ApplicationStatusBaseComponent';
import {AscStatusProgressStep} from '../asc-status-progress-bar/asc-status-progress-step';
import {dateFormatter} from '../../../common/utils/formatters/formatter';
import {
  ApplicationProgramStatus,
  ApplicationStatusType,
  DisabilityApplicationPathwayType,
  DisabilityApplicationStatus
} from '../../../models/application-status-response';
import {ProgramType} from '../../../models/program-type';
import {UrlInfo} from '../../../models/url-map';
import {ConfigService} from '../../../services/config.service';

@Component({
  selector: 'sd-ods-asc-status',
  templateUrl: './ods-asc-status.component.html',
  styleUrls: ['./ods-asc-status.component.scss']
})
export class OdsAscStatusComponent extends ApplicationStatusBaseComponent {
  public showMedicalEligibility: boolean;
  public isDisabilityAdjudicationUnit: boolean;

  public financialEligibilityProgressSteps: AscStatusProgressStep[];
  public medicalEligibilityProgressSteps: AscStatusProgressStep[];
  public applicationDecisionProgressSteps: AscStatusProgressStep[];

  constructor(protected translator: TranslateService,
              protected configService: ConfigService) {
    super(translator, configService);
    this.hasLanguageDependentParam = true;
  }

  protected buildProgressSteps(): void {
    if ((this.applicationStatus?.programStatuses?.length !== 1) ||
      (this.applicationStatus?.programStatuses[0].programType !== ProgramType.ODS)) {
      return;
    }

    // tslint:disable-next-line:max-line-length
    if(this.applicationStatus?.programStatuses[0].disabilityApplicationPathway===DisabilityApplicationPathwayType.DisabilityAdjudicationUnit){
      this.isDisabilityAdjudicationUnit = true;
    }

    this.showMedicalEligibility = true;
    if (this.isDecisionStatus(this.applicationStatus?.programStatuses[0].applicationStatus)) {
      return this.buildDecisionSteps(this.applicationStatus?.programStatuses[0]);
    } else if (this.applicationStatus?.programStatuses[0].applicationStatus === ApplicationStatusType.Assigned) {
      return this.buildAssignedForReviewSteps(this.applicationStatus?.programStatuses[0]);
    } else if (this.applicationStatus?.programStatuses[0].applicationStatus === ApplicationStatusType.Submitted) {
      return this.buildSubmittedSteps(this.applicationStatus?.programStatuses[0]);
    }
  }

  private buildSubmittedSteps(programStatus: ApplicationProgramStatus): void {
    const financialEligibilitySteps = [];
    financialEligibilitySteps.push({
      programType: programStatus.programType,
      id: 'submitted',
      name: '.financial-eligibility.step1.name.SUBMITTED',
      text: '.financial-eligibility.step1.text',
      textParam: {link: programStatus.submittedDate ? dateFormatter(programStatus.submittedDate, this.translator.currentLang) : '???'},
      completed: false,
      active: true,
      failed: false,
      hasStepFollowed: true,
    });
    financialEligibilitySteps.push({
      programType: programStatus.programType,
      id: 'assigned',
      name: '.financial-eligibility.step2.name.SUBMITTED',
      completed: false,
      active: false,
      hasStepFollowed: false,
    });
    this.financialEligibilityProgressSteps = financialEligibilitySteps;

    const medicalEligibilitySteps = [];
    medicalEligibilitySteps.push({
      programType: programStatus.programType,
      id: 'no-additional-steps-required',
      name: '.medical-eligibility.step1.name.SUBMITTED',
      completed: false,
      active: false,
      failed: false,
      hasStepFollowed: false,
    });
    this.medicalEligibilityProgressSteps = medicalEligibilitySteps;

    const applicationDecisionSteps = [];
    applicationDecisionSteps.push({
      programType: programStatus.programType,
      id: 'pending',
      name: '.application-decision.step1.name.SUBMITTED',
      completed: false,
      active: false,
      failed: false,
      hasStepFollowed: false,
    });
    this.applicationDecisionProgressSteps = applicationDecisionSteps;
  }

  private buildAssignedForReviewSteps(programStatus: ApplicationProgramStatus): void {
    const financialEligibilitySteps = [];
    const medicalEligibilitySteps = [];
    const applicationDecisionSteps = [];
    financialEligibilitySteps.push({
      programType: programStatus.programType,
      id: 'submitted',
      name: '.financial-eligibility.step1.name.ASSIGNED',
      nameParam: {link: programStatus.submittedDate ? dateFormatter(programStatus.submittedDate, this.translator.currentLang) : '???'},
      completed: true,
      active: false,
      failed: false,
      hasStepFollowed: true,
    });
    financialEligibilitySteps.push({
      programType: programStatus.programType,
      id: 'assigned',
      name: '.financial-eligibility.step2.name.ASSIGNED.nodate',
      text: '.financial-eligibility.step2.text2',
      textParam: {
        assignedDate: programStatus.assignedForReviewDate
          ? dateFormatter(programStatus.assignedForReviewDate, this.translator.currentLang)
          : '???',
        link: this.getUrl(UrlInfo.odspMedicalCheckExemptions)
      },
      completed: false,
      active: true,
      hasStepFollowed: false,
    });
    medicalEligibilitySteps.push({
      programType: programStatus.programType,
      id: 'no-additional-steps-required',
      name: '.medical-eligibility.step1.name.SUBMITTED',
      completed: false,
      active: false,
      failed: false,
      hasStepFollowed: false,
    });
    // DAU pathway review steps
    if(programStatus.disabilityApplicationPathway===DisabilityApplicationPathwayType.DisabilityAdjudicationUnit) {
      financialEligibilitySteps.pop();
      financialEligibilitySteps.push(
        {
          programType: programStatus.programType,
          id: 'assigned',
          name: '.financial-eligibility.step2.name.ASSIGNED',
          nameParam: {
            link: programStatus.assignedForReviewDate ?
              dateFormatter(programStatus.assignedForReviewDate, this.translator.currentLang) : '???'
          },
          completed: true,
          active: false,
          hasStepFollowed: true,
        }
      )
      financialEligibilitySteps.push({
        programType: programStatus.programType,
        id: 'assigned',
        name: '.financial-eligibility.step3.name.DECISION',
        nameParam: {
          link: programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate ?
            dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang) : '???'
        },
        completed: true,
        active: false,
        hasStepFollowed: false,
      });
      if(programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus !== DisabilityApplicationStatus.DisabilityDeterminationPackageIssued) {
        medicalEligibilitySteps.pop();
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'assigned',
          name: '.medical-eligibility.step2.name.DECISION',
          nameParam: {
            link: programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate ?
              dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang) : '???'
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: true,
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageExpired) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'expired',
          name: '.medical-eligibility.step2.name.EXPIRED.nodate',
          text: '.medical-eligibility.step2.text',
          textParam: {
            link: programStatus.disabilityApplicationStatusInfo?.disabilityDeterminationPackageInfo?.ddpExpiryDate ?
              dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpExpiryDate, this.translator.currentLang) : '???'
          },
          completed: false,
          active: true,
          failed: true,
          hasStepFollowed: false,
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.PersonWithDisability) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'person-with-disability',
          name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpReceivedDate, this.translator.currentLang),
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: true
        });
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'person-with-disability',
          name: '.medical-eligibility.step3.name.APPROVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.dauDecisionDate, this.translator.currentLang),
          },
          text: '.medical-eligibility.step3.text.PERSON_WITH_DISABILITY',
          completed: false,
          active: true,
          failed: false,
          hasStepFollowed: false,
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.IncompleteDisabilityDeterminationPackageReceived) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'no-additional-steps-required',
          name: '.medical-eligibility.step3.name.INCOMPLETE_DDP_RECEIVED',
          text: '.medical-eligibility.step3.text.INCOMPLETE_DDP_RECEIVED',
          completed: false,
          active: true,
          failed: true,
          hasStepFollowed: false,
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.ReferralCancelled) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'incomplete-disability-determination-package-received',
          name: '.medical-eligibility.step3.name.REFERRAL_CANCELLED',
          text: '.medical-eligibility.step3.text.REFERRAL_CANCELLED',
          completed: false,
          active: true,
          failed: true,
          hasStepFollowed: false,
          normalizeHeading: true
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.PersonWithoutDisability) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'person-without-disability',
          name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo?.ddpReceivedDate, this.translator.currentLang),
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: true
        });
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'person-without-disability',
          name: '.medical-eligibility.step3.name.APPROVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.dauDecisionDate, this.translator.currentLang),
          },
          text: '.medical-eligibility.step3.text.PERSON_WITHOUT_DISABILITY',
          completed: false,
          active: true,
          failed: false,
          hasStepFollowed: false,
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.CompleteDisabilityDeterminationPackageReceived) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'complete-disability-determination-package-received',
          name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED',
          text:'.medical-eligibility.step3.text.COMPLETE_DDP_RECEIVED',
          textParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo?.ddpReceivedDate, this.translator.currentLang),
          },
          completed: false,
          active: true,
          failed: false,
          hasStepFollowed: true
        });
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'disability-determination-package-issued-outcome',
          name: '.medical-eligibility.step3.name.OUTCOME',
          completed: false,
          active: false,
          failed: false,
          hasStepFollowed: false,
          normalizeHeading: true
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageIssued) {
        medicalEligibilitySteps.pop();
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'disability-determination-package-issued',
          name: '.medical-eligibility.step3.name.ASSIGNED.DDP_ISSUED',
          text: '.medical-eligibility.step3.text.DDP_ISSUED',
          textParam: {
            link1:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang),
            link2:this.getUrl(UrlInfo.disabilityAdjudicationUnit),
            link3:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpExpiryDate, this.translator.currentLang)
          },
          completed: false,
          active: true,
          failed: false,
          onhold:true,
          hasStepFollowed: true,
        });
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'disability-determination-package-issued-review',
          name: '.medical-eligibility.step3.name.REVIEW',
          completed: false,
          active: false,
          failed: false,
          hasStepFollowed: true,
          normalizeHeading: true
        });
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'disability-determination-package-issued-outcome',
          name: '.medical-eligibility.step3.name.OUTCOME',
          completed: false,
          active: false,
          failed: false,
          hasStepFollowed: false,
          normalizeHeading: true
        });
      }
    }
    // DDE_PC pathway review steps
    if(programStatus.disabilityApplicationPathway === DisabilityApplicationPathwayType.DisabilityDeterminationEvidence_PrescribedClass) {
      if (programStatus.disabilityApplicationStatusInfo && programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityAdjudicationNotRequired) {
        financialEligibilitySteps.pop();
        financialEligibilitySteps.push(
          {
            programType: programStatus.programType,
            id: 'assigned',
            name: '.financial-eligibility.step2.name.ASSIGNED',
            nameParam: {
              link: programStatus.assignedForReviewDate ?
                dateFormatter(programStatus.assignedForReviewDate, this.translator.currentLang) : '???'
            },
            completed: true,
            active: false,
            hasStepFollowed: false,
          }
        )
        medicalEligibilitySteps.pop();
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'adjudication-not-required',
          name: '.medical-eligibility.step3.name.ASSIGNED.REVIEW_COMPLETE',
          text: '.medical-eligibility.step3.text.REVIEW_COMPLETE',
          completed: false,
          active: true,
          failed: false,
          hasStepFollowed: false,
        });
      }
    }
    applicationDecisionSteps.push({
      programType: programStatus.programType,
      id: 'pending',
      name: '.application-decision.step1.name.ASSIGNED',
      completed: false,
      active: false,
      failed: false,
      hasStepFollowed: false,
    });
    this.financialEligibilityProgressSteps = financialEligibilitySteps;
    this.medicalEligibilityProgressSteps = medicalEligibilitySteps;
    this.applicationDecisionProgressSteps = applicationDecisionSteps;
  }

  private buildDecisionSteps(programStatus: ApplicationProgramStatus): void {
    const financialEligibilitySteps = [];
    const medicalEligibilitySteps = [];
    const applicationDecisionSteps = [];
    financialEligibilitySteps.push({
      programType: programStatus.programType,
      id: 'submitted',
      name: '.financial-eligibility.step1.name.DECISION',
      nameParam: {link: programStatus.submittedDate ? dateFormatter(programStatus.submittedDate, this.translator.currentLang) : '???'},
      completed: true,
      active: false,
      failed: false,
      hasStepFollowed: true,
    });
    financialEligibilitySteps.push({
      programType: programStatus.programType,
      id: 'assigned',
      name: '.financial-eligibility.step2.name.DECISION',
      nameParam: {
        link: programStatus.assignedForReviewDate ?
          dateFormatter(programStatus.assignedForReviewDate, this.translator.currentLang) : '???'
      },
      completed: true,
      active: false,
      hasStepFollowed: this.isDauDdpReceivedNotNullExemptedStatus(programStatus)
    });
    // DAU pathway applications decision steps
    if(this.isDauDdpReceivedNotNullExemptedStatus(programStatus)) {
      financialEligibilitySteps.push({
        programType: programStatus.programType,
        id: 'assigned',
        name: '.financial-eligibility.step3.name.DECISION',
        nameParam: {
          link: programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate ?
            dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang) : '???'
        },
        completed: true,
        active: false,
        hasStepFollowed: false,
      });
      medicalEligibilitySteps.push({
        programType: programStatus.programType,
        id: 'assigned',
        name: '.medical-eligibility.step2.name.DECISION',
        nameParam: {
          link: programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate ?
            dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpIssuedDate, this.translator.currentLang) : '???'
        },
        completed: true,
        active: false,
        failed: false,
        hasStepFollowed: true,
      });
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageIssued) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'disability-determination-package-issued-review',
          name: '.medical-eligibility.step3.name.REVIEW',
          completed: false,
          active: false,
          failed: false,
          hasStepFollowed: true,
          normalizeHeading: true
        });
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'disability-determination-package-issued-outcome',
          name: '.medical-eligibility.step3.name.OUTCOME',
          completed: false,
          active: false,
          failed: false,
          hasStepFollowed: false,
          normalizeHeading: true
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.IncompleteDisabilityDeterminationPackageReceived) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'no-additional-steps-required',
          name: '.medical-eligibility.step3.name.INCOMPLETE_DDP_RECEIVED',
          completed: false,
          active: true,
          failed: true,
          failedIconWithX:true,
          hasStepFollowed: false,
          normalizeHeading: true
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.ReferralCancelled) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'incomplete-disability-determination-package-received',
          name: '.medical-eligibility.step3.name.REFERRAL_CANCELLED',
          completed: false,
          active: true,
          failed: true,
          failedIconWithX: true,
          hasStepFollowed: false,
          normalizeHeading: true
        });
      }if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.CompleteDisabilityDeterminationPackageReceived) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'complete-disability-determination-package-received',
          name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpReceivedDate, this.translator.currentLang),
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: true
        });
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'disability-determination-package-issued-outcome',
          name: '.medical-eligibility.step3.name.OUTCOME',
          completed: false,
          active: false,
          failed: false,
          hasStepFollowed: false,
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.PersonWithoutDisability) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'person-without-disability',
          name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpReceivedDate, this.translator.currentLang),
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: true
        });
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'person-without-disability',
          name: '.medical-eligibility.step3.name.APPROVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.dauDecisionDate, this.translator.currentLang),
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: false,
          normalizeHeading:true
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.PersonWithDisability) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'person-with-disability',
          name: '.medical-eligibility.step3.name.COMPLETE_DDP_RECEIVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpReceivedDate, this.translator.currentLang),
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: true
        });
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'person-with-disability',
          name: '.medical-eligibility.step3.name.APPROVED_WITH_DATE',
          nameParam: {
            link:dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.dauDecisionDate, this.translator.currentLang),
          },
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: false,
          normalizeHeading:true
        });
      }
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityDeterminationPackageExpired) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'expired',
          name: '.medical-eligibility.step2.name.EXPIRED',
          nameParam: {
            link: programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpExpiryDate ?
              dateFormatter(programStatus.disabilityApplicationStatusInfo.disabilityDeterminationPackageInfo.ddpExpiryDate, this.translator.currentLang) : '???'
          },
          completed: false,
          active: true,
          failed: true,
          failedIconWithX: true,
          hasStepFollowed: false,
          normalizeHeading: true
        });
      }
    }
    // DDE_PC pathway decision steps
    if(programStatus.disabilityApplicationPathway === DisabilityApplicationPathwayType.DisabilityDeterminationEvidence_PrescribedClass) {
      if (programStatus.disabilityApplicationStatusInfo.disabilityApplicationStatus === DisabilityApplicationStatus.DisabilityAdjudicationNotRequired) {
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'adjudication-not-required',
          name: '.medical-eligibility.step3.name.ADJUDICATION_NOT_REQUIRED',
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: false,
        });
      }
    }
    if(this.catchAllStatusAndExemptions(programStatus)) {
      if ((programStatus.applicationStatus === 'DENIED') || (programStatus.applicationStatus === 'WITHDRAWN')) {
        this.showMedicalEligibility = false;
      }

      if (programStatus.applicationStatus === ApplicationStatusType.Approved) {  // Approved
        medicalEligibilitySteps.push({
          programType: programStatus.programType,
          id: 'no-additional-steps-required',
          name: '.medical-eligibility.step1.name.DECISION',
          completed: true,
          active: false,
          failed: false,
          hasStepFollowed: false,
        });
      }
    }
    applicationDecisionSteps.push({
      programType: programStatus.programType,
      id: 'pending',
      name: '.application-decision.step1.name.'.concat(programStatus.applicationStatus),
      text: '.application-decision.step1.text.'.concat(programStatus.applicationStatus),
      textParam: {link: programStatus.icaDecisionDate ? dateFormatter(programStatus.icaDecisionDate, this.translator.currentLang) : '???'},
      completed: false,
      active: true,
      failed: programStatus.applicationStatus === 'WITHDRAWN',
      hasStepFollowed: false,
    });
    this.financialEligibilityProgressSteps = financialEligibilitySteps;
    this.medicalEligibilityProgressSteps = medicalEligibilitySteps;
    this.applicationDecisionProgressSteps = applicationDecisionSteps;
  }
}
