<div class="d-flex ">
  <div class="ontario-h2 flex-fill dialog-title">{{ parameter.dialogName + '.heading' | translate }}</div>
  <div class="close-button-div">
    <input type="image" id="close-button" alt="close button" tabindex="0" class="flex-fill" (click)="handleClose()" role="img" src="assets/images/close-x.svg"/>
  </div>
</div>

<div [ngClass]="{'dialog-min-width': parameter.dialogMinWidth}" class="pt-4 dialog-body-container">
  <form [formGroup]="form" sdDisableAutocomplete>
    <div *ngIf="!parameter.noBodyContent" class="dialog-body flex-fill" [innerHTML]="parameter.dialogName + '.body' | translate"></div>
    <sd-checkbox-question [id]="'create-account-reminder-dialog.termsAndConditions'" [options]="termsAndConditionsItems"
                          [showLabel]=false
                          [showCustomError]="showCustomError"
                          (toggle)="onTermsAndConditionsBoxChanges('termsAndConditions', $event)">
    </sd-checkbox-question>
    <div class="button-container ontario-padding-top-16-!">
      <app-mccss-submit-button dataE2E="createAccountBtn" buttonLabel="{{ parameter.dialogName + '.create-account-button' | translate }}" [preventDoubleClick]="true" (handleClick)="onCreateAccountClicked()"></app-mccss-submit-button>
      <app-mccss-submit-button dataE2E="skipBtn" buttonLabel="{{ parameter.dialogName + '.exit-with-saving-button' | translate }}" [secondaryButton]="true" [preventDoubleClick]="true" (handleClick)="handleContinueWithoutAccount()"></app-mccss-submit-button>
    </div>
  </form>
</div>

<div>
  <a *ngIf="!parameter.noReturnToApplicationLink" id="return-to-app-link" (click)="handleReturnToApplication()">
    <span>{{ parameter.dialogName + '.return-to-application-link' | translate }}</span>
  </a>
  <span *ngIf="isLocal">
    &nbsp;&nbsp;&nbsp;
    <a id="mock-signIn-link" (click)="handleMockLogin()">
      <span>Mock Login</span>
    </a>
  </span>
</div>
