import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-mccss-dialog-title',
  templateUrl: './mccss-dialog-title.component.html',
  styleUrls: ['./mccss-dialog-title.component.scss']
})
export class MccssDialogTitleComponent implements OnInit,AfterViewInit {
  dialogBody: any
  dialogBodyLink: any
  dialogButtonText: any
  buttonAction: () => void
  rightDialogButtonText: any
  rightButtonAction: () => void
  closeButtonAction: () => void
  showCloseButton = true
  dialogHeader: any
  enableReturnToAppLink?: boolean
  alertInfoKey: any

  constructor() { }

  ngOnInit(): void {
  }

  onButtonClicked() {
    this.buttonAction()
  }

  onRightButtonClicked() {
    this.rightButtonAction()
  }

  onCloseButtonClicked() {
    this.closeButtonAction()
  }

  handleReturnToApplication() {
    this.closeButtonAction()
  }

  ngAfterViewInit(): void {
    const isMobile = window.matchMedia('(max-width: 768px)');
    if (isMobile && isMobile.matches && document && window.innerHeight) {
      const vhWithoutBrowserElements: number = window.innerHeight;
      const sadaDialogContainerEle = document.querySelector('.sada-dialog-class') as HTMLElement;
      if (sadaDialogContainerEle) {
        sadaDialogContainerEle.style.setProperty('--vhWithoutBrowserElements', `${vhWithoutBrowserElements}px`);
      }
    }
  }
}

