<div class="form-group" [formGroup]="form">
  <sd-radio-question [showError]="showError" [id]="'child-care-income-program-type-'+parentFormIndex"
                     [required]="true"
                     [choices]="incomeTypes"
                     label="earned-income.child.care.income.program.type.select"
                     [controlName]="formControlNames.EXPENSE_TYPE">
  </sd-radio-question>
  <sd-text-question [showError]="showError"
                    [id]="'child-care-provider-name-'+parentFormIndex"
                    label="earned-income.child.care.provider.name"
                    [required]=true
                    [maxLength]=60
                    [minLength]=1
                    [capitalizeFirstLetter]=true
                    [validationRegex]="nameCharacterPattern" [validationRegexError]="'earned-income.error.invalidProviderName'"
                    [controlName]="formControlNames.CARE_PROVIDER_NAME">
  </sd-text-question>
  <sd-text-question [showError]="showError"
                    [id]="'child-care-monthly-amount-'+parentFormIndex"
                    label="earned-income.child.care.monthly.amount"
                    [required]=true
                    [characterWidth]=10
                    [isDollarValue]=true
                    [validationRegex]="accountValuePattern" [validationRegexError]="'error.invalid.currency.amount'"
                    [validationFns]="accountValueValidationFns"
                    [controlName]="formControlNames.EXPENSE_AMOUNT">
  </sd-text-question>
</div>
