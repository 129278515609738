import {StatusNotEligibleForSupport} from '../../utils/questions/question-choices';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';

export class StatusInCanadaComponentUtil {
  static isRefugee(statusInCanada: string): boolean {
    return ['Convention refugee', 'Refugee claimant','Government assisted refugee'].includes(statusInCanada);
  }

  static isGovernmentAssistedRefugee(statusInCanada: string): boolean {
    return 'Government assisted refugee' === statusInCanada;
  }

  static isNonCanadianBornCitizenNonRefugee(statusInCanada: string):boolean {
    return ['Canadian citizen not born in Canada', 'Permanent resident', 'Applicant for permanent residence',
      'Deportee'].includes(statusInCanada)
  }

  static isCitizenBornInCanada(statusInCanada: string) {
    return 'Canadian citizen born in Canada' === statusInCanada
  }

  static isStatusIndian(statusInCanada: string) {
    return 'Status Indian' === statusInCanada;
  }

  static isStatusRequireImmigrationRelatedFields(statusInCanadaValue: string): boolean {
    return ['Canadian citizen not born in Canada', 'Permanent resident', 'Applicant for permanent residence',
      'Convention refugee', 'Refugee claimant', 'Deportee','Government assisted refugee'].includes(statusInCanadaValue);
  }

  static isStatusRequireArrivalDate(statusInCanadaValue: string): boolean {
    return ['Canadian citizen not born in Canada', 'Permanent resident', 'Applicant for permanent residence',
      'Convention refugee', 'Refugee claimant', 'Deportee', 'Government assisted refugee',
      'Temporary resident permit', 'Visitor or tourist', 'Student visa'].includes(statusInCanadaValue);
  }

  static isStatusNotEligibleForSupport(statusInCanada: string) {
    return StatusNotEligibleForSupport.includes(statusInCanada);
  }

  static isTenYearsLivedInCanadaFieldVisible(statusInCanadaValue: string, dateOfBirth: string): boolean {
    if (!!dateOfBirth && dateOfBirth.replace(/\D/g,'').length !== 8) {  // Not valid until the full-date is provided.
      return false;
    }

    return ['Canadian citizen not born in Canada', 'Permanent resident', 'Applicant for permanent residence',
        'Convention refugee', 'Refugee claimant', 'Deportee'].includes(statusInCanadaValue) &&
      SadaCustomValidator.validateOverAge(dateOfBirth, 65);
  }
}
