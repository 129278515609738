import {Choice} from '../../common/utils/questions/question-choices';

export function householdOtherIncomeTypeChoices() {
  const householdOtherIncomeType: Choice[] = [
    {value: 'Child support', label: 'household-income.other.income.type.option.child-support'},
    {value: 'HST return', label: 'household-income.other.income.type.option.hst-return'},
    {value: 'Income tax return', label: 'household-income.other.income.type.option.tax-return'},
    {value: 'Legal settlement or award', label: 'household-income.other.income.type.option.settlement-reward'},
    {value: 'Pension - private', label: 'household-income.other.income.type.option.pension-private'},
    {value: 'Pension - foreign', label: 'household-income.other.income.type.option.pension-foreign'},
    {value: 'Rental income for a second property other than where you live', label: 'household-income.other.income.type.option.rental-income'},
    {value: 'Spousal support', label: 'household-income.other.income.type.option.spousal-support'},
    {value: 'RRSP or RRIF payments', label: 'household-income.other.income.type.option.rrsp-rrif'},
    {value: 'War Veterans Allowance', label: 'household-income.other.income.type.option.war-veterans-allowance'},
    {value: 'CPP disabled contributor\'s child\'s benefit', label: 'household-income.other.income.type.option.cpp-disabled-cb'},
    {value: 'CPP surviving child\'s benefit', label: 'household-income.other.income.type.option.cpp-surviving-cb'},
    {value: 'QPP death benefit', label: 'household-income.other.income.type.option.qpp-death-benefit'},
    {value: 'QPP surviving spouse\'s pension', label: 'household-income.other.income.type.option.qpp-surviving-spouse-pension'},
    {value: 'QPP retirement', label: 'household-income.other.income.type.option.qpp-retirement'},
    {value: 'QPP orphan\'s pension', label: 'household-income.other.income.type.option.qpp-orphan-pension'},
    {value: 'QPP disability', label: 'household-income.other.income.type.option.qpp-disability'},
    {value: 'Ontario Child Benefit', label: 'household-income.other.income.type.option.ontario-child-ben'},
    {value: 'Canada Child Benefit', label: 'household-income.other.income.type.option.canada-child-ben'},
    {value: 'Resettlement Assistance Program (RAP)', label: 'household-income.other.income.type.option.resettlement-assist-program'},
    {value: 'My income type is not listed here', label: 'household-income.other.income.type.option.not-listed-here'},
  ];
  return householdOtherIncomeType
}

export const householdOtherIncomeTypeDropdownOptions = [
    'Child support', 'Pension alimentaire pour enfants',
    'HST return', 'Déclaration de TVH',
    'Income tax return', 'Déclaration d\'impôt sur le revenu',
    'Legal settlement or award', 'Règlement ou sentence judiciaire',
    'Pension - private', 'Pension - privée',
    'Pension - foreign', 'Pension - étrangère',
    'Rental income for a second property other than where you live', 'Revenu de location pour une deuxième propriété autre que celle où vous vivez',
    'Spousal support', 'Pension alimentaire pour le conjoint',
    'RRSP or RRIF payments', 'Versements d\'un REER ou d\'un FERR',
    'War Veterans Allowance', 'Allocation d\'ancien combattant',
    'CPP disabled contributor\'s child\'s benefit', 'Prestation d’enfant de cotisant invalide du RPC',
    'CPP surviving child\'s benefit', 'Prestation d’enfant survivant du RPC',
    'QPP death benefit', 'Prestation de décès du RRQ',
    'QPP surviving spouse\'s pension', 'Rente de conjoint survivant du RRQ',
    'QPP retirement', 'Prestation de retraite du RRQ',
    'QPP orphan\'s pension', 'Rente d’orphelin du RRQ',
    'QPP disability', 'Rente d’invalidité du RRQ',
    'Ontario Child Benefit', 'Prestation ontarienne pour enfants',
    'Canada Child Benefit', 'Allocation canadienne pour enfants',
    'Resettlement Assistance Program (RAP)', 'Programme d’aide à la réinstallation (PAR)'
]
