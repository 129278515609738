import {AbstractControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

import {FormDataMapping, FormMapping} from './form-data-mapping-config';
import {SponsorshipMappingUtil} from './sponsorship-mapping.util';
import {FormConverter1} from '../../../common/utils/converters/converter';
import {Question} from '../../../common/utils/questions/question';
import {FormToAppDataBase} from '../../../common/utils/converters/form-to-app-data-base';
import {ApplicationAnswers} from '../../../models/data.model';

export class SponsorshipQuestionsToAppData extends FormToAppDataBase implements FormConverter1<FormGroup,
  ApplicationAnswers, Question<string>[], TranslateService> {
  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[], translator: TranslateService): void {
    new SponsorshipFamilyMemberQuestionsToAppData().convert(form, appData, questions, translator);
  }
}

// Structure of anyoneSponsoredList in appData.
interface AnyoneSponsored {
  applicant: string;
  applicantType: 'Applicant'| 'Spouse'| 'Child 1'| 'Child 2'| 'Child 3'| 'Child 4'| 'Child 5'| 'Child 6'| 'Child 7'| 'Child 8';
  applicantName: string;
  applicantDisplayType: string;
  applicantDisplayTypeFr: string;
  sponsorFirstName?: string;
  sponsorLastName?: string;
  sponsorNoLastName?: string[];
  sponsorSupportAmount?: string;
  sponsorLivesWith?: string;
}

export class SponsorshipFamilyMemberQuestionsToAppData extends FormToAppDataBase implements FormConverter1<FormGroup,
  ApplicationAnswers, Question<string>[], TranslateService> {
  translator: TranslateService;

  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[], translator: TranslateService): void {
    this.resetData(appData);

    this.translator = translator;
    const sponsoredPersons = SponsorshipMappingUtil.extractSponsoredPersons('appData.applicant.label', appData);

    if (sponsoredPersons?.length === 1 && sponsoredPersons[0].key.includes('applicant.label.applicant')) {// Single member; applicant
      // @ts-ignore
      const control = form.get(FormMapping.SPONSOR_DETAIL_PANEL_ELEMENT)?.get(FormMapping.PANELS_ELEMENT)?.controls[0];
      this.populateSponsorData(new Map(Object.entries(FormDataMapping.Sponsor)), control, appData.jsonData);
    } else {
      appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_CHECKBOX] = this.constructAnyoneSponsoredCheckbox(sponsoredPersons);
      if (form.get(FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR)?.value === 'yes') {  // Multiple members with same sponsors
        appData.jsonData[FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR] = 'yes';
        this.populateSponsorData(new Map(Object.entries(FormDataMapping.Common)), form, appData.jsonData);
        appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_LIST] = this.constructAnyoneSponsoredList(true, sponsoredPersons, form);
      } else if (sponsoredPersons?.length > 1) {  // Multiple members with different sponsors
        appData.jsonData[FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR] = 'no';
        appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_LIST] = this.constructAnyoneSponsoredList(false, sponsoredPersons, form);
      } else {  // Single member; other family-member
        appData.jsonData[FormDataMapping.Root.ANYONE_SPONSORED_LIST] = this.constructAnyoneSponsoredList(true, sponsoredPersons, form);
      }
    }
  }

  private resetData(appData: ApplicationAnswers): void {
    for (const [k, v] of Object.entries(FormDataMapping.Common)) {
      delete appData.jsonData[v];
    }
    for (const [k, v] of Object.entries(FormDataMapping.Sponsor)) {
      delete appData.jsonData[v];
    }
    for (const [k, v] of Object.entries(FormDataMapping.Root)) {
      delete appData.jsonData[v];
    }
  }

  private populateSponsorData(mappings: Map<string, string>, control: AbstractControl, data: any) {
    for (const [k, v] of mappings.entries()) {
      if (Array.isArray(control.get(v)?.value)) {
        if (control.get(v)?.value[0]) {
          data[v] = ['yes'];
        }
      } else if (control.get(v)?.value) {
        data[v] = control.get(v)?.value;
      }
    }
  }

  private constructAnyoneSponsoredCheckbox(sponsoredPersons): string[] {
    return sponsoredPersons.map((person) => this.translator.instant(person.key, person.param));
  };

  private constructAnyoneSponsoredList(sameSponsor: boolean, sponsoredPersons: {key: string, param: {name: string, index?: number}}[],
        form: FormGroup): AnyoneSponsored[] {
    return sponsoredPersons.map((person, index) => {
      const wholeString = this.translator.instant(person.key, person.param);
      const type = wholeString.substring(0, wholeString.indexOf(':'));
      let panelElementName: string;
      if (sponsoredPersons.length === 1) {
        panelElementName = FormMapping.SPONSOR_DETAIL_PANEL_ELEMENT;
      } else {
        panelElementName = sameSponsor ? FormMapping.SAME_SPONSOR_DETAIL_PANEL_ELEMENT + index :
          FormMapping.DIFFERENT_SPONSOR_DETAIL_PANEL_ELEMENT + index;
      }
      // @ts-ignore
      const control = form.get(panelElementName)?.get(FormMapping.PANELS_ELEMENT)?.controls[0];
      const sponsor = {
        applicant: wholeString,
        applicantType: SponsorshipMappingUtil.translateApplicantTypeToEnglish(type),
        applicantName: ' ' + person.param.name,
        applicantDisplayType: type,
        applicantDisplayTypeFr: SponsorshipMappingUtil.translateApplicantTypeToFrench(type),
      } as AnyoneSponsored;
      this.populateSponsorData(new Map(Object.entries(FormDataMapping.Sponsor)), control, sponsor);
      return sponsor;
    })
  };
}
