import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Idle} from '@ng-idle/core';
import {TranslateService} from '@ngx-translate/core';

import {ExternalRouter} from '../../external.router';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../services/config.service';
import {IntakeService} from '../../services/intake.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageService} from '../../services/page.service';
import {PageBaseComponent} from '../PageBaseComponent';

@Component({
  selector: 'sd-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent extends PageBaseComponent implements OnInit, OnDestroy {
  form: FormGroup | undefined;
  showCustomError: string;

  constructor(private router: Router,
              public route: ActivatedRoute,
              public intakeService: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              public formBuilder: FormBuilder) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.createAccount;
    this.form = this.formBuilder.group({
      termAndCondition: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.populateTermAndConditionItems();
    // initialize form with application data
    this.initializeForm();

    this.translator.onLangChange.subscribe(() => {
      this.populateTermAndConditionItems();
    });
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  postInitializeForm() {}

  preSaveApplication() {}

  onSubmit(toPublicSecure?: boolean, isSignIn?: boolean): void {
    if (toPublicSecure) {
      if (this.form.valid) {
          this.redirectToPublicSecure(PageInfo.personalInfo, this.applicationAnswers.jsonData.email);
      } else {
        if (isSignIn) {
          this.showCustomError = 'create-account.error.termAndCondition.signIn.required'
        } else {
          this.showCustomError = 'create-account.error.termAndCondition.required'
        }
      }
    } else {
      this.handleSkipNow();
    }
  }

  onMockLogin() {
    this.doMockLogin(PageInfo.personalInfo, this.applicationAnswers.jsonData.email);
  }

  get showMockLoginLink() {
    return this.configService.getClientConfig()?.local;
  }
  private isSpouseInfoRequired() {
    return ['Married', 'Common Law'].includes(this.applicationAnswers.jsonData.maritalStatus);
  }
  private isChildInfoRequired() {
    return this.applicationAnswers.jsonData.childrenLivingWithYou === 'yes';
  }

  private handleSkipNow(): void {
        let redirectTo: string;
    if (this.isSpouseInfoRequired()) {
      this.intake.setAppSpouseRequired(true)
      if (this.isChildInfoRequired()) {
        this.intake.setAppChildRequired(true);
      }
      redirectTo = ['/', 'intake/', PageInfo.spouseInfo].join('');
    } else if (this.isChildInfoRequired()) {
      this.intakeService.setAppChildRequired(true);
      redirectTo = ['/', 'intake/', PageInfo.childrenInfo].join('');
    } else {
      redirectTo = ['/', 'intake/', PageInfo.additionalInfo].join('');
    }
    this.router.navigate([redirectTo]);
  }

  onTermAndConditionBoxChanges(questionKey: string, event: any): void {
    if (event && event.length) {
      this.form.controls.termAndCondition.setValue(event[0])
      this.showCustomError = undefined
    } else {
      this.showCustomError = undefined
      this.form.get('termAndCondition').setValue('')
    }
  }
}
