import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {concatMap, map} from 'rxjs/operators';

import {popup} from '../dialog-constants';
import {AuthService} from './auth.service';
import {IntakeService} from './intake.service';
import {PageService} from './page.service';
import {WindowService} from './window.service';
import {ConfigService} from './config.service';
import {UrlInfo} from '../models/url-map';

@Injectable({
  providedIn: 'root'
})
export class PageServiceImpl implements PageService {
  constructor(private authService: AuthService,
              private intakeService: IntakeService,
              private windowService: WindowService,
              private translator: TranslateService,
              private configService: ConfigService) {
  }

  /**
   * This method clears the auth-token from local-storage, resets the auth values in Auth Service and invalidates the Spring Session and
   * then returns the URL to logout from PSE for authenticated user, while just invalidates the Spring Session for unauthenticated user and
   * returns the PS sessions logout URL.
   * Note: Reverse the order of logoff calls for the authenticated user does not work, the Public Secure logout has dependency on Session.
   */
  public logout(redirectToUrl: string, envName?: string): Observable<string> {
    if (this.authService.isAuthorizedToSave()) {  // Authenticated user
      return this.authService.psLogout(redirectToUrl ? redirectToUrl : null)
        .pipe(
          concatMap(url => this.intakeService.logout().pipe(
              map(() => of(url))
            )
          ),
          concatMap(result => result)
        )
    } else {  // Unauthenticated user
      popup.absoluteTimeout.link = this.configService.getUrl(this.translator.currentLang, UrlInfo.ontarioWorks);
      return this.intakeService.logout()
        .pipe(
          concatMap(() => of(popup.absoluteTimeout.link))
        )
    }
  }

  public findRouteUrl(pageName: string): string[] {
    return ['/', 'intake', pageName];
  }
}
