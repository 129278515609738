import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup} from '@angular/forms';
import {ChildCareIncomeTypes, Choice} from '../../../common/utils/questions/question-choices';
import {amountMaxLength, positiveCurrencyPattern, nameCharacterPattern} from '../../../common/utils/validators/custom-validators';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';
import {ChildCareFormNames} from '../earned-income.model';

@Component({
  selector: 'sd-child-care-panel',
  templateUrl: './child-care-panel.component.html',
  styleUrls: ['./child-care-panel.component.scss']
})
export class ChildCarePanelComponent implements OnInit {
  @Input()
  parentFormIndex: number;

  @Input()
  applicantType: string;

  @Input()
  showError = false;

  @Input()
  data: any;

  @Input()
  monthAndYear: any;

  form: FormGroup;
  incomeTypes: Choice[] = ChildCareIncomeTypes;
  accountValuePattern = positiveCurrencyPattern;
  accountValueValidationFns: { validationFunction: (params: any) => boolean; errorKey: string; }[];
  formControlNames = ChildCareFormNames
  nameCharacterPattern = nameCharacterPattern;

  constructor(public controlContainer: ControlContainer, public fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.controlContainer?.control as FormGroup
    this.accountValueValidationFns = [
      {
        validationFunction: (value: any[]) => {
          return isNaN(value[0]) || (!isNaN(value[0]) && value[0] > 0);
        },
        errorKey: 'error.invalid.min.amount.detailed'
      },
      {
        validationFunction: (value: any[]) => {
          return isNaN(value[0]) || (!isNaN(value[0]) && value[0] >= 0);
        },
        errorKey: 'error.invalid.min.amount'
      },
      {
        validationFunction: (value: []) => {
          return SadaCustomValidator.validateMaxNumericValueLength([...value, amountMaxLength]);
        },
        errorKey: 'error.invalid.max.amount'
      },
    ];
  }
}
