import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BankingService} from './banking.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BankingServiceImpl implements BankingService {

  manualTriggerValidation:boolean;

  constructor(private http: HttpClient) {
  }

  validateBankDetails(institutionNumber: string, transitNumber: string, resultCallback: any) {
    return this.http.post(
      `/v1/validation/bank`,
      {institutionNumber, transitNumber},
      {withCredentials: true}
    ).subscribe(
      () => resultCallback(true),
      () => resultCallback(false)
    )
  }

  validateInstitutionBranchNumber(institutionNumber: string, transitNumber: string): Observable<boolean> {
    return this.http.post(
      `/v1/validation/bank`,
      {institutionNumber, transitNumber},
      {withCredentials: true}
    ).pipe(map(response => {
        return true
      }),
      catchError((err) => {
        return of(false)
      })
    )

  }

  setManualTriggerValidation(indicator:boolean):void {
    this.manualTriggerValidation = indicator;
  }

  isManualTriggerValidation():boolean{
    return this.manualTriggerValidation;
  }
}
