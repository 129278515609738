import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {FormDataMapping, FormMapping} from './converter/form-data-mapping-config';
import {SponsorshipMappingUtil} from './converter/sponsorship-mapping.util';
import {Question} from '../../common/utils/questions/question';
import {amountMaxLength, positiveCurrencyPattern} from '../../common/utils/validators/custom-validators';
import {ApplicationAnswers} from '../../models/data.model';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';

@Injectable()
export class SponsorshipQuestionService {
  translator: TranslateService;
  readonly APPLICANT_PERSON_HEADING = 'sponsorship.applicant.person.heading';
  readonly SPOUSE_PERSON_HEADING = 'sponsorship.spouse.person.heading';
  readonly CHILD_PERSON_HEADING = 'sponsorship.child.person.heading';

  getSponsorshipQuestions(translator: TranslateService, appData: ApplicationAnswers): Question<string>[] {
    this.translator = translator;
    return [...this.getSponsorshipFamilyMemberQuestions(appData)];
  }

  // Constructs the top-level questions.
  private getSponsorshipFamilyMemberQuestions(appData: ApplicationAnswers): Question<string>[] {
    const questions: Question<string>[] = [
      {
        controlType: 'bulletPoint',
        key: FormMapping.SELECT_SPONSORED_PERSON_ELEMENT,
        label: 'sponsorship.select.all.members',
        labelOnly: true
      },
      {
        controlType: 'radio',
        key: FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR,
        label: 'sponsorship.same.sponsor',
        required: true,
        needMarginTop:true,
        context: 'sponsorship.same.sponsor.context',
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
          },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ],
      },
      {
        controlType: 'textbox',
        key: FormDataMapping.Common.COMMON_SPONSOR_FIRST_NAME,
        label: 'sponsorship.sponsor.first.name',
        capitalizeFirstLetter: true,
        required: true,
        needMarginTop:true,
        characterWidth: 20,
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'maxLength',
            maxLengthValue: 65,
            errorKey: 'housing-situation.error.invalid.livingWithYouFirstName.length'
          },
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
            errorKey: 'error.invalid.firstNameWithOnlyCharacters'
          },
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateSponsorName,
            errorKey: 'sponsorship.sponsor.invalid.last.name'
          }
        ],
        visibleOnConditions: [
          {
            questionKey: FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR,
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'textbox',
        key: FormDataMapping.Common.COMMON_SPONSOR_LAST_NAME,
        label: 'sponsorship.sponsor.last.name',
        capitalizeFirstLetter: true,
        resetWhenDisabled: true,
        required: true,
        needMarginTop:true,
        characterWidth: 20,
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'maxLength',
            maxLengthValue: 65,
            errorKey: 'housing-situation.error.invalid.livingWithYouFirstName.length'
          },
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
            errorKey: 'error.invalid.lastNameWithOnlyCharacters'
          },
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateSponsorName,
            errorKey: 'sponsorship.sponsor.invalid.last.name'
          }
        ],
        visibleOnConditions: [
          {
            questionKey: FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR,
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: FormDataMapping.Common.COMMON_SPONSOR_NO_LAST_NAME,
        resetWhenDisabled: true,
        options: [{
          value: false,
          label: 'sponsorship.sponsor.no.last.name'
        }],
        visibleOnConditions: [
          {
            questionKey: FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR,
            questionControlType: 'radio',
            value: ['yes']
          },
        ],
        disableOthersOnCondition:[
          {
            questionKey: FormDataMapping.Common.COMMON_SPONSOR_LAST_NAME,
            questionControlType: 'textbox',
            value: [true]
          }
        ]
      },
    ]
    questions.push(...this.getSponsorshipDetailPanels(appData));

    return questions;
  }

  private getSponsorshipDetailPanels(appData: ApplicationAnswers): Question<string>[] {
    const sponsoredPersons = SponsorshipMappingUtil.extractSponsoredPersons('applicant.label', appData);
    const sponsorshipDetailPanels: Question<string>[] = [];
    if (sponsoredPersons && sponsoredPersons.length) {
      const memberCount = SponsorshipMappingUtil.getNumberOfMembersInApplication(appData);
      sponsoredPersons.forEach((person, index) => {
        sponsorshipDetailPanels.push(...this.getSponsorshipDetailPanel((sponsoredPersons?.length > 1),
          person, index, memberCount));
      })
    }
    return sponsorshipDetailPanels;
  }

  private getSponsorshipDetailPanel(multiplePersons: boolean,
        person: {key: string, param: {name: string, index?: number}}, index: number, memberCount: number): Question<string>[] {
    const text = this.translator.instant(person.param.name, {name: person.param.name});
    let applicantType = '';
    let heading = '';
    if(person.key==='applicant.label.applicant'){
      applicantType = 'sponsorship.multiple.applicant.amount.sponsor.pay';
      heading = this.APPLICANT_PERSON_HEADING;
    }else if(person.key==='applicant.label.spouse'){
      applicantType = 'sponsorship.multiple.spouse.amount.sponsor.pay';
      heading = this.SPOUSE_PERSON_HEADING;
    }else if(person.key==='applicant.label.child'){
      applicantType = 'sponsorship.multiple.child.amount.sponsor.pay';
      heading = this.CHILD_PERSON_HEADING;
    }
    // If there are multiple sponsored members, it returns 2 sets of panels (different and same sponsor).
    if (multiplePersons) {
      return [{
        controlType: 'panel',
        key: FormMapping.DIFFERENT_SPONSOR_DETAIL_PANEL_ELEMENT + index,
        needMarginTop: true,
        displayInNewPanel: true,
        displayWithInnerCard: true,
        visibleOnConditions: [{
          questionKey: FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR,
          questionControlType: 'radio',
          value: ['no']
        }],
        panels: [{
          name: FormMapping.DIFFERENT_SPONSOR_DETAIL_PANEL_ELEMENT + index,
          heading1: heading,
          heading1Param: {name: person.param.name},
          questions: this.getSponsorshipDetailQuestions(false, [text], memberCount, applicantType, person),
          showAddButton: false,
          showLineSeparator: false
        }]
      },
      {
        controlType: 'panel',
        key: FormMapping.SAME_SPONSOR_DETAIL_PANEL_ELEMENT + index,
        needMarginTop: true,
        displayInNewPanel: true,
        displayWithInnerCard: true,
        visibleOnConditions: [{
          questionKey: FormDataMapping.Root.ALL_HAVE_SAME_SPONSOR,
          questionControlType: 'radio',
          value: ['yes']
        }],
        panels: [{
          name: FormMapping.SAME_SPONSOR_DETAIL_PANEL_ELEMENT + index,
          heading1: heading,
          heading1Param: {name: person.param.name},
          questions: this.getSponsorshipDetailQuestions(true, [text], memberCount, applicantType, person),
          showAddButton: false,
          showLineSeparator: false
        }]
      }];
    } else {  // Single panel for single sponsored member
      return [{
        controlType: 'panel',
        key: FormMapping.SPONSOR_DETAIL_PANEL_ELEMENT,
        needMarginTop: true,
        displayInNewPanel: true,
        displayWithInnerCard: true,
        panels: [{
          name: FormMapping.SPONSOR_DETAIL_PANEL_ELEMENT,
          heading1: heading,
          heading1Param: {name: person.param.name},
          questions: this.getSponsorshipDetailQuestions(false, [text], memberCount, applicantType, person),
          showAddButton: false,
          showLineSeparator: false
        }]
      }];
    }
  }

  /**
   * Construct the set of question based on the type panel, and the member count.
   * @param sameSponsor Type of panel that the questions are being displayed.
   * @param labelParams List of parameters for the support amount label.
   * @param memberCount Number of sponsored members in the application.
   * @private
   */
  // tslint:disable-next-line:max-line-length
  private getSponsorshipDetailQuestions(sameSponsor: boolean, labelParams: [string], memberCount: number, applicantType: string, sponsoredPerson: {}): Question<string>[] {
    const questions: Question<string>[] = [];
    if (!sameSponsor) {
      questions.push(...[
        {
          controlType: 'textbox',
          key: FormDataMapping.Sponsor.SPONSOR_FIRST_NAME,
          label: 'sponsorship.sponsor.first.name',
          capitalizeFirstLetter: true,
          required: true,
          needMarginTop:true,
          characterWidth: 20,
          validators: [
            {
              type: 'required',
              errorKey: 'error.required'
            },
            {
              type: 'maxLength',
              maxLengthValue: 65,
              errorKey: 'housing-situation.error.invalid.livingWithYouFirstName.length'
            },
            {
              type: 'validatorFn',
              validatorFn: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
              errorKey: 'error.invalid.firstNameWithOnlyCharacters'
            },
            {
              type: 'validatorFn',
              validatorFn: SadaCustomValidator.validateSponsorName,
              errorKey: 'sponsorship.sponsor.invalid.last.name'
            }
          ],
        },
        {
          controlType: 'textbox',
          key: FormDataMapping.Sponsor.SPONSOR_LAST_NAME,
          label: 'sponsorship.sponsor.last.name',
          capitalizeFirstLetter: true,
          resetWhenDisabled: true,
          required: true,
          needMarginTop:true,
          characterWidth: 20,
          validators: [
            {
              type: 'required',
              errorKey: 'error.required'
            },
            {
              type: 'maxLength',
              maxLengthValue: 65,
              errorKey: 'housing-situation.error.invalid.livingWithYouFirstName.length'
            },
            {
              type: 'validatorFn',
              validatorFn: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
              errorKey: 'error.invalid.lastNameWithOnlyCharacters'
            },
            {
              type: 'validatorFn',
              validatorFn: SadaCustomValidator.validateSponsorName,
              errorKey: 'sponsorship.sponsor.invalid.last.name'
            }
          ],
        },
        {
          controlType: 'checkbox',
          key: FormDataMapping.Sponsor.SPONSOR_NO_LAST_NAME,
          resetWhenDisabled: true,
          options: [{
            value: false,
            label: 'sponsorship.sponsor.no.last.name'
          }],
          disableOthersOnCondition:[
            {
              questionKey: FormDataMapping.Sponsor.SPONSOR_LAST_NAME,
              questionControlType: 'textbox',
              value: [true]
            }
          ]
        }
      ]);
    }
    questions.push(...[
      {
        controlType: 'textbox',
        key: FormDataMapping.Sponsor.SPONSOR_SUPPORT_AMOUNT,
        label: memberCount === 1 ? 'sponsorship.single.amount.sponsor.pay' : applicantType,
        isDollarValue: true,
        labelParams,
        required: true,
        needMarginTop:true,
        characterWidth: 10,
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'min',
            minimumValue: 0,
            errorKey: 'error.invalid.min.amount'
          },
          {
            type: 'maxNumericLength',
            maxLengthValue: amountMaxLength,
            errorKey: 'error.invalid.max.amount'
          },
          {
            type: 'pattern',
            regex: positiveCurrencyPattern,
            errorKey: 'error.invalid.currency.amount'
          },
        ]
      },
      {
        controlType: 'radio',
        key: FormDataMapping.Sponsor.SPONSOR_LIVES_WITH,
        label: 'sponsorship.live.with.you',
        required: true,
        needMarginTop:true,
        context: 'sponsorship.live.with.you.context',
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
        ]
      },
      {
        controlType: 'odsdate',
        key: FormDataMapping.Sponsor.SPONSOR_END_DATE,
        label: 'sponsorship.end.date',
        required: false,
        skipContainerLabel: true,
        needMarginTop: true,
        dateSmallRightMargin: true,
        showDay: true,
        customHintText: 'sponsorship.end.date.hintText',
        validatorFns: [
          {
            validationFunction: (value: []) => {
              return SadaCustomValidator.isValidDate(value) &&
                SadaCustomValidator.validateDateWithMin([...value, sponsoredPerson['param']['dob']]);
            },
            errorKey: 'sponsorship.error.invalid.date'
          },
          {
            validationFunction: (value: []) => {
              return SadaCustomValidator.isValidDate(value) &&
                SadaCustomValidator.validateDateWithMin([...value, sponsoredPerson['param']['arrival']]);
            },
            errorKey: 'sponsorship.error.invalid.date'
          },
          // this validation is enough as it supersedes both dob and arrival date validations which are obviously in the past.
          // the validations are separated as we may have custom error messages in the future.
          {
            validationFunction: (value: []) => {
              return SadaCustomValidator.isValidDate(value) &&
                !SadaCustomValidator.validatePast(value);
            },
            errorKey: 'sponsorship.error.invalid.date'
          },
        ]
      }
    ]);

    return questions;
  }
}
