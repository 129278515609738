import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {PostalService} from './postal.service';

@Injectable({
  providedIn: 'root'
})
export class PostalServiceImpl implements PostalService {
  constructor(private http: HttpClient) {
  }

  validatePostalCode(postalCode: string, resultCallback: any) {
    return this.http.get(
      `/v1/validation/postal-code/${postalCode}`
    ).subscribe(
      (data) => resultCallback(data),
      () => resultCallback(false)
    )
  }
}
