<div id="address-text">
  <div *ngIf="!address.text">
    <div>
      <span *ngIf="address.unitNumber"><span>{{'address-display.unitNumberLabel' | translate}}</span> # {{address.unitNumber}}</span>
    </div>
    <div>
      <span *ngIf="address.streetNumber">{{address.streetNumber}} </span>
      <span *ngIf="address.streetNumberSuffix">{{address.streetNumberSuffix}} </span>
      <span *ngIf="address.streetName">{{address.streetName}} </span>
      <span *ngIf="address.streetDisplayType">{{address.streetDisplayType}} </span>
      <span *ngIf="address.streetDirection">{{address.streetDirectionDisplay}} </span>
    </div>
    <div>
      <span *ngIf="address.routeNumber">RR {{address.routeNumber}} </span>
      <span *ngIf="address.boxNumber">PO Box {{address.boxNumber}} </span>
      <span *ngIf="address.deliveryInstallationType">{{address.deliveryInstallationType}} </span>
      <span *ngIf="address.deliveryInstallationName">{{address.deliveryInstallationName}} </span>
      <span *ngIf="address.deliveryInstallationArea">{{address.deliveryInstallationArea}}</span>
    </div>
    <div>{{address.municipality}}, {{address.province}}</div>
    <div>{{address.postalCode}}</div>
  </div>

  <div *ngIf="!!address.text">
    <div>
      <strong>{{address.text | translate}}</strong>
    </div>
  </div>
</div>
