import {Observable} from 'rxjs';

export abstract class BankingService {

  abstract validateBankDetails(institutionNumber: string, transitNumber: string, resultCallback: any)

  abstract validateInstitutionBranchNumber(institutionNumber: string, transitNumber: string): Observable<boolean>

  abstract setManualTriggerValidation(manual:boolean):void

  abstract isManualTriggerValidation():boolean

}
