<div *ngIf="panel?.showSpace" class="ontario-margin-top-40-!"></div>
<div *ngIf="panel?.showLineSeparator" class="grey-background ontario-padding-top-8-! ontario-padding-bottom-8-!">
  <hr class="hr">
</div>

<div [formGroup]="form">
  <div [ngClass]="{'grey-background': !(panel.text || panel.heading1 || panel.heading2 || checkboxPanelBorders),
                   'ontario-padding-bottom-8-!': !(panel.text || panel.heading1 || panel.heading2 || checkboxPanelBorders),
                   'panel-container' : (panel.text || panel.heading1 || panel.heading2), 'single-checkbox-borders':(checkboxPanelBorders)}">
  <ng-container formArrayName="panels">

      <div [formGroupName]="panelIndex" *ngFor="let panelForm of panelsFormGroup; let panelIndex = index; trackBy: trackByFn">

        <div [ngClass]="{'indented-row': panel.indented }">
          <div class="ontario-label" *ngIf="panelIndex === 0 && panel.heading1">{{panel.heading1 | translate:panel.heading1Param}}</div>

          <div *ngIf="panel.heading2" class="ontario-label">{{panel.heading2 | translate}} {{' '.concat((panelIndex+ 1).toString())}}</div>

          <div *ngIf="alertMessageKeys[panelIndex]?.key" class="alert mccssWarning">
            <span id="{{id}}{{panelIndex}}-alert" role="alert">{{alertMessageKeys[panelIndex].key | translate}}</span>
          </div>

          <p *ngIf="panel.text" [innerHTML]="panel.text | translate"></p>

          <div *ngFor="let question of questions; let i = index; trackBy: trackByFn"
               [ngClass]="{'indented-form-row': question.indentedRow }">
            <sd-dynamic-question *ngIf="isVisible(question,panelIndex)" [showError]="showError" [question]="question"
                                 [pageQuestions]="questions"
                                 [panelIndex]="panelIndex" [panelName]="panel.name"
                                 [disabled]="isDisabled(question, panelIndex)"
                                 [form]="panelForm"></sd-dynamic-question>
          </div>

          <button *ngIf="panels.length > 1" [id]="'remove-panel-' + panelIndex" class="ontario-button ontario-button--tertiary ontario-margin-top-24-! remove-item-text-align-left" type="button" (click)="removePanel(panelIndex)">{{panel.removeButton | translate}}</button>
        </div>
        <hr *ngIf="isAddButtonVisible" class="hr">

      </div>

  </ng-container>

  <button *ngIf="isAddButtonVisible" id="add-panel" class="ontario-button ontario-button--secondary" type="button" (click)="addPanel()">{{panel.addButton | translate}}</button>
  </div>
</div>
