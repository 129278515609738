import {Injectable} from '@angular/core';
import {ExternalRouter} from '../external.router';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from './config.service';
import {RedirectService} from './redirect.service';
import {PageInfo} from '../models/page-map';
import {Router} from '@angular/router';
import {UrlInfo} from '../models/url-map';

@Injectable({
  providedIn: 'root'
})
export class RedirectServiceImpl implements RedirectService {

  constructor(private externalRouter: ExternalRouter, private translateService: TranslateService, private configService: ConfigService,
              private router: Router) {
  }

  redirectToMyB(errorCode?: string) {
    let myBUrl = this.configService.getUrl(this.translateService.currentLang, UrlInfo.myBenefitsLogin);
    myBUrl = myBUrl.substring(0, myBUrl.indexOf('?'));          // remove lang query param temporarily

    const langStr = '?lang=' + this.translateService.currentLang + '-CA'; // determine en or fr

    let redirectUrl = myBUrl;

    if (errorCode) {
      redirectUrl = redirectUrl + '/' + errorCode;
    }

    redirectUrl = redirectUrl + langStr
    this.externalRouter.navigate(redirectUrl, false);
  }

  redirectToLandingPage(errorCode?: string) {
    const queryParams = errorCode ? {queryParams: {errorCode}} : {};
    this.router.navigate(['/', 'intake', PageInfo.home], queryParams)
  }

  redirectToGlobalErrorPage(errorCode?: string) {
    const queryParams = errorCode ? {queryParams: {errorCode}} : {};
    this.router.navigate(['/', PageInfo.globalError], queryParams)
  }
}
