import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config.service';
import {UrlInfo} from '../../../models/url-map';

@Component({
  selector: 'sd-alert-error-box',
  templateUrl: './alert-err-box.component.html',
  styleUrls: ['./alert-err-box.component.scss']
})
export class AlertErrBoxComponent implements OnInit {
  @Input()
  errorHeader: string | undefined
  @Input()
  errorBody: string | undefined

  linkParam: any;

  constructor(private translator: TranslateService, public configService: ConfigService){}

  ngOnInit(): void {
    this.translator.onLangChange.subscribe((lang) => {
      this.linkParam = this.configService.getUrl(this.translator.currentLang, UrlInfo.socialAssistance);
    });
  }
}
