import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PageBaseComponent} from '../PageBaseComponent';
import {IntakeService} from '../../services/intake.service';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ExternalRouter} from '../../external.router';
import {catchError, tap} from 'rxjs/operators';
import {ConfigService} from '../../services/config.service';
import {ScheduledMaintenanceService} from '../../services/scheduled.maintenance.service';
import {of} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {PageInfo} from '../../models/page-map';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {SessionStorageService} from '../../services/session-storage.service';
import {SadaErrorCodes} from '../../utils/sada-error-codes';
import {UrlInfo} from '../../models/url-map';
import {MaintenanceBannerConfiguration} from '../../models/MaintenanceBannerConfiguration';
import {getBannerClass, getBannerIcon, isPastDateCheck} from '../../utils/outage-banner-util';

@Component({
  selector: 'sd-before-you-start-one',
  templateUrl: './before-you-start-one.component.html',
  styleUrls: ['./before-you-start-one.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BeforeYouStartOneComponent extends PageBaseComponent implements OnInit, OnDestroy {
  showError = false;

  banners: MaintenanceBannerConfiguration[] = [];
  existingSessionWarning: boolean;
  psSignInUrl: any;
  errorHeader: string;
  errorCode: string;

  applicationStatusLink: string;
  benefitsFinderLink: string;
  contactUsSendEmailLink: string;
  myBenefitsLink: string;
  odspLink: string;
  officeLocatorLink: string;
  ontarioWorksSectionLink: string;
  privacySecurityLearnMoreLink: string;
  suspectFraudLink: string;

    constructor(private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              private cdr: ChangeDetectorRef,
              protected configService: ConfigService,
              private schMaintenanceService: ScheduledMaintenanceService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              private sessionStorageService: SessionStorageService) {
      super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
        loadingSpinnerService);
      this.pageId = PageInfo.home;
      this.setupLinks();
    }

  ngOnInit(): void {
    this.existingSessionWarning = this.authService.isActiveSessionExists() && !this.sessionStorageService.getStartedTime();

    this.initializeForm();

    this.schMaintenanceService.getBannerConfig().pipe(
      tap( res => {
        this.banners = res.banners
          .filter(banner =>
            banner.enable &&
            isPastDateCheck(banner.postStart, banner.postEnd) &&
            banner.shownOnLanding
          )
          .sort((a, b) => a.order - b.order);
      }),
      catchError( err => {
        // ignore all errors
        return of(err)
      })
    ).subscribe();

    this.translator.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setupLinks();
    });
    this.handleErrorDisplay();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onSubmit(): void {
    this.saveForm()?.subscribe(() => {
        this.sessionStorageService.saveStartedTime();
        this.router.navigate(['/', 'intake',PageInfo.bya])
      }
    );
  }

  private setupLinks(): void {
    this.applicationStatusLink = '/applicationstatuschecker';
    this.benefitsFinderLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.benefitsFinder);
    this.contactUsSendEmailLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.contactUs);
    this.myBenefitsLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.myBenefitsLogin);
    this.odspLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.applyForOdsp);
    this.officeLocatorLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation);
    this.ontarioWorksSectionLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.ontarioWorksSection);
    this.privacySecurityLearnMoreLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.securityLearn);
    this.suspectFraudLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.suspectFraud);
  }

  postInitializeForm(){}

  preSaveApplication() {
    // initialize address data first time
    if (!this.applicationAnswers.jsonData.addressSame
      // add this checking in case user coming back from review or switch language
      && !this.applicationAnswers.jsonData.deliveryType
      && !this.applicationAnswers.jsonData.mDeliveryType) {
      this.applicationAnswers.jsonData = { ...this.applicationAnswers.jsonData, ...InitialAddressData};
    }
  }

  getBannerClass(type: string): string {
      return getBannerClass(type)
  }

  getBannerIcon(type: string): string {
    return getBannerIcon(type)
  }

  handleErrorDisplay() {
    this.subscriptions$.push(this.route.queryParams.subscribe(params => {
      if (params?.errorCode) {
        if (params?.errorCode === SadaErrorCodes.AUTHEC09) {
          this.existingSessionWarning = true;
        } else {
          this.errorCode = params?.errorCode;
          if (SadaErrorCodes.AUTHEC01 === params?.errorCode) {
            this.errorHeader = 'global-error.page.title';
          } else {
            this.errorHeader = 'global-error.error';
          }
          // Error message for AUTHEC01 and AUTHEC05 needs PS URL
          if (SadaErrorCodes.AUTHEC01 === params?.errorCode || SadaErrorCodes.AUTHEC05 === params?.errorCode) {
            this.authService.getPSRedirectURL('', this.translator.currentLang)
              .subscribe(url => {
                this.psSignInUrl = {psSignInUrl: url};

                // Get the URL again if the language changes. This needs to be done because the new lang needs to be added to the state
                // in the PS URL.
                this.subscriptions$.push(this.translator.onLangChange.subscribe((lang) => {
                  this.authService.getPSRedirectURL('', lang.lang)
                    .subscribe(urlWithChangedLang => {
                      this.psSignInUrl = {psSignInUrl: urlWithChangedLang};
                    })
                }));
              }, error => {
                // Redirect to global error with Auth service error
                this.router.navigate(['/', PageInfo.globalError], {queryParams: {errorCode: 'AUTHEC01'}});
              })
          }
        }
      }
    }));
  }
}

export const InitialAddressData = {
  deliveryType: 'Standard street address',
  province: 'Ontario',
  addressSame: [
    'yes'
  ],
  mProvince: 'Ontario'
}
