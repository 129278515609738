<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [customPageTitleKey]="getPageTitleKey()"
                   [disableScrollEvent]="true"
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <form [formGroup]="form" sdDisableAutocomplete>
    <div class="form-group required">
      <sd-dynamic-container [id]="'additional-information.select.all'" [showError]="showError" [questions]="questions">
      </sd-dynamic-container>
    </div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
