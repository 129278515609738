import {Observable} from 'rxjs';
import {ApplicationValidationResponse} from '../models/application-validation-response';
import {LoginConfirmationRequest} from '../models/login-confirmation-request';

export abstract class ValidationService {
  abstract validateSocialInsuranceNumber(sin: string, resultCallback: any);
  abstract validateHealthCardNumber(healthCardNumber: string, resultCallback: any);
  abstract validate(uri: string, payload: string): Observable<boolean>;
  abstract validateApplication(): Observable<ApplicationValidationResponse>;
  abstract confirmLogin(loginConfirmationRequest: LoginConfirmationRequest): Observable<boolean>;
}
