import {ApplicationAnswers} from '../../models/data.model';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';

export const APPLICANT = 'Applicant';
export const SPOUSE = 'Spouse';
export const CHILD_PREFIX= 'Child';
export const NONE= 'None';

export function getChildrenYoungerThan18(applicationAnswers: ApplicationAnswers): any[] {
  if (applicationAnswers && applicationAnswers.jsonData?.childList) {
    return applicationAnswers.jsonData.childList.filter((child) =>
      !SadaCustomValidator.validateOverAge(child.childDateOfBirth, 18)
    );
  } else {
    return [];
  }
}

export function isChildrenYoungerThan18(child: any): boolean {
  return !SadaCustomValidator.validateOverAge(child.childDateOfBirth, 18)
}
