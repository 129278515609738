import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {ExternalRouter} from '../../external.router';
import {PageInfo} from '../../models/page-map';
import {UrlInfo} from '../../models/url-map';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-something-went-wrong',
  templateUrl: './something-went-wrong.component.html',
  styleUrls: ['./something-went-wrong.component.scss']
})
export class SomethingWentWrongComponent implements OnInit {
  pageId: string;

  constructor(private externalRouter: ExternalRouter, private translator: TranslateService, public configService: ConfigService) {
    this.pageId = PageInfo.somethingWentWrong;
  }

  ngOnInit(): void {
  }

  onGoHomeClicked() {
    const url = this.configService.getUrl(this.translator.currentLang, UrlInfo.socialAssistance);
    this.externalRouter.navigate(url);
  }
}
