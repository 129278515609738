import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {AuthService} from './services/auth.service';
import {ExternalRouter} from './external.router';
import {LoadingSpinnerService} from './services/loading-spinner.service';
import {CaseWorkerAuthorizeService} from './services/caseWorkerAuthorizeService';
import {RouteStateService} from './services/route-state.service';

@Component({
  selector: 'sd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  private readonly _destroying$ = new Subject<void>()

  loading = false
  isLandingPage: boolean;
  isErrorOrInfoPage: boolean = false;
  finished = false

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private routeStateService: RouteStateService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private externalRouter: ExternalRouter,
    public loadingSpinnerService: LoadingSpinnerService,
    private caseWorkerAuthorizeService: CaseWorkerAuthorizeService
    ) {

    router.events
        .pipe(takeUntil(this._destroying$),
            filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent))
        .subscribe((routerEvent: Event | RouterEvent) => {
          const navigationEndEvent = routerEvent as NavigationEnd;
          const parts = navigationEndEvent.url?.split('/');
          if (parts) {
            if (!parts.some(v => ['eid', 'esignature'].includes(v))) {
              this.updateLoadingSpinnerStatus(navigationEndEvent);
            }

            this.isLandingPage = parts[3] === 'home-page' || parts[1] === '';
          }

          if (routerEvent instanceof NavigationStart) {
            // Done on each page to make sure the authentication is synced in case it happened in another tab
            this.caseWorkerAuthorizeService.syncAuthorization();
          }
        });

    this.routeStateService.isErrorOrInfoPage$
      .pipe(takeUntil(this._destroying$))
      .subscribe(isErrorOrInfoPage => {
        this.isErrorOrInfoPage = isErrorOrInfoPage;
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  updateLoadingSpinnerStatus(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.loadingSpinnerService.show();
      this.finished = false;
    }

    setTimeout(()=> {
      if (!this.finished && routerEvent instanceof NavigationStart) {
        this.loadingSpinnerService.show(true);
      }
    },8000)

    if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
      this.loadingSpinnerService.hide();
      this.finished = true;
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
